import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import * as yup from "yup";
import { isAnnual, isDollar } from "../../../Common/Utils";
import axiosInstance from "../../../config/axios-config";

const DEVURL = process.env.REACT_APP_ANC_API + "/api/GasStation";

const validationSchema = yup.object().shape({
  grossSale: yup
    .string()
    .trim()
    .required("Gross sale is required")
    .label("Gross sale"),
  gasolineAnnualSale: yup
    .string()
    .trim()
    .required("Gasoline gallons is required")
    .label("Gasoline gallons"),
  feinNum: yup
    .string()
    .trim()
    .nullable(true)
    .matches(/^\d{2}-\d{7,8}$/, "Please enter a valid fein")
    .label("FEIN"),
  isCarWash: yup.string().required("Car wash is required").label("Car wash"),
  termsCondition: yup
    .boolean()
    .test("termsCondition", "Terms & conditions is required", (val) => {
      return val;
    }),
});
const Step3 = ({ stepHandler, loader, details }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    watch,
    control,
  } = useForm({
    defaultValues: {
      grossSale: null,
      gasolineAnnualSale: null,
      cookingType: [],
      leasedOutSqft: null,
      annualPropaneSale: null,
      isCarWash: null,
      carWashRent: null,
      carWashAnnual: null,
      beerWineAnnualSale: null,
      gameMachineAnnualsale: null,
      coinLaundryAnnualSale: null,
      sqFtOfLeaseProperty: null,
      totalPayRoll: null,
      feinNum: null,
      numOfEmp: null,
      termsCondition: false,
    },
    resolver: yupResolver(validationSchema),
  });

  const [disableAdd, setDisableAdd] = useState(false);
  const [saleUnit, setSaleUnit] = useState("%");
  const [gasolineSaleUnit, setGasolineSaleUnit] = useState("Monthly");

  const [isLoading, setLoading] = useState(false);

  const clearLocalStorage = () => {
    localStorage.removeItem("step3");
    localStorage.removeItem("step1");
    localStorage.removeItem("step2");
  };
  const fillupStep3GasStationInfoFromLocalStorage = (formData) => {
    setValue("grossSale", formData?.grossSale);
    if (isAnnual(formData?.gasolineAnnualSale)) {
      setGasolineSaleUnit("Annual");
    } else {
      setGasolineSaleUnit("Monthly");
    }
    setValue("gasolineAnnualSale", formData?.gasolineAnnualSale);
    setValue("cookingType", formData?.cookingType);
    setValue("leasedOutSqft", formData?.leasedOutSqft);
    setValue("annualPropaneSale", formData?.annualPropaneSale);
    setValue("carWashRent", formData?.carWashRent);
    setValue("carWashAnnual", formData?.carWashAnnual);
    if (isDollar(formData?.beerWineAnnualSale)) {
      setSaleUnit("$");
    } else {
      setSaleUnit("%");
    }
    setValue("beerWineAnnualSale", formData?.beerWineAnnualSale);
    setValue("gameMachineAnnualsale", formData?.gameMachineAnnualsale);
    setValue("coinLaundryAnnualSale", formData?.coinLaundryAnnualSale);
    setValue("sqFtOfLeaseProperty", formData?.sqFtOfLeaseProperty);
    setValue("totalPayRoll", formData?.totalPayRoll);
    setValue("feinNum", formData?.feinNum);
    setValue("numOfEmp", formData?.numOfEmp);
    setValue("termsCondition", formData?.termsCondition);
    setValue("isCarWash", formData?.isCarWash);
  };
  useEffect(() => {
    const formData = JSON.parse(localStorage.getItem("step3"));
    if (formData) {
      fillupStep3GasStationInfoFromLocalStorage(formData);
    }
  }, []);

  const creategasStationInfoObj = (gasStationInfo) => {
    let gasStationToCreate = {
      customerInfo: {
        agencyId: gasStationInfo.agencyId,
        agentId: gasStationInfo.agentId,
        customerId: gasStationInfo.customerId,
        firstName: gasStationInfo.firstName,
        lastName: gasStationInfo.lastName,
        locationAddress: gasStationInfo.locationAddress,
        locationCity: gasStationInfo.locationCity,
        locationZip: gasStationInfo.locationZip,
        locationState: gasStationInfo.locationState,
        mailingAddress: gasStationInfo.mailingAddress,
        mailingCity: gasStationInfo.mailingCity,
        mailingZip: gasStationInfo.mailingZip,
        mailingState: gasStationInfo.mailingState,
        businessName: gasStationInfo.businessName,
        dbaname: gasStationInfo.dbaname,
        hoursOfOperation: gasStationInfo.hoursOfOperation,
        typeOfBusiness: gasStationInfo?.businessType,
        mobileNum: gasStationInfo.mobileNum,
        workNum: gasStationInfo.workNum,
        email: gasStationInfo.email,
        faxNum: "",
        termsCondition: gasStationInfo.termsCondition?.toString(),
      },
      gasstationinfo: {
        yearOfBuild: gasStationInfo.yearOfBuild,
        noOfBuilding: gasStationInfo.noOfBuilding,
        buildingValue: gasStationInfo.buildingValue,
        pumpValue: gasStationInfo.pumpValue,
        gasstationId: gasStationInfo.gasstationId,
        lastRoofUpdate: gasStationInfo.lastRoofUpdate,
        lastWireUpdate: gasStationInfo.lastWireUpdate,
        lastPlumbingUpdate: gasStationInfo.lastPlumbingUpdate,
        lastHeatUpdate: gasStationInfo.lastHeatUpdate,
        isAutomaticShutPump: gasStationInfo.isAutomaticShutPump,
        canopyValue: gasStationInfo.canopyValue,
        propaneSale: gasStationInfo.propaneSale,
        moneyOrder: gasStationInfo.moneyOrder,
        signValue: gasStationInfo.signValue,
        protectiveSafeguard: gasStationInfo.protectiveSafeguard,
        securityLimit: gasStationInfo.securityLimit,
        priorLossAmt: gasStationInfo.priorLossAmt,
        atmMachineFlag: gasStationInfo.atmMachineFlag,
        bppLimit: gasStationInfo.bppLimit,
        numOfPump: gasStationInfo.numOfPump,
        isCheckCashing: gasStationInfo.isCheckCashing,
        sprinkerType: gasStationInfo.sprinkerType,
        lossRunYear: gasStationInfo.lossRunYear,
        anyClaim: gasStationInfo.anyClaim,
        claimType: gasStationInfo.claimType,
        claimDate: gasStationInfo.claimDate,
        coverage: gasStationInfo.coverage,
        effectiveDate: gasStationInfo.effectiveDate,
        grossSale: gasStationInfo.grossSale,
        gasolineAnnualSale: gasStationInfo.gasolineAnnualSale,
        cookingType: gasStationInfo.cookingType,
        leasedOutSqft: gasStationInfo.leasedOutSqft,
        annualPropaneSale: gasStationInfo.annualPropaneSale,
        isCarWash: gasStationInfo.isCarWash,
        carWashRent: gasStationInfo.carWashRent,
        carWashAnnual: gasStationInfo.carWashAnnual,
        beerWineAnnualSale: gasStationInfo.beerWineAnnualSale,
        gameMachineAnnualsale: gasStationInfo.gameMachineAnnualsale,
        coinLaundryAnnualSale: gasStationInfo.coinLaundryAnnualSale,
        sqFtOfLeaseProperty: gasStationInfo.sqFtOfLeaseProperty,
        totalPayRoll: gasStationInfo.totalPayRoll,
        feinNum: gasStationInfo.feinNum,
        numOfEmp: gasStationInfo.numOfEmp,
        hoursOfOperation: gasStationInfo.hoursOfOperation,
        gasstationinfocol: "to do",
      },
    };
    return gasStationToCreate;
  };

  const handleFormSubmit = (data) => {
    localStorage.setItem("step3", JSON.stringify(data));
    const step1 = localStorage.getItem("step1");
    const step2 = localStorage.getItem("step2");
    const step3 = localStorage.getItem("step3");
    const gasStationInfo = {
      ...JSON.parse(step1),
      ...JSON.parse(step2),
      ...JSON.parse(step3),
    };

    const gasStationInfoObj = creategasStationInfoObj(gasStationInfo);

    Swal.fire({
      title: "Are you sure you want to submit the form?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        axiosInstance
          .put(
            `${DEVURL}/UpdateGasStationApp/${details?.businessCustomer?.customerId}/${details?.gasStation?.gasstationId}`,
            gasStationInfoObj
          )
          .then((res) => {
            if (res.status === 200) {
              setLoading(false);
              clearLocalStorage();
              reset();
              stepHandler(4);
              setTimeout(() => {
                stepHandler(1);
              }, 3000);
            }
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
          });
      } else {
        setLoading(false);
        return;
      }
    });
  };

  const handleFeinChange = (e) => {
    console.log(e.keyCode);
    let inputValue = e.target.value;
    inputValue = inputValue.replace(/[^0-9]/g, "");
    if (inputValue.length > 2) {
      inputValue = inputValue.slice(0, 2) + "-" + inputValue.slice(2);
    }
    const name = e.target.name;
    setValue(name, inputValue);
  };

  if (isLoading) {
    return loader();
  }
  return (
    <>
      <form
        onSubmit={handleSubmit(handleFormSubmit)}
        className="tab-wizard wizard-circle wizard needs-validation"
      >
        <section>
          <h4 class="wrap-title mb-3">Annual Sales</h4>

          <div class="row">
            <div class="col-md-6 col-lg-4">
              <div class="mb-3">
                <label class="brand-label">
                  Gross Sales?<span className="required-mark">*</span>
                </label>
                <Controller
                  name="grossSale"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      {...field}
                      id="grossSale"
                      className="form-control"
                      thousandSeparator={true}
                      prefix="$"
                      name="grossSale"
                      placeholder="Enter Gross Sales"
                      onValueChange={(values) => {
                        setValue("grossSale", values.floatValue);
                      }}
                      {...register("grossSale")}
                    />
                  )}
                />
                <p className="text-danger">
                  {errors.grossSale && <p>{errors.grossSale.message}</p>}
                </p>
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="mb-3">
                <label class="brand-label">
                  Gasoline (Annual Gallons)
                  <span className="required-mark">*</span>
                </label>
                <div class="input-group mb-3">
                  <Controller
                    name="gasolineAnnualSale"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <NumericFormat
                        allowNegative={false}
                        {...field}
                        id="gasolineAnnualSale"
                        className="form-control"
                        thousandSeparator={true}
                        suffix={
                          gasolineSaleUnit === "Annual" ? " Annual" : " Monthly"
                        }
                        name="gasolineAnnualSale"
                        onValueChange={(values) => {
                          setValue("gasolineAnnualSale", values.formattedValue);
                        }}
                        placeholder={`Enter Gallons ${
                          gasolineSaleUnit === "Annual"
                            ? "(e.g., 3344 Annual)"
                            : "(e.g., 3344 Monthly)"
                        }`}
                      />
                    )}
                  />
                  <button
                    className="btn  dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={{
                      backgroundColor: "transparent",
                      color: "#212529",
                      boxShadow: "none",
                      border: "1px solid #ced4da",
                    }}
                  >
                    {gasolineSaleUnit}
                  </button>
                  <ul className="dropdown-menu dropdown-menu-end">
                    <li>
                      <button
                        type="button"
                        className="dropdown-item"
                        onClick={() => setGasolineSaleUnit("Annual")}
                      >
                        Annual
                      </button>
                    </li>
                    <li>
                      <button
                        type="button"
                        className="dropdown-item"
                        onClick={() => setGasolineSaleUnit("Monthly")}
                      >
                        Monthly
                      </button>
                    </li>
                  </ul>
                </div>

                <p className="text-danger">
                  {errors.gasolineAnnualSale && (
                    <p>{errors.gasolineAnnualSale.message}</p>
                  )}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              {" "}
              <div class="mb-3">
                <label class="brand-label d-grid">Annual Propane Sales?</label>
                <Controller
                  name="annualPropaneSale"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      {...field}
                      id="annualPropaneSale"
                      className="form-control"
                      thousandSeparator={true}
                      prefix="$"
                      name="annualPropaneSale"
                      onValueChange={(values) => {
                        setValue("annualPropaneSale", values.floatValue);
                      }}
                      placeholder="Enter Annual Propane Sales"
                      {...register("annualPropaneSale")}
                    />
                  )}
                />
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="mb-3">
                <label class="brand-label d-grid">
                  Annual Sales Of Coin Laundry?
                </label>
                <Controller
                  name="coinLaundryAnnualSale"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      {...field}
                      id="coinLaundryAnnualSale"
                      className="form-control"
                      thousandSeparator={true}
                      prefix="$"
                      name="coinLaundryAnnualSale"
                      onValueChange={(values) => {
                        setValue("coinLaundryAnnualSale", values.floatValue);
                      }}
                      placeholder="Please Enter Annual Sales"
                      {...register("coinLaundryAnnualSale")}
                    />
                  )}
                />
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="mb-3">
                <label class="brand-label d-grid">
                  Sq. Ft. For Restaurant, If Leased Out?
                </label>

                <Controller
                  name="leasedOutSqft"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      {...field}
                      id="leasedOutSqft"
                      className="form-control"
                      thousandSeparator={true}
                      // prefix="$"
                      name="leasedOutSqft"
                      onValueChange={(values) => {
                        setValue("leasedOutSqft", values.floatValue);
                      }}
                      placeholder="Enter Sq.Ft Of Restaurant"
                      {...register("leasedOutSqft")}
                    />
                  )}
                />
              </div>
            </div>

            <div class="col-md-6 col-lg-4">
              <div class="mb-3">
                <label class="brand-label d-grid">
                  Annual Sales Of Beer & Wine?
                </label>
                <div class="input-group mb-3">
                  <Controller
                    name="beerWineAnnualSale"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <NumericFormat
                        allowNegative={false}
                        {...field}
                        id="beerWineAnnualSale"
                        className="form-control"
                        thousandSeparator={true}
                        prefix={saleUnit === "%" ? "" : "$"}
                        suffix={saleUnit === "%" ? "%" : ""}
                        name="beerWineAnnualSale"
                        onValueChange={(values) => {
                          console.log("values", values);
                          setValue("beerWineAnnualSale", values.formattedValue);
                        }}
                        placeholder={`Enter Annual Sales ${
                          saleUnit === "%" ? "(e.g., 44%)" : "(e.g., $3344)"
                        }`}
                      />
                    )}
                  />
                  <button
                    className="btn  dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={{
                      backgroundColor: "transparent",
                      color: "#212529",
                      boxShadow: "none",
                      border: "1px solid #ced4da",
                    }}
                  >
                    {saleUnit}
                  </button>
                  <ul className="dropdown-menu dropdown-menu-end">
                    <li>
                      <button
                        type="button"
                        className="dropdown-item"
                        onClick={() => setSaleUnit("%")}
                      >
                        %
                      </button>
                    </li>
                    <li>
                      <button
                        type="button"
                        className="dropdown-item"
                        onClick={() => setSaleUnit("$")}
                      >
                        $
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="col-md-6 col-lg-6">
              <div class="mb-3">
                <label class="brand-label d-grid">
                  Annual Sales Of Gaming Machine?
                </label>
                <Controller
                  name="gameMachineAnnualsale"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      {...field}
                      id="gameMachineAnnualsale"
                      className="form-control"
                      thousandSeparator={true}
                      prefix="$"
                      name="gameMachineAnnualsale"
                      onValueChange={(values) => {
                        setValue("gameMachineAnnualsale", values.floatValue);
                      }}
                      placeholder="Please Enter Annual Sales"
                      {...register("gameMachineAnnualsale")}
                    />
                  )}
                />
              </div>
            </div>
            <div class="col-md-6 col-lg-6">
              <div class="mb-3">
                <label class="brand-label d-grid">
                  Sq. Ft. Of The Leased Property?
                </label>
                <Controller
                  name="sqFtOfLeaseProperty"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      {...field}
                      id="sqFtOfLeaseProperty"
                      className="form-control"
                      thousandSeparator={true}
                      name="sqFtOfLeaseProperty"
                      onValueChange={(values) => {
                        setValue("sqFtOfLeaseProperty", values.floatValue);
                      }}
                      placeholder="Please Enter The Leased Property"
                      {...register("sqFtOfLeaseProperty")}
                    />
                  )}
                />
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="mb-3">
                <label class="brand-label d-grid">
                  <div>
                    Car Wash?<span className="required-mark">*</span>
                  </div>
                </label>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="carWashYes"
                    value="1"
                    {...register("isCarWash")}
                  />

                  <label class="form-check-label" for="carWashYes">
                    Yes
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="carwashNo"
                    value="0"
                    {...register("isCarWash")}
                  />
                  <label class="form-check-label" for="carwashNo">
                    No
                  </label>
                </div>
                <p className="text-danger">
                  {errors.isCarWash && <p>{errors.isCarWash.message}</p>}
                </p>
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="mb-3">
                <label class="brand-label d-grid">
                  Car Wash Rent, If Leased?
                </label>
                <Controller
                  name="carWashRent"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      {...field}
                      id="carWashRent"
                      className="form-control"
                      thousandSeparator={true}
                      prefix="$"
                      name="carWashRent"
                      onValueChange={(values) => {
                        setValue("carWashRent", values.floatValue);
                      }}
                      placeholder="Please Enter Rental Amount"
                      {...register("carWashRent")}
                    />
                  )}
                />
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="mb-3">
                <label class="brand-label d-grid">
                  Car Wash Annual Sales, If Owned?
                </label>
                <Controller
                  name="carWashAnnual"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      {...field}
                      id="carWashAnnual"
                      className="form-control"
                      thousandSeparator={true}
                      prefix="$"
                      name="carWashAnnual"
                      onValueChange={(values) => {
                        setValue("carWashAnnual", values.floatValue);
                      }}
                      placeholder="Please Enter Annual Sales"
                      {...register("carWashAnnual")}
                    />
                  )}
                />
              </div>
            </div>
            <div class="col-md-6 col-lg-12">
              <div class="mb-3">
                <label class="brand-label d-grid">
                  Cooking (if any cooking exposure)
                </label>
                <div className="row">
                  <div className="col-lg-6">
                    <div class="form-check ">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="limitedCookingCheck"
                        value="Limited Cooking"
                        {...register("cookingType")}
                      />
                      <label class="form-check-label" for="limitedCookingCheck">
                        Limited Cooking
                      </label>
                    </div>
                    <div class="form-check ">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="fullCookingCheck"
                        value="Full Cooking"
                        {...register("cookingType")}
                      />
                      <label class="form-check-label" for="fullCookingCheck">
                        Full Cooking
                      </label>
                    </div>
                    <div class="form-check ">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="fryerGrillingCheck"
                        value="Fryer or Grilling System"
                        {...register("cookingType")}
                      />
                      <label class="form-check-label" for="fryerGrillingCheck">
                        Fryer Or Grilling System?
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div class="form-check ">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="exhaustCheck"
                        value="Exhaust System in Kitchen"
                        {...register("cookingType")}
                      />
                      <label class="form-check-label" for="exhaustCheck">
                        Exhaust System In Kitchen
                      </label>
                    </div>
                    <div class="form-check ">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="seatingArrangementCheck"
                        value="Yes"
                        {...register("cookingType")}
                      />
                      <label
                        class="form-check-label"
                        for="seatingArrangementCheck"
                      >
                        Seating Arrangement?
                      </label>
                    </div>
                    <div class="form-check ">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="insuredCheck"
                        value="Yes"
                        {...register("cookingType")}
                      />
                      <label class="form-check-label" for="insuredCheck">
                        Does Restaurant Run By The Insured Or Leased Out?
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-check ">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="othersCheck"
                        value="Others"
                        {...register("cookingType")}
                      />
                      <label
                        className="form-check-label text-capitalize"
                        for="othersCheck"
                      >
                        Others
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h4 class="wrap-title mb-2">Other</h4>
            <div class="col-md-4">
              <div class="mb-3">
                <label class="brand-label d-grid">Total Payroll</label>
                <Controller
                  name="totalPayRoll"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      {...field}
                      id="totalPayRoll"
                      className="form-control"
                      thousandSeparator={true}
                      prefix="$"
                      name="totalPayRoll"
                      onValueChange={(values) => {
                        setValue("totalPayRoll", values.floatValue);
                      }}
                      placeholder="Enter Total Payroll"
                      {...register("totalPayRoll")}
                    />
                  )}
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-3">
                <label class="brand-label d-grid">FEIN Number</label>
                <input
                  class="form-control"
                  type="text"
                  name="feinNum"
                  onKeyUp={handleFeinChange}
                  placeholder="Enter FEIN Number"
                  {...register("feinNum")}
                />
              </div>
              <p className="text-danger mt-2">
                {errors.feinNum && <p>{errors.feinNum.message}</p>}
              </p>
            </div>
            <div class="col-md-4">
              <div class="mb-3">
                <label class="brand-label d-grid">No. of Employees</label>
                <Controller
                  name="numOfEmp"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      {...field}
                      id="numOfEmp"
                      className="form-control"
                      thousandSeparator={true}
                      name="numOfEmp"
                      onValueChange={(values) => {
                        setValue("numOfEmp", values.floatValue);
                      }}
                      placeholder="No. of Employees"
                      {...register("numOfEmp")}
                    />
                  )}
                />
              </div>
            </div>
            <div className="col-md-12 col-lg-12 mt-1">
              <div className="mb-3">
                <div className="d-flex align-items-baseline gap-10">
                  <input
                    className="me-2"
                    type="checkbox"
                    id="termsCondition"
                    {...register("termsCondition")}
                  />

                  <label className="brand-label ">
                    I understand loss runs are required to receive a quote and I
                    hereby allow the agent to retrieve information using the
                    above-mentioned business information and provide me with
                    quote(s) for my perusal. I also understand that quotes are
                    for information and marketing purpose only.
                  </label>
                </div>

                <p className="text-danger mt-2">
                  {errors.termsCondition && (
                    <p>{errors.termsCondition.message}</p>
                  )}
                </p>
              </div>
            </div>
          </div>
        </section>
        <div className="d-flex justify-content-end">
          <button
            className="btn btn-warning"
            type="button"
            onClick={() => stepHandler(2)}
          >
            Previous
          </button>
          <button
            type="submit"
            className="btn btn-primary ms-2"
            disabled={disableAdd}
          >
            Submit
          </button>
        </div>
      </form>
    </>
  );
};

export default Step3;
