import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import * as yup from "yup";
import { default as axios } from "../../config/axios-config";
import useExitConfirmationHandler from "../../hooks/useExitConfirmationHandler.hook";

const DEVURL = process.env.REACT_APP_ANC_API + "/api/health/";

const schema = yup.object().shape({
  dependents: yup.array().of(
    yup.object().shape({
      dependentName: yup.string().trim().required("Dependent name is required"),
      dependentBirthday: yup
        .date()
        .typeError("Date of birth is required")
        .max(new Date(Date.now() - 86400000), "Date of birth can not be future or current date")
        .required()
        .label("Date of birth"),
      dependentGender: yup.string().required("Gender is required"),
      dependentCitizenshipSelect: yup.string().required("Citizenship is required"),
      documentTypeSelect: yup.string().required("Document type is required"),
      tobaccoSelect: yup.string().required("Tobacco status is required"),
    })
  ),
});

const Step2 = ({ stepHandler, agencyId, agentId }) => {
  const handleExitButton = useExitConfirmationHandler();
  const {
    handleSubmit,
    control,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      dependents: [
        {
          dependentName: "",
          dependentBirthday: "",
          dependentGender: "",
          dependentCitizenshipSelect: "",
          documentTypeSelect: "",
          tobaccoSelect: "",
        },
      ],
    },
  });

  const queryParams = new URLSearchParams(window.location.search);

  const primaryId = queryParams.get("primaryid") || "";
  const planId = queryParams.get("planId");
  const navigate = useNavigate();
  const [disableAdd, setDisableAdd] = useState(false);
  const [dependents, setDependents] = useState([{ id: 0 }]);
  const [isCaptchaSuccessful, setIsCaptchaSuccess] = useState(false);

  useEffect(() => {
    if (dependents.length === 6) {
      setDisableAdd(true);
    } else {
      setDisableAdd(false);
    }
    const dependentsFromStorage = JSON.parse(localStorage.getItem("step2"));
    if (dependentsFromStorage) {
      setDependents(dependentsFromStorage);
      setValue("dependents", dependentsFromStorage);
    }
  }, []);

  const handleAddDependent = () => {
    if (dependents.length < 5) {
      const newDependents = [...getValues("dependents"), { id: dependents.length }];
      setDependents(newDependents);
    }
  };

  const handleRemoveDependent = (id) => {
    const newDependents = dependents.filter((dep) => dep.id !== id);
    setDependents(newDependents);
    setValue("dependents", newDependents);
  };

  const clearLocalStorage = () => {
    localStorage.removeItem("step1");
    localStorage.removeItem("step2");
  };

  const setStep2ToLocalStorage = (data) => {
    localStorage.setItem("step2", JSON.stringify(data));
  };

  const insertHealthcareInfo = async () => {
    const step1 = localStorage.getItem("step1");
    const step2 = localStorage.getItem("step2");
    const healthcareInfo = {
      primary: {
        ...JSON.parse(step1),
        agencyId: agencyId,
        planId: planId,
        primaryId: primaryId,
        agentId: agentId,
      },
      dependents: JSON.parse(step2),
    };

    await axios
      .post(`${DEVURL}insertHealthcare`, healthcareInfo)
      .then((res) => {
        if (res.status === 200) {
          reset();
          clearLocalStorage();
          stepHandler(3);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateHealthcareInfo = async () => {
    const step1 = localStorage.getItem("step1");
    const step2 = localStorage.getItem("step2");
    const healthcareInfo = {
      primary: {
        ...JSON.parse(step1),
        agencyId: agencyId,
        planId: planId,
        primaryId: primaryId,
        agentId: agentId,
      },
      dependents: JSON.parse(step2),
    };

    await axios
      .put(`${DEVURL}updateHealthcare/${primaryId}`, healthcareInfo)
      .then((res) => {
        if (res.status === 204) {
          reset();
          clearLocalStorage();
          stepHandler(3);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const insertPrimaryHealthcareInfo = async () => {
    const step1 = localStorage.getItem("step1");
    const primaryHealthcareInfo = {
      ...JSON.parse(step1),
      agencyId: agencyId,
      planId: planId,
      primaryId: primaryId,
      agentId: agentId,
    };
    await axios({
      method: "post",
      url: `${DEVURL}insertPrimaryHealthcare`,
      headers: { "Content-Type": "application/json" },
      data: primaryHealthcareInfo,
    })
      .then((res) => {
        if (res.status === 200) {
          reset();
          clearLocalStorage();
          stepHandler(3);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updatePrimaryHealthcareInfo = async () => {
    const step1 = localStorage.getItem("step1");
    const primaryHealthcareInfo = {
      ...JSON.parse(step1),
      agencyId: agencyId,
      planId: planId,
      primaryId: primaryId,
      agentId: agentId,
    };
    await axios({
      method: "put",
      url: `${DEVURL}updatePrimaryHealthcare/${primaryId}`,
      headers: { "Content-Type": "application/json" },
      data: primaryHealthcareInfo,
    })
      .then((res) => {
        if (res.status === 204) {
          clearLocalStorage();
          reset();
          stepHandler(3);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleFormSubmit = async (data) => {
    setStep2ToLocalStorage(data.dependents);
    if (!primaryId) {
      insertHealthcareInfo();
    } else {
      updateHealthcareInfo();
    }
  };

  const handleSkipDependent = async () => {
    if (!primaryId) {
      insertPrimaryHealthcareInfo();
    } else {
      updatePrimaryHealthcareInfo();
    }
  };

  const onChangeRecaptcha = (value) => {
    setIsCaptchaSuccess(true);
  };

  return (
    <>
      <div className="container">
        <div className="main-wrap">
          <div className="wizard-content">
            <form onSubmit={handleSubmit(handleFormSubmit)} className="tab-wizard wizard-circle wizard needs-validation">
              <section>
                <h4 className="wrap-title mb-3">Dependent Details</h4>
                <div className="brand-table">
                  <div className="table-responsive table-container">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Persons Name</th>
                          <th scope="col">Date of Birth</th>
                          <th scope="col">Gender</th>
                          <th scope="col">Immigration</th>
                          <th scope="col">Relation</th>
                          <th scope="col">Tobacco</th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {dependents.map((dependent, index) => (
                          <tr key={dependent.id}>
                            <th scope="row">{index + 1}</th>
                            <td className="depenedentNameInput">
                              <Controller
                                control={control}
                                name={`dependents[${index}].dependentName`}
                                render={({ field }) => <input className="form-control dependentName " placeholder="Enter Dependent Name" {...field} />}
                              />
                              <p className="error dependentNameError">{errors.dependents?.[index]?.dependentName?.message}</p>
                            </td>
                            <td>
                              <Controller
                                control={control}
                                name={`dependents[${index}].dependentBirthday`}
                                render={({ field }) => (
                                  <input
                                    className="form-control dependentBirthday"
                                    type="date"
                                    max="2099-12-31"
                                    {...field}
                                  />
                                )}
                              />
                              <p className="error dependentBirthdayError">{errors.dependents?.[index]?.dependentBirthday?.message}</p>
                            </td>
                            <td>
                              <Controller
                                control={control}
                                name={`dependents[${index}].dependentGender`}
                                render={({ field }) => (
                                  <select className="form-select dependentGender" {...field}>
                                    <option value="">Select Gender</option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                  </select>
                                )}
                              />
                              <p className="error dependentGenderError">{errors.dependents?.[index]?.dependentGender?.message}</p>
                            </td>
                            <td>
                              <div className="mb-2">
                                <Controller
                                  control={control}
                                  name={`dependents[${index}].dependentCitizenshipSelect`}
                                  render={({ field }) => (
                                    <select className="form-select dependentCitizenshipSelect" {...field}>
                                      <option value="">Select</option>
                                      <option value="Citizen">Citizen</option>
                                      <option value="Immigrant">Immigrant</option>
                                      <option value="Student">Student</option>
                                      <option value="Visitor">Visitor</option>
                                      <option value="Asylum">Asylum</option>
                                      <option value="Work Permit">Work Permit</option>
                                      <option value="Other">Other</option>
                                    </select>
                                  )}
                                />
                                <p className="error dependentCitizenshipError">{errors.dependents?.[index]?.dependentCitizenshipSelect?.message}</p>
                              </div>
                            </td>
                            <td>
                              <Controller
                                control={control}
                                name={`dependents[${index}].documentTypeSelect`}
                                render={({ field }) => (
                                  <select className="form-select documentTypeSelect" {...field}>
                                    <option value="">Select</option>
                                    <option value="Self">Self</option>
                                    <option value="Brother or Sister">Brother or Sister</option>
                                    <option value="Child">Child</option>
                                    <option value="Collateral Dependent">Collateral Dependent</option>
                                    <option value="Ex-Spouse">Ex-Spouse</option>
                                    <option value="Foster Child">Foster Child</option>
                                    <option value="Grandson or Granddaughter">Grandson or Granddaughter</option>
                                    <option value="Life Partner">Life Partner</option>
                                    <option value="Nephew or Niece">Nephew or Niece</option>
                                    <option value="Other Relationship">Other Relationship</option>
                                    <option value="Other Relative">Other Relative</option>
                                    <option value="Sponsored Dependent">Sponsored Dependent</option>
                                    <option value="Stepson or Stepdaughter">Stepson or Stepdaughter</option>
                                    <option value="Ward">Ward</option>
                                  </select>
                                )}
                              />
                              <p className="error documentTypeSelectError">{errors.dependents?.[index]?.documentTypeSelect?.message}</p>
                            </td>
                            <td>
                              <Controller
                                control={control}
                                name={`dependents[${index}].tobaccoSelect`}
                                render={({ field }) => (
                                  <select className="form-select tobaccoSelect" {...field}>
                                    <option value="">Select</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                  </select>
                                )}
                              />
                              <p className="error tobaccoSelectError">{errors.dependents?.[index]?.tobaccoSelect?.message}</p>
                            </td>
                            <td>
                              {index === 0 ? (
                                <button type="button" className="btn btn-primary btn-sm" onClick={handleAddDependent} disabled={disableAdd}>
                                  Add
                                </button>
                              ) : (
                                <button type="button" className="btn btn-danger btn-sm" onClick={() => handleRemoveDependent(dependent.id)}>
                                  Remove
                                </button>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="text-center mb-3">
                      {primaryId && (
                        <button type="button" onClick={() => handleExitButton("Healthcare Application")} className="btn btn-danger ms-2 text-capitalize">
                          Exit if no changes required
                        </button>
                      )}
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <ReCAPTCHA sitekey="6LeS4L8mAAAAAEGzyClAMce6777ssfu5VYovTkaW" onChange={onChangeRecaptcha} />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <div className="d-flex justify-content-between">
                <button type="button" className="btn btn-outline-primary" onClick={() => stepHandler(1)}>
                  Previous
                </button>
                <button type="button" className="btn btn-primary" disabled={disableAdd || !isCaptchaSuccessful} onClick={handleSkipDependent}>
                  Skip Dependent & Submit
                </button>
                <button type="submit" className="btn btn-primary" disabled={disableAdd || !isCaptchaSuccessful}>
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Step2;
