export const formattedDate = (date) => {
  if (date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  return null;
};

export const formatDate = (inputDate) => {
  const parsedDate = new Date(inputDate);
  const month = (parsedDate.getMonth() + 1).toString().padStart(2, "0");
  const day = parsedDate.getDate().toString().padStart(2, "0");
  const year = parsedDate.getFullYear();

  // Formating the date as mm-dd-yyyy
  const formattedDate = `${month}-${day}-${year}`;

  return formattedDate;
};

export const formatHours = (hours) => {
  // Checking if the input matches the expected format
  const isValidFormat = /^(\d{2}:\d{2}-\d{2}:\d{2})$/.test(hours);

  if (!isValidFormat) {
    // Return the original input if it doesn't match the expected format
    return hours;
  }

  const [startTime, endTime] = hours.split("-");

  const formatTime = (time) => {
    const [hour, minute] = time.split(":");
    const formattedHour = (parseInt(hour, 10) % 12 || 12).toString().padStart(2, "0");
    return `${formattedHour}:${minute} ${parseInt(hour, 10) < 12 ? "AM" : "PM"}`;
  };

  const formattedHours = `${formatTime(startTime)} - ${formatTime(endTime)}`;

  return formattedHours;
};

export const isAnnual = (value) => value?.includes("Annual");
export const isDollar = (value) => value?.includes("$");

export const NumberFormat = ({ value }) => {
  if (value === "" || value === null) {
    return "";
  } else {
    const formattedValue = `$${Number(value).toLocaleString("en-US")}`;
    return <span>{formattedValue}</span>;
  }
};

export const ThousandSeparator = ({ value }) => {
  if (value === "" || value === null) {
    return;
  }
  const formattedValue = `${Number(value).toLocaleString("en-US")}`;
  return <span>{formattedValue}</span>;
};

export const formatTelNumber = (input) => {
  const cleaned = ("" + input).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  } else {
    return cleaned;
  }
};
