import React, { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { default as axios } from "../../../config/axios-config";
import { showDateFormat } from "../../helper/Helper";
import Agent from "./Agent";

const Agents = () => {
  const [loader, setLoader] = useState(false);
  const agencyId = localStorage.getItem("AgencyId");
  const [agents, setAgents] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [totalPages, setTotalPages] = useState(0);
  const [tableRenderar, setTableRenderar] = useState(false);
console.log('selectedAgent',selectedAgent)
  const getAgentsByAgentId = async (param) => {
    setLoader(true);
    if (param === "reset") {
      setSearchText("");
      setAgents([]);
      setCurrentPage(1);
      setTotalPages(0);
    }
    await axios
      .get(
        `${process.env.REACT_APP_ANC_API}/api/agent/GetAgentByAgencyId?agencyId=${agencyId}&page=${currentPage}&pageSize=${itemsPerPage}&searchText=${searchText}`
      )
      .then((response) => {
        if (response.status === 200) {
          if (response.data.items && response.data.totalPages) {
            setAgents(response.data.items);
            setTotalPages(response.data.totalPages);
          } else {
            setAgents([]);
            setTotalPages(0);
            setCurrentPage(1);
          }
          setLoader(false);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
      });
  };

  useEffect(() => {
    if (agencyId) {
      setTableRenderar(false);
      getAgentsByAgentId();
    }
  }, [currentPage, tableRenderar]);

  const handleAddClick = () => {
    setSelectedAgent(null);
  };

  const handleEditClick = async (agent) => {
    setSelectedAgent(agent);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <>
      <div className="col py-3">
        <div className="content-panel">
          <div className="panel-title mb-5 ">
            <h4>Agents</h4>
          </div>
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link brand-tab active`}
                id="account-tab"
                data-bs-toggle="tab"
                data-bs-target="#account"
                type="button"
                role="tab"
                aria-controls="account"
                aria-selected="true"
              >
                Agent
              </button>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="account"
              role="tabpanel"
              aria-labelledby="account-tab"
            >
              <div className="filter-box mt-3 mb-2">
                <div className="filter-box-content">
                  <div className="row align-items-center">
                    <div className="col-lg-5">
                      <div className="form-floating">
                        <input
                          type="text"
                          size={500}
                          className="form-control"
                          value={searchText}
                          onChange={(event) =>
                            setSearchText(event.target.value)
                          }
                          id="floatingInput"
                          placeholder=""
                        />
                        <label htmlFor="floatingInput">
                          Search by first name last name email
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-5">
                      <div
                        className="d-grid d-md-flex justify-content-md-left"
                        style={{ height: "50px" }}
                      >
                        <button
                          className="btn btn-primary"
                          type="button"
                          onClick={() => getAgentsByAgentId()}
                        >
                          <div className="button-body-for-loader">
                            <span>Search</span>
                            {loader && (
                              <ThreeDots
                                color="#ffdb58"
                                height={30}
                                width={40}
                              />
                            )}
                          </div>
                        </button>
                        <button
                          className="mx-2 btn btn-warning"
                          type="button"
                          onClick={() => getAgentsByAgentId("reset")}
                        >
                          <div className="button-body-for-loader">
                            <span>Reset</span>
                          </div>
                        </button>
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <div
                        className="d-grid d-md-flex justify-content-md-left"
                      >
                        <button
                          className="btn btn-primary"
                          type="button"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#agent"
                          onClick={handleAddClick}
                        >
                          <div className="button-body-for-loader">
                            <span>Add Agent</span>
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="data-box ">
                <div className="activity-status-table">
                  <div className="table-responsive">
                    <table className="table activity-status-table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">Agent Name</th>
                          <th scope="col">Email</th>
                          <th scope="col">Phone No</th>
                          <th scope="col">User Id</th>
                          <th scope="col">Created Date</th>
                          <th scope="col">Status</th>
                          <th scope="col">Edit</th>
                        </tr>
                      </thead>
                      <tbody id="body-render">
                        {agents?.map((item, index) => (
                          <tr key={index}>
                            <td>
                              <div className="cust-info">
                                <h6>
                                  {" "}
                                  {item.firstName} {item.lastName}
                                </h6>
                              </div>
                            </td>
                            <td>
                              <p>{item.email}</p>
                            </td>
                            <td>
                              <p>{item.phoneNo}</p>
                            </td>
                            <td>
                              <p>{item.userId}</p>
                            </td>
                            <td>
                              <div className="last-received-date-time">
                                <p>{showDateFormat(item.createdDate)}</p>
                              </div>
                            </td>
                            <td>
                              <p>{item.status === 1 ? "Active" : "Inactive"}</p>
                            </td>

                            <td>
                              <a
                                data-bs-toggle="offcanvas"
                                data-bs-target="#agent"
                                onClick={() => handleEditClick(item)}
                                href="#"
                              >
                                <i className="bi bi-pencil-square"></i>
                              </a>
                            </td>
                          </tr>
                        ))}
                        {agents.length === 0 && !loader && (
                          <tr>
                            <td
                              colSpan={7}
                              className="text-center text-warning"
                            >
                              Data Not found
                            </td>
                          </tr>
                        )}
                        {loader && (
                          <td colSpan={7} className="text-center">
                            <ThreeDots
                              height="40"
                              width="80"
                              color="#4fa94d"
                              ariaLabel="bars-loading"
                              wrapperStyle={{
                                justifyContent: "center",
                                paddingBottom: "10px",
                              }}
                              wrapperClass=""
                              visible={true}
                            />
                          </td>
                        )}
                      </tbody>
                    </table>

                    <nav
                      aria-label="Page navigation example"
                      className="pull-right"
                    >
                      <ul className="pagination">
                        <li className="page-item">
                          <button
                            className="page-link"
                            onClick={handlePrevPage}
                            disabled={currentPage === 1}
                          >
                            Previous
                          </button>
                        </li>
                        <li className="page-item">
                          <button
                            className="page-link"
                            onClick={handleNextPage}
                            disabled={currentPage === totalPages}
                          >
                            Next
                          </button>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      <Agent
        selectedAgent={selectedAgent}
        setTableRenderar={setTableRenderar}
      />
      </div>
    </>
  );
};

export default Agents;
