import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { default as axios } from "../../config/axios-config";

const DEVURL = process.env.REACT_APP_ANC_API;

const url = DEVURL + "/api/tank/";
const validationSchema = yup.object().shape({
  name: yup
    .string()
    .matches(/^[a-zA-Z\s]*$/, "Please enter valid alphabets only")
    .required()
    .label("Applicant Name"),
  address: yup.object().required().label("Applicant Address"),
  acity: yup.string().required().label("Applicant city"),
  astate: yup.string().required().label("Applicant state"),
  azipcode: yup.string().required().label("Applicant zipcode"),

  facility: yup.object().required().label("Facility Address"),
  fcity: yup.string().required().label("Facility city"),
  fstate: yup.string().required().label("Facility state"),
  fzipcode: yup.string().required().label("Facility zipcode"),

  phone: yup.string().required().label("Phone Number"),
  email: yup.string().email().required().label("Email"),
  entity: yup.array().min(1).of(yup.string()).required().label("Entity"),
  contactPerson: yup.string().required().label("Contact Person"),
  cPhone: yup.string().required().label("Contact Person Phone Number"),
  cEmail: yup.string().email().required().label("Contact Person Email"),
  limitsRequested: yup
    .array()
    .min(1)
    .of(yup.string())
    .required()
    .label("Limits Requested"),
  deductibleRequested: yup
    .array()
    .min(1)
    .of(yup.string())
    .required()
    .label("Deductible Requested"),
});

const Step1 = ({ stepHandler }) => {
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      name: "",
      address: "",
      acity: "",
      astate: "",
      azipcode: "",
      facility: "",
      fcity: "",
      fstate: "",
      fzipcode: "",
      phone: "",
      fax: "",
      email: "",
      entity: [],
      contactPerson: "",
      cPhone: "",
      cFax: "",
      cEmail: "",
      UST: "",
      premium: "",
      renewal: "",
      retroactive: "",
      agent: "",
      aPhone: "",
      aFax: "",
      aEmail: "",
      // aAddress: "",
      otherentitytxt: "",
      limitsRequested: [],
      otherLimitsRequested: "",
      deductibleRequested: [],
      otherDeductibleRequested: "",
    },
    resolver: yupResolver(validationSchema),
  });
  const [isLoading, settankstep1Loading] = useState(false); // Loading state
  const [tankId, settankId] = useState(""); // Loading state
  useEffect(() => {
    const formData = JSON.parse(localStorage.getItem("step1"));
    if (formData) {
      setValue("name", formData.name);

      setValue("address", formData.address);
      setValue("acity", formData.acity);
      setValue("astate", formData.astate);
      setValue("azipcode", formData.azipcode);

      setValue("facility", formData.facility);
      setValue("fcity", formData.fcity);
      setValue("fstate", formData.fstate);
      setValue("fzipcode", formData.fzipcode);

      setValue("phone", formData.phone);
      setValue("fax", formData.fax);
      setValue("email", formData.email);
      setValue("entity", formData.entity);
      setValue("contactPerson", formData.contactPerson);
      setValue("cPhone", formData.cPhone);
      setValue("cFax", formData.cFax);
      setValue("cEmail", formData.cEmail);
      setValue("UST", formData.UST);
      setValue("premium", formData.premium);
      setValue("renewal", formData.renewal);
      setValue("retroactive", formData.retroactive);
      setValue("agent", formData.agent);
      setValue("aPhone", formData.aPhone);
      setValue("aFax", formData.aFax);
      setValue("aEmail", formData.aEmail);
      setValue("aAddress", formData.aAddress);
      setValue("limitsRequested", formData.limitsRequested);
      setValue("deductibleRequested", formData.deductibleRequested);
      setValue("otherentitytxt", formData.otherentitytxt);
      setValue("otherLimitsRequested", formData.otherLimitsRequested);
      setValue("otherDeductibleRequested", formData.otherDeductibleRequested);
    }
  }, []);

  const handleFormSubmit = async (data) => {
    try {
      const newdata = { ...data };
      // console.log(newdata.name)
      settankstep1Loading(true);
      const agencyInfo = JSON.parse(localStorage.getItem("agencyInfo"));
      const agencyId = agencyInfo.id;

      await axios
        .post(url, {
          agencyId: "" + agencyId,
          applicantName: newdata.name,

          address: newdata.address,
          acity: newdata.acity,
          astate: newdata.astate,
          azipcode: newdata.azipcode,

          facilityaddress: newdata.facility,
          fcity: newdata.fcity,
          fstate: newdata.fstate,
          fzipcode: newdata.fzipcode,

          phone: newdata.phone,
          fax: newdata.fax,
          email: newdata.email,
          contactPerson: newdata.contactPerson,
          cPhone: newdata.contactPerson,
          cFax: newdata.cFax,
          cEmail: newdata.cEmail,
          UST: newdata.UST,
          premium: newdata.premium,
          renewal: newdata.renewal,
          retroactive: newdata.retroactive,
          agent: newdata.agent,
          aPhone: newdata.aPhone,
          aFax: newdata.aFax,
          aEmail: newdata.aEmail,
          aAddress: newdata.aAddress,
          entity: newdata.entity,
          otherentitytxt: newdata.otherentitytxt,
          limitsRequested: newdata.limitsRequested,
          otherLimitsRequested: newdata.otherLimitsRequested,
          deductibleRequested: newdata.deductibleRequested,
          otherDeductibleRequested: newdata.otherDeductibleRequested,
        })
        .then((res) => {
          // console.log(res.data)
          localStorage.setItem("newtankId", JSON.stringify(res.data));
          settankstep1Loading(false);
        });

      //console.log("==============")
      //console.log(newdata);

      stepHandler(2);
      localStorage.setItem("step1", JSON.stringify(data));
    } catch (err) {
      console.log(err);
    }
  };

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <>
      <div className="container">
        <div className="main-wrap">
          <div className="wizard-content">
            <form
              className="tab-wizard wizard-circle wizard needs-validation"
              onSubmit={handleSubmit(handleFormSubmit)}
            >
              <p align="center" className="brand-label">
                <h2>Tank Insurance Application</h2>
              </p>
              <section>
                <h4 className="wrap-title mb-3">Basic Information</h4>

                <div className="row">
                  <div className="col-md-6 col-lg-4">
                    <div className="mb-3">
                      <label className="brand-label">Applicant Name</label>
                      <input
                        {...register("name")}
                        className="form-control"
                        type="text"
                        placeholder="Enter name of the applicant"
                      />
                      <p className="text-danger">{errors.name?.message}</p>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-4">
                    <div className="mb-3">
                      <label className="brand-label">Applicant Address</label>
                      <Controller
                        name="address"
                        rules={{
                          required: "This is a required field",
                        }}
                        control={control}
                        render={({ field, fieldState }) => (
                          <GooglePlacesAutocomplete
                            // apiKey={process.env.REACT_APP_GOOGLE_KEY}
                            apiKey="AIzaSyAK27GLKwTWdgbE7R_2Vunml5Pd1q1h5kc"
                            selectProps={{ ...field }}
                          />
                        )}
                      />
                      <p className="text-danger">{errors.address?.message}</p>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4">
                    <div className="mb-3">
                      <label className="brand-label">Applicant City</label>
                      <input
                        className="form-control"
                        type="text"
                        {...register("acity")}
                        placeholder="Enter City of the applicant"
                      />
                      <p className="text-danger">{errors.acity?.message}</p>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4">
                    <div className="mb-3">
                      <label className="brand-label">Applicant Zip code</label>
                      <input
                        className="form-control"
                        type="text"
                        {...register("azipcode")}
                        placeholder="Enter zip code of the applicant"
                      />
                      <p className="text-danger">{errors.azipcode?.message}</p>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4">
                    <div className="mb-3">
                      <label className="brand-label">Applicant State</label>
                      <input
                        className="form-control"
                        type="text"
                        {...register("astate")}
                        placeholder="Enter state of the applicant"
                      />
                      <p className="text-danger">{errors.astate?.message}</p>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4">
                    <div className="mb-3">
                      <label className="brand-label">Facility Address</label>
                      <Controller
                        name="facility"
                        rules={{
                          required: "This is a required field",
                        }}
                        control={control}
                        render={({ field, fieldState }) => (
                          <GooglePlacesAutocomplete
                            // apiKey={process.env.REACT_APP_GOOGLE_KEY}
                            apiKey="AIzaSyAK27GLKwTWdgbE7R_2Vunml5Pd1q1h5kc"
                            selectProps={{ ...field }}
                          />
                        )}
                      />

                      <p className="text-danger">{errors.facility?.message}</p>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4">
                    <div className="mb-3">
                      <label className="brand-label">Facility City</label>
                      <input
                        className="form-control"
                        type="text"
                        {...register("fcity")}
                        placeholder="Enter city of the facility"
                      />
                      <p className="text-danger">{errors.acity?.message}</p>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4">
                    <div className="mb-3">
                      <label className="brand-label">Facility Zip code</label>
                      <input
                        className="form-control"
                        type="text"
                        {...register("fzipcode")}
                        placeholder="Enter zip code of the facility"
                      />
                      <p className="text-danger">{errors.azipcode?.message}</p>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4">
                    <div className="mb-3">
                      <label className="brand-label">Facility State</label>
                      <input
                        className="form-control"
                        type="text"
                        {...register("fstate")}
                        placeholder="Enter state of the facility"
                      />
                      <p className="text-danger">{errors.fstate?.message}</p>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-4">
                    <div className="mb-3">
                      <label className="brand-label">Phone Number</label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Enter Phone Number"
                        {...register("phone")}
                      />
                      <p className="text-danger">{errors.phone?.message}</p>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-4">
                    <div className="mb-3">
                      <label className="brand-label">Fax Number</label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Enter Fax Number"
                        {...register("fax")}
                      />
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-4">
                    <div className="mb-3">
                      <label className="brand-label">Email</label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Enter Email Address"
                        {...register("email")}
                      />
                      <p className="text-danger">{errors.email?.message}</p>
                    </div>
                  </div>

                  <label className="brand-label d-grid">Type of Entity</label>
                  <div className="col-md-6 col-lg-2">
                    <div className="mb-3">
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="corporationCheck"
                          value="Corporation"
                          {...register("entity")}
                        />
                        <label
                          className="form-check-label text-capitalize"
                          for="corporationCheck"
                        >
                          Corporation
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-2">
                    <div className="mb-3">
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="individualCheck"
                          value="Individual"
                          {...register("entity")}
                        />
                        <label
                          className="form-check-label text-capitalize"
                          for="individualCheck"
                        >
                          Individual
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-2">
                    <div className="form-check form-check-inline">
                      <div className="mb-3">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="partnershipCheck"
                          value="Partnership"
                          {...register("entity")}
                        />
                        <label
                          className="form-check-label text-capitalize"
                          for="partnershipCheck"
                        >
                          Partnership
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-2">
                    <div className="mb-3">
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="llcCheck"
                          value="LLC"
                          {...register("entity")}
                        />
                        <label
                          className="form-check-label text-capitalize"
                          for="llcCheck"
                        >
                          LLC
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-2">
                    <div className="mb-3">
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="otherCheck"
                          value="Other"
                          {...register("entity")}
                        />
                        <label
                          className="form-check-label text-capitalize"
                          for="otherCheck"
                        >
                          Other
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-2">
                    <div className="mb-3">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="If Other, please mention type of entity"
                        {...register("otherentitytxt")}
                      />
                    </div>
                  </div>
                  <p className="text-danger">
                    {errors.entity && <p>{errors.entity.message}</p>}
                  </p>
                  <div className="col-md-6 col-lg-3">
                    <div className="mb-3">
                      <label className="brand-label">Contact Person</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Contact Person Name"
                        {...register("contactPerson")}
                      />
                      <p className="text-danger">
                        {errors.contactPerson?.message}
                      </p>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-3">
                    <div className="mb-3">
                      <label className="brand-label">Phone Number</label>
                      <input
                        type="tel"
                        className="form-control"
                        placeholder="Enter Contact Person Phone Number"
                        {...register("cPhone")}
                      />
                      <p className="text-danger">{errors.cPhone?.message}</p>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-3">
                    <div className="mb-3">
                      <label className="brand-label">Fax</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Contact Person Fax Number"
                        {...register("cFax")}
                      />
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-3">
                    <div className="mb-3">
                      <label className="brand-label">Email Address</label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Enter Contact Person Email Address"
                        {...register("cEmail")}
                      />
                      <p className="text-danger">{errors.cEmail?.message}</p>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-3">
                    <div className="mb-3">
                      <label className="brand-label">
                        Current UST Insurance Co.
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter UST Insurance Company Name"
                        {...register("UST")}
                      />
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-3">
                    <div className="mb-3">
                      <label className="brand-label">Premium</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Premium"
                        {...register("premium")}
                      />
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-3">
                    <div className="mb-3">
                      <label className="brand-label">Renewal Date</label>
                      <input
                        type="date"
                        max="2099-12-31"
                        className="form-control"
                        placeholder="Enter Renewal Date"
                        {...register("renewal")}
                      />
                      <p className="text-danger">{errors.renewal?.message}</p>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-3">
                    <div className="mb-3">
                      <label className="brand-label">Retroactive Date(s)</label>
                      <input
                        type="date"
                        max="2099-12-31"
                        className="form-control"
                        placeholder="Enter Retroactive Date"
                        {...register("retroactive")}
                      />
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-3">
                    <div className="mb-3">
                      <label className="brand-label">Insurance Agent</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Insurance Agent Name"
                        {...register("agent")}
                      />
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-3">
                    <div className="mb-3">
                      <label className="brand-label">Phone Number</label>
                      <input
                        type="tel"
                        className="form-control"
                        placeholder="Enter Insurance Agent Phone Number"
                        {...register("aPhone")}
                      />
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-3">
                    <div className="mb-3">
                      <label className="brand-label">Fax</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Insurance Agent Fax Number"
                        {...register("aFax")}
                      />
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-3">
                    <div className="mb-3">
                      <label className="brand-label">Email Address</label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Enter Insurance Agent Email Address"
                        {...register("aEmail")}
                      />
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-4">
                    <div className="mb-3">
                      <label className="brand-label">
                        Insurance Agency Address
                      </label>

                      <Controller
                        name="aAddress"
                        rules={{
                          required: "This is a required field",
                        }}
                        control={control}
                        render={({ field, fieldState }) => (
                          <GooglePlacesAutocomplete
                            // apiKey={process.env.REACT_APP_GOOGLE_KEY}
                            apiKey="AIzaSyAK27GLKwTWdgbE7R_2Vunml5Pd1q1h5kc"
                            selectProps={{ ...field }}
                          />
                        )}
                      />
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-4">
                    <div className="mb-3">
                      <label className="brand-label d-grid">
                        Limits Requested
                      </label>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="milCheck1"
                          value="1Mil/1Mil"
                          {...register("limitsRequested")}
                        />
                        <label
                          className="form-check-label text-capitalize"
                          for="milCheck1"
                        >
                          1Mil/1Mil
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="milCheck2"
                          value="1Mil/2Mil"
                          {...register("limitsRequested")}
                        />
                        <label
                          className="form-check-label text-capitalize"
                          for="milCheck2"
                        >
                          1Mil/2Mil
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="milCheck3"
                          value="2Mil/2Mil"
                          {...register("limitsRequested")}
                        />
                        <label
                          className="form-check-label text-capitalize"
                          for="milCheck3"
                        >
                          2Mil/2Mil
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="milOtherCheck"
                          value="Other"
                          {...register("limitsRequested")}
                        />
                        <label
                          className="form-check-label text-capitalize"
                          for="milOtherCheck"
                        >
                          Other
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="If Other, please mention"
                          {...register("otherLimitsRequested")}
                        />
                      </div>
                      <p className="text-danger">
                        {errors.limitsRequested && (
                          <p>{errors.limitsRequested.message}</p>
                        )}
                      </p>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-4">
                    <div className="mb-3">
                      <label className="brand-label d-grid">
                        Deductible Requested
                      </label>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="milCheck1"
                          value="5,000"
                          {...register("deductibleRequested")}
                        />
                        <label
                          className="form-check-label text-capitalize"
                          for="milCheck1"
                        >
                          5,000
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="milCheck2"
                          value="10,000"
                          {...register("deductibleRequested")}
                        />
                        <label
                          className="form-check-label text-capitalize"
                          for="milCheck2"
                        >
                          10,000
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="milCheck3"
                          value="20,000"
                          {...register("deductibleRequested")}
                        />
                        <label
                          className="form-check-label text-capitalize"
                          for="milCheck3"
                        >
                          20,000
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="milOtherCheck"
                          value="25,000"
                          {...register("deductibleRequested")}
                        />
                        <label
                          className="form-check-label text-capitalize"
                          for="milOtherCheck"
                        >
                          25,000
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="dedOtherCheck"
                          value="Other"
                          {...register("deductibleRequested")}
                        />
                        <label
                          className="form-check-label text-capitalize"
                          for="dedOtherCheck"
                        >
                          Other
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="If Other, please mention"
                          {...register("otherDeductibleRequested")}
                        />
                      </div>
                      <p className="text-danger">
                        {errors.deductibleRequested && (
                          <p>{errors.deductibleRequested.message}</p>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </section>
              <button className="btn btn-primary">Next</button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Step1;
