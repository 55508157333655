import React, { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import Stepper from "react-stepper-horizontal";
import ThankyouResponseForUpdate from "../../ThankyoupageForUpdate";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
const Master = ({details}) => {
  const [step, setStep] = useState(1);
  const steps = [
    { title: "General Information" },
    { title: "Property Information" },
    { title: "General Liability" },
    { title: "Finish" },
  ];
  var activeStep = step;
  if (step === 1) {
    activeStep = 0;
  } else {
    activeStep = activeStep - 1;
  }

  useEffect(() => {
    localStorage.removeItem("step1");
    localStorage.removeItem("step2");
    localStorage.removeItem("step3");
  }, []);

  const loader = () => {
    return (
      <ThreeDots
        height="40"
        width="80"
        color="#4fa94d"
        ariaLabel="bars-loading"
        wrapperStyle={{ justifyContent: "center", paddingBottom: "10px" }}
        wrapperClass=""
        visible={true}
      />
    );
  };
  return (
    <div>

      <div className="container">
          <div className="wizard-content">
            <Stepper steps={steps} activeStep={activeStep} />
            {step === 1 && <Step1 stepHandler={setStep} details={details} loader={loader} />}
            {step === 2 && <Step2 stepHandler={setStep} details={details} loader={loader} />}
            {step === 3 && <Step3 stepHandler={setStep} details={details} loader={loader} />}
            {step === 4 && <ThankyouResponseForUpdate />}
          </div>
        </div>

    </div>
  );
};

export default Master;
