import * as yup from "yup";
import { cellRegex, emailRegex, nameRegex, paswordRegex } from "../../../Common/regex";

const AgentValidationSchema = (isEditForm) =>
  yup.object().shape({
    firstName: yup.string().trim().matches(nameRegex, "Please enter a valid First Name").max(45).required().label("First Name"),
    lastName: yup.string().trim().matches(nameRegex, "Please enter a valid Last Name").max(45).required().label("Last Name"),
    email: yup.string().trim().required().matches(emailRegex, "Please enter a valid email address").max(45).label("Email Address"),
    status: yup.string().required().label("Status"),
    password: yup.string().trim().required().min(8).max(45).matches(paswordRegex, "Password must contain alphanumeric characters").label("Password"),
    userId: isEditForm
      ? yup.string().trim().label("User Id")
      : yup.string().trim().required().label("User Id"),
    phoneNo: yup.string().trim().required().min(8).max(45).matches(cellRegex, "Please enter a valid Phone No").label("Phone No"),
    ProductAllowed: yup.array().typeError("Product Allowed is required").min(1, "Product Allowed is required").of(yup.string()).required("Product Allowed is required").label("Product Allowed"),
  });

export default AgentValidationSchema;
