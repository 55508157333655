import dayjs from "dayjs";
import React from "react";

const PrimaryDetails = ({ primaryInfo }) => {
  return (
    <>
      <section>
        <h4 class="wrap-title mb-3">Personal Information</h4>

        <div class="row">
          <div class="col-md-6">
            <div class="mb-3">
              <label class="brand-label">
                First Name<span class="required-mark">*</span>
              </label>
              <p>
                <b>{primaryInfo.firstName}</b>
              </p>
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <label class="brand-label">
                Last Name<span class="required-mark">*</span>
              </label>
              <p>
                <b>{primaryInfo.lastName}</b>
              </p>
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <label class="brand-label">
                Gender<span class="required-mark">*</span>
              </label>
              <p>
                <b>{primaryInfo.gender}</b>
              </p>
            </div>
          </div>

          <div class="col-md-6 col-lg-3">
            <div class="mb-3">
              <label class="brand-label">
                Apt/Suite No.<span class="required-mark">*</span>
              </label>
              <p>
                <b>{primaryInfo.apt}</b>
              </p>

              <p class="error apartmentError"></p>
            </div>
          </div>

          <div class="col-md-6 col-lg-3">
            <div class="mb-3">
              <label class="brand-label">
                City<span class="required-mark">*</span>
              </label>
              <p>
                <b>{primaryInfo.city}</b>
              </p>
            </div>
          </div>

          <div class="col-md-6 col-lg-3">
            <div class="mb-3">
              <label class="brand-label">
                State<span class="required-mark">*</span>
              </label>
              <p>
                <b>{primaryInfo.state}</b>
              </p>
            </div>
          </div>

          <div class="col-md-6 col-lg-3">
            <div class="mb-3">
              <label class="brand-label">
                Zipcode<span class="required-mark">*</span>
              </label>
              <p>
                <b>{primaryInfo.zipCode}</b>
              </p>
            </div>
          </div>

          <div class="col-md-6 col-lg-3">
            <div class="mb-3">
              <label class="brand-label">
                Home Phone Number<span class="required-mark">*</span>
              </label>
              <p>
                <b>N/A</b>
              </p>
              <p class="error homePhoneError"></p>
            </div>
          </div>

          <div class="col-md-6 col-lg-3">
            <div class="mb-3">
              <label class="brand-label">
                Cell Phone Number<span class="required-mark">*</span>
              </label>
              <p>
                <b>{primaryInfo.cell}</b>
              </p>
            </div>
          </div>
          <div class="col-md-6 col-lg-3">
            <div class="mb-3">
              <label class="brand-label">
                Email Address<span class="required-mark">*</span>
              </label>
              <p>
                <b>{primaryInfo.email}</b>
              </p>
            </div>
          </div>
          <div class="col-md-6 col-lg-3">
            <div class="mb-3">
              <label class="brand-label">Fax Number</label>
              <p>
                <b>{primaryInfo.fax}</b>
              </p>
            </div>
          </div>

          <div class="col-md-6">
            <div class="mb-3">
              <label class="brand-label">
                Date of Birth<span class="required-mark">*</span>
              </label>
              <p>
                <b>{dayjs(primaryInfo.dob).format("MM/DD/YYYY")}</b>
              </p>
            </div>
          </div>

          <div class="col-md-6">
            <div class="mb-3">
              <label class="brand-label">
                Do you use tobacco?<span class="required-mark">*</span>
              </label>
              <p>
                <b>{primaryInfo.tobacco}</b>
              </p>
            </div>
          </div>

          <div class="col-md-6">
            <div class="mb-3">
              <label class="brand-label">
                Immigrant Status<span class="required-mark">*</span>
              </label>
              <p>
                <b>{primaryInfo.status}</b>
              </p>
            </div>
          </div>
          <hr />
          <h4 class="wrap-title mb-3">Income Details</h4>
          <div class="col-md-6">
            <div class="mb-3">
              <label class="brand-label">
                Your Employment Status
                <span class="required-mark">*</span>
              </label>
              <p>
                <b>{primaryInfo.status}</b>
              </p>
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3" id="income">
              <label class="brand-label">
                Please specify Yearly Income
                <span class="required-mark">*</span>
              </label>
              <p>{primaryInfo.income}</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default PrimaryDetails;
