import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import PlaceComponent from "../../healthcare/GooglePlace";
import { formatTelNumber, formattedDate } from "../../../Common/Utils";
import axiosInstance from "../../../config/axios-config";

const validationSchema = yup.object().shape({
  firstName: yup
    .string()
    .trim()
    .matches(/^[A-Za-z ]*$/, "Please enter a valid first name")
    .max(45)
    .required("First name is required")
    .label("First name"),
  lastName: yup
    .string()
    .trim()
    .matches(/^[A-Za-z ]*$/, "Please enter a valid last name")
    .max(45)
    .required("Last name is required")
    .label("Last name"),

  cell: yup
    .string()
    .trim()
    .required("Phone number is required")
    .matches(/^(\([0-9]{3}\)|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/, "Please enter valid phone number")
    .label("Phone number"),
  workPhone: yup
    .string()
    .trim()
    .matches(/^(\([0-9]{3}\)|[0-9]{3})[\s\-]?[0-9]{3}[\s\-]?[0-9]{4}$|^$/, "Please enter valid work phone number")
    .label("Work phone number"),
  email: yup
    .string()
    .trim()
    .required("Email is required")
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Enter a valid email address"
    )
    .label("Email address"),
  apt: yup.string().trim().required("Street address is required").label("Address"),
  garrageStreetAddress: yup.string().trim().required("Garrage street address is required").label("Mailing address"),
  garragingEffectiveDate: yup
    .date()
    .typeError("Effective date is required")
    .test("is-future-date", "Date must be today or in the future", (value) => {
      if (!value) {
        return true;
      }
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      const inputDate = new Date(value);
      inputDate.setHours(0, 0, 0, 0);

      return inputDate >= currentDate;
    }),
  dob: yup
    .date()
    .typeError("Date of birth required")
    .max(new Date(Date.now() - 86400000), "Date of birth can not be future or current date")
    .required("Date of birth required")
    .test("is-age-15-or-older", "User age must be 15 or older", (value) => {
      if (!value) {
        return true;
      }
      const birthYear = value.getFullYear();
      const currentYear = new Date().getFullYear();
      return currentYear - birthYear >= 15;
    })
    .label("Date of Birth"),
});

const DEVURL = process.env.REACT_APP_ANC_API + "/api/Auto/GetAutoByCustomerId";

const Step1 = ({ stepHandler, details, loader }) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    getValues,
    reset,
    control,
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      garragingEffectiveDate: "",
      workPhone: "",
      apt: "",
      garrageStreetAddress: "",
      garageCity: "",
      garageZipcode: "",
      garageState: "",
      cell: "",
      email: "",
      dob: "",
      customerId: "",
    },
    resolver: yupResolver(validationSchema),
  });
  const [isLoading, setLoading] = useState(false);
  const [addesss, setAddress] = useState("");
  const [garageAddress, setGarageAddress] = useState("");
  const [sameAsAddress, setSameAsAddress] = useState(false);
  const cell = watch("cell");
  const workPhone = watch("workPhone");

  const getStep1AutoInfo = (autoinfo) => {
    let step1 = {
      customerId: autoinfo?.customerId,
      agencyId: autoinfo?.agencyId,
      agentId: autoinfo?.agencyId,
      dob: autoinfo?.dob,
      garragingEffectiveDate: autoinfo?.garragingEffectiveDate,
      garrageStreetAddress: autoinfo?.garrageStreetAddress,
      email: autoinfo?.email,
      workPhone: autoinfo?.workPhone,
      cell: autoinfo?.cell,
      apt: autoinfo?.apt,
      lastName: autoinfo?.lastName,
      firstName: autoinfo?.firstName,
      city: autoinfo?.city,
      state: autoinfo?.state,
      zipCode: autoinfo?.zip,
      garageCity: autoinfo?.garageCity,
      garageState: autoinfo?.garageState,
      garageZipcode: autoinfo?.garageZipcode,
    };
    return step1;
  };
  const getStep2AutoInfo = (autoinfo) => {
    let step2 = {
      terms: false,
      carrierDuration: autoinfo?.carrierDuration,
      insurancePremium: autoinfo?.insurancePremium,
      renewalDate: autoinfo?.renewalDate,
      carrierName: autoinfo?.carrierName,
      drivers: autoinfo?.drivers,
      vehicles: autoinfo?.vehicles,
    };
    return step2;
  };
  const fillupStep1DefaultValues = (formData) => {
    setValue("customerId", formData.customerId);
    setValue("firstName", formData.firstName);
    setValue("lastName", formData.lastName);
    setValue("gender", formData.gender);
    setValue("apt", formData.apt);
    setValue("garrageStreetAddress", formData.garrageStreetAddress);
    setValue("phone", formData.phone);
    setValue("workPhone", formData.workPhone);
    setValue("cell", formData.cell);
    setValue("email", formData.email);
    setValue("dob", formattedDate(formData.dob));
    setValue("garragingEffectiveDate", formattedDate(formData.garragingEffectiveDate));
    setValue("garageCity", formData.garageCity);
    setValue("garageZipcode", formData.garageZipcode);
    setValue("garageState", formData.garageState);
    setValue("agencyId", formData.agencyId);
    setValue("agentId", formData.agentId);
    setAddress(formData.apt);
    setGarageAddress(formData.garrageStreetAddress);
    if (formData.garrageStreetAddress === formData.apt) {
      setSameAsAddress(true);
    }
  };

  const loadInitialAutoInfo = () => {
    const formData = JSON.parse(localStorage.getItem("step1"));
    if (true) {
      setLoading(true);
      axiosInstance
        .get(`${DEVURL}/${details?.customerId}`)
        .then((response) => {
          setLoading(false);
          setAutoInfoInLocalStorage(response.data);
          fillupStep1DefaultValues(response.data);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } else if (formData) {
      setAutoInfoInLocalStorage(formData);
      fillupStep1DefaultValues(formData);
    }
  };
  const setAutoInfoInLocalStorage = (details) => {
    localStorage.setItem("step1", JSON.stringify(getStep1AutoInfo(details)));
    localStorage.setItem("step2", JSON.stringify(getStep2AutoInfo(details)));
    localStorage.setItem("customerId", details?.customerId);
  };
  useEffect(() => {
    loadInitialAutoInfo();
  }, [details]);

  const getLocation = async (data) => {
    let address_components = data?.address_components;
    let formatted_address = data?.formatted_address;
    setValue("apt", formatted_address);

    setAddress(formatted_address);
    formatted_address = formatted_address.split(",");
    let zip_code = "";
    address_components.map((data) => {
      if (data.types.includes("postal_code")) {
        zip_code = data.long_name + zip_code;
      }
      // if (data.types.includes("postal_code_suffix")) {
      //     zip_code =  zip_code +'-'+ data.long_name;
      // }
      if (data.types.includes("locality")) {
        setValue("city", data.long_name);
      }
      if (data.types.includes("administrative_area_level_1")) {
        setValue("state", data.long_name);
      }
    });
    setValue("zipCode", zip_code);
  };
  const getGarageAddress = async (data) => {
    //let formatted_address = data?.formatted_address;
    //setValue("garrageStreetAddress", formatted_address);
    //return false;
    console.log("data", data);
    let address_components = data?.address_components;
    console.log("address_components", address_components);
    let formatted_address = data?.formatted_address;
    setValue("garrageStreetAddress", formatted_address);
    setAddress(formatted_address);
    formatted_address = formatted_address.split(",");
    let zip_code = "";
    address_components.map((data) => {
      if (data.types.includes("postal_code")) {
        console.log("zip");
        zip_code = data.long_name + zip_code;
      }
      if (data.types.includes("locality")) {
        console.log("city");
        setValue("garageCity", data.long_name);
      }
      if (data.types.includes("administrative_area_level_1")) {
        console.log("state");
        setValue("garageState", data.long_name);
      }
    });
    setValue("garageZipcode", zip_code);
  };

  const handleFormSubmit = async (data) => {
    stepHandler(2);
    localStorage.setItem("step1", JSON.stringify(data));
  };

  if (isLoading) {
    return loader();
  }

  return (
    <>
      <form onSubmit={handleSubmit(handleFormSubmit)} className="tab-wizard wizard-circle wizard needs-validation">
        <section>
          <h4 className="wrap-title mb-3">Personal Information</h4>

          <div className="row">
            <div className="col-md-4">
              <div className="mb-3">
                <label className="brand-label">
                  First Name<span className="required-mark">*</span>
                </label>
                <input id="firstName" type="text" className="form-control" placeholder="Enter First Name" {...register("firstName")} />
                <p className="text-danger mt-2">{errors.firstName && <p>{errors.firstName.message}</p>}</p>
              </div>
            </div>

            <div className="col-md-4">
              <div className="mb-3">
                <label className="brand-label">
                  Last Name<span className="required-mark">*</span>
                </label>
                <input id="lastName" type="text" className="form-control" placeholder="Enter Last Name" {...register("lastName")} />
                <p className="text-danger mt-2">{errors.lastName && <p>{errors.lastName.message}</p>}</p>
              </div>
            </div>

            <div className="col-md-4 col-lg-4">
              <div className="mb-3">
                <label className="brand-label">
                  Email Address<span className="required-mark">*</span>
                </label>
                <input id="email" type="email" className="form-control" placeholder="Enter Email Address" {...register("email")} />
                <p className="text-danger mt-2">{errors.email && <p>{errors.email.message}</p>}</p>
              </div>
            </div>

            <div className="col-md-4 col-lg-4">
              <div className="mb-3">
                <label className="brand-label">
                  Mobile Phone Number<span className="required-mark">*</span>
                </label>
                <Controller
                  name="cell"
                  control={control}
                  render={({ field }) => <input {...field} type="text" class="form-control" placeholder="(XXX) XXX-XXXX" value={formatTelNumber(cell)} {...register("cell")} />}
                />
                <p className="text-danger mt-2">{errors.cell && <p>{errors.cell.message}</p>}</p>
              </div>
            </div>

            <div className="col-md-4 col-lg-4">
              <div className="mb-3">
                <label className="brand-label">Work Phone Number</label>
               
                <Controller
                  name="workPhone"
                  control={control}
                  render={({ field }) => <input {...field} type="text" class="form-control" placeholder="(XXX) XXX-XXXX" value={formatTelNumber(workPhone)} {...register("workPhone")} />}
                />
                <p className="text-danger mt-2">{errors.workPhone && <p>{errors.workPhone.message}</p>}</p>
              </div>
            </div>

            <div className="col-md-4 col-lg-4">
              <div className="mb-3">
                <label className="brand-label">
                  Date of Birth<span className="required-mark">*</span>
                </label>
                <input id="birthday" type="date" max="2099-12-31" className="form-control" placeholder="Enter Enrollment Date" {...register("dob")} />
                <p className="text-danger mt-2">{errors.dob && <p>{errors.dob.message}</p>}</p>
              </div>
            </div>
            {/* Garrage Address  */}
            <div className="col-md-4 col-lg-4">
              <div className="mb-3">
                <label className="brand-label">
                  Street Address<span className="required-mark">*</span>
                </label>
                <PlaceComponent getLocationAddress={getLocation} control={control} name="apt" value={addesss} type="profile-update"></PlaceComponent>
                <p className="text-danger mt-2">
                  <p>{errors?.apt?.message}</p>
                </p>
              </div>
            </div>

            <div className="col-md-5 col-lg-5">
              <div className="mb-3">
                <label className="brand-label" style={{ display: "flex" }}>
                  Garage Street Address <span className="required-mark me-2">*</span>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={sameAsAddress}
                      onChange={(e) => {
                        setSameAsAddress(e.target.checked);
                        setValue("garrageStreetAddress", addesss);
                        setGarageAddress(addesss);
                        setValue("garageZipcode", getValues("zipCode"));
                        setValue("garageState", getValues("state"));
                        setValue("garageCity", getValues("city"));
                      }}
                      id="same-as"
                    />
                    <label className="form-check-label text-capitalize" htmlFor="flexCheckDefault">
                      Same as Address
                    </label>
                  </div>
                </label>
                <PlaceComponent
                  getLocationAddress={getGarageAddress}
                  control={control}
                  name="garrageStreetAddress"
                  value={garageAddress}
                  type="profile-update"
                  readOnly={sameAsAddress}
                ></PlaceComponent>
                <p className="text-danger mt-2">
                  <p>{errors?.garrageStreetAddress?.message}</p>
                </p>
              </div>
            </div>

            <div className="col-md-3 col-lg-3">
              <div className="mb-3">
                <label className="brand-label">
                  Effective Date<span className="required-mark">*</span>
                </label>
                <input
                  id="garraging_effective_date"
                  type="date"
                  max="2099-12-31"
                  className="form-control"
                  placeholder="Enter Garraging Effective Date"
                  {...register("garragingEffectiveDate")}
                />
                <p className="text-danger mt-2">{errors.garragingEffectiveDate && <p>{errors.garragingEffectiveDate.message}</p>}</p>
              </div>
            </div>
          </div>
          <div className="text-end">
            <button className="btn btn-primary ms-2">Next</button>
          </div>
        </section>
      </form>
    </>
  );
};

export default Step1;
