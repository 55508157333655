import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import Badge from "react-bootstrap/Badge";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import { NumericFormat } from "react-number-format";
import { formatTelNumber } from "../../../Common/Utils";
import { cellRegex, emailRegex, floatRegex, nameRegex, zipcodeRegex } from "../../../Common/regex";
import useAgencyInfo from "../../../hooks/useAgencyInfo.hook";
import Footer from "../../ancfooter";
import PlaceComponent from "../GooglePlace";
import PlaceComponentFloating from "../GooglePlaceFloating";
import BusinessHour from "../../../Common/BusinessHour";
import Swal from "sweetalert2";
import axios from "axios";
import { default as axiosInstance } from "../../../config/axios-config";

const getValidationSchema = (activeNav, agentId) => {
  let schema = yup.object().shape({});
  if (activeNav === "individual") {
    schema = schema.shape({
      name: yup.string().trim().matches(nameRegex, "Please enter a valid Name").required().label("Name"),
      gender: yup.string().oneOf(["Male", "Female"], "Please select a gender").required().label("Gender"),
      citizen: yup.string().oneOf(["citizen", "non-citizen"], "Please select a citizenship status").required().label("Citizenship Status"),
      dob: yup
        .date()
        .typeError("Date of Birth is a required field")
        .max(new Date(Date.now() - 86400000), "Date of birth can not be current or future date")
        .required()
        .label("Date of Birth"),
      income: yup.string().trim().required().matches(floatRegex, "Enter a valid income").label("Income"),
      zip: yup.string().trim().required().length(5).matches(zipcodeRegex, "Enter a valid Zip Code").label("Zip Code"),
      emailAddress: yup.string().trim().required().matches(emailRegex, "Enter a valid email address").label("Email Id"),
      mobile: yup.string().trim().required().matches(cellRegex, "Enter a valid Mobile Number").label("Mobile"),
      consent: yup.boolean().oneOf([true], "Terms and conditions is a required field"),
    });
  }
  if (activeNav === "company") {
    schema = schema.shape({
      companyName: yup.string().trim().required().matches(nameRegex, "Please enter a valid Name").required().label("Company Name"),
      feinNum: yup
        .string()
        .trim()
        .nullable(true)
        .required()
        .matches(/^\d{2}-\d{7,8}$/, "Please enter a valid fein")
        .label("FEIN"),
      companyAddress: yup.string().trim().required("Company address is required").label("Company address"),
      contactPerson: yup.string().trim().required().matches(nameRegex, "Please enter a valid Contact Person Name").required().label("Contact Person Name"),
      emailAddress: yup.string().trim().required().matches(emailRegex, "Enter a valid email address").label("Email Id"),
      mobile: yup.string().trim().required().matches(cellRegex, "Enter a valid Mobile Number").label("Mobile"),
      fullTimerEmployeeNo: yup.string().trim().required().nullable(true).required().label("No of Full-Time Employee"),
      partTimerEmployeeNo: yup.string().trim().required().nullable(true).required().label("No of Part-Time Employee"),
      income: yup.string().trim().required().nullable(true).required().label("Annual Payroll"),
      submitTWC: yup.boolean().oneOf([true], "TWC is a required field"),
      consent: yup.boolean().oneOf([true], "Terms and conditions is a required field"),
    });
  }
  if (activeNav === "life") {
    schema = schema.shape({
      name: yup.string().trim().matches(nameRegex, "Please enter a valid Name").required().label("Name"),

      dob: yup
        .date()
        .typeError("Date of Birth is a required field")
        .max(new Date(Date.now() - 86400000), "Date of birth can not be current or future date")
        .required()
        .label("Date of Birth"),
      emailAddress: yup.string().trim().required().matches(emailRegex, "Enter a valid email address").label("Email Id"),
      mobile: yup.string().trim().required().matches(cellRegex, "Enter a valid Mobile Number").label("Mobile"),
      deathBenefitAmount: yup.string().trim().required().matches(floatRegex, "Enter a valid benefit amount").label("Death Benefit Amount"),
      duration: yup.string().required().nullable(true).label("Carrier duration"),
      policy: yup.string().oneOf(["term", "wholeLife"], "Please select a policy").required().label("Policy"),
      consent: yup.boolean().oneOf([true], "Terms and conditions is a required field"),
    });
  }
  if (agentId == null) {
    schema = schema.shape({
      agentId: yup.string().required("Selecting an agent is required.").notOneOf(["", null, undefined], "You must select a valid agent."),
    });
  }
  return schema;
};
const queryParams = new URLSearchParams(window.location.search);
const DEVURL = process.env.REACT_APP_ANC_API + "/api";
const DEVURL_MANAGEAGENCY = process.env.REACT_APP_ANC_API + "/api/manageagency";

const Search = () => {
  const { agencyName, agentName } = useParams();
  const { data, agencyId, agentId, isValidUrl } = useAgencyInfo(agencyName, agentName);
  const [agentList, setAgentList] = useState();
  const [loading, setLoading] = useState(false);
  const [geoLocation, setGeoLocation] = useState(null);
  const [showModel, setShowModal] = useState(false);
  const [showCompanyModal, setShowCompanyModal] = useState(false);
  const [isAddDependent, setIsAddDependent] = useState(true);
  const [dependents, setDependents] = useState([]);
  const [activeNav, setActiveNav] = useState("individual");
  const [addesss, setAddress] = useState("");
  const [hoursOfOperation, setHoursOfOperation] = useState(["", ""]);
  const [newDependent, setNewDependent] = useState({
    age: "",
    gender: "",
    tobacco: "No",
  });
  const [newDependentErrors, setNewDependentErrors] = useState({
    age_error: "",
    gender_error: "",
    tobacco_error: "",
  });
  const [newDependent_company, setNewDependent_company] = useState({
    name: "",
    dob: "",
  });
  const [newDependentErrors_company, setNewDependentErrors_company] = useState({
    name_error: "",
    dob_error: "",
  });
  const validationSchema = getValidationSchema(activeNav, agentId);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    getValues,
    control,
    watch,
  } = useForm({
    // defaultValues: {
    //   name: "",
    //   gender: "",
    //   dob: "",
    //   income: "",
    //   zip: "",
    //   emailAddress: "",
    //   mobile: "",
    //   countyfips: "",
    //   consent: false,
    //   agentId: agentId,
    //   dependents: [],
    //   companyName: "",
    //   companyAddress: "",
    //   feinNum: null,
    //   employeeNo: null,
    //   hoursOfOperation: null,
    // },
    resolver: yupResolver(validationSchema),
  });
  const navigation = useNavigate();
  const mobile = watch("mobile");
  console.log("first", getValues("companyAddress"));
  const getData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    setGeoLocation(res.data);
  };
  const getLocation = async (data) => {
    let address_components = data.address_components;
    let formatted_address = data.formatted_address;
    console.log("formatted_address", formatted_address);
    // setValue("locationAddress", data.name);
    // setAddress(data.name);
    setValue("companyAddress", formatted_address);
    setAddress(formatted_address);
    formatted_address = formatted_address.split(",");
    console.log("formatted_address", formatted_address);
    let zip_code = "";
    address_components.map((data) => {
      if (data.types.includes("postal_code")) {
        zip_code = data.long_name + zip_code;
      }
      // if (data.types.includes("postal_code_suffix")) {
      //     zip_code =  zip_code +'-'+ data.long_name;
      // }
      if (data.types.includes("locality")) {
        setValue("locationCity", data.long_name);
      }
      if (data.types.includes("administrative_area_level_1")) {
        setValue("locationState", data.long_name);
      }
    });
    setValue("locationZip", zip_code);
  };

  console.log("errors", errors);
  const handleFormSubmit = async (data) => {
    console.log("data", data);

    if (activeNav === "individual") {
      const updatedData = {
        ...data,
        geolocation: geoLocation,
        agencyId,
        agentId: agentId == null ? data.agentId : agentId,
      };

      const result_Url = queryParams.get("id") != null ? "/result?id=" + agencyId : agentName != null ? `/result/${agencyName}/${agentName}` : `/result/${agencyName}`;
      const dob = new Date(data.dob);
      const today = new Date();
      const yearsAgo65 = new Date(today.getFullYear() - 65, today.getMonth(), today.getDate());
      if (dob <= yearsAgo65 && data.citizen === "citizen") {
        Swal.fire({
          icon: "info",
          text: "You may qualify for Medicare. Our Medicare expert assist you shortly.",
        }).then((result) => {
          if (result.isConfirmed) {
            navigation(result_Url, { state: { data: updatedData } });
          } else {
            return;
          }
        });
      } else {
        navigation(result_Url, { state: { data: updatedData } });
      }
    }
    if (activeNav === "company") {
      setLoading(true);
      const updatedData = {
        ...data,
        agencyId: +agencyId,
        agentId: agentId == null ? data.agentId : agentId,
      };
      console.log("updatedData", updatedData);
      Swal.fire({
        title: "Are you sure you want to submit the form?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
      }).then((result) => {
        if (result.isConfirmed) {
          axiosInstance
            .post(`${DEVURL}/GroupInsuranceApp/InsertGroupInsuranceApp`, updatedData)
            .then((res) => {
              if (res.status === 200) {
                setLoading(false);
                setAddress(null);
                setDependents([]);
                reset();
                Swal.fire({
                  title: "Successfully Submitted!",
                  text: "One of our healthcare insurance experts will contact you from 817-710-8738.",
                  icon: "success",
                });
              }
            })
            .catch((error) => {
              console.log(error);
              setLoading(false);
            });
        } else {
          setLoading(false);
          return;
        }
      });
    }
    if (activeNav === "life") {
      setLoading(true);
      const updatedData = {
        ...data,
        agencyId: +agencyId,
        agentId: agentId == null ? data.agentId : agentId,
      };
      console.log("updatedData", updatedData);
      Swal.fire({
        title: "Are you sure you want to submit the form?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
      }).then((result) => {
        if (result.isConfirmed) {
          axiosInstance
            .post(`${DEVURL}/LifeInsuranceApp/InsertLifeInsuranceApp`, updatedData)
            .then((res) => {
              if (res.status === 200) {
                setLoading(false);
                reset();
                Swal.fire({
                  title: "Successfully Submitted!",
                  text: "One of our healthcare insurance experts will contact you from 817-710-8738.",
                  icon: "success",
                });
              }
            })
            .catch((error) => {
              console.log(error);
              setLoading(false);
            });
        } else {
          setLoading(false);
          return;
        }
      });
    }
  };

  const getAgentList = async () => {
    await axios
      .get(`${DEVURL_MANAGEAGENCY}/GetAgentInfoByAgency/${agencyName}`)
      .then((response) => {
        if (response.status === 200) {
          setAgentList(response.data.agent_info_list);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (agencyName) getAgentList();
  }, [agencyName]);

  const addMoreDependent = () => {
    if (newDependent.age == "" || newDependent.age < 0 || newDependent.gender === "") {
      let errors = {};
      if (newDependent.age == "" || newDependent.age < 0) {
        errors = {
          ...newDependentErrors,
          age_error: "Age should be greater than 0",
        };
      } else {
        errors = { ...newDependentErrors, age_error: "" };
      }
      if (newDependent.gender === "") {
        errors = {
          ...newDependentErrors,
          gender_error: "Gender is required field",
        };
      } else {
        errors = { ...newDependentErrors, gender_error: "" };
      }
      setNewDependentErrors(errors);
      return false;
    }
    setNewDependentErrors({
      age_error: "",
      gender_error: "",
      tobacco_error: "",
    });
    const newDependent_ = [...dependents, newDependent];
    setDependents(newDependent_);
    setNewDependent({ age: "", gender: "", tobacco: "No" });
    setIsAddDependent(false);
    setValue("dependents", newDependent_);
  };

  const addMoreDependent_Company = () => {
    let errors = { ...newDependentErrors_company };

    if (newDependent_company.dob === "") {
      errors.dob_error = "Date of birth is required field";
    } else {
      errors.dob_error = "";
    }

    if (newDependent_company.name === "") {
      console.log("sfgdfffff", newDependent_company, newDependentErrors_company);
      errors.name_error = "Name is required field";
    } else {
      errors.name_error = "";
    }

    if (errors.name_error || errors.dob_error) {
      setNewDependentErrors_company(errors);
      return false;
    }

    setNewDependentErrors_company({
      name_error: "",
      dob_error: "",
    });

    const newDependent_ = [...dependents, newDependent_company];
    setDependents(newDependent_);
    setNewDependent_company({ name: "", dob: "" });
    setIsAddDependent(false);
    setValue("dependents", newDependent_);
  };
  const getAgentName = () => {
    if (agentList?.length) {
      const agent = agentList?.find((agent) => agent.id === getValues("agentId"));
      console.log("agent", agent, getValues("agentId"));
      return `${agent?.firstName} ${agent?.lastName}`;
    }
  };
  const newDependentHandler = async (e) => {
    console.log("e", e.target);
    let { name, value } = e.target;
    if (name === "tobacco") {
      value = e.target.checked ? "Yes" : "No";
    }
    const updatedDependent = { ...newDependent, [name]: value };
    setNewDependent(updatedDependent);
  };
  const newDependentHandler_company = async (e) => {
    console.log("e", e.target);
    let { name, value } = e.target;

    const updatedDependent = { ...newDependent_company, [name]: value };
    setNewDependent_company(updatedDependent);
  };

  const removeElement = (index, type) => {
    if (type === "driver") {
      const dependentsCopy = [...dependents];
      dependentsCopy.splice(index, 1);
      if (dependentsCopy.length === 0) {
        setIsAddDependent(true);
      }
      setDependents(dependentsCopy);
      setValue("dependents", dependentsCopy);
    }
  };
  const handleFeinChange = (e) => {
    console.log(e.keyCode);
    let inputValue = e.target.value;
    inputValue = inputValue.replace(/[^0-9]/g, "");
    if (inputValue.length > 2) {
      inputValue = inputValue.slice(0, 2) + "-" + inputValue.slice(2);
    }
    const name = e.target.name;
    setValue(name, inputValue);
  };

  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    reset();
    setAddress(null);
    setDependents([]);
    setIsAddDependent(true);
  }, [activeNav, reset]);

  const getTimeRange = async (range) => {
    setValue("hoursOfOperation", range);
  };

  const getStartApplicationURL = () => {
    if (agentName == null) {
      return `/HealthForm/${agencyName}`;
    } else {
      return `/HealthForm/${agencyName}/${agentName}`;
    }
  };
  const getRetrieveURL = agentName == null ? `/retrieve/health/${agencyName}` : `/retrieve/health/${agencyName}/${agentName}`;

  return (
    <>
      <section className="health-section">
        <div className="container" style={{ "z-index": "999" }}>
          {isValidUrl === false ? (
            <div className="health-content">
              <h1>Enter valid URL</h1>
            </div>
          ) : isValidUrl === true ? (
            <div className="row align-items-center">
              <div className="col-lg-6" style={{ marginTop: "20px" }}>
                <h1
                  style={{
                    position: "absolute",
                    top: 10,
                    right: 50,
                    color: "#fff",
                  }}
                >{`${agencyName}${data.phone ? ` / ${data.phone}` : ""}`}</h1>
                <div className="health-form">
                  <h4 className="mb-2">Get estimated price on health/life plan(s)</h4>
                  <form onSubmit={handleSubmit(handleFormSubmit)}>
                    <nav class="nav nav-pills nav-justified my-4 border border-primary rounded ">
                      <a className={`nav-link  ${activeNav === "individual" ? "active" : ""}`} aria-current="page" onClick={() => setActiveNav("individual")}>
                        Individual
                      </a>
                      <a className={`nav-link  ${activeNav === "company" ? "active" : ""}`} onClick={() => setActiveNav("company")}>
                        Group
                      </a>
                      <a className={`nav-link  ${activeNav === "life" ? "active" : ""}`} onClick={() => setActiveNav("life")}>
                        Life
                      </a>
                    </nav>
                    <div className="mb-md-3">
                      {activeNav === "individual" && (
                        <>
                          <div className="form-floating mb-1">
                            <input type="text" tabIndex={1} className="form-control brand-input" id="floatingName" placeholder="Your Name" {...register("name")} />
                            <label htmlFor="floatingName">Your Name</label>
                            <p className="text-danger mt-1">{errors.name && <p>{errors.name.message}</p>}</p>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-floating mb-1">
                                <div>
                                  <input type="radio" tabIndex={2} className="form-check-input" id="maleGender" value="Male" {...register("gender")} />
                                  <label htmlFor="maleGender" className="mx-2">
                                    Male
                                  </label>
                                  <input type="radio" tabIndex={3} className="form-check-input" id="femaleGender" value="Female" {...register("gender")} />
                                  <label htmlFor="femaleGender" className="mx-2">
                                    Female
                                  </label>
                                  <p className="text-danger mt-1">{errors.gender && <p>{errors.gender.message}</p>}</p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-floating mb-1">
                                <div>
                                  <input type="radio" tabIndex={2} className="form-check-input" id="citizen" value="citizen" {...register("citizen")} />
                                  <label htmlFor="citizen" className="mx-2">
                                    Citizen
                                  </label>
                                  <input type="radio" tabIndex={3} className="form-check-input" id="nonCitizen" value="non-citizen" {...register("citizen")} />
                                  <label htmlFor="nonCitizen" className="mx-2">
                                    Non-citizen
                                  </label>
                                  <p className="text-danger mt-1">{errors.citizen && <p>{errors.citizen.message}</p>}</p>
                                </div>
                              </div>
                            </div>

                            <div className="form-floating mb-1">
                              <input
                                type="date"
                                tabIndex={4}
                                className="form-control brand-input custom-date-input"
                                id="floatingDob"
                                placeholder="Your Date of Birth"
                                max="2099-12-31"
                                onChange={(e) => {
                                  setValue("dob", e.target.valueAsDate.toISOString());
                                }}
                              />
                              <label htmlFor="floatingDob">&nbsp; Date of Birth</label>
                              <p className="text-danger mt-1">{errors.dob && <p>{errors.dob.message}</p>}</p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6 form-floating mb-1">
                              <Controller
                                name="income"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                  <NumericFormat
                                    allowNegative={false}
                                    {...field}
                                    id="floatingIncome"
                                    tabIndex={5}
                                    type="numeric"
                                    placeholder="Annual Income"
                                    className="form-control brand-input"
                                    thousandSeparator={true}
                                    prefix="$"
                                    name="income"
                                    onValueChange={(values) => {
                                      setValue("income", values.floatValue);
                                    }}
                                    {...register("income")}
                                  />
                                )}
                              />

                              <label htmlFor="floatingIncome">&nbsp;Annual Income</label>
                              <p className="text-danger mt-1">{errors.income && <p>{errors.income.message}</p>}</p>
                            </div>
                            <div className="col-md-6 form-floating mb-1">
                              <input tabIndex={6} type="text" maxLength={5} className="form-control brand-input" id="floatingZip" placeholder="Your Zipcode" {...register("zip")} />
                              <label htmlFor="floatingZip">&nbsp;Zipcode</label>
                              <p className="text-danger mt-1">{errors.zip && <p>{errors.zip.message}</p>}</p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6 form-floating mb-1">
                              <input
                                type="text"
                                tabIndex={7}
                                className="form-control brand-input"
                                id="floatingEmailAddress"
                                placeholder="name@example.com"
                                {...register("emailAddress")}
                              />
                              <label htmlFor="floatingEmailAddress">&nbsp;Email Id</label>
                              <p className="text-danger mt-1">{errors.emailAddress && <p>{errors.emailAddress.message}</p>}</p>
                            </div>
                            <div className=" col-md-6 form-floating mb-1">
                              <Controller
                                name="mobile"
                                control={control}
                                render={({ field }) => (
                                  <input
                                    {...field}
                                    type="tel"
                                    tabIndex={8}
                                    id="floatingMobileNum"
                                    class="form-control brand-input"
                                    placeholder="(XXX) XXX-XXXX"
                                    value={formatTelNumber(mobile)}
                                    {...register("mobile")}
                                  />
                                )}
                              />

                              <label htmlFor="floatingEmailAddress">&nbsp;Mobile</label>
                              <p className="text-danger mt-1">{errors.mobile && <p>{errors.mobile.message}</p>}</p>
                            </div>
                          </div>
                          <Badge bg="info">
                            {dependents?.length > 0 && <span style={{ color: "#1e2dde" }}>{dependents?.length > 0 ? ` You added ${dependents?.length} dependent` : ""}</span>}
                          </Badge>
                          <div class="d-grid gap-2 mx-auto d-md-flex">
                            <button type="button" className="brand-btn-quote" onClick={() => setShowModal(true)}>
                              Add {dependents?.length > 0 ? "/ Update" : ""} Dependent
                            </button>
                          </div>
                        </>
                      )}
                      {activeNav === "company" && (
                        <>
                          <div className="row">
                            <div className="col-md-6 ">
                              <div className="mb-3">
                                <label className="brand-label">
                                  Company Name<span className="required-mark">*</span>
                                </label>
                                <input id="firstName" type="text" className="form-control" placeholder="Enter Company Name" {...register("companyName")} />
                                <p className="text-danger mt-2">{errors.companyName && <p>{errors.companyName.message}</p>}</p>
                              </div>
                            </div>
                            <div className="col-md-6 ">
                              <div className="mb-3">
                                <label className="brand-label">
                                  FEIN<span className="required-mark">*</span>
                                </label>
                                <input className="form-control" type="text" name="feinNum" onKeyUp={handleFeinChange} placeholder="Enter FEIN " {...register("feinNum")} />
                                <p className="text-danger">{errors.feinNum && <p>{errors.feinNum.message}</p>}</p>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6 ">
                              <div className="mb-3">
                                <label className="brand-label">
                                  Company Address<span className="required-mark">*</span>
                                </label>
                                <PlaceComponent
                                  getLocationAddress={getLocation}
                                  value={addesss}
                                  onChange={(e) => {
                                    console.log("e", e.target.value);
                                    setAddress(e.target.value);
                                    setValue("companyAddress", e.target.value);
                                    setValue("locationCity", null);
                                    setValue("locationState", null);
                                    setValue("locationZip", null);
                                  }}
                                  type="profile-update"
                                  name="companyAddress"
                                  control={control}
                                ></PlaceComponent>
                                <p className="text-danger">{errors.companyAddress && <p>{errors.companyAddress?.message}</p>}</p>
                              </div>
                            </div>
                            <div className="col-md-6 ">
                              <div className="mb-3">
                                <label className="brand-label">
                                  Contact Person Name<span className="required-mark">*</span>
                                </label>
                                <input id="contactPerson" type="text" className="form-control" placeholder="Enter Contact Person Name" {...register("contactPerson")} />
                                <p className="text-danger mt-2">{errors.contactPerson && <p>{errors.contactPerson.message}</p>}</p>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div class="col-md-6 ">
                              <div class="mb-3">
                                <label class="brand-label">
                                  Email Id<span className="required-mark">*</span>
                                </label>
                                <input type="email" class="form-control" placeholder="Enter Email Address" {...register("emailAddress")} />
                                <p className="text-danger">{errors.emailAddress?.message}</p>
                              </div>
                            </div>
                            <div class="col-md-6 ">
                              <div class="mb-3">
                                <label class="brand-label">
                                  Mobile Number<span className="required-mark">*</span>
                                </label>
                                <Controller
                                  name="mobile"
                                  control={control}
                                  render={({ field }) => (
                                    <input {...field} type="text" class="form-control" placeholder="(XXX) XXX-XXXX" value={formatTelNumber(mobile)} {...register("mobile")} />
                                  )}
                                />

                                <p className="text-danger">{errors.mobile?.message}</p>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6 ">
                              <div className="mb-3">
                                <label className="brand-label">
                                  No of Full Time Employees<span className="required-mark">*</span>
                                </label>
                                <input id="fullTimerEmployeeNo" type="text" className="form-control" placeholder="Enter No of Employees" {...register("fullTimerEmployeeNo")} />
                                <p className="text-danger mt-2">{errors.fullTimerEmployeeNo && <p>{errors.fullTimerEmployeeNo.message}</p>}</p>
                              </div>
                            </div>
                            <div className="col-md-6 ">
                              <div className="mb-3">
                                <label className="brand-label">
                                  No of part-Time Employees<span className="required-mark">*</span>
                                </label>
                                <input id="partTimerEmployeeNo" type="text" className="form-control" placeholder="Enter No of Employees" {...register("partTimerEmployeeNo")} />
                                <p className="text-danger mt-2">{errors.partTimerEmployeeNo && <p>{errors.partTimerEmployeeNo.message}</p>}</p>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div class="col-md-6 ">
                              <div class="mb-3">
                                <label class="brand-label">
                                  Annual Payroll<span className="required-mark">*</span>
                                </label>
                                <Controller
                                  name="income"
                                  control={control}
                                  defaultValue=""
                                  render={({ field }) => (
                                    <NumericFormat
                                      allowNegative={false}
                                      {...field}
                                      id="buildingValue"
                                      className="form-control"
                                      thousandSeparator={true}
                                      prefix="$"
                                      name="income"
                                      onValueChange={(values) => {
                                        setValue("income", values.floatValue);
                                      }}
                                      placeholder="Enter Annual Income"
                                      {...register("income")}
                                    />
                                  )}
                                />

                                <p className="text-danger">{errors.income && <p>{errors.income.message}</p>}</p>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6 ">
                            <div className="form-floating mb-3 brand-label">
                              <input type="checkbox" id="submitTWC" {...register("submitTWC")} />
                              &nbsp; I need to submit TWC report
                              <p className="text-danger mt-1">{errors.submitTWC && <p>{errors.submitTWC.message}</p>}</p>
                            </div>
                          </div>
                          <Badge bg="info">
                            {dependents?.length > 0 && <span style={{ color: "#1e2dde" }}>{dependents?.length > 0 ? ` You added ${dependents?.length} dependent` : ""}</span>}
                          </Badge>
                          <div class="d-grid gap-2 mx-auto d-md-flex">
                            <button type="button" className="brand-btn-quote" onClick={() => setShowCompanyModal(true)}>
                              Add {dependents?.length > 0 ? "/ Update" : ""} Dependent
                            </button>
                          </div>
                        </>
                      )}
                      {activeNav === "life" && (
                        <>
                          <div className="row">
                            <div className="col-md-6 ">
                              <div className="mb-3">
                                <label className="brand-label">
                                  Name<span className="required-mark">*</span>
                                </label>
                                <input id="name" type="text" className="form-control" placeholder="Full Name" {...register("name")} />
                                <p className="text-danger mt-2">{errors.name && <p>{errors.name.message}</p>}</p>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label className="brand-label">
                                  Date of Birth<span className="required-mark">*</span>
                                </label>
                                <input id="birthday" type="date" max="2099-12-31" className="form-control" placeholder="Enter Enrollment Date" {...register("dob")} />
                                <p className="text-danger mt-2">{errors.dob && <p>{errors.dob.message}</p>}</p>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div class="col-md-6 ">
                              <div class="mb-3">
                                <label class="brand-label">
                                  Email Id<span className="required-mark">*</span>
                                </label>
                                <input type="email" class="form-control" placeholder="Enter Email Address" {...register("emailAddress")} />
                                <p className="text-danger">{errors.emailAddress?.message}</p>
                              </div>
                            </div>
                            <div class="col-md-6 ">
                              <div class="mb-3">
                                <label class="brand-label">
                                  Mobile Number<span className="required-mark">*</span>
                                </label>
                                <Controller
                                  name="mobile"
                                  control={control}
                                  render={({ field }) => (
                                    <input {...field} type="text" class="form-control" placeholder="(XXX) XXX-XXXX" value={formatTelNumber(mobile)} {...register("mobile")} />
                                  )}
                                />

                                <p className="text-danger">{errors.mobile?.message}</p>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div class="col-md-6 ">
                              <div class="mb-3">
                                <label class="brand-label">
                                  Death Benefit Amount<span className="required-mark">*</span>
                                </label>
                                <Controller
                                  name="deathBenefitAmount"
                                  control={control}
                                  defaultValue=""
                                  render={({ field }) => (
                                    <NumericFormat
                                      allowNegative={false}
                                      {...field}
                                      id="deathBenefitAmount"
                                      className="form-control"
                                      thousandSeparator={true}
                                      prefix="$"
                                      name="deathBenefitAmount"
                                      onValueChange={(values) => {
                                        setValue("deathBenefitAmount", values.floatValue);
                                      }}
                                      placeholder="$"
                                      {...register("deathBenefitAmount")}
                                    />
                                  )}
                                />

                                <p className="text-danger">{errors.deathBenefitAmount && <p>{errors.deathBenefitAmount.message}</p>}</p>
                              </div>
                            </div>
                            <div className="col-md-6 ">
                              <div className="mb-3">
                                <label className="brand-label">
                                  Duration<span className="required-mark">*</span>
                                </label>
                                <input id="duration" type="number" className="form-control" placeholder="Enter Number of Years" {...register("duration")} />
                                <p className="text-danger mt-2">{errors.duration && <p>{errors.duration.message}</p>}</p>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div class="col-md-6 ">
                              <div class="mb-3">
                                <label class="brand-label d-grid">
                                  <div>Policy Term</div>
                                </label>
                                <div class="form-check form-check-inline">
                                  <input class="form-check-input" type="radio" name="inlineRadioOptions" id="term" value="term" {...register("policy")} />
                                  <label class="form-check-label" for="term">
                                    Term
                                  </label>
                                </div>
                                <div class="form-check form-check-inline">
                                  <input class="form-check-input" type="radio" name="inlineRadioOptions" id="wholeLife" value="wholeLife" {...register("policy")} />
                                  <label class="form-check-label" for="wholeLife">
                                    Whole life
                                  </label>
                                </div>

                                <p className="text-danger">{errors.policy && <p>{errors.policy.message}</p>}</p>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      {agentId == null ? (
                        <div className="row ">
                          <div className="col-md-6 col-lg-6">
                            {/* {
                              primaryId ?   <label className="brand-label">
                              Selected Agent: &nbsp;
                              {getAgentName()}
                            </label>: */}

                            <div className="mt-3">
                              <label className="brand-label">
                                Select your contact person<span className="required-mark">*</span>
                              </label>
                              <select class="form-select" aria-label="Default select example" {...register("agentId")}>
                                <option value={""} selected>
                                  Select Agent
                                </option>
                                {agentList?.length ? (
                                  agentList.map((agent) => (
                                    <option value={agent.id}>
                                      {agent?.firstName} {agent?.lastName}
                                    </option>
                                  ))
                                ) : (
                                  <option disabled>No agent found</option>
                                )}
                              </select>
                              <p className="text-danger mt-2">{errors.agentId && <p>{errors.agentId.message}</p>}</p>
                            </div>
                            {/* } */}
                          </div>
                        </div>
                      ) : null}
                      <div className="form-floating mb-3 brand-label">
                        <input type="checkbox" tabIndex={9} id="floatingeConsent" {...register("consent")} />
                        &nbsp; I give my consent to allow 3rd parties to use my data to get me quote(s) from multiple insurance carriers including from Healthcare.gov. I agree to
                        receive your email and text notifications related to the quote.
                        <p className="text-danger mt-1">{errors.consent && <p>{errors.consent.message}</p>}</p>
                      </div>
                    </div>
                    {loading ? (
                      <button disabled tabIndex={10} className="brand-btn-quote">
                        <div class="spinner-border spinner-border-sm text-light" role="status">
                          <span class="visually-hidden">Loading...</span>
                        </div>
                      </button>
                    ) : activeNav === "individual" ? (
                      <button type="submit" tabIndex={10} className="brand-btn-quote">
                      Request Quote
                      </button>
                    ) : (
                      <button type="submit" tabIndex={10} className="brand-btn-quote">
                        Submit
                      </button>
                    )}
                  </form>
                  {activeNav === "individual" && (
                    <>
                      <hr />
                      <div class="d-grid gap-2 mx-auto d-md-flex">
                        <a href={getRetrieveURL} className="brand-btn-quote">
                          Retrieve My Application
                        </a>
                        <a href={getStartApplicationURL()} className="brand-btn-quote">
                          Start Application
                        </a>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="health-content">
                  <h1>Simplifying Healthcare Costs for You. See Plans & Prices</h1>
                </div>
              </div>
            </div>
          ) : null}
        </div>
        {/* <div className="hero-custom-shape-divider-bottom">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
              className="shape-fill"
            ></path>
          </svg>
        </div> */}
        <div className="hero-custom-shape-divider-bottom">
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path
              d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
              className="shape-fill"
            ></path>
          </svg>
        </div>
      </section>

      <Modal size="lg" show={showModel} onHide={() => setShowModal(false)} aria-labelledby="example-modal-sizes-title-lg">
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">Add Dependent Information</Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
          <div className="row">
            {dependents?.length > 0 && (
              <div className="table-responsive">
                <table className="table table-striped activity-status-table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col" width="50">
                        #
                      </th>
                      <th scope="col"> Age</th>
                      <th scope="col"> Gender</th>
                      <th scope="col">Tobacco</th>

                      <th scope="col" width="100">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="show-dependent-list">
                    {dependents &&
                      dependents.map((driver, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{driver.age}</td>
                          <td>{driver.gender}</td>
                          <td>{driver.tobacco}</td>
                          <td>
                            <a onClick={() => removeElement(index, "driver")} className="btn btn-danger">
                              Remove
                            </a>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
            {!isAddDependent && (
              <div className="text-end">
                <a
                  onClick={() => {
                    setShowModal(false);
                    setValue("dependents", dependents);
                  }}
                  className="btn btn-success m-2"
                >
                  Close
                </a>
                <a onClick={() => setIsAddDependent(true)} className="btn btn-primary m-2">
                  Add More Dependent
                </a>
              </div>
            )}

            {isAddDependent && (
              <Card className="p-3 mb-3">
                <div className="row">
                  <div className="col-md-3 col-lg-3">
                    <div className="mb-3">
                      <label className="brand-label">
                        Dependent Age <span className="required-mark">*</span>
                      </label>
                      <input id="age" type="number" required min={0} name="age" className="form-control" placeholder="Enter Age" onChange={newDependentHandler} />

                      <p className="text-danger mt-2">{newDependentErrors?.age_error && <p>{newDependentErrors?.age_error}</p>}</p>
                    </div>
                  </div>
                  <div className="col-md-3 col-lg-3">
                    <div className="mb-3">
                      <label className="brand-label">
                        Dependent Gender
                        <span className="required-mark"> *</span>
                      </label>
                      <br />
                      <input className="form-check-input" type="radio" name="gender" onChange={newDependentHandler} id="gender" value="Male" /> &nbsp;
                      <label className="form-check-label text-capitalize" for="gender">
                        Male
                      </label>
                      &nbsp;&nbsp;
                      <input className="form-check-input" type="radio" name="gender" onChange={newDependentHandler} id="gender1" value="Female" />
                      &nbsp;
                      <label className="form-check-label text-capitalize" for="gender1">
                        Female
                      </label>
                      <p className="text-danger mt-2">{newDependentErrors?.gender_error && <p>{newDependentErrors?.gender_error}</p>}</p>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6 ">
                    <div className="">
                      <label className="brand-label">
                        Dependent Tobacco
                        {/*<span className="required-mark"> *</span>*/}
                      </label>
                      <div style={{ display: "flex" }}>
                        <div className="" style={{ width: "50%" }}>
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={newDependent?.tobacco === "Yes" ? true : false}
                              name="tobacco"
                              id="tobacco"
                              onChange={newDependentHandler}
                            />
                            <label className="form-check-label text-capitalize" htmlFor="tobacco">
                              {newDependent?.tobacco}
                            </label>
                          </div>

                          <p className="text-danger mt-2">{newDependentErrors?.tobacco_error && <p>{newDependentErrors?.tobacco_error}</p>}</p>
                        </div>
                        <div>
                          {" "}
                          <a onClick={addMoreDependent} className="btn btn-primary mx-2" style={{ "min-width": "30%" }}>
                            Add
                          </a>
                          <a onClick={() => setIsAddDependent(false)} className="btn btn-warning" style={{ "min-width": "30%" }}>
                            Cancel
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            )}
          </div>
        </Modal.Body>
      </Modal>
      <Modal size="lg" show={showCompanyModal} onHide={() => setShowCompanyModal(false)} aria-labelledby="example-modal-sizes-title-lg">
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">Add Dependent Information</Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
          <div className="row">
            {dependents?.length > 0 && (
              <div className="table-responsive">
                <table className="table table-striped activity-status-table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col" width="50">
                        #
                      </th>
                      <th scope="col"> Name</th>
                      <th scope="col"> Date of Birth</th>

                      <th scope="col" width="100">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="show-dependent-list">
                    {dependents &&
                      dependents.map((driver, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{driver.name}</td>
                          <td>{driver.dob}</td>
                          <td>
                            <a onClick={() => removeElement(index, "driver")} className="btn btn-danger">
                              Remove
                            </a>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
            {!isAddDependent && (
              <div className="text-end">
                <a
                  onClick={() => {
                    setShowCompanyModal(false);
                    setValue("dependents", dependents);
                  }}
                  className="btn btn-success m-2"
                >
                  Close
                </a>
                <a onClick={() => setIsAddDependent(true)} className="btn btn-primary m-2">
                  Add More Dependent
                </a>
              </div>
            )}

            {isAddDependent && (
              <Card className="p-3 mb-3">
                <div className="row" style={{ alignItems: "center" }}>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label className="brand-label">
                        Dependent Name <span className="required-mark">*</span>
                      </label>
                      <input id="name" type="text" name="name" className="form-control" placeholder="Enter Name" onChange={newDependentHandler_company} />

                      <p className="text-danger mt-2">{newDependentErrors_company?.name_error && <p>{newDependentErrors_company?.name_error}</p>}</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label className="brand-label">
                        Dependent Date of Birth<span className="required-mark">*</span>
                      </label>
                      <input
                        id="birthday"
                        type="date"
                        max="2099-12-31"
                        className="form-control"
                        name="dob"
                        placeholder="Enter Date of Birth"
                        onChange={newDependentHandler_company}
                      />
                      <p className="text-danger mt-2">{newDependentErrors_company?.dob_error && <p>{newDependentErrors_company?.dob_error}</p>}</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="">
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div>
                          <a onClick={addMoreDependent_Company} className="btn btn-primary mx-2" style={{ "min-width": "30%" }}>
                            Add
                          </a>
                          <a onClick={() => setIsAddDependent(false)} className="btn btn-warning" style={{ "min-width": "30%" }}>
                            Cancel
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            )}
          </div>
        </Modal.Body>
      </Modal>
      <Footer />
    </>
  );
};

export default Search;
