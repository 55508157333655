import React, {useEffect} from "react";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import {json} from "react-router-dom";

const ThankyouResponseForUpdate = () => {

    const [getagencydata, readagencydata] = React.useState(null)

    function closeTab() {
        window.open('', '_self', '');
        window.close();
    }

    useEffect(() => {


        if (localStorage.key("agencyInfo") !== null) {
            // const agencyData = localStorage.getItem("agencyInfo");
            // console.log(agencyData);

        }
    }, [])
    return (
        <>

            <p align="center">
                <Card>
                    <Card.Body>
                        <Card.Title>
                            <h4>
                                <Badge bg="success">Your application has been sent.</Badge>
                            </h4>
                        </Card.Title>
                        <Card.Text>
                            Thank you for choosing us.
                        </Card.Text>
                    </Card.Body>
                </Card>
            </p>
        </>
    )
}
export default ThankyouResponseForUpdate;
