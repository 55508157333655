import { IconMailFast } from "@tabler/icons-react";
import { IconMailCog } from "@tabler/icons-react";
import { IconExternalLink } from "@tabler/icons-react";
import { IconClipboard } from "@tabler/icons-react";
import axios from "axios";
import React, { useState } from "react";
import { Button, Card, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";

const SmartLink = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
    setValue,
  } = useForm();
  const [showSendMailModal, setShowSendMailModal] = useState(false);
  const userData = useSelector((state) => state.auth.user);
  const { agencyName, agentName } = useParams();
  const DEV_URL = process.env.REACT_APP_ANC_DOMAIN;
  const DEV_ENDPINT = process.env.REACT_APP_ANC_API;
  const smartLinkURL = agentName == null ? `${DEV_URL}/smartlink/${agencyName}` : `${DEV_URL}/smartlink/${agencyName}/${agentName}`;
  const handleMailSubmit = async (formData) => {
    Swal.fire({
      title: "Are you sure you want to send instant email?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const agencyData = JSON.parse(localStorage.getItem("agency_data"));
        const data = {
          EmailTemplate: 2,
          ReceiverName: formData.customerName,
          ReceiverEmail: formData.email,
          SenderName: agencyName,
          Subject: "SmartlinkEmail",
          AgencyName: agentName == null ? agencyName : agentName,
          AgencyEmail: userData.emailAddress || "",
          AgencyContact: agencyData.phone || "",
          hyerplink: smartLinkURL,
        };
        let config = {
          method: "POST",
          maxBodyLength: Infinity,
          url: `${DEV_ENDPINT}/api/sendemail/send`,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `basic ${process.env.REACT_APP_ANC_API_HEADER_KEY}`,
          },
          data: data,
        };
        await axios(config)
          .then((res) => {
            if (res.status === 200) {
              handleCloseEmailSendModal();
              Swal.fire({
                title: "Thanks. your mail has been sent",
                icon: "success",
                showCancelButton: true,
                confirmButtonText: "Ok",
                cancelButtonText: "Close",
              });
            }
          })
          .catch((error) => {
            console.log("error", error);
            // setIsLoading(false);
            // setErrorMessage("Invalid user or password!");
          });
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
      }
    });
  };
  const handleCloseEmailSendModal = () => {
    setShowSendMailModal(false);
    reset();
  };
  return (
    <>
   
      <div className="col py-3">
        <div className="content-panel">
          <div className="panel-title mb-5 ">{/* <h4>Title: Smart Link</h4> */}</div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-6 ">
            <Card className="brand-card h-100">
              <Card.Header className="brand-card-title">
                <h4>Smart Link</h4>
              </Card.Header>
              <Card.Body>
                <ul className="list-group mb-3 d-flex w-100">
                  <li className="list-group-item d-flex align-items-center justify-content-center">
                    <button type="button" class="btn btn-primary w-40" onClick={() => navigator.clipboard.writeText(smartLinkURL)}>
                      <IconClipboard />
                      &nbsp; Copy & Paste link
                    </button>
                  </li>
                  <li className="list-group-item d-flex align-items-center justify-content-center">
                    <button type="button" class="btn btn-primary   w-40" onClick={() => setShowSendMailModal(true)}>
                      <IconMailFast />
                      &nbsp; Send Instant Email
                    </button>
                  </li>
                  <li className="list-group-item d-flex align-items-center justify-content-center">
                    <a target="blank" class="btn btn-primary  w-40" href={smartLinkURL}>
                      <IconExternalLink />
                      &nbsp; <span>Open Link</span>
                    </a>
                  </li>
                </ul>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
      <Modal
        show={showSendMailModal}
        onHide={handleCloseEmailSendModal}
        // style={{
        //   zIndex:999
        // }}
        // backdrop="static"
        // size={'lg'}
        // keyboard={false}
      >
        <Modal.Body>
          <Card style={{ width: "100%" }}>
            <Card.Img variant="top" />
            <form onSubmit={handleSubmit(handleMailSubmit)}>
              <Card.Body>
                <Card.Title>
                  <h1>Send Email</h1>
                </Card.Title>
                <Card.Subtitle className="mt-3 mb-3">Please enter customer name :</Card.Subtitle>
                <Card.Text>
                  <input type="text" name="customerName" required={true} className="form-control" {...register("customerName", { required: true })} />
                </Card.Text>
                <Card.Subtitle className="mt-3 mb-3">Please enter email :</Card.Subtitle>
                <Card.Text>
                  <input type="email" name="email" required={true} className="form-control" {...register("email", { required: true })} />
                  {/* {sendMailDataError.email && <p className="text-danger mt-3">{sendMailDataError.email}</p>} */}
                </Card.Text>

                <Card.Text style={{ float: "right" }}>
                  <Button variant="primary" type="submit" disabled={!isValid}>
                    <div className="button-body-for-loader">
                      <span>Send Mail</span>
                    </div>
                  </Button>
                  <Button variant="warning" className=" mx-2" onClick={handleCloseEmailSendModal}>
                    <div className="button-body-for-loader mx-2">
                      <span>Close</span>
                    </div>
                  </Button>
                </Card.Text>
                <Card.Text></Card.Text>
                {/* {message && <div className="text-success">{message}</div>} */}
              </Card.Body>
            </form>
          </Card>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SmartLink;
