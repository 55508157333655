import React from "react";
import {
  NumberFormat,
  ThousandSeparator,
  formatDate,
  formatHours,
} from "../../../Common/Utils";

const CustomerDetailsrestaurantInfo = ({ details }) => {
  return (
    <>
      <div
        className="offcanvas offcanvas-end"
        tabIndex="-1"
        id="restaurantDetail"
        style={{ width: "75%" }}
        aria-labelledby="offcanvasRightLabel"
      >
        <div className="offcanvas-header activity-status-table brand-offcanvas-header">
          <h5 id="offcanvasRightLabel">Resturant Information</h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <div className="activity-cust-data">
            <h6 className="mb-3">Details</h6>

            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link brand-tab active`}
                  id="build-info"
                  data-bs-toggle="tab"
                  data-bs-target="#building-info-details"
                  type="button"
                  role="tab"
                  aria-controls="building-info-details"
                  aria-selected="true"
                >
                  General Information
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link brand-tab`}
                  id="geo-location1"
                  data-bs-toggle="tab"
                  data-bs-target="#annual-info"
                  type="button"
                  role="tab"
                  aria-controls="annual-info"
                  aria-selected="false"
                >
                  Property Information
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link brand-tab`}
                  id="geo-location1"
                  data-bs-toggle="tab"
                  data-bs-target="#general-liability"
                  type="button"
                  role="tab"
                  aria-controls="general-liability"
                  aria-selected="false"
                >
                  General Liability
                </button>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade active show"
                id="building-info-details"
                role="tabpanel"
                aria-labelledby="build-info"
              >
                <div className="activity-status-table">
                  <div className="table-responsive">
                    <table className="table table-striped table table-bordered">
                      <thead></thead>
                      <tbody id="body-render">
                        <tr>
                          <td>First Name</td>
                          <td>{details?.customerInfo?.firstName}</td>
                        </tr>
                        <tr>
                          <td>Last Name</td>
                          <td>{details?.customerInfo?.lastName}</td>
                        </tr>
                        <tr>
                          <td>Business Name</td>
                          <td>{details?.customerInfo?.businessName}</td>
                        </tr>
                        <tr>
                          <td>DBA</td>
                          <td>{details?.customerInfo?.dbaname}</td>
                        </tr>
                        <tr>
                          <td>Hours of Operation</td>
                          <td>
                            {details?.customerInfo?.hoursOfOperation &&
                              formatHours(
                                details?.customerInfo?.hoursOfOperation
                              )}
                          </td>
                        </tr>
                        <tr>
                          <td>Work Number</td>
                          <td>{details?.customerInfo?.workNum}</td>
                        </tr>
                        <tr>
                          <td>Mobile Number</td>
                          <td>{details?.customerInfo?.mobileNum}</td>
                        </tr>
                        <tr>
                          <td>Location Address</td>
                          <td>{details?.customerInfo?.locationAddress}</td>
                        </tr>
                        <tr>
                          <td>Mailing Address</td>
                          <td>{details?.customerInfo?.mailingAddress}</td>
                        </tr>
                        <tr>
                          <td>Email Address</td>
                          <td>{details?.customerInfo?.email}</td>
                        </tr>
                        <tr>
                          <td>Effective Date</td>
                          <td>
                            {details?.restaurantInfo?.effectiveDate &&
                              formatDate(
                                details?.restaurantInfo?.effectiveDate
                              )}
                          </td>
                        </tr>
                        <tr>
                          <td>No. Of Years At The Same Location</td>
                          <td>
                            {details?.restaurantInfo?.howManyYearOnSameLocation}
                          </td>
                        </tr>
                        <tr>
                          <td>Years of Experience in Restaurant Business</td>
                          <td>
                            {details?.restaurantInfo?.experienceRestaurant}
                          </td>
                        </tr>
                        <tr>
                          <td>Business Type</td>
                          <td>
                            {details?.customerInfo?.typeOfBusiness?.map(
                              (type, index) => {
                                return (
                                  <span
                                    key={index}
                                    className="badge rounded-pill bg-info text-light me-1"
                                  >
                                    {type}
                                  </span>
                                );
                              }
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Coverage</td>
                          <td>
                            {details?.restaurantInfo?.coverage?.map(
                              (type, index) => {
                                return (
                                  <span
                                    key={index}
                                    className="badge rounded-pill bg-info text-light me-1"
                                  >
                                    {type}
                                  </span>
                                );
                              }
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div
                className={`tab-pane fade`}
                id="annual-info"
                role="tabpanel"
                aria-labelledby="build-info"
              >
                <div className="activity-status-table">
                  <div className="table-responsive">
                    <table className="table table-striped table table-bordered">
                      <thead></thead>
                      <tbody id="body-render">
                        <tr>
                          <td>Year Built</td>
                          <td>{details?.restaurantInfo?.propertyYearOfBuil}</td>
                        </tr>
                        <tr>
                          <td>No. of Buildings</td>
                          <td>
                            <ThousandSeparator
                              value={
                                details?.restaurantInfo?.propertyNoofBuilding
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>BPP Limit?</td>
                          <td>
                            <NumberFormat
                              value={details?.restaurantInfo?.propertyBpp}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Sign Limit</td>
                          <td>
                            {" "}
                            <NumberFormat
                              value={details?.restaurantInfo?.propertySignLimit}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Sq. Ft.</td>
                          <td>
                            <ThousandSeparator
                              value={details?.restaurantInfo?.propertySqft}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Signed Value</td>
                          <td>
                            <NumberFormat
                              value={
                                details?.restaurantInfo?.propertySignedValue
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Protective Safeguard</td>
                          <td>
                            {details?.restaurantInfo?.propertyProtactive?.map(
                              (type, index) => {
                                return (
                                  <span
                                    key={index}
                                    className="badge rounded-pill bg-info text-light me-1"
                                  >
                                    {type}
                                  </span>
                                );
                              }
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Table System</td>
                          <td>
                            {details?.restaurantInfo?.propertyAnyTable === "1"
                              ? "Yes"
                              : "No"}
                          </td>
                        </tr>
                        <tr>
                          <td>Buffet System</td>
                          <td>
                            {details?.restaurantInfo?.propertyAnyBuffet === "1"
                              ? "Yes"
                              : "No"}
                          </td>
                        </tr>
                        <tr>
                          <td>Bar or Dance Floor</td>
                          <td>
                            {details?.restaurantInfo?.propertyAnyBar === "1"
                              ? "Yes"
                              : "No"}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Service Contract for Cleaning of Kitchen Equipment
                            and Refrigeration
                          </td>
                          <td>
                            {details?.restaurantInfo
                              ?.propertyServiceContract === "1"
                              ? "Yes"
                              : "No"}
                          </td>
                        </tr>
                        <tr>
                          <td>Catering Service</td>
                          <td>
                            {details?.restaurantInfo?.propertyCatering === "1"
                              ? "Yes"
                              : "No"}
                          </td>
                        </tr>
                        <tr>
                          <td>Spoilage Limit</td>
                          <td>
                            <NumberFormat
                              value={
                                details?.restaurantInfo?.propertySpoilageLimit
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Food Contamination Limit</td>
                          <td>
                            <NumberFormat
                              value={
                                details?.restaurantInfo
                                  ?.propertyFoodContainerLimit
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Money & Securities Limit</td>
                          <td>
                            <NumberFormat
                              value={
                                details?.restaurantInfo?.propertyMoneyLimit
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>For Catering Serivce Employee Use Vechicle</td>
                          <td>
                            {details?.restaurantInfo?.propertyVehicle === "1"
                              ? "Yes"
                              : "No"}
                          </td>
                        </tr>
                        <tr>
                          <td>Last Roof updated</td>
                          <td>
                            {details?.restaurantInfo?.roofDate &&
                              formatDate(details?.restaurantInfo?.roofDate)}
                          </td>
                        </tr>
                        <tr>
                          <td>Last Wiring updated</td>
                          <td>
                            {details?.restaurantInfo?.electricalDate &&
                              formatDate(
                                details?.restaurantInfo?.electricalDate
                              )}
                          </td>
                        </tr>
                        <tr>
                          <td>Last Plumbing updated</td>
                          <td>
                            {details?.restaurantInfo?.plumbingDate &&
                              formatDate(details?.restaurantInfo?.plumbingDate)}
                          </td>
                        </tr>
                        <tr>
                          <td>Last Heating updated</td>
                          <td>
                            {details?.restaurantInfo?.hvacDate &&
                              formatDate(details?.restaurantInfo?.hvacDate)}
                          </td>
                        </tr>
                        <tr>
                          <td>Prior Losses</td>
                          <td>
                            <NumberFormat
                              value={details?.restaurantInfo?.propertyPriorLoss}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Loss Runs (Years)</td>
                          <td>{details?.restaurantInfo?.propertyLossRun}</td>
                        </tr>
                        <tr>
                          <td>Any Claims in past 5 years</td>
                          <td>{details?.restaurantInfo?.propertyIfClaim}</td>
                        </tr>
                        <tr>
                          <td>Claim Date</td>
                          <td>
                            {details?.restaurantInfo?.claimDate &&
                              formatDate(details?.restaurantInfo?.claimDate)}
                          </td>
                        </tr>
                        <tr>
                          <td>Claim Type</td>
                          <td>{details?.restaurantInfo?.propertyAnyUpdate}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div
                className={`tab-pane fade`}
                id="general-liability"
                role="tabpanel"
                aria-labelledby="build-info"
              >
                <div className="activity-status-table">
                  <div className="table-responsive">
                    <table className="table table-striped table table-bordered">
                      <thead></thead>
                      <tbody id="body-render">
                        <tr>
                          <td>What type of Cooking?</td>
                          <td>
                            {details?.restaurantInfo?.glCookingType?.map(
                              (type, index) => {
                                return (
                                  <span
                                    key={index}
                                    className="badge rounded-pill bg-info text-light me-1"
                                  >
                                    {type}
                                  </span>
                                );
                              }
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Seating Capacity</td>
                          <td>
                            <ThousandSeparator
                              value={details?.restaurantInfo?.glSeatingCapacity}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Serving Liquor</td>
                          <td>
                            {details?.restaurantInfo?.glLiquour === "1"
                              ? "Yes"
                              : "No"}
                          </td>
                        </tr>
                        <tr>
                          <td>Happy Hours</td>
                          <td>
                            {details?.restaurantInfo?.glHappyHours === "1"
                              ? "Yes"
                              : "No"}
                          </td>
                        </tr>
                        <tr>
                          <td>Hood & Duct System in Kitchen</td>
                          <td>
                            {details?.restaurantInfo?.glHoodAndDuck === "1"
                              ? "Yes"
                              : "No"}
                          </td>
                        </tr>
                        <tr>
                          <td>Any Open Fire Cooking</td>
                          <td>
                            {details?.restaurantInfo?.glOpenFireCooking?.map(
                              (type, index) => {
                                return (
                                  <span
                                    key={index}
                                    className="badge rounded-pill bg-info text-light me-1"
                                  >
                                    {type}
                                  </span>
                                );
                              }
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Liquor Liabilities Limit</td>
                          <td>
                            {" "}
                            <NumberFormat
                              value={details?.restaurantInfo?.glLiquorLimit}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Annual Liquor Sales</td>
                          <td>
                            <NumberFormat
                              value={
                                details?.restaurantInfo?.glAnnualLiquorSales
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Annual Food Sales</td>
                          <td>
                            <NumberFormat
                              value={details?.restaurantInfo?.glAnnualFoodSale}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Annual Catering Service Sales</td>
                          <td>
                            <NumberFormat
                              value={details?.restaurantInfo?.glCateringServce}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Is Banquet Service Available</td>
                          <td>
                            {details?.restaurantInfo?.glIsBanquest === "1"
                              ? "Yes"
                              : "No"}
                          </td>
                        </tr>
                        <tr>
                          <td>Total Payroll</td>
                          <td>
                            <NumberFormat
                              value={details?.restaurantInfo?.glTotalPayRoll}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>FEIN Number</td>
                          <td>{details?.restaurantInfo?.glFein}</td>
                        </tr>
                        <tr>
                          <td>No. of Employees</td>
                          <td>
                            <ThousandSeparator
                              value={details?.restaurantInfo?.glNoEmp}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Bank Details</td>
                          <td>{details?.restaurantInfo?.glBankDetails}</td>
                        </tr>
                        <tr>
                          <td>What is the Bank Property Value</td>
                          <td>{details?.restaurantInfo?.glPropValueByBank}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerDetailsrestaurantInfo;
