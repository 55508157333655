import React, { Fragment } from "react";
import { NumberFormat, ThousandSeparator, formatDate, formatHours } from "../../../../Common/Utils";

const ViewAuto = ({ details }) => {
  console.log("details ", details);
  return (
    <>
      <div className="offcanvas offcanvas-end" tabIndex="-1" id="auto-view" style={{ width: "80%" }} aria-labelledby="offcanvasRightLabel">
        <div className="offcanvas-header activity-status-table brand-offcanvas-header">
          <h5 id="offcanvasRightLabel">Auto Information</h5>
          <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
          <div className="activity-cust-data">
            <h6 className="mb-3">Details</h6>

            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link brand-tab active`}
                  id="build-info"
                  data-bs-toggle="tab"
                  data-bs-target="#building-info-details"
                  type="button"
                  role="tab"
                  aria-controls="building-info-details"
                  aria-selected="true"
                >
                  Personal Information
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link brand-tab`}
                  id="geo-location1"
                  data-bs-toggle="tab"
                  data-bs-target="#annual-info"
                  type="button"
                  role="tab"
                  aria-controls="annual-info"
                  aria-selected="false"
                >
                  Driver(s) Information
                </button>
              </li>
            </ul>
            {details && (
              <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade active show" id="building-info-details" role="tabpanel" aria-labelledby="build-info">
                  <div className="activity-status-table">
                    <div className="table-responsive">
                      <table className="table table-striped table table-bordered">
                        <thead></thead>
                        <tbody id="body-render">
                          <tr>
                            <td>First Name</td>
                            <td>{details?.firstName}</td>
                          </tr>
                          <tr>
                            <td>Last Name</td>
                            <td>{details?.lastName}</td>
                          </tr>

                          <tr>
                            <td>Date of Birth</td>
                            <td>{details?.dob && formatDate(details?.dob)}</td>
                          </tr>
                          <tr>
                            <td>Mobile Phone Number</td>
                            <td>{details?.cell}</td>
                          </tr>
                          <tr>
                            <td>Work Phone Number</td>
                            <td>{details?.workPhone}</td>
                          </tr>

                          <tr>
                            <td>Email Address</td>
                            <td>{details?.email}</td>
                          </tr>
                          <tr>
                            <td>Street Address</td>
                            <td>{details?.apt}</td>
                          </tr>
                          <tr>
                            <td>Garage Address</td>
                            <td>{details?.garrageStreetAddress}</td>
                          </tr>

                          <tr>
                            <td>Effective Date</td>
                            <td>{details?.garragingEffectiveDate && formatDate(details?.garragingEffectiveDate)}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className={`tab-pane fade`} id="annual-info" role="tabpanel" aria-labelledby="build-info">
                  <div className="activity-status-table">
                    <div className="table-responsive">
                      <table className="table table-striped table table-bordered">
                        <thead></thead>
                        <tbody id="body-render">
                          {details?.drivers?.map((driver, i) => (
                            <Fragment key={driver.id}>
                              <tr>
                                <th colSpan={2}>Driver(s) Information - {i + 1}</th>
                              </tr>
                              <tr>
                                <td>Driver Name</td>
                                <td>{driver?.name}</td>
                              </tr>
                              <tr>
                                <td>Primary Driver Date of Birth</td>
                                <td>{driver?.dob}</td>
                              </tr>
                              <tr>
                                <td>Primary Driver Lic</td>
                                <td>{driver?.lic}</td>
                              </tr>
                            </Fragment>
                          ))}
                          {details?.vehicles?.map((vehicle, i) => (
                            <Fragment key={vehicle.id}>
                              <tr>
                                <th colSpan={2}>Vehicle(s) Information - {i + 1}</th>
                              </tr>
                              <tr>
                                <td>vehicle Covergae Type</td>
                                <td>{vehicle?.type}</td>
                              </tr>
                              <tr>
                                <td>Primary Vin Number</td>
                                <td>{vehicle?.description}</td>
                              </tr>
                            </Fragment>
                          ))}
                          <tr>
                            <td>Carrier Name</td>
                            <td>{details?.carrierName}</td>
                          </tr>
                          <tr>
                            <td>Renewal Date</td>
                            <td>{details?.renewalDate && formatDate(details?.renewalDate)}</td>
                          </tr>
                          <tr>
                            <td>Preiumm Amount</td>
                            <td><NumberFormat value={details?.insurancePremium} /></td>
                          </tr>
                          <tr>
                            <td>Duration With Current Carrier</td>
                            <td>{details?.carrierDuration}</td>
                          </tr>
                          
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewAuto;
