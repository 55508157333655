import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";

import { Controller, useForm } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import * as yup from "yup";
import { currentYear, floatRegex } from "../../../../Common/regex";
import axiosInstance from "../../../../config/axios-config";

const DEVURL = process.env.REACT_APP_ANC_API + "/api/GeneralStore";

const validationSchema = yup.object().shape({
  sqftspace: yup.string().trim().required("Sq. ft. is required").label("Sq. ft."),
  salesAmt: yup.string().trim().required("Sales is required").label("Sales"),
  tenantInfo: yup
    .string()
    .trim()
    .required("Tenant is required")
    .label("Tenant"),
  moneyLimit: yup
    .string()
    .trim()
    // .required("Money & securities limit is required")
    .nullable(true)
    .matches(floatRegex, "Please enter valid money & securities limit")
    .label("Money & securities limit"),
  // buildingValue: yup
  // .string()
  // .trim()
  // .required("Building value is required")
  // .matches(floatRegex, "Please enter a valid building value")
  // .nullable(true)
  // .label("Building value"),
  signValue: yup
    .string()
    .trim()
    .required("Sign limit is required")
    .label("Sign limit"),
  lossRunYear: yup
    .string()
    .trim()
    .nullable(true)
    .matches(floatRegex, "Please enter a valid no. of years")
    .test(
      "is-past-or-current-year",
      "Year must be in the past or current year",
      function (value) {
        return value <= currentYear;
      }
    )
    .label("Loss run"),
  protectiveGaurd: yup
    .array()
    .min(1, "Protective safeguard is required")
    .of(yup.string())
    .required("Protective safeguard is required")
    .label("Protective safeguard"),
  storeType: yup
    .array()
    .min(1, "Store type is required")
    .of(yup.string())
    .required("Store type is required")
    .label("Store type"),
  bppValue: yup
    .string()
    .trim()
    .required("Bpp value is required")
    .label("Bpp value"),
  isAdditionalInsured: yup
    .string()
    .required("Any insured is required")
    .label("Any insured"),
  anyClaim: yup.string().required("Any claim is required").label("Any claim"),
  termsCondition: yup
    .boolean()
    .test("termsCondition", "Terms & Conditions is required", (val) => {
      return val;
    }),
});

const Step2 = ({ details, stepHandler, loader }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
    reset,
    watch,
    control,
  } = useForm({
    defaultValues: {
      salesAmt: null,
      sqftspace: null,
      termsCondition: false,
      tenantInfo: "",
      bppValue: null,
      moneyLimit: null,
      protectiveGaurd: [],
      buildingValue: null,
      isAdditionalInsured: "",
      storeType: [],
      lossRunYear: null,
      anyClaim: "",
      signValue: null,
      anyLandlordRequirment: "",
      franchiseInfo: "",
      electronicseInfo: "",
    },
    resolver: yupResolver(validationSchema),
  });

  const fillupStep2HotelInfoFromLocalStorage = (formData) => {
    setValue("salesAmt", formData.salesAmt);
    setValue("sqftspace", formData.sqftspace);
    setValue("tenantInfo", formData.tenantInfo);
    setValue("bppValue", formData.bppValue);
    setValue("moneyLimit", formData.moneyLimit);
    setValue("buildingValue", formData.buildingValue);
    setValue("isAdditionalInsured", formData.isAdditionalInsured);
    setValue("storeType", formData.storeType);
    setValue("protectiveGaurd", formData.protectiveGaurd);
    setValue("lossRunYear", formData.lossRunYear);
    setValue("anyClaim", formData.anyClaim);
    setValue("signValue", formData.signValue);
    setValue("termsCondition", formData?.termsCondition);
    setValue("anyLandlordRequirment", formData?.anyLandlordRequirment);
    setValue("franchiseInfo", formData.franchiseInfo);
    setValue("electronicseInfo", formData.electronicseInfo);
  };

  const [isCaptchaSuccessful, setIsCaptchaSuccess] = useState(false);
  const [disableAdd, setDisableAdd] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const query = new URLSearchParams(useLocation().search);
  const salonId = query.get("primaryid");
  const navigate = useNavigate();

  const clearLocalStorage = () => {
    localStorage.removeItem("step1");
    localStorage.removeItem("step2");
    localStorage.removeItem("customerId");
  };

  useEffect(() => {
    const formData = JSON.parse(localStorage.getItem("step2"));
    if (formData) {
      fillupStep2HotelInfoFromLocalStorage(formData);
    }
  }, []);

  const creategeneralStoreInfoObj = (generalStoreInfo) => {
    console.log(generalStoreInfo);
    let salonToCreate = {
      customerInfo: {
        agencyId: generalStoreInfo.agencyId,
        agentId: generalStoreInfo.agentId,
        customerId: generalStoreInfo.customerId,
        firstName: generalStoreInfo.firstName,
        lastName: generalStoreInfo.lastName,
        locationAddress: generalStoreInfo.locationAddress,
        locationCity: generalStoreInfo.locationCity,
        locationZip: generalStoreInfo.locationZip,
        locationState: generalStoreInfo.locationState,
        mailingAddress: generalStoreInfo.mailingAddress,
        mailingCity: generalStoreInfo.mailingCity,
        mailingZip: generalStoreInfo.mailingZip,
        mailingState: generalStoreInfo.mailingState,
        businessName: generalStoreInfo.businessName,
        dbaname: generalStoreInfo.dbaname,
        hoursOfOperation: generalStoreInfo.hoursOfOperation,
        mobileNum: generalStoreInfo.mobileNum,
        workNum: generalStoreInfo.workNum,
        email: generalStoreInfo.email,
        typeOfBusiness: [],
        faxNum: "",
        termsCondition: generalStoreInfo.termsCondition?.toString(),
      },
      generalStoreInfo: {
        storeId: generalStoreInfo.storeId,
        customerId: generalStoreInfo.customerId,
        salesAmt: generalStoreInfo.salesAmt,
        coverage: generalStoreInfo.coverage,
        sqftspace: generalStoreInfo.sqftspace,
        tenantInfo: generalStoreInfo.tenantInfo,
        bppValue: generalStoreInfo.bppValue,
        moneyLimit: generalStoreInfo.moneyLimit,
        protectiveGaurd: generalStoreInfo.protectiveGaurd,
        buildingValue: generalStoreInfo.buildingValue,
        lossRunYear: generalStoreInfo.lossRunYear,
        signValue: generalStoreInfo.signValue,
        storeType: generalStoreInfo.storeType,
        isAdditionalInsured: generalStoreInfo.isAdditionalInsured,
        anyClaim: generalStoreInfo.anyClaim,
        anyLandlordRequirment: generalStoreInfo.anyLandlordRequirment,
        franchiseInfo: generalStoreInfo.franchiseInfo,
        effectiveDate: generalStoreInfo.effectiveDate,
        electronicseInfo: generalStoreInfo.electronicseInfo,
        hoursOfOperation: generalStoreInfo.hoursOfOperation,
        typeOfBusiness: [],
        termsCondition: generalStoreInfo.termsCondition?.toString(),
      },
    };
    return salonToCreate;
  };
  const handleFormSubmit = async (data) => {
    localStorage.setItem("step2", JSON.stringify(data));
    const customerId = localStorage.getItem("customerId");
    const step1 = localStorage.getItem("step1");
    const step2 = localStorage.getItem("step2");
    const generalStoreInfo = {
      ...JSON.parse(step1),
      ...JSON.parse(step2),
    };

    const generalStoreInfoObj = creategeneralStoreInfoObj(generalStoreInfo);

    Swal.fire({
      title: "Are you sure you want to submit the form?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        axiosInstance
          .put(
            `${DEVURL}/UpdateGeneralStoreApp/${details?.generalStoreInfo?.customerId}/${details?.generalStoreInfo?.storeId}`,
            generalStoreInfoObj
          )
          .then((res) => {
            if (res.status === 200) {
              setLoading(false);
              clearLocalStorage();
              reset();
              stepHandler(3);
              setTimeout(() => {
                stepHandler(1);
              }, 3000);
            }
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
          });
      } else {
        setLoading(false);
        return;
      }
    });
  };

  if (isLoading) {
    return loader();
  }

  return (
    <>
      <form
        className="tab-wizard wizard-circle wizard needs-validation"
        onSubmit={handleSubmit(handleFormSubmit)}
      >
        <section>
          <h4 className="wrap-title mb-3">General Liability Information:</h4>
          <div className="row">
            <div className="col-md-6 col-lg-6">
              <div className="mb-2">
                <label className="brand-label text-capitalize">
                  Sq. Ft.?<span className="required-mark">*</span>
                </label>

                <Controller
                  name="sqftspace"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      {...field}
                      id="sqftspace"
                      className="form-control"
                      thousandSeparator={true}
                      name="sqftspace"
                      onValueChange={(values) => {
                        setValue("sqftspace", values.floatValue);
                      }}
                      placeholder="Enter Sq. Ft."
                      {...register("sqftspace")}
                    />
                  )}
                />
                <p className="text-danger">{errors.sqftspace?.message}</p>
              </div>
            </div>
            <div class="col-md-6 col-lg-6">
              <div class="mb-3">
                <label class="brand-label">
                  Sales?<span className="required-mark">*</span>
                </label>
                <Controller
                  name="salesAmt"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      {...field}
                      id="salesAmt"
                      className="form-control"
                      thousandSeparator={true}
                      prefix="$"
                      name="salesAmt"
                      placeholder="Enter Sales"
                      onValueChange={(values) => {
                        setValue("salesAmt", values.floatValue);
                      }}
                      {...register("salesAmt")}
                    />
                  )}
                />
                <p className="text-danger">
                  {errors.salesAmt && <p>{errors.salesAmt.message}</p>}
                </p>
              </div>
            </div>

            <h4 className="wrap-title mb-3">Property Information:</h4>
            <div className="col-md-6 col-lg-4">
              <div className="mb-2">
                <label className="brand-label text-capitalize">
                  Tenant?
                  <span className="required-mark">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Enter Tenant"
                  {...register("tenantInfo")}
                />
                <p className="text-danger">{errors.tenantInfo?.message}</p>
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="mb-3">
                <label class="brand-label">
                  BPP Value?
                  <span className="required-mark">*</span>
                </label>
                <Controller
                  name="bppValue"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      {...field}
                      id="bppValue"
                      className="form-control"
                      thousandSeparator={true}
                      prefix="$"
                      name="bppValue"
                      onValueChange={(values) => {
                        setValue("bppValue", values.floatValue);
                      }}
                      placeholder="Enter Bpp Value"
                      {...register("bppValue")}
                    />
                  )}
                />

                <p className="text-danger">
                  {errors.bppValue && <p>{errors.bppValue.message}</p>}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="mb-2">
                <label className="brand-label d-grid">
                  <div>
                    Money & Securities Limit?
                    {/* <span className="required-mark">*</span> */}
                  </div>
                </label>
                <Controller
                  name="moneyLimit"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      {...field}
                      id="moneyLimit"
                      className="form-control"
                      thousandSeparator={true}
                      prefix="$"
                      name="moneyLimit"
                      onValueChange={(values) => {
                        setValue("moneyLimit", values.floatValue);
                      }}
                      placeholder="Enter Money & Securities Limit"
                      {...register("moneyLimit")}
                    />
                  )}
                />
                <p className="text-danger">{errors.moneyLimit?.message}</p>
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="mb-3">
                <label class="brand-label">
                  If Owner, Building Value?
                  {/* <span className="required-mark">*</span> */}
                </label>
                <Controller
                  name="buildingValue"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      {...field}
                      id="buildingValue"
                      className="form-control"
                      thousandSeparator={true}
                      prefix="$"
                      name="buildingValue"
                      onValueChange={(values) => {
                        setValue("buildingValue", values.floatValue);
                      }}
                      placeholder="Enter Building Value"
                      {...register("buildingValue")}
                    />
                  )}
                />

                <p className="text-danger">
                  {errors.buildingValue && (
                    <p>{errors.buildingValue.message}</p>
                  )}
                </p>
              </div>
            </div>
            <div className="col-md-4 col-lg-4">
              <div className="mb-3">
                <label className="brand-label text-capitalize">
                  Any Other Requirement from landlord?
                </label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Enter Landlord Requirement"
                  {...register("anyLandlordRequirment")}
                />
              </div>
            </div>
            <div className="col-md-4 col-lg-4">
              <div className="mb-3">
                <label className="brand-label">Franchise?</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Enter Franchise"
                  {...register("franchiseInfo")}
                />
              </div>
            </div>

            <div className="col-md-4 col-lg-4">
              <div className="mb-3">
                <label className="brand-label text-capitalize">
                  Any other electronics?
                </label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Enter Any Other Electronics"
                  {...register("electronicseInfo")}
                />
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="mb-2">
                <label className="brand-label">
                  Sign Value?<span className="required-mark">*</span>
                </label>
                <Controller
                  name="signValue"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      {...field}
                      id="signValue"
                      className="form-control"
                      thousandSeparator={true}
                      prefix="$"
                      name="signValue"
                      onValueChange={(values) => {
                        setValue("signValue", values.floatValue);
                      }}
                      placeholder="Enter Sign Value"
                      {...register("signValue")}
                    />
                  )}
                />
                <p className="text-danger">
                  {errors.signValue && <p>{errors.signValue.message}</p>}
                </p>
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="mb-3">
                <label class="brand-label">Loss Run (Years)</label>
                <Controller
                  name="lossRunYear"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      {...field}
                      allowNegative={false}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      id="lossRunYear"
                      className="form-control"
                      name="lossRunYear"
                      onValueChange={(values) => {
                        setValue("lossRunYear", values.value);
                      }}
                      placeholder="Enter Loss Run (Years)"
                      {...register("lossRunYear")}
                    />
                  )}
                />
                <p className="text-danger">
                  {errors.lossRunYear && <p>{errors.lossRunYear.message}</p>}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="mb-2">
                <label className="brand-label">Protective Safeguard?</label>
                <span className="required-mark">*</span>
                <div className="row">
                  <div className="col-md-6 col-lg-6">
                    <div className="form-check ">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="cctvCheck"
                        value="CCTV Camera"
                        {...register("protectiveGaurd")}
                      />
                      <label
                        className="form-check-label text-capitalize"
                        for="cctvCheck"
                      >
                        CCTV Camera
                      </label>
                    </div>
                    <div className="form-check ">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="fireAlarmCheck"
                        value="Fire Alarm"
                        {...register("protectiveGaurd")}
                      />
                      <label
                        className="form-check-label text-capitalize"
                        for="fireAlarmCheck"
                      >
                        Fire Alarm
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6">
                    <div className="form-check ">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="burglarAlarmCheck"
                        value="Burglar Alarm"
                        {...register("protectiveGaurd")}
                      />
                      <label
                        className="form-check-label text-capitalize"
                        for="burglarAlarmCheck"
                      >
                        Burglar Alarm
                      </label>
                    </div>
                    <div className="form-check ">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="protectiveGaurdCheck"
                        value="Others"
                        {...register("protectiveGaurd")}
                      />
                      <label
                        className="form-check-label text-capitalize"
                        for="protectiveGaurdCheck"
                      >
                        Others
                      </label>
                    </div>
                  </div>
                </div>

                <p className="text-danger">{errors.protectiveGaurd?.message}</p>
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="mb-3">
                <label class="brand-label d-grid">
                  <div>
                    Any Additional Insured?
                    <span className="required-mark">*</span>
                  </div>
                </label>

                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="insuredYes"
                    value="1"
                    {...register("isAdditionalInsured")}
                  />
                  <label class="form-check-label" for="insuredYes">
                    Yes
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="insuredNo"
                    value="0"
                    {...register("isAdditionalInsured")}
                  />
                  <label class="form-check-label" for="insuredNo">
                    No
                  </label>
                </div>
                <p className="text-danger">
                  {errors.isAdditionalInsured && (
                    <p>{errors.isAdditionalInsured.message}</p>
                  )}
                </p>
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="mb-3">
                <label class="brand-label d-grid">
                  <div>
                    Any Claim in past 5 years?
                    <span className="required-mark">*</span>
                  </div>
                </label>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="claimYes"
                    value="1"
                    {...register("anyClaim")}
                  />
                  <label class="form-check-label" for="claimYes">
                    Yes
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="claimNo"
                    value="0"
                    {...register("anyClaim")}
                  />
                  <label class="form-check-label" for="claimNo">
                    No
                  </label>
                </div>
                <p className="text-danger">
                  {errors.anyClaim && <p>{errors.anyClaim.message}</p>}
                </p>
              </div>
            </div>

            <div className="col-md-6 col-lg-6">
              <div className="mb-2">
                <label className="brand-label">Store Type?</label>
                <span className="required-mark">*</span>
                <div className="row">
                  <div className="col-md-6 col-lg-6">
                    <div className="form-check ">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="repairStoreCheck"
                        value="Repair Store"
                        {...register("storeType")}
                      />
                      <label
                        className="form-check-label text-capitalize"
                        for="repairStoreCheck"
                      >
                        Repair Store
                      </label>
                    </div>
                    <div className="form-check ">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="installationCheck"
                        value="Installation"
                        {...register("storeType")}
                      />
                      <label
                        className="form-check-label text-capitalize"
                        for="installationCheck"
                      >
                        Installation
                      </label>
                    </div>
                    <div className="form-check ">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="accessoriesCheck"
                        value="Accessories"
                        {...register("storeType")}
                      />
                      <label
                        className="form-check-label text-capitalize"
                        for="accessoriesCheck"
                      >
                        Accessories
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6">
                    <div className="form-check ">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="newCellphoneCheck"
                        value="New Cell Phone"
                        {...register("storeType")}
                      />
                      <label
                        className="form-check-label text-capitalize"
                        for="newCellphoneCheck"
                      >
                        New Cell Phone
                      </label>
                    </div>
                    <div className="form-check ">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="oldCellphoneCheck"
                        value="Old Cellphone"
                        {...register("storeType")}
                      />
                      <label
                        className="form-check-label text-capitalize"
                        for="oldCellphoneCheck"
                      >
                        Old Cellphone
                      </label>
                    </div>
                    <div className="form-check ">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="othersStoreTypeCheck"
                        value="Others"
                        {...register("storeType")}
                      />
                      <label
                        className="form-check-label text-capitalize"
                        for="othersStoreTypeCheck"
                      >
                        Others
                      </label>
                    </div>
                  </div>
                </div>

                <p className="text-danger">{errors.storeType?.message}</p>
              </div>
            </div>
            <div className="col-md-12 col-lg-12 mt-1">
              <div className="mb-3">
                <div className="d-flex align-items-baseline gap-10">
                  <input
                    className="me-2"
                    type="checkbox"
                    id="termsCondition"
                    {...register("termsCondition")}
                  />

                  <label className=" brand-label ">
                    I understand loss runs are required to receive a quote and I
                    hereby allow the agent to retrieve information using the
                    above-mentioned business information and provide me with
                    quote(s) for my perusal. I also understand that quotes are
                    for information and marketing purpose only.
                  </label>
                </div>

                <p className="text-danger mt-2">
                  {errors.termsCondition && (
                    <p>{errors.termsCondition.message}</p>
                  )}
                </p>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-warning"
              type="button"
              onClick={() => stepHandler(1)}
            >
              Previous
            </button>
            <button
              type="submit"
              className="btn btn-primary ms-2"
              disabled={disableAdd}
            >
              Submit
            </button>
          </div>
        </section>
      </form>
    </>
  );
};

export default Step2;
