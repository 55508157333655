import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

const validationSchema = yup.object().shape({
  q1: yup.string().oneOf(["yes", "no"]).required().label("This"),
  q2: yup.string().oneOf(["yes", "no"]).required().label("This"),
  q3: yup.string().oneOf(["yes", "no"]).required().label("This"),
  q4: yup.string().oneOf(["yes", "no"]).required().label("This"),
  q5: yup.string().oneOf(["yes", "no"]).required().label("This"),
  q6: yup.string().oneOf(["yes", "no"]).required().label("This"),
  q7: yup.string().oneOf(["yes", "no"]).required().label("This"),
  q8: yup.string().oneOf(["yes", "no"]).required().label("This"),
});

const Step2 = ({ stepHandler }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      q1: "",
      q2: "",
      q3: "",
      q4: "",
      q5: "",
      q6: "",
      q7: "",
      q8: "",
    },
    resolver: yupResolver(validationSchema),
  });
  // console.log(localStorage.getItem("newtankId"));
  useEffect(() => {
    const formData = JSON.parse(localStorage.getItem("step2"));
    if (formData) {
      setValue("q1", formData.q1);
      setValue("q2", formData.q2);
      setValue("q3", formData.q3);
      setValue("q4", formData.q4);
      setValue("q5", formData.q5);
      setValue("q6", formData.q6);
      setValue("q7", formData.q7);
      setValue("q8", formData.q8);
    }
  }, []);

  const handleFormSubmit = (data) => {
    const step1val = localStorage.getItem("step1");
    //const step2  = {...data}

    //console.log(step1val);
    //console.log(data);

    stepHandler(3);
    localStorage.setItem("step2", JSON.stringify(data));
  };
  return (
    <>
      <div className="container">
        <section>
          <div className="main-wrap">
            <div className="wizard-content">
              <form onSubmit={handleSubmit(handleFormSubmit)}>
                <div className="row">
                  <p align="center" className="brand-label">
                    <h2>Tank Insurance Application</h2>
                  </p>
                  <h4 className="wrap-title mb-3">Tank Background</h4>
                  <div className="col-md-12 col-lg-6">
                    <div className="mb-3">
                      <label className="brand-label d-block mb-0">
                        Are ALL tanks in compliance with current EPA
                        regulations?
                      </label>
                      <small className="d-block mb-2">
                        If "no", please provide full details (Attach seperately)
                      </small>
                      <div id="group1">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="q1"
                            id="yesCheck1"
                            value="yes"
                            {...register("q1")}
                          />
                          <label
                            className="form-check-label text-capitalize"
                            htmlFor="yesCheck1"
                          >
                            yes
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="q1"
                            id="noCheck1"
                            value="no"
                            {...register("q1")}
                          />
                          <label
                            className="form-check-label text-capitalize"
                            htmlFor="noCheck1"
                          >
                            no
                          </label>
                        </div>
                        <p className="text-danger">{errors.q1?.message}</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 col-lg-6">
                    <div className="mb-3">
                      <label className="brand-label d-block mb-0">
                        Do you own the tanks?
                      </label>
                      <small className="d-block mb-2">
                        If "no", please provide full details (Attach seperately)
                      </small>
                      <div id="group2">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="q2"
                            id="yesCheck2"
                            value="yes"
                            {...register("q2")}
                          />
                          <label
                            className="form-check-label text-capitalize"
                            htmlFor="yesCheck2"
                          >
                            yes
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="q2"
                            id="noCheck2"
                            value="no"
                            {...register("q2")}
                          />
                          <label
                            className="form-check-label text-capitalize"
                            htmlFor="noCheck2"
                          >
                            no
                          </label>
                        </div>
                        <p className="text-danger">{errors.q2?.message}</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 col-lg-12">
                    <div className="mb-3">
                      <label className="brand-label d-block mb-0">
                        Is the applicant aware of any incident, fact,
                        cirumstances, or situation including any act, error or
                        mission that may result in a claim being made aginst it
                        or any other person or entity for which coverage is
                        sought?
                      </label>
                      <small className="d-block mb-2">
                        If "yes", please provide full details (Attach
                        seperately)
                      </small>
                      <div id="group3">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="q3"
                            id="yesCheck3"
                            value="yes"
                            {...register("q3")}
                          />
                          <label
                            className="form-check-label text-capitalize"
                            htmlFor="yesCheck3"
                          >
                            yes
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="q3"
                            id="noCheck3"
                            value="no"
                            {...register("q3")}
                          />
                          <label
                            className="form-check-label text-capitalize"
                            htmlFor="noCheck3"
                          >
                            no
                          </label>
                        </div>
                        <p className="text-danger">{errors.q3?.message}</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 col-lg-12">
                    <div className="mb-3">
                      <label className="brand-label d-block mb-0">
                        Have any claims ever been made against the applicant or
                        have any claims ever been reported under any storage
                        tank policy?
                      </label>
                      <small className="d-block mb-2">
                        If "yes", please provide full details (Attach
                        seperately)
                      </small>
                      <div id="group4">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="q4"
                            id="yesCheck4"
                            value="yes"
                            {...register("q4")}
                          />
                          <label
                            className="form-check-label text-capitalize"
                            for="yesCheck4"
                          >
                            yes
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="q4"
                            id="noCheck4"
                            value="no"
                            {...register("q4")}
                          />
                          <label
                            className="form-check-label text-capitalize"
                            for="noCheck4"
                          >
                            no
                          </label>
                        </div>
                        <p className="text-danger">{errors.q4?.message}</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 col-lg-12">
                    <div className="mb-3">
                      <label className="brand-label d-block mb-0">
                        Has the applicant ever had any reportable releases or
                        spills of regulated substances, hazardous waste or any
                        other popullants, as defined by the applicable
                        environmental statues and regulations?
                      </label>
                      <small className="d-block mb-2">
                        If "yes", please provide full details (Attach
                        seperately)
                      </small>
                      <div id="group5">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="q5"
                            id="yesCheck5"
                            value="yes"
                            {...register("q5")}
                          />
                          <label
                            className="form-check-label text-capitalize"
                            for="yesCheck5"
                          >
                            yes
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="q5"
                            id="noCheck5"
                            value="no"
                            {...register("q5")}
                          />
                          <label
                            className="form-check-label text-capitalize"
                            for="noCheck5"
                          >
                            no
                          </label>
                        </div>
                        <p className="text-danger">{errors.q5?.message}</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 col-lg-12">
                    <div className="mb-3">
                      <label className="brand-label d-block mb-0">
                        Has there ever been any contamination at any owned
                        facility prior to or during your tenancy, operation
                        and/or ownership?
                      </label>
                      <small className="d-block mb-2">
                        If "yes", please provide full details (Attach
                        seperately)
                      </small>
                      <div id="group6">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="q6"
                            id="yesCheck6"
                            value="yes"
                            {...register("q6")}
                          />
                          <label
                            className="form-check-label text-capitalize"
                            for="yesCheck6"
                          >
                            yes
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="q6"
                            id="noCheck6"
                            value="no"
                            {...register("q6")}
                          />
                          <label
                            className="form-check-label text-capitalize"
                            for="noCheck6"
                          >
                            no
                          </label>
                        </div>
                        <p className="text-danger">{errors.q6?.message}</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 col-lg-12">
                    <div className="mb-3">
                      <label className="brand-label d-block mb-0">
                        Are there any plans to close, remove or upgrade any
                        tanks at any facility in the next 18 months?
                      </label>
                      <small className="d-block mb-2">
                        If "yes", please provide full details (Attach
                        seperately)
                      </small>
                      <div id="group7">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="q7"
                            id="yesCheck7"
                            value="yes"
                            {...register("q7")}
                          />
                          <label
                            className="form-check-label text-capitalize"
                            for="group7"
                          >
                            yes
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="q7"
                            id="noCheck7"
                            value="no"
                            {...register("q7")}
                          />
                          <label
                            className="form-check-label text-capitalize"
                            for="group7"
                          >
                            no
                          </label>
                        </div>
                        <p className="text-danger">{errors.q7?.message}</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 col-lg-12">
                    <div className="mb-3">
                      <label className="brand-label d-block mb-0">
                        Are there any insureds?
                      </label>
                      <small className="d-block mb-2">
                        If "yes", please provide information on step 3
                      </small>
                      <div id="group8">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="q8"
                            id="yesCheck7"
                            value="yes"
                            {...register("q8")}
                          />
                          <label
                            className="form-check-label text-capitalize"
                            for="yesCheck7"
                          >
                            yes
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="q8"
                            id="noCheck7"
                            value="no"
                            {...register("q8")}
                          />
                          <label
                            className="form-check-label text-capitalize"
                            for="noCheck7"
                          >
                            no
                          </label>
                        </div>
                        <p className="text-danger">{errors.q8?.message}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="d-flex gap-4 flex-end">
                  <button
                    className="btn btn-warning"
                    type="button"
                    onClick={() => stepHandler(1)}
                  >
                    Previous
                  </button>
                  <button className="btn btn-primary">Next</button>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Step2;
