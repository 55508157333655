import { IconBuildingStore } from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import { ThreeDots } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { default as axios } from "../../config/axios-config";
import { addColorsToChartData, convertChartData, getAllDataTogether, handleNotifications } from "../helper/Helper";
import "./../../portal-assets/normalize.css";
import "./../../portal-assets/styles.css";
import WelcomeModal from "./Modal";
import CanvasJSReact from "@canvasjs/react-charts";

const DEVURL = process.env.REACT_APP_ANC_API + "/api/Products";
const DEVURL1 = process.env.REACT_APP_ANC_API + "/api";

const Dashboard = () => {
  var CanvasJSChart = CanvasJSReact.CanvasJSChart;
  const dispatch = useDispatch();
  let allData = useSelector((state) => state.someData);
  const userData = useSelector((state) => state.auth.user);
  const query = new URLSearchParams(useLocation().search);
  const verifyId = query.get("verifyid");
  const agencyId = userData.id;
  if (allData?.data.length <= 0) {
    getAllDataTogether(agencyId, userData.agentId, dispatch);
  }
  const [agencyName, setAgencyName] = useState("");
  const [loading, setLoading] = useState(true);
  const [productWithLink, setProductWithLink] = useState(null);
  const [profileDetails, setProDetails] = useState(null);
  const [productLists, setProductLists] = useState(null);
  const [TotalLeads, setTotalLeads] = useState(null);
  const [welcomeModal, setWelcomeModal] = useState(false);
  const [totalFormSubmissionByMonthStat, setTotalFormSubmissionByMonthStat] = useState([]);
  const [totalFormSubmissionByTypeStat, setTtalFormSubmissionByTypeStat] = useState([]);
  const getProductData = () => {
    if (allData?.data?.createdLinkResponse) {
      setProductWithLink(allData?.data?.createdLinkResponse);
    }
    setLoading(true);

    let AgencyId = localStorage.getItem("AgencyId");
    let AId = agencyId ?? AgencyId;
    const url = userData.agentId ? `${DEVURL}/GetCreatedLink/${userData.id}/${userData.agentId}` : `${DEVURL}/GetCreatedLink/${userData.id}`;
    axios
      .get(url)
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          let product = response.data;
          product = product.map((item) => {
            item.activated = item.isUseStatus;
            return item;
          });
          setProductWithLink(product);
        }
      })
      .catch((error) => {
        setLoading(false);
        // handleNotifications("Something is wrong with get products! ", 'error');
        if (error?.response?.data?.status === 404) {
        } else {
        }
      });
  };
  const getActiveProductData = () => {
    setLoading(true);
    let AgencyId = localStorage.getItem("AgencyId");
    let AId = agencyId ?? AgencyId;
    const url = userData.agentId ? `${DEVURL}/GetProduct/${userData.id}/${userData.agentId}` : `${DEVURL}/GetProduct/${userData.id}`;
    axios
      .get(url)
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          let product = response.data;
          product = product.map((item) => {
            item.activated = item.isUseStatus ?? 0;
            return item;
          });
          setProductLists(product);
        }
      })
      .catch((error) => {
        setLoading(false);
        // handleNotifications("Something is wrong with get products! ", 'error');
        if (error?.response?.data?.status === 404) {
        } else {
        }
      });
  };

  const getProfileData = (data) => {
    if (allData?.data?.agencyInfo) {
      setProDetails(allData?.data?.agencyInfo);
    }

    console.log("userData.userType", userData.userType);
    if (userData.userType === 1) {
      axios
        .get(`${DEVURL1}/agency/getAgencyById/${userData.id}`)
        .then((response) => {
          if (response.status === 200) {
            let data = response.data;
            data.base64logo = `data:image/jpeg;base64, ${data.base64Logo}`;
            setProDetails(data);
          }
        })
        .catch((error) => {
          if (error?.response?.data?.status === 404) {
          } else {
          }
        });
    } else {
      axios
        .get(`${DEVURL1}/Agent/GetAgentInfoById/${userData.agentId}`)
        .then((response) => {
          if (response.status === 200) {
            let data = response.data;
            data.base64logo = `data:image/jpeg;base64, ${data.base64Logo}`;
            setProDetails(data);
          }
        })
        .catch((error) => {
          if (error?.response?.data?.status === 404) {
          } else {
          }
        });
    }
  };
  const getLeadsAndHealthAppCount = () => {
    axios
      .get(`${DEVURL1}/leads/TotalLeadsByAgencyid/${userData.id}/${userData.agentId}`)
      .then((response) => {
        if (response.status === 200) {
          let data = response.data;
          setTotalLeads(data);
        }
      })
      .catch((error) => {
        if (error?.response?.data?.status === 404) {
        } else {
        }
      });
  };
  const getControlPanelData = () => {
    const url = userData.agentId
      ? `${DEVURL1}/ControlPanel/GetDashboardInfoByName/${userData.id}/${userData.agentId}`
      : `${DEVURL1}/ControlPanel/GetDashboardInfoByName/${userData.id}`;
    axios
      .get(url)
      .then((response) => {
        if (response.status === 200) {
          let data = response.data;
          // setTotalLeads(data);
          console.log("data", data);
          setTotalFormSubmissionByMonthStat(convertChartData(data.totalFormSubmissionByMonthStat));
          setTtalFormSubmissionByTypeStat(convertChartData(data.totalFormSubmissionByTypeStat.data));
        }
      })
      .catch((error) => {
        if (error?.response?.data?.status === 404) {
        } else {
        }
      });
  };

  useEffect(() => {
    getControlPanelData();
  }, []);
  const buttonStyles = (type) => {
    if (type) {
      return { cursor: "none" };
    } else {
      return { cursor: "pointer" };
    }
  };
  const handleActivated = (product) => {
    let data = {
      AgencyId: profileDetails?.id,
      AgencyName: profileDetails?.agencyName,
      FirstName: profileDetails?.firstName,
      LastName: profileDetails?.lastName ?? "",
      Email: profileDetails?.email,
      ProductName: product.productName,
      ProductId: product.productId,
      ProductActivation: product.status,
    };
    axios
      .post(`${DEVURL}/Activate`, data)
      .then((response) => {
        if (response.status === 200) {
          setTimeout(function () {
            let activated_ids = {};
            let products = productLists.map((item) => {
              if (product.productName == item.productName) {
                item.activated = product.status;
              }
              return item;
            });
            setProductLists(products);
            getActiveProductData();
          }, 1000);
          handleNotifications("Successfully activated " + product.productName + " Product");
        }
      })
      .catch((error) => {
        if (error?.response?.data?.status === 404) {
        } else {
        }
      });
  };

  useEffect(() => {
    if (verifyId) {
      setWelcomeModal(true);
    }
  }, []);
  useEffect(() => {
    getLeadsAndHealthAppCount();
  }, []);
  useEffect(() => {
    getProductData();
    getActiveProductData();
    setTimeout(() => {
      setAgencyName(localStorage.getItem("AgencyName"));
      getProfileData();
    }, 1000);
  }, []);

  const loader = () => {
    return (
      <ThreeDots
        height="40"
        width="80"
        color="#4fa94d"
        ariaLabel="bars-loading"
        wrapperStyle={{ justifyContent: "center", paddingBottom: "10px" }}
        wrapperClass=""
        visible={true}
      />
    );
  };

  const data = React.useMemo(
    () => [
      {
        label: "Series 1",
        data: [
          [0, 1],
          [1, 2],
          [2, 4],
          [3, 2],
          [4, 7],
        ],
      },
      {
        label: "Series 2",
        data: [
          [0, 3],
          [1, 1],
          [2, 5],
          [3, 6],
          [4, 4],
        ],
      },
    ],
    []
  );

  const axes = React.useMemo(
    () => [
      { primary: true, type: "linear", position: "bottom" },
      { type: "linear", position: "left" },
    ],
    []
  );

  // const lineChart = (
  //   // A react-chart hyper-responsively and continuously fills the available
  //   // space of its parent element automatically
  //   <div
  //     style={{
  //       width: "500px",
  //       height: "300px",
  //     }}
  //   >
  //     <Chart data={data} axes={axes} />
  //   </div>
  // );

  const importantNextStepsLists = [
    {
      routeName: "myhealthcare",
      imgUrl: "/assets/images/medical-symbol.png",
      title: "Healthcare",
      disabled: false,
      productId: "myHealthcare",
    },
    {
      routeName: "auto",
      imgUrl: "/assets/images/vehicle.png",
      title: "Auto",
      disabled: false,
      productId: "mypersonalline",
    },
    {
      routeName: "home",
      imgUrl: "/assets/images/home.png",
      title: "Home",
      disabled: false,
      productId: "mypersonalline",
    },

    {
      routeName: "gasstation",
      imgUrl: "/assets/images/gas-station.png",
      title: "Gas Station",
      disabled: false,
      productId: "mybusinessline",
    },
    {
      routeName: "restaurantform",
      imgUrl: "/assets/images/restaurant.png",
      title: "Restaurant",
      disabled: false,
      productId: "mybusinessline",
    },
    {
      routeName: "salon",
      imgUrl: "/assets/images/salon.png",
      title: "Salon",
      disabled: false,
      productId: "mybusinessline",
    },
    {
      routeName: "generalContractor",
      imgUrl: "/assets/images/support.png",
      title: "General Contractor",
      disabled: false,
      productId: "mybusinessline",
    },
    {
      routeName: "shoppingMall",
      imgUrl: "/assets/images/mall.png",
      title: "Shopping Mall",
      disabled: false,
      productId: "mybusinessline",
    },
    // {
    //   routeName: "#",
    //   imgUrl: "/assets/images/callcenter.png",
    //   title: "Cellphone Center",
    //   disabled: true,
    //   productId: "mybusinessline",
    // },
    {
      routeName: "generalStore",
      icon: <IconBuildingStore class="prod-img" color="#ffdb58" />,
      title: "General Business",
      disabled: false,
      productId: "mybusinessline",
    },
    {
      routeName: "hotelForm",
      imgUrl: "/assets/images/hotel.png",
      title: "Hotel Commercial",
      disabled: false,
      productId: "mybusinessline",
    },
  ];
  console.log("importantNextStepsLists", importantNextStepsLists, productLists);
  const filteredNextSteps = importantNextStepsLists?.filter((nextStep) => {
    const correspondingProduct = productLists?.find((product) => product?.productId?.toLowerCase() === nextStep?.productId?.toLowerCase());
    return correspondingProduct && correspondingProduct?.isUseStatus === 1;
  });
  const totalFormSubmissionByMonthStat_options = {
    title: {
      text: "Annual form submission report",
    },
    data: [
      {
        type: "column",
        dataPoints: addColorsToChartData(totalFormSubmissionByMonthStat, "column"),
      },
    ],
  };
  console.log("totalFormSubmissionByTypeStat", totalFormSubmissionByTypeStat);

  const totalFormSubmissionByTypeStat_options = {
    exportEnabled: true,
    animationEnabled: true,
    colorSet: "customColorSet2",
    title: {
      text: "Monthly form submission report",
    },
    data: [
      {
        type: "pie",
        startAngle: 75,
        toolTipContent: "<b>{label}</b>: {y}",
        showInLegend: "true",
        legendText: "{label}",
        indexLabelFontSize: 16,
        indexLabel: "{label} - {y}",
        dataPoints: addColorsToChartData(totalFormSubmissionByTypeStat, "pie"),
      },
    ],
  };

  return (
    <div className="col py-3">
      <div className="content-panel">
        <div className="panel-title">
          <h4>Dashboard</h4>
        </div>

        <div className="user-intro-msg align-items: center">
          {(!profileDetails || !userData) && loader()}
          {(profileDetails || userData) && <h3>Hello, {profileDetails?.agencyName || userData.name}! Here's your recent activity.</h3>}
        </div>
        <div className="row user-intro-ms">
          <div className="col-md-3 center">
            <Link to="/myvault/activity/myhealthcare">
              <div class="stats-card">
                <h6>healthCare App. </h6>
                <h3>{TotalLeads?.healthAppCount ?? 0}</h3>
              </div>
            </Link>
          </div>

          <div className="col-md-3">
            <Link to="/myvault/activity/myhealthcare?tab=2">
              <div className="stats-card">
                <h6>Leads</h6>
                <h3>{TotalLeads?.healthleadCount ?? 0}</h3>
              </div>
            </Link>
          </div>
        </div>
        <hr />
        {totalFormSubmissionByTypeStat.length && totalFormSubmissionByMonthStat.length ? (
          <>
            <div className="row  my-4 py-4">
              <div className="brand-card col-7 ">
                <CanvasJSChart options={totalFormSubmissionByMonthStat_options} />
              </div>
              <div className="brand-card col-5 ">
                <CanvasJSChart options={totalFormSubmissionByTypeStat_options} />
              </div>
            </div>
            <hr />
          </>
        ) : (
          <>{loader()}</>
        )}
        <div class="row flex-nowrap">
          <div class="col py-3">
            <div class="row">
              {loading && loader()}
              {!loading &&
                filteredNextSteps?.map((item, index) => (
                  <div key={index} class="col-md-4">
                    <Link to={`/myvault/activity/${item.routeName}`}>
                      <div class={`brand-card mt-2  card-link-height ${item.disabled ? "disabled-card" : "card-link"}`}>
                        {item.icon ? item.icon : <img class="prod-img prod-img-color" src={item.imgUrl} alt="" />}
                        <h6 class="brand-title text-center  mb-3">{item.title}</h6>
                      </div>
                    </Link>
                  </div>
                ))}
            </div>
          </div>
        </div>

        <div className="chart-section mt-4"></div>
        {/* <div className="row">
          <div className="col-md-6">
            <Card className="brand-card h-100">
              <Card.Header className="brand-card-title">
                <h4>Your Information</h4>
              </Card.Header>
              <Card.Body>
                {profileDetails !== null && (
                  <>
                    {userData.userType === 1 ? (
                      <ul className="list-group mb-3">
                        <li className="list-group-item d-flex align-items-center">
                          <h6 className="mb-0 w-30 me-3">Name</h6>
                          <p className="mb-0">{profileDetails?.agencyName}</p>
                        </li>
                        <li className="list-group-item d-flex align-items-center">
                          <h6 className="mb-0 w-30 me-3">Address</h6>
                          <p className="mb-0">{profileDetails?.address + "," + profileDetails?.city + "," + profileDetails?.state + "-" + profileDetails?.zip}</p>
                        </li>

                        <li className="list-group-item d-flex align-items-center">
                          <h6 className="mb-0 w-30 me-3">Phone</h6>
                          <p className="mb-0">{profileDetails?.phone}</p>
                        </li>
                      </ul>
                    ) : (
                      <ul className="list-group mb-3">
                        <li className="list-group-item d-flex align-items-center">
                          <h6 className="mb-0 w-30 me-3">Name</h6>
                          <p className="mb-0">
                            {profileDetails?.firstName} {profileDetails.lastName}
                          </p>
                        </li>
                        <li className="list-group-item d-flex align-items-center">
                          <h6 className="mb-0 w-30 me-3">Email</h6>
                          <p className="mb-0">{profileDetails?.email}</p>
                        </li>
                        <li className="list-group-item d-flex align-items-center">
                          <h6 className="mb-0 w-30 me-3">User ID</h6>
                          <p className="mb-0">{profileDetails?.userId}</p>
                        </li>
                        <li className="list-group-item d-flex align-items-center">
                          <h6 className="mb-0 w-30 me-3">Phone</h6>
                          <p className="mb-0">{profileDetails?.phoneNo}</p>
                        </li>
                      </ul>
                    )}
                  </>
                )}
              </Card.Body>
            </Card>
          </div>

          <div className="col-md-6">
            <Card className="brand-card h-100">
              <Card.Header className="brand-card-title">
                <h4>Help and Support</h4>
              </Card.Header>
              <Card.Body>
                <ul>
                  <li>contact@agentsandcarriers.com</li>
                </ul>
              </Card.Body>
            </Card>
          </div>
        </div> */}
      </div>
      <div></div>
      <WelcomeModal showModal={welcomeModal} closeModal={setWelcomeModal}></WelcomeModal>
    </div>
  );
};

Dashboard.displayName = "dashboard";
//
// const mapStateToProps = (state) => {
//     return {
//         allData: state.someData, // Make sure to match the correct reducer and property name
//     };
// };

export default Dashboard;
// export default connect(mapStateToProps)(Dashboard);
// export default Dashboard;
