import React, { useEffect, useState } from "react";
import EditPrimaryHealthcare from "./EditPrimaryHealthcare";
import EditDependentHealthcare from "./EditDependentHealthcare";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import PlaceComponent from "../../../healthcare/GooglePlace";
import { Badge } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { cellRegex, emailRegex, floatRegex, nameRegex } from "../../../../Common/regex";
import { formatTelNumber, formattedDate } from "../../../../Common/Utils";
import Swal from "sweetalert2";
import axios from "axios";
import { ThreeDots } from "react-loader-spinner";
import ThankyouResponse from "../../../Thankyoupage";
import axiosInstance from "../../../../config/axios-config";
import { useSelector } from "react-redux";
import { NumericFormat } from "react-number-format";

const validationSchema = yup.object().shape({
  roofDate: yup
    .string()
    .nullable(true)
    .test("is-past-date", "Date must be a past date", (value) => {
      if (!value) {
        return true;
      }
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);

      const inputDate = new Date(value);
      inputDate.setHours(0, 0, 0, 0);
      return inputDate < currentDate;
    })
    .label("Roof date"),

  electricalDate: yup
    .string()
    .nullable(true)
    .typeError("Please enter valid date")
    .test("is-past-date", "Date must be a past date", (value) => {
      if (!value) {
        return true;
      }
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);

      const inputDate = new Date(value);
      inputDate.setHours(0, 0, 0, 0);

      return inputDate < currentDate;
    })
    .label("Electrical date"),

  plumbingDate: yup
    .string()
    .nullable(true)
    .typeError("Please enter valid date")
    .test("is-past-date", "Date must be a past date", (value) => {
      if (!value) {
        return true;
      }
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);

      const inputDate = new Date(value);
      inputDate.setHours(0, 0, 0, 0);

      return inputDate < currentDate;
    })
    .label("Plumbing date"),
  hvacDate: yup
    .string()
    .nullable(true)
    .typeError("Please enter valid date")
    .test("is-past-date", "Date must be a past date", (value) => {
      if (!value) {
        return true;
      }
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);

      const inputDate = new Date(value);
      inputDate.setHours(0, 0, 0, 0);

      return inputDate < currentDate;
    })
    .label("Havc date"),
  propertyAddress: yup.string().trim().required("Address is required").label("Address"),
  firstName: yup.string().trim().matches(nameRegex, "Please enter a valid name").max(45).required("First name is required").label("First name"),
  currentHomeValue: yup.string().trim().nullable(true).matches(floatRegex, "Please enter a valid current home value").label("Current home value"),
  lastName: yup.string().trim().matches(nameRegex, "Please enter a valid name").max(45).required("Last name is required").label("Last name"),
  cell: yup.string().trim().required("Mobile number is required").matches(cellRegex, "Please enter a valid mobile number").label("Mobile number"),
  workPhone: yup
    .string()
    .trim()
    .nullable(true)
    .matches(/^(\([0-9]{3}\)|[0-9]{3})[\s\-]?[0-9]{3}[\s\-]?[0-9]{4}$|^$/, "Please enter a valid work phone number")
    .label("Work mobile number"),
  email: yup.string().trim().required("Email address is required").matches(emailRegex, "Enter a valid email address").label("Email address"),
  dob: yup
    .date()
    .typeError("Date of birth is a required")
    .test("is-age-15-or-older", "User age must be 15 or older", (value) => {
      if (!value) {
        return true;
      }
      const birthYear = value.getFullYear();
      const currentYear = new Date().getFullYear();
      return currentYear - birthYear >= 15;
    })
    .required("Date of birth is required")
    .label("Date of birth"),
  spouseFirstName: yup.string().trim().matches(nameRegex, "Please enter a valid name").max(45).label("First name"),
  spouseLastName: yup.string().trim().matches(nameRegex, "Please enter a valid name").max(45).label("Last name"),
  spouseDob: yup
    .date()
    .nullable(true)
    // .typeError("Date of birth is a required")
    .test("is-age-15-or-older", "Spouse age must be 15 or older", (value) => {
      if (!value) {
        return true;
      }
      const birthYear = value.getFullYear();
      const currentYear = new Date().getFullYear();
      return currentYear - birthYear >= 15;
    })
    .label("Date of birth"),
  insurancePremium: yup.string().nullable(true).matches(/^\d+$/, "Please enter a valid amount"),
});

const EditHealthcare = ({ selectedHealthcare }) => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    setValue,
    reset,
    watch,
    control,
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      cell: "",
      workPhone: "",
      spouseFirstName: "",
      spouseLastName: "",
      spouseDob: null,
      dob: null,
      propertyAddress: "",
      city: "",
      state: "",
      zipCode: "",
      currentHomeValue: null,
      DiscountsCheck: [],
      carrierName: "",
      renewalDate: null,
      insurancePremium: null,
      carrierDuration: null,
      apt: "",
      claimDate: null,
      claimReason: "",
      roofDate: null,
      electricalDate: null,
      plumbingDate: null,
      hvacDate: null,
      agentId: "",
      propertyAnyUpdate: [],
    },
    resolver: yupResolver(validationSchema),
  });
  const cell = watch("cell");
  const workPhone = watch("workPhone");
  const userData = useSelector((state) => state.auth.user);
  const [isCaptchaSuccessful, setIsCaptchaSuccess] = useState(false);
  const [addesss, setAddress] = useState("");
  const [isThanksPage, setIsThanksPage] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const DEVURL = process.env.REACT_APP_ANC_API + "/api/HomeApplication/";

  const getLocation = async (data) => {
    let address_components = data?.address_components;
    let formatted_address = data?.formatted_address;
    setAddress(formatted_address);
    formatted_address = formatted_address.split(",");
    setValue("propertyAddress", formatted_address[0] ?? "");
    let zip_code = "";
    address_components.map((data) => {
      if (data.types.includes("postal_code")) {
        zip_code = data.long_name + zip_code;
      }
      // if (data.types.includes("postal_code_suffix")) {
      //     zip_code =  zip_code +'-'+ data.long_name;
      // }
      if (data.types.includes("locality")) {
        setValue("city", data.long_name);
      }
      if (data.types.includes("administrative_area_level_1")) {
        setValue("state", data.long_name);
      }
    });
    setValue("zipCode", zip_code);
  };

  const handleCancelClick = () => {
    selectedHealthcare = null;
  };
  const onChangeRecaptcha = (value) => {
    setIsCaptchaSuccess(true);
  };

  const setDefaultData = (primaryInfo) => {
    setValue("homeAppId", primaryInfo.homeAppId);
    setValue("firstName", primaryInfo.customerFirstName);
    setValue("lastName", primaryInfo.customerLastName);
    setValue("email", primaryInfo.customerEmail);
    setValue("cell", primaryInfo.customerMobileNum);
    setValue("workPhone", primaryInfo.customerWorkNum);
    setValue("spouseFirstName", primaryInfo.spouseFirstName);
    setValue("spouseLastName", primaryInfo.spouseLastName);
    setValue("spouseDob", formattedDate(primaryInfo.dateOfBirth));
    setValue("dob", formattedDate(primaryInfo.customerDateOfBirth));
    setValue("propertyAddress", primaryInfo.propertyAddress);
    setValue("city", primaryInfo.propertyCity);
    setValue("state", primaryInfo.propertyState);
    setValue("zipCode", primaryInfo.propertyZipeCode);
    setValue("currentHomeValue", primaryInfo.currentHomeValue);
    setValue("DiscountsCheck", primaryInfo.discountsCheck);
    setValue("carrierName", primaryInfo.carrierName);
    setValue("renewalDate", formattedDate(primaryInfo.renewalDate));
    setValue("insurancePremium", primaryInfo.premiumAmount);
    setValue("carrierDuration", primaryInfo.duration);
    setValue("apt", primaryInfo.propertyAddress);
    setValue("claimDate", formattedDate(primaryInfo.dateOfClaim));
    setValue("claimReason", primaryInfo.claimReason);
    setValue("roofDate", formattedDate(primaryInfo.isRoofUpdated));
    setValue("electricalDate", formattedDate(primaryInfo.isWiringUpdated));
    setValue("plumbingDate", formattedDate(primaryInfo.isPlumbingUpdated));
    setValue("hvacDate", formattedDate(primaryInfo.isHeatingUpdated));
    setAddress(primaryInfo.propertyAddress);
  };
  useEffect(() => {
    console.log("selectedHealthcare", selectedHealthcare);
    if (selectedHealthcare) setDefaultData(selectedHealthcare);
  }, [selectedHealthcare]);

  const handleFormSubmit = async (data) => {
    data = {
      HomeAppId: data?.homeAppId,
      CustomerFirstName: data?.firstName,
      CustomerLastName: data?.lastName,
      CustomerDateOfBirth: data?.dob,
      CustomerMobileNum: data?.cell,
      CustomerEmail: data?.email,
      CustomerWorkNum: data?.workPhone,
      SpouseFirstName: data?.spouseFirstName,
      SpouseLastName: data?.spouseLastName,
      DateOfBirth: data?.spouseDob,
      PropertyAddress: data.propertyAddress,
      PropertyCity: data.city,
      PropertyZipeCode: data.zipCode,
      PropertyState: data.state,
      CurrentHomeValue: data?.currentHomeValue,
      CentralMonitorDis: 1,
      GatedComDis: 1,
      WaterShutDis: 1,
      SprinklerDis: 1,
      UlComplaintDis: 1,
      IsRoofUpdated: data?.roofDate,
      IsWiringUpdated: data.electricalDate,
      IsPlumbingUpdated: data.plumbingDate,
      IsHeatingUpdated: data.hvacDate,
      DateOfClaim: data?.claimDate,
      ClaimReason: data?.claimReason,
      AgencyId: userData.id,
      AgentId: userData?.agentId,
      DiscountsCheck: data.DiscountsCheck,
      carrierName: data.carrierName,
      carrierDuration: data.carrierDuration,
      renewalDate: data.renewalDate,
      premiumAmount: data.insurancePremium,
    };
    // console.log(data);
    // setStep1ToLocalStorage(data);
    Swal.fire({
      title: "Are you sure you want to submit the form?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        // Call the onSubmit function when the user clicks "OK".

        updateHomeApplicationInfo(data);
      } else {
        // The user clicked "Cancel," so exit without taking any action.
        return;
      }
    });
  };

  const updateHomeApplicationInfo = async (data) => {
    await axiosInstance
      .put(`${DEVURL}UpdateHomeApplication`, data)
      .then((res) => {
        if (res.status === 200) {
          reset();
          setLoading(false);
          setIsThanksPage(true);
          setTimeout(() => {
            setIsThanksPage(false);
            setDefaultData(selectedHealthcare);
          }, 3000);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <>
      <div className="offcanvas offcanvas-end" tabIndex="-1" id="editHealthcare" aria-labelledby="offcanvasRightLabel">
        <div className="offcanvas-header activity-status-table brand-offcanvas-header">
          <h5 id="offcanvasRightLabel">Edit Home Product</h5>
          <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" onClick={handleCancelClick} aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
          <div className="activity-cust-data">
            <form onSubmit={handleSubmit(handleFormSubmit)} className="tab-wizard wizard-circle wizard needs-validation">
              {isLoading && (
                <ThreeDots
                  height="40"
                  width="80"
                  color="#4fa94d"
                  ariaLabel="bars-loading"
                  wrapperStyle={{ justifyContent: "center", paddingBottom: "10px" }}
                  wrapperClass=""
                  visible={true}
                />
              )}
              {!isThanksPage && !isLoading && (
                <section>
                  <h4 className="wrap-title mb-3">Home Information </h4>

                  <div className="row">
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label className="brand-label">
                          First Name<span className="required-mark">*</span>
                        </label>
                        <input id="firstName" type="text" className="form-control" placeholder="Enter First Name" {...register("firstName")} />
                        <p className="text-danger mt-2">
                          <p>{errors?.firstName?.message}</p>
                        </p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label className="brand-label">
                          Last Name<span className="required-mark">*</span>
                        </label>
                        <input id="lastName" type="text" className="form-control" placeholder="Enter Last Name" {...register("lastName")} />
                        <p className="text-danger mt-2">{errors.lastName && <p>{errors.lastName.message}</p>}</p>
                      </div>
                    </div>
                    <div className="col-md-4 col-lg-4">
                      <div className="mb-3">
                        <label className="brand-label">
                          Email Address<span className="required-mark">*</span>
                        </label>
                        <input id="email" type="email" className="form-control" placeholder="Enter Email Address" {...register("email")} />
                        <p className="text-danger mt-2">{errors.email && <p>{errors.email.message}</p>}</p>
                      </div>
                    </div>
                    <div className="col-md-4 col-lg-4">
                      <div className="mb-3">
                        <label className="brand-label">
                          Mobile Number
                          <span className="required-mark">*</span>
                        </label>
                        <Controller
                          name="cell"
                          control={control}
                          render={({ field }) => (
                            <input {...field} type="text" class="form-control" placeholder="(XXX) XXX-XXXX" value={formatTelNumber(cell)} {...register("cell")} />
                          )}
                        />
                        <p className="text-danger mt-2">{errors.cell && <p>{errors.cell.message}</p>}</p>
                      </div>
                    </div>
                    <div className="col-md-4 col-lg-4">
                      <div className="mb-3">
                        <label className="brand-label">Work Mobile Number</label>

                        <Controller
                          name="workPhone"
                          control={control}
                          render={({ field }) => (
                            <input {...field} type="text" class="form-control" placeholder="(XXX) XXX-XXXX" value={formatTelNumber(workPhone)} {...register("workPhone")} />
                          )}
                        />
                        <p className="text-danger mt-2">{errors.workPhone && <p>{errors.workPhone.message}</p>}</p>
                      </div>
                    </div>
                    <div className="col-md-4 col-lg-4">
                      <div className="mb-3">
                        <label className="brand-label">
                          Date of Birth<span className="required-mark">*</span>
                        </label>
                        <input id="birthday" type="date" max="2099-12-31" className="form-control" placeholder="Enter Enrollment Date" {...register("dob")} />
                        <p className="text-danger mt-2">{errors.dob && <p>{errors.dob.message}</p>}</p>
                      </div>
                    </div>

                    {/* Garrage Address  */}
                  </div>

                  <div className="row my-3">
                    <h5 className=" wrap-title  mb-3">Spouse (if applicable):</h5>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label className="brand-label">First Name</label>
                        <input id="firstName" type="text" className="form-control" placeholder="Enter First Name" {...register("spouseFirstName")} />
                        <p className="text-danger mt-2">{errors.spouseFirstName && <p>{errors.spouseFirstName.message}</p>}</p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <label className="brand-label">Last Name</label>
                        <input id="lastName" type="text" className="form-control" placeholder="Enter Last Name" {...register("spouseLastName")} />
                        <p className="text-danger mt-2">{errors.spouseLastName && <p>{errors.spouseLastName.message}</p>}</p>
                      </div>
                    </div>
                    <div className="col-md-4 col-lg-4">
                      <div className="mb-3">
                        <label className="brand-label">Date of Birth</label>
                        <input id="birthday" type="date" max="2099-12-31" className="form-control" placeholder="Enter Enrollment Date" {...register("spouseDob")} />
                        <p className="text-danger mt-2">{errors.spouseDob && <p>{errors.spouseDob?.message}</p>}</p>
                      </div>
                    </div>

                    <h4 className=" wrap-title  mb-3">Property and Discount Info:</h4>
                    <div className="col-md-4 col-lg-4">
                      <div className="mb-3">
                        <label className="brand-label">
                          Property address<span className="required-mark">*</span>
                        </label>
                        <PlaceComponent getLocationAddress={getLocation} value={addesss} type="profile-update">
                          {" "}
                        </PlaceComponent>
                        <p className="text-danger mt-2">{errors.propertyAddress && <p>{errors.propertyAddress.message}</p>}</p>
                      </div>
                      <div className="mb-3">
                        <label className="brand-label">Current home value (Optional)</label>
                       <Controller
                          name="currentHomeValue"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <NumericFormat
                              allowNegative={false}
                              {...field}
                              className="form-control"
                              thousandSeparator={true}
                              prefix="$"
                              onValueChange={(values) => {
                                setValue("currentHomeValue", values.floatValue);
                              }}
                              placeholder="Current insurance premium"
                              {...register("currentHomeValue")}
                            />
                          )}
                        />
                        <p className="text-danger mt-2">{errors.currentHomeValue && <p>{errors.currentHomeValue?.message}</p>}</p>
                      </div>
                    </div>

                    <div className="col-md-3 col-lg-8 px-5">
                      <h5 className=" wrap-title  mb-3">Discounts:</h5>
                      <div className="row">
                        <div className="col-md-6 col-lg-6">
                          <label className="brand-label d-grid">
                            <div className="form-check form-check-inline">
                              <input className="form-check-input" type="checkbox" id="burglarAlarm" value="burglarAlarm" {...register("DiscountsCheck")} />
                              <label className="form-check-label text-capitalize" htmlFor="burglarAlarm">
                                Centrally monitored burglar alarm
                              </label>
                            </div>
                          </label>
                        </div>
                        <div className="col-md-6 col-lg-6">
                          <label className="brand-label d-grid">
                            <div className="form-check form-check-inline">
                              <input className="form-check-input" type="checkbox" id="gatedCommunity" value="gatedCommunity" {...register("DiscountsCheck")} />
                              <label className="form-check-label text-capitalize" htmlFor="gatedCommunity">
                                Gated community
                              </label>
                            </div>
                          </label>
                        </div>
                        <div className="col-md-6 col-lg-6">
                          <label className="brand-label d-grid">
                            <div className="form-check form-check-inline">
                              <input className="form-check-input" type="checkbox" id="WaterShutOff" value="WaterShutOff" {...register("DiscountsCheck")} />
                              <label className="form-check-label text-capitalize" htmlFor="WaterShutOff">
                                Water shut-off device
                              </label>
                            </div>
                          </label>
                        </div>
                        <div className="col-md-6 col-lg-6">
                          <label className="brand-label d-grid">
                            <div className="form-check form-check-inline">
                              <input className="form-check-input" type="checkbox" id="ULCompliantroofCredit" value="ULCompliantroofCredit" {...register("DiscountsCheck")} />
                              <label className="form-check-label text-capitalize" htmlFor="ULCompliantroofCredit">
                                UL Compliant roof credit
                              </label>
                            </div>
                          </label>
                        </div>
                        <div className="col-md-6 col-lg-6">
                          <label className="brand-label d-grid">
                            <div className="form-check form-check-inline">
                              <input className="form-check-input" type="checkbox" id="ResidentialSprinkler" value="ResidentialSprinkler" {...register("DiscountsCheck")} />
                              <label className="form-check-label text-capitalize" htmlFor="ResidentialSprinkler">
                                Residential sprinkler
                              </label>
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>

                    <h4 className=" wrap-title  mb-3">Last update year:</h4>
                    <div className="col-md-6 col-lg-3">
                      <div className="mb-3">
                        <label className="brand-label">Last Roof updated?</label>
                        <input id="roofDate" type="date" max="2099-12-31" className="form-control" placeholder="Enter Last Roof Update" {...register("roofDate")} />
                        <p className="text-danger">{errors.roofDate && <p>{errors.roofDate.message}</p>}</p>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-3">
                      <div className="mb-3">
                        <label className="brand-label">Last Wiring updated?</label>
                        <input id="electricalDate" type="date" max="2099-12-31" className="form-control" placeholder="Enter Last Wiring Update" {...register("electricalDate")} />
                        <p className="text-danger">{errors.electricalDate && <p>{errors.electricalDate.message}</p>}</p>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-3">
                      <div className="mb-3">
                        <label className="brand-label">Last Plumbing updated?</label>
                        <input type="date" max="2099-12-31" className="form-control" placeholder="Enter Last Plumbing Update" id="plumbingDate" {...register("plumbingDate")} />
                        <p className="text-danger">{errors.plumbingDate && <p>{errors.plumbingDate.message}</p>}</p>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-3">
                      <div className="mb-3">
                        <label className="brand-label">Last Heating updated?</label>
                        <input
                          type="date"
                          max="2099-12-31"
                          className="form-control"
                          placeholder="Enter Last Heating Update"
                          id="hvacDate"
                          name="hvacDate"
                          {...register("hvacDate")}
                        />
                        <p className="text-danger">{errors.hvacDate && <p>{errors.hvacDate.message}</p>}</p>
                      </div>
                    </div>

                    <h4 className="mt-2 wrap-title  mb-3">Claim information (if applicable):</h4>
                    <div className="col-md-6 col-lg-4">
                      <div className="mb-3">
                        <label className="brand-label d-grid">
                          <label className="form-check-label text-capitalize" htmlFor="claimDate">
                            Date of claim
                          </label>
                        </label>
                        <input id={"claimDate"} type="date" max="2099-12-31" className="form-control" placeholder="Date of claim" {...register("claimDate")} />
                      </div>
                      <p className="text-danger">{errors.claimDate?.message}</p>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="mb-3">
                        <label className="brand-label d-grid">
                          <label className="form-check-label text-capitalize" htmlFor="claimReason">
                            Claim Reason
                          </label>
                        </label>
                        <input type="text" id="claimReason" className="form-control" placeholder="Claim Reason" {...register("claimReason")} />
                      </div>
                      <p className="text-danger">{errors.claimReason?.message}</p>
                    </div>
                  </div>
                  <div className="row">
                    <h4 className=" wrap-title  mb-3">Current insurance carrier’s information: (optional)</h4>
                    <div className="col-md-6 col-lg-3">
                      <div className="mb-3">
                        <label className="brand-label">Carrier Name:</label>
                        <input id="carrierName" type="text" className="form-control" placeholder="Enter  Carrier Name" {...register("carrierName")} />
                        <p className="text-danger mt-2">{errors.carrierName && <p>{errors.carrierName.message}</p>}</p>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-3">
                      <div className="mb-3">
                        <label className="brand-label">Renewal Date:</label>
                        <input id="renewalDate" type="date" max="2099-12-31" className="form-control" placeholder="Enter  Renewal Date " {...register("renewalDate")} />
                        <p className="text-danger mt-2">{errors.renewalDate && <p>{errors.renewalDate.message}</p>}</p>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-3">
                      <div className="mb-3">
                        <label className="brand-label">Premium Amount:</label>

                        <Controller
                          name="insurancePremium"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <NumericFormat
                              allowNegative={false}
                              {...field}
                              className="form-control"
                              thousandSeparator={true}
                              prefix="$"
                              onValueChange={(values) => {
                                setValue("insurancePremium", values.floatValue);
                              }}
                              placeholder="Current insurance premium"
                              {...register("insurancePremium")}
                            />
                          )}
                        />
                        <p className="text-danger mt-2">{errors.insurancePremium && <p>{errors.insurancePremium.message}</p>}</p>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-3">
                      <div className="mb-3">
                        <label className="brand-label">Duration With Current Carrier:</label>
                        <input id="carrierDuration" type="text" className="form-control" placeholder="Carrier Duration" {...register("carrierDuration")} />
                        <p className="text-danger mt-2">{errors.carrierDuration && <p>{errors.carrierDuration.message}</p>}</p>
                      </div>
                    </div>
                  </div>
                  <div className="text-end">
                    <button className="btn btn-primary">Submit</button>
                  </div>
                </section>
              )}
              {isThanksPage && <ThankyouResponse />}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditHealthcare;
