import React from "react";
import {
  NumberFormat,
  ThousandSeparator,
  formatDate,
  formatHours,
} from "../../../Common/Utils";

const CustomerDetailsGasStation = ({ details }) => {
  console.log("details gas", details);

  return (
    <>
      <div
        className="offcanvas offcanvas-end"
        tabIndex="-1"
        id="gas-station"
        style={{ width: "75%" }}
        aria-labelledby="offcanvasRightLabel"
      >
        <div className="offcanvas-header activity-status-table brand-offcanvas-header">
          <h5 id="offcanvasRightLabel">Gas Station Information</h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <div className="activity-cust-data">
            <h6 className="mb-3">Details</h6>

            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link brand-tab active`}
                  id="build-info"
                  data-bs-toggle="tab"
                  data-bs-target="#building-info-details"
                  type="button"
                  role="tab"
                  aria-controls="building-info-details"
                  aria-selected="true"
                >
                  General Information
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link brand-tab`}
                  id="geo-location1"
                  data-bs-toggle="tab"
                  data-bs-target="#annual-info"
                  type="button"
                  role="tab"
                  aria-controls="annual-info"
                  aria-selected="false"
                >
                  Building Information
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link brand-tab`}
                  id="geo-location1"
                  data-bs-toggle="tab"
                  data-bs-target="#general-liability"
                  type="button"
                  role="tab"
                  aria-controls="general-liability"
                  aria-selected="false"
                >
                  Annual Sales
                </button>
              </li>
            </ul>
            {details && (
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade active show"
                  id="building-info-details"
                  role="tabpanel"
                  aria-labelledby="build-info"
                >
                  <div className="activity-status-table">
                    <div className="table-responsive">
                      <table className="table table-striped table table-bordered">
                        <thead></thead>
                        <tbody id="body-render">
                          <tr>
                            <td>First Name</td>
                            <td>{details?.businessCustomer?.firstName}</td>
                          </tr>
                          <tr>
                            <td>Last Name</td>
                            <td>{details?.businessCustomer?.lastName}</td>
                          </tr>
                          <tr>
                            <td>Business Name</td>
                            <td>{details?.businessCustomer?.businessName}</td>
                          </tr>
                          <tr>
                            <td>DBA</td>
                            <td>{details?.businessCustomer?.dbaname}</td>
                          </tr>
                          <tr>
                            <td>Hours of Operation</td>
                            <td>
                              {details?.businessCustomer?.hoursOfOperation &&
                                formatHours(
                                  details?.businessCustomer?.hoursOfOperation
                                )}
                            </td>
                          </tr>
                          <tr>
                            <td>Work Number</td>
                            <td>{details?.businessCustomer?.workNum}</td>
                          </tr>
                          <tr>
                            <td>Mobile Number</td>
                            <td>{details?.businessCustomer?.mobileNum}</td>
                          </tr>
                          <tr>
                            <td>Location Address</td>
                            <td>
                              {details?.businessCustomer?.locationAddress}
                            </td>
                          </tr>
                          <tr>
                            <td>Mailing Address</td>
                            <td>{details?.businessCustomer?.mailingAddress}</td>
                          </tr>
                          <tr>
                            <td>Email Address</td>
                            <td>{details?.businessCustomer?.email}</td>
                          </tr>
                          <tr>
                            <td>Effective Date</td>
                            <td>
                              {details?.gasStation?.effectiveDate &&
                                formatDate(details?.gasStation?.effectiveDate)}
                            </td>
                          </tr>

                          <tr>
                            <td>Coverage</td>
                            <td>
                              {details?.gasStation?.coverage?.map(
                                (type, index) => {
                                  return (
                                    <span
                                      key={index}
                                      className="badge rounded-pill bg-info text-light me-1"
                                    >
                                      {type}
                                    </span>
                                  );
                                }
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div
                  className={`tab-pane fade`}
                  id="annual-info"
                  role="tabpanel"
                  aria-labelledby="build-info"
                >
                  <div className="activity-status-table">
                    <div className="table-responsive">
                      <table className="table table-striped table table-bordered">
                        <thead></thead>
                        <tbody id="body-render">
                          <tr>
                            <td>Year Built</td>
                            <td>{details?.gasStation?.yearOfBuild}</td>
                          </tr>
                          <tr>
                            <td>No. of Buildings</td>
                            <td>
                              <ThousandSeparator
                                value={details?.gasStation?.noOfBuilding}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Building Value?</td>
                            <td>
                              <NumberFormat
                                value={details?.gasStation?.buildingValue}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Pump Value</td>
                            <td>
                              <NumberFormat
                                value={details?.gasStation?.pumpValue}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Canopy Value</td>
                            <td>
                              <NumberFormat
                                value={details?.gasStation?.canopyValue}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Money Order</td>
                            <td>
                              
                              {details?.gasStation?.moneyOrder === 1
                                ? "Yes"
                                : "No"}
                            </td>
                          </tr>
                          <tr>
                            <td>Sign Value</td>
                            <td>
                              <NumberFormat
                                value={details?.gasStation?.signValue}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Money & Securities Limit</td>
                            <td>
                              <NumberFormat
                                value={details?.gasStation?.securityLimit}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Prior Losses</td>
                            <td>
                              <NumberFormat
                                value={details?.gasStation?.priorLossAmt}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Content/BPP Limit?</td>
                            <td>
                              <NumberFormat
                                value={details?.gasStation?.bppLimit}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>No. of Pumps</td>
                            <td>
                              <ThousandSeparator
                                value={details?.gasStation?.numOfPump}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Loss Run (Years)</td>
                            <td>{details?.gasStation?.lossRunYear}</td>
                          </tr>
                          <tr>
                            <td>Protective Safeguard</td>
                            <td>
                              {details?.gasStation?.protectiveSafeguard?.map(
                                (type, index) => {
                                  return (
                                    <span
                                      key={index}
                                      className="badge rounded-pill bg-info text-light me-1"
                                    >
                                      {type}
                                    </span>
                                  );
                                }
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>ATM Machine</td>
                            <td>
                              {details?.gasStation?.atmMachineFlag?.map(
                                (type, index) => {
                                  return (
                                    <span
                                      key={index}
                                      className="badge rounded-pill bg-info text-light me-1"
                                    >
                                      {type}
                                    </span>
                                  );
                                }
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>Propane Sales</td>
                            <td>{details?.gasStation?.propaneSale}</td>
                          </tr>
                          <tr>
                            <td>Check Cashing</td>
                            <td>
                              {details?.gasStation?.isCheckCashing === 1
                                ? "Yes"
                                : "No"}
                            </td>
                          </tr>
                          <tr>
                            <td>Full/Partial Sprinkler</td>
                            <td>{details?.gasStation?.sprinkerType}</td>
                          </tr>
                          <tr>
                            <td>Automatic Shut of System for Pumps</td>
                            <td>{details?.gasStation?.isAutomaticShutPump}</td>
                          </tr>

                          <tr>
                            <td>Last Roof updated</td>
                            <td>
                              {details?.gasStation?.lastRoofUpdate &&
                                formatDate(details?.gasStation?.lastRoofUpdate)}
                            </td>
                          </tr>
                          <tr>
                            <td>Last Wiring updated</td>
                            <td>
                              {details?.gasStation?.lastWireUpdate &&
                                formatDate(details?.gasStation?.lastWireUpdate)}
                            </td>
                          </tr>
                          <tr>
                            <td>Last Plumbing updated</td>
                            <td>
                              {details?.gasStation?.lastPlumbingUpdate &&
                                formatDate(
                                  details?.gasStation?.lastPlumbingUpdate
                                )}
                            </td>
                          </tr>
                          <tr>
                            <td>Last Heating updated</td>
                            <td>
                              {details?.gasStation?.lastHeatUpdate &&
                                formatDate(details?.gasStation?.lastHeatUpdate)}
                            </td>
                          </tr>
                          <tr>
                            <td>Any Claims in past 5 years</td>
                            <td>
                              {details?.gasStation?.anyClaim === 1
                                ? "Yes"
                                : "No"}
                            </td>
                          </tr>
                          <tr>
                            <td>Claim Date</td>
                            <td>
                              {details?.gasStation?.claimDate &&
                                formatDate(details?.gasStation?.claimDate)}
                            </td>
                          </tr>
                          <tr>
                            <td>Claim Type</td>
                            <td>{details?.gasStation?.claimType}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div
                  className={`tab-pane fade`}
                  id="general-liability"
                  role="tabpanel"
                  aria-labelledby="build-info"
                >
                  <div className="activity-status-table">
                    <div className="table-responsive">
                      <table className="table table-striped table table-bordered">
                        <thead></thead>
                        <tbody id="body-render">
                          <tr>
                            <td>Gross Sales</td>
                            <td>
                              <NumberFormat
                                value={details?.gasStation?.grossSale}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Gasoline (Annual Gallons)</td>
                            <td>{details?.gasStation?.gasolineAnnualSale}</td>
                          </tr>
                          <tr>
                            <td>Annual Propane Sales</td>
                            <td>
                              <NumberFormat
                                value={details?.gasStation?.annualPropaneSale}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Annual Sales Of Coin Laundry</td>
                            <td>
                              <NumberFormat
                                value={
                                  details?.gasStation?.coinLaundryAnnualSale
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Sq. Ft. For Restaurant, If Leased Out</td>
                            <td>
                              <ThousandSeparator
                                value={details?.gasStation?.leasedOutSqft}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Annual Sales Of Beer & Wine</td>
                            <td>{details?.gasStation?.beerWineAnnualSale}</td>
                          </tr>
                          <tr>
                            <td>Annual Sales Of Gaming Machine</td>
                            <td>
                              <NumberFormat
                                value={
                                  details?.gasStation?.gameMachineAnnualsale
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Sq. Ft. Of The Leased Property</td>
                            <td>
                              <ThousandSeparator
                                value={details?.gasStation?.sqFtOfLeaseProperty}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Car Wash</td>
                            <td>
                              {details?.gasStation?.glLiquour === 1
                                ? "Yes"
                                : "No"}
                            </td>
                          </tr>
                          <tr>
                            <td>Car Wash Rent, If Leased</td>
                            <td>
                              <NumberFormat
                                value={details?.gasStation?.carWashRent}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Car Wash Annual Sales, If Owned</td>
                            <td>
                              <NumberFormat
                                value={details?.gasStation?.carWashAnnual}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Cooking (if any cooking exposure)</td>
                            <td>
                              {details?.gasStation?.cookingType?.map(
                                (type, index) => {
                                  return (
                                    <span
                                      key={index}
                                      className="badge rounded-pill bg-info text-light me-1"
                                    >
                                      {type}
                                    </span>
                                  );
                                }
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>Total Payroll</td>
                            <td>
                              <NumberFormat
                                value={details?.gasStation?.totalPayRoll}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>FEIN Number</td>
                            <td>{details?.gasStation?.feinNum}</td>
                          </tr>
                          <tr>
                            <td>No. of Employees</td>
                            <td>
                              <ThousandSeparator
                                value={details?.gasStation?.numOfEmp}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerDetailsGasStation;
