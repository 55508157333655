import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Controller, useForm } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import * as yup from "yup";
import { default as axios } from "../../config/axios-config";
import useExitConfirmationHandler from "../../hooks/useExitConfirmationHandler.hook";

const DEVURL = process.env.REACT_APP_ANC_API + "/api/Salon";
const DEVURL_MANAGEAGENCY = process.env.REACT_APP_ANC_API + "/api/manageagency";

const getValidationSchema = (agentId) => {
  let baseSchema = {
    feinNumber: yup
      .string()
      .trim()
      .nullable(true)
      .matches(/^\d{2}-\d{7,8}$/, "Please enter a valid fein")
      .label("FEIN"),
    annualGrossSale: yup.string().trim().required("Annual Gross sale is required").label("Annual Gross sale"),
    anyProferssionalLib: yup.string().trim().required("Liabilities coverage is required").label("Liabilities coverage"),
    converageLimit: yup
      .string()
      .nullable(true)
      .when("anyProferssionalLib", (anyProferssionalLib, schema) => {
        if (anyProferssionalLib && anyProferssionalLib?.includes("1")) {
          return schema.required("Liabilities coverage limit is required");
        } else return schema.notRequired();
      })
      .label("Liabilities coverage limit"),

    malPracticeCoverage: yup.string().trim().required("Malpractice coverage is required").label("Malpractice coverage"),

    malPracticeCoverageAmt: yup
      .string()
      .nullable(true)
      .when("malPracticeCoverage", (malPracticeCoverage, schema) => {
        if (malPracticeCoverage && malPracticeCoverage?.includes("1")) {
          return schema.required("Malpractice coverage limit is required");
        } else return schema.notRequired();
      })
      .label("Malpractice coverage limit"),
    termsCondition: yup.boolean().test("termsCondition", "Terms & Conditions is required", (val) => {
      return val;
    }),
  };
  if (agentId == null) {
    baseSchema = {
      ...baseSchema,
      agentId: yup.string().required("Selecting an agent is required.").notOneOf(["", null, undefined], "You must select a valid agent."),
    };
  }
  return yup.object().shape(baseSchema);
};

const Step3 = ({ stepHandler, loader, agentId }) => {
  const handleExitButton = useExitConfirmationHandler();
  const { agencyName, agentName } = useParams();
  const validationSchema = getValidationSchema(agentId);
  const [agentList, setAgentList] = useState();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    getValues,
    clearErrors,
    reset,
    watch,
    control,
  } = useForm({
    defaultValues: {
      annualGrossSale: null,
      anyProferssionalLib: "",
      converageLimit: null,
      malPracticeCoverage: "",
      malPracticeCoverageAmt: null,
      totalPayroll: null,
      feinNumber: null,
      noOfEmployee: null,
      termsCondition: false,
      bankDetails: "",
      propValueByBank: null,
    },
    resolver: yupResolver(validationSchema),
  });

  const anyProferssionalLibValue = watch("anyProferssionalLib");
  const malPracticeCoverageValue = watch("malPracticeCoverage");

  const fillupStep3HotelInfoFromLocalStorage = (formData) => {
    setValue("agentId", agentId == null ? formData?.agentId : agentId);
    setValue("annualGrossSale", formData.annualGrossSale);
    setValue("converageLimit", formData.converageLimit);
    setValue("anyProferssionalLib", formData.anyProferssionalLib);
    setValue("malPracticeCoverage", formData.malPracticeCoverage);
    setValue("malPracticeCoverageAmt", formData.malPracticeCoverageAmt);
    setValue("totalPayroll", formData.totalPayroll);
    setValue("feinNumber", formData.feinNumber);
    setValue("noOfEmployee", formData.noOfEmployee);
    setValue("bankDetails", formData.bankDetails);
    setValue("propValueByBank", formData.propValueByBank);
    setValue("termsCondition", formData?.termsCondition);
  };

  const [isCaptchaSuccessful, setIsCaptchaSuccess] = useState(false);
  const [disableAdd, setDisableAdd] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const query = new URLSearchParams(useLocation().search);
  const id = query.get("htappid");
  const agencyId = query.get("id");
  // const agentId = query.get("agentId");
  const salonId = query.get("primaryid");
  const navigate = useNavigate();

  const getAgentName = () => {
    if (agentList?.length) {
      const agent = agentList?.find((agent) => agent.id === getValues("agentId"));
      console.log("agent", agent, getValues("agentId"));
      return `${agent?.firstName} ${agent?.lastName}`;
    }
  };

  const clearLocalStorage = () => {
    localStorage.removeItem("step1");
    localStorage.removeItem("step2");
    localStorage.removeItem("step3");
    localStorage.removeItem("customerId");
  };

  useEffect(() => {
    const formData = JSON.parse(localStorage.getItem("step3"));
    if (formData) {
      fillupStep3HotelInfoFromLocalStorage(formData);
    }
  }, []);

  const getAgentList = async () => {
    await axios
      .get(`${DEVURL_MANAGEAGENCY}/GetAgentInfoByAgency/${agencyName}`)
      .then((response) => {
        if (response.status === 200) {
          setAgentList(response.data.agent_info_list);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (agencyName) getAgentList();
  }, [agencyName]);

  const onChangeRecaptcha = async (value) => {
    if (value === null) {
      setIsCaptchaSuccess(false);
    } else {
      setIsCaptchaSuccess(true);
    }
  };

  const createSalonInfoObj = (salonInfo) => {
    console.log(salonInfo);
    let salonToCreate = {
      customerInfo: {
        agencyId: salonInfo.agencyId,
        agentId: salonInfo.agentId,
        customerId: salonInfo.customerId,
        firstName: salonInfo.firstName,
        lastName: salonInfo.lastName,
        locationAddress: salonInfo.locationAddress,
        locationCity: salonInfo.locationCity,
        locationZip: salonInfo.locationZip,
        locationState: salonInfo.locationState,
        mailingAddress: salonInfo.mailingAddress,
        mailingCity: salonInfo.mailingCity,
        mailingZip: salonInfo.mailingZip,
        mailingState: salonInfo.mailingState,
        businessName: salonInfo.businessName,
        dbaname: salonInfo.dba,
        hoursOfOperation: salonInfo.hoursOfOperation,
        typeOfBusiness: salonInfo?.businessType,
        mobileNum: salonInfo.mobileNum,
        workNum: salonInfo.workNum,
        email: salonInfo.email,
        faxNum: "",
        termsCondition: salonInfo.termsCondition?.toString(),
      },
      salonInfo: {
        noOfLocation: salonInfo.noOfLocation,
        signLimit: salonInfo.signLimit,
        salonId: salonInfo.salonId,
        coverage: salonInfo.coverage,
        effectiveDate: salonInfo.effectiveDate,
        noOfBuildingEachLocation: salonInfo.noOfBuildingEachLocation,
        busInterruptionLimit: salonInfo.busInterruptionLimit,
        theftLimit: salonInfo.theftLimit,
        yearOfConstruction: salonInfo.yearOfConstruction,
        moneyNsecurity: salonInfo.moneyNsecurity,
        buildingValue: salonInfo.buildingValue,
        lastRoofUpdate: salonInfo.lastRoofUpdate,
        lastWiringUpdate: salonInfo.lastWiringUpdate,
        lastPlumbingUpdate: salonInfo.lastPlumbingUpdate,
        lastHeating: salonInfo.lastHeating,
        sqftspace: salonInfo.sqftspace,
        equipmentValue: salonInfo.equipmentValue,
        noOfBeauticians: salonInfo.noOfBeauticians,
        workType: salonInfo.workType,
        totalFullTimeEmployee: salonInfo.totalFullTimeEmployee,
        partTimeEmployee: salonInfo.partTimeEmployee,
        anyClaim: salonInfo.anyClaim,
        claimDate: salonInfo.claimDate,
        claimType: salonInfo.claimType,
        priorLoss: salonInfo.priorLoss,
        lossRunYear: salonInfo.lossRunYear,
        annualGrossSale: salonInfo.annualGrossSale,
        anyProferssionalLib: salonInfo.anyProferssionalLib,
        converageLimit: salonInfo.converageLimit,
        malPracticeCoverage: salonInfo.malPracticeCoverage,
        malPracticeCoverageAmt: salonInfo.malPracticeCoverageAmt,
        totalPayroll: salonInfo.totalPayroll,
        bankDetails: salonInfo.bankDetails,
        propValueByBank: salonInfo.propValueByBank,
        feinNumber: salonInfo.feinNumber,
        noOfEmployee: salonInfo.noOfEmployee,
        licensedStatus: salonInfo.licensedStatus,
        hoursOfOperation: salonInfo.hoursOfOperation,
        typeOfBusiness: salonInfo?.businessType,
        noOfBuilding: 0,
        AnyPhysician: 0,
      },
    };
    return salonToCreate;
  };
  const handleFormSubmit = async (data) => {
    localStorage.setItem("step3", JSON.stringify(data));
    const customerId = localStorage.getItem("customerId");
    const step1 = localStorage.getItem("step1");
    const step2 = localStorage.getItem("step2");
    const step3 = localStorage.getItem("step3");
    const salonInfo = {
      ...JSON.parse(step1),
      ...JSON.parse(step2),
      ...JSON.parse(step3),
    };

    const salonInfoObj = createSalonInfoObj(salonInfo);

    Swal.fire({
      title: "Are you sure you want to submit the form?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        if (!salonId) {
          setLoading(true);
          salonInfoObj.agencyId = agencyId;
          axios
            .post(`${DEVURL}/InsertsalonApp`, salonInfoObj)
            .then((res) => {
              if (res.status === 200) {
                setLoading(false);
                clearLocalStorage();
                reset();
                stepHandler(4);
              }
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
            });
        } else {
          setLoading(true);
          axios
            .put(`${DEVURL}/UpdateSalonApp/${customerId}/${salonId}`, salonInfoObj)
            .then((res) => {
              if (res.status === 200) {
                setLoading(false);
                clearLocalStorage();
                reset();
                stepHandler(4);
              }
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
            });
        }
      } else {
        setLoading(false);
        return;
      }
    });
  };

  const handleFeinChange = (e) => {
    console.log(e.keyCode);
    let inputValue = e.target.value;

    // Remove non-numeric characters using a regular expression
    inputValue = inputValue.replace(/[^0-9]/g, "");

    // Format the numeric value with a hyphen after the first two digits

    if (inputValue.length > 2) {
      inputValue = inputValue.slice(0, 2) + "-" + inputValue.slice(2);
    }
    const name = e.target.name;
    setValue(name, inputValue);
  };

  useEffect(() => {
    if (anyProferssionalLibValue?.includes("0")) {
      setValue("converageLimit", null);
    }
    if (malPracticeCoverageValue?.includes("0")) {
      setValue("malPracticeCoverageAmt", null);
    }
  }, [anyProferssionalLibValue, malPracticeCoverageValue]);

  if (isLoading) {
    return loader();
  }

  return (
    <>
      <form className="tab-wizard wizard-circle wizard needs-validation" onSubmit={handleSubmit(handleFormSubmit)}>
        <section>
          <h4 className="wrap-title mb-3">General Liability Information:</h4>
          <div className="row">
            <div class="col-md-6 col-lg-6">
              <div class="mb-3">
                <label class="brand-label d-grid">
                  <div>
                    Professional Liabilities Coverage?
                    <span className="required-mark">*</span>
                  </div>
                </label>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="inlineRadioOptions" id="anyProferssionalLibYes" value="1" {...register("anyProferssionalLib")} />
                  <label class="form-check-label" for="anyProferssionalLibYes">
                    Yes
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="inlineRadioOptions" id="anyProferssionalLibNo" value="0" {...register("anyProferssionalLib")} />
                  <label class="form-check-label" for="anyProferssionalLibNo">
                    No
                  </label>
                </div>
                <p className="text-danger">{errors.anyProferssionalLib && <p>{errors.anyProferssionalLib.message}</p>}</p>
              </div>
            </div>
            <div class="col-md-6 col-lg-6">
              <div class="mb-3">
                <label class="brand-label d-grid">
                  <div>
                    Professional Malpractice Coverage?
                    <span className="required-mark">*</span>
                  </div>
                </label>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="inlineRadioOptions" id="malPracticeCoverageYes" value="1" {...register("malPracticeCoverage")} />
                  <label class="form-check-label" for="malPracticeCoverageYes">
                    Yes
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="inlineRadioOptions" id="malPracticeCoverageNo" value="0" {...register("malPracticeCoverage")} />
                  <label class="form-check-label" for="malPracticeCoverageNo">
                    No
                  </label>
                </div>
                <p className="text-danger">{errors.malPracticeCoverage && <p>{errors.malPracticeCoverage.message}</p>}</p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="mb-2">
                <label className="brand-label d-grid">
                  <div>Liabilities Coverage Limit?</div>
                </label>
                <Controller
                  name="converageLimit"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      {...field}
                      id="converageLimit"
                      className="form-control"
                      thousandSeparator={true}
                      prefix="$"
                      value={anyProferssionalLibValue?.includes("1") ? field.value : ""}
                      disabled={!anyProferssionalLibValue?.includes("1")}
                      onValueChange={(values) => {
                        setValue("converageLimit", values.floatValue);
                      }}
                      placeholder="Enter Coverage Limit"
                      {...register("converageLimit")}
                    />
                  )}
                />
                <p className="text-danger">{errors.converageLimit?.message}</p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="mb-2">
                <label className="brand-label d-grid">
                  <div>Malpractice Coverage Limit?</div>
                </label>
                <Controller
                  name="malPracticeCoverageAmt"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      {...field}
                      id="malPracticeCoverageAmt"
                      className="form-control"
                      thousandSeparator={true}
                      prefix="$"
                      name="malPracticeCoverageAmt"
                      value={malPracticeCoverageValue?.includes("1") ? field.value : ""}
                      disabled={!malPracticeCoverageValue?.includes("1")}
                      onValueChange={(values) => {
                        setValue("malPracticeCoverageAmt", values.floatValue);
                      }}
                      placeholder="Enter Malpractice Coverage Limit"
                      {...register("malPracticeCoverageAmt")}
                    />
                  )}
                />
                <p className="text-danger">{errors.malPracticeCoverageAmt?.message}</p>
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="mb-3">
                <label class="brand-label">
                  Annual Gross Sales?<span className="required-mark">*</span>
                </label>
                <Controller
                  name="annualGrossSale"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      {...field}
                      id="annualGrossSale"
                      className="form-control"
                      thousandSeparator={true}
                      prefix="$"
                      name="annualGrossSale"
                      placeholder="Enter Annual Gross Sales"
                      onValueChange={(values) => {
                        setValue("annualGrossSale", values.floatValue);
                      }}
                      {...register("annualGrossSale")}
                    />
                  )}
                />
                <p className="text-danger">{errors.annualGrossSale && <p>{errors.annualGrossSale.message}</p>}</p>
              </div>
            </div>

            <h4 className="wrap-title mb-3">Other:</h4>
            <div class="col-md-4">
              <div class="mb-3">
                <label class="brand-label d-grid">Total Payroll</label>
                <Controller
                  name="totalPayroll"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      {...field}
                      id="totalPayroll"
                      className="form-control"
                      thousandSeparator={true}
                      prefix="$"
                      name="totalPayroll"
                      onValueChange={(values) => {
                        setValue("totalPayroll", values.value);
                      }}
                      placeholder="Enter Total Payroll"
                      {...register("totalPayroll")}
                    />
                  )}
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-3">
                <label class="brand-label d-grid">FEIN Number</label>
                <input class="form-control" type="text" name="feinNumber" onKeyUp={handleFeinChange} placeholder="Enter FEIN Number" {...register("feinNumber")} />
                <p className="text-danger mt-2">{errors.feinNumber && <p>{errors.feinNumber.message}</p>}</p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-3">
                <label class="brand-label d-grid">No. of Employees</label>
                <Controller
                  name="noOfEmployee"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      {...field}
                      id="noOfEmployee"
                      className="form-control"
                      thousandSeparator={true}
                      name="noOfEmployee"
                      onValueChange={(values) => {
                        setValue("noOfEmployee", values.floatValue);
                      }}
                      placeholder="No. of Employees"
                      {...register("noOfEmployee")}
                    />
                  )}
                />
              </div>
            </div>

            <h6 className="wrap-title mb-2">If financed from bank, then:</h6>

            <div className="col-md-6">
              <div className="mb-3">
                <label className="brand-label">Bank Details</label>
                <input className="form-control" type="text" placeholder="Enter Bank Details" {...register("bankDetails")} />
              </div>
            </div>

            <div className="col-md-6">
              <div className="mb-3">
                <label className="brand-label text-capitalize">What is the Bank Property Value?</label>
                <Controller
                  name="propValueByBank"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      {...field}
                      id="propValueByBank"
                      className="form-control"
                      thousandSeparator={true}
                      prefix="$"
                      name="propValueByBank"
                      onValueChange={(values) => {
                        setValue("propValueByBank", values.value);
                      }}
                      placeholder="Enter Bank Property Value"
                      {...register("propValueByBank")}
                    />
                  )}
                />
              </div>
            </div>
            {agentId == null ? (
              <div className="row">
                <div className="col-md-6 col-lg-3">
                  {salonId ? (
                    <label className="brand-label">
                      Selected Agent: &nbsp;
                      <span>{getAgentName()}</span>
                    </label>
                  ) : (
                    <div className="mb-3">
                      <label className="brand-label">
                        Select your contact person<span className="required-mark">*</span>
                      </label>
                      <select class="form-select" aria-label="Default select example" {...register("agentId")}>
                        <option value={""} selected>
                          Select Agent
                        </option>
                        {agentList?.length ? (
                          agentList.map((agent) => (
                            <option value={agent.id}>
                              {agent?.firstName} {agent?.lastName}
                            </option>
                          ))
                        ) : (
                          <option disabled>No agent found</option>
                        )}
                      </select>
                      <p className="text-danger mt-2">{errors.agentId && <p>{errors.agentId.message}</p>}</p>
                    </div>
                  )}
                </div>
              </div>
            ) : null}
            <div className="text-center mb-3">
              {salonId && (
                <button type="button" onClick={() => handleExitButton("Salon Insurance Application")} className="btn btn-danger ms-2 text-capitalize">
                  Exit if no changes required
                </button>
              )}
            </div>
            <div className="col-md-12 col-lg-12 mt-1">
              <div className="mb-3">
                <div className="d-flex align-items-baseline gap-10">
                  <input className="me-2" type="checkbox" id="termsCondition" {...register("termsCondition")} />

                  <label className="brand-label text-capitalize">
                    I understand loss runs are required to receive a quote and I hereby allow the agent to retrieve information using the above-mentioned business information and
                    provide me with quote(s) for my perusal. I also understand that quotes are for information and marketing purpose only.
                  </label>
                </div>

                <p className="text-danger mt-2">{errors.termsCondition && <p>{errors.termsCondition.message}</p>}</p>
                <div className="col-md-6">
                  <div className="mb-3">
                    <ReCAPTCHA sitekey="6LeS4L8mAAAAAEGzyClAMce6777ssfu5VYovTkaW" onChange={onChangeRecaptcha} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <button className="btn btn-warning" type="button" onClick={() => stepHandler(2)}>
              Previous
            </button>
            <button type="submit" className="btn btn-primary ms-2" disabled={disableAdd || !isCaptchaSuccessful}>
              Submit
            </button>
          </div>
        </section>
      </form>
    </>
  );
};

export default Step3;
