import React from "react";

const DEVURL = process.env.REACT_APP_ANC_API + "/api/health";

const Tanks = () => {
  return (
    <>
      <div className="col py-3">
        <div className="content-panel">
          <div className="panel-title">
            <h4>Tanks</h4>
          </div>

          <div className="filter-box mt-3 mb-2">
            <div className="filter-box-content">
              <div className="row align-items-center">
                <div className="col-lg-2">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      id="floatingInput"
                      placeholder="name@example.com"
                    />
                    <label htmlFor="floatingInput">First or Last Name</label>
                  </div>
                </div>
                <div className="col-lg-2">
                  <div className="form-floating">
                    <input
                      type="date"
                      max="2099-12-31"
                      className="form-control"
                      id="floatingInput"
                      placeholder="name@example.com"
                    />
                    <label htmlFor="floatingInput">Application Sent Date</label>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div
                    className="d-grid gap-1 d-md-flex justify-content-md-end"
                    style={{ height: "58px" }}
                  >
                    <button className="btn btn-primary" type="button">
                      Search
                    </button>
                    <button
                      className="btn btn-outline-secondary me-md-2"
                      type="button"
                    >
                      Reset
                    </button>
                    <button
                      className="btn btn-outline-secondary me-md-2"
                      type="button"
                    >
                      Refresh
                    </button>
                    <button className="btn btn-primary " type="button">
                      Export CSV
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Tanks;
