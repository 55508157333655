import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { default as axios } from "../../../../config/axios-config";
import PlaceComponent from "../../../healthcare/GooglePlace";
import { formattedDate, handleNotifications } from "../../../helper/Helper";
import { useSelector } from "react-redux";

const DEVURL = process.env.REACT_APP_ANC_API + "/api";

const validationSchema = yup.object().shape({
  firstName: yup
    .string()
    .trim()
    .matches(/^[A-Za-z ]*$/, "Please enter a valid First Name")
    .max(45)
    .required()
    .label("First Name"),
  lastName: yup
    .string()
    .trim()
    .matches(/^[A-Za-z ]*$/, "Please enter a valid Last Name")
    .max(45)
    .required()
    .label("Last Name"),
  gender: yup.string().oneOf(["Male", "Female"], "Gender is required field").required("Gender"),
  //apt: yup.string().trim().required("Street Address is a required field"),
  city: yup
    .string()
    .trim()
    .matches(/^[A-Za-z ]*$/, "Please enter a valid City")
    .max(45)
    .required()
    .label("City"),
  state: yup
    .string()
    .trim()
    .matches(/^[A-Za-z ]*$/, "Please enter a valid State")
    .max(45)
    .required()
    .label("State"),
  zipCode: yup.string().trim().required().label("Zip Code"),
  phone: yup
    .string()
    .trim()
    .required()
    .matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im, "Please enter a valid Home Phone Number")
    .label("Home Phone Number"),
  cell: yup
    .string()
    .trim()
    .required()
    .matches(/^(\([0-9]{3}\)|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/, "Please enter a valid Cell Phone Number")
    .label("Cell Phone Number"),
  email: yup
    .string()
    .trim()
    .required()
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Enter a valid email address"
    )
    .label("Email Address"),
  fax: yup.string().matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im, {
    message: "Please enter a valid Fax Number",
    excludeEmptyString: true,
  }),
  dob: yup
    .date()
    .typeError("Date of Birth is a required field")
    .max(new Date(Date.now() - 86400000), "Date of Birth can not be future or current date")
    .required()
    .label("Date of Birth"),
  tobacco: yup.string().oneOf(["yes", "no"], "Tobacco is a required field").required().label("Tobacco"),
  status: yup.string().required().label("Immigrant Status"),
  employment: yup.string().required().label("Employment Status"),
  income: yup
    .string()
    .trim()
    .required()
    .matches(/[+-]?([0-9]*[.])?[0-9]+/, "Please enter a vaild income")
    .label("Yearly Income"),
});

const EditPrimaryHealthcare = ({ selectedHealthcare }) => {
  const userData = useSelector((state) => state.auth.user);
  const [agentList, setAgentList] = useState();
  const [addesss, setAddress] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      gender: "",
      apt: "",
      city: "",
      state: "",
      zipCode: "",
      phone: "",
      cell: "",
      email: "",
      fax: "",
      dob: "",
      tobacco: "",
      status: "",
      employment: "",
      income: "",
      agencyId: "",
      primaryId: "",
    },
    resolver: yupResolver(validationSchema),
  });
  console.log("selectedHealthcare", selectedHealthcare);
  const fillupPrimaryHealthcare = () => {
    setValue("agentId", selectedHealthcare?.agentId);
    setValue("firstName", selectedHealthcare?.firstName);
    setValue("lastName", selectedHealthcare?.lastName);
    setValue("gender", selectedHealthcare?.gender);
    setValue("apt", selectedHealthcare?.apt);
    setAddress(selectedHealthcare?.address);
    setValue("city", selectedHealthcare?.city);
    setValue("state", selectedHealthcare?.state);
    setValue("zipCode", selectedHealthcare?.zipCode);
    setValue("phone", selectedHealthcare?.phoneNumber);
    setValue("cell", selectedHealthcare?.contactNumber);
    setValue("email", selectedHealthcare?.emailId);
    setValue("fax", selectedHealthcare?.fax);
    setValue("dob", formattedDate(selectedHealthcare?.dateOfBirth));
    setValue("tobacco", selectedHealthcare?.tobaccoFlag);
    setValue("status", selectedHealthcare?.immigratonStatus);
    setValue("employment", selectedHealthcare?.employmentStatus);
    setValue("income", selectedHealthcare?.annualInome);
    setValue("agencyId", selectedHealthcare?.agencyId);
    setValue("primaryId", selectedHealthcare?.primaryid);
  };

  const getAgentList = async () => {
    await axios
      .get(`${DEVURL}/manageagency/GetAgentInfoByAgency/${userData.agencyName}`)
      .then((response) => {
        if (response.status === 200) {
          setAgentList(response.data.agent_info_list);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getAgentList();
    fillupPrimaryHealthcare();
  }, [selectedHealthcare]);

  const getLocation = async (data) => {
    let address_components = data.address_components;
    let formatted_address = data.formatted_address;
    formatted_address = formatted_address.split(",");
    setValue("apt", data.name ?? formatted_address["0"]);
    let zip_code = "";
    address_components.map((data) => {
      if (data.types.includes("postal_code")) {
        zip_code = data.long_name + zip_code;
      }
      // if (data.types.includes("postal_code_suffix")) {
      //     zip_code =  zip_code +'-'+ data.long_name;
      // }
      if (data.types.includes("locality")) {
        setValue("city", data.long_name);
      }
      if (data.types.includes("administrative_area_level_1")) {
        setValue("state", data.long_name);
      }
    });
    setValue("zipCode", zip_code);
  };

  const savePrimary = async (data) => {
    await axios
      .put(`${DEVURL}/health/updatePrimaryHealthcare/${selectedHealthcare?.primaryid}`, data)
      .then((res) => {
        if (res.status === 204) {
          //alert("Primary infomation has been updated successfully");
          handleNotifications("Primary infomation has been updated successfully");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleFormSubmit = async (data) => {
    const answer = window.confirm("Are you sure you want to update primary infomation?");
    if (answer) {
      await savePrimary(data);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(handleFormSubmit)} className="tab-wizard wizard-circle wizard needs-validation">
        <section>
          <div className="row">
            {/* {userData.agentId == null && ( */}
            <div className="col-md-6">
              <div className="mb-3">
                <label className="brand-label">
                  Assigned Agent<span className="required-mark">*</span>
                </label>
                <select class="form-select" aria-label="Default select example" {...register("agentId")}>
                  {agentList?.length ? (
                    agentList.map((agent) => (
                      <option value={agent.id}>
                        {agent?.firstName} {agent?.lastName}
                      </option>
                    ))
                  ) : (
                    <option disabled>No agent found</option>
                  )}
                </select>
              </div>
            </div>
            {/* )} */}
            {/* <hr /> */}
            <h4 className="wrap-title mb-3">Personal Information</h4>
            <div className="col-md-6">
              <div className="mb-3">
                <label className="brand-label">
                  First Name<span className="required-mark">*</span>
                </label>
                <input id="firstName" type="text" className="form-control" placeholder="Enter First Name" {...register("firstName")} />
                <p className="text-danger mt-2">{errors.firstName && <p>{errors.firstName.message}</p>}</p>
              </div>
            </div>

            <div className="col-md-6">
              <div className="mb-3">
                <label className="brand-label">
                  Last Name<span className="required-mark">*</span>
                </label>
                <input id="lastName" type="text" className="form-control" placeholder="Enter Last Name" {...register("lastName")} />
                <p className="text-danger mt-2">{errors.lastName && <p>{errors.lastName.message}</p>}</p>
              </div>
            </div>

            <div className="col-md-6">
              <div className="mb-3">
                <label className="brand-label">
                  Gender<span className="required-mark">*</span>
                </label>

                <select className="form-select" id="applicant-gender" {...register("gender")}>
                  <option value="">Select...</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
                <p className="text-danger mt-2">{errors.gender && <p>{errors.gender.message}</p>}</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-3">
              <div className="mb-3">
                <label className="brand-label">
                  Street Address<span className="required-mark">*</span>
                </label>
                <PlaceComponent getLocationAddress={getLocation} value={addesss}>
                  {" "}
                </PlaceComponent>
                <p className="text-danger mt-2">{errors.apt && <p>{errors.apt.message}</p>}</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-3">
              <div className="mb-3">
                <label className="brand-label">
                  City<span className="required-mark">*</span>
                </label>
                <input id="city" type="text" className="form-control" placeholder="Enter City Name" {...register("city")} />
                <p className="text-danger mt-2">{errors.city && <p>{errors.city.message}</p>}</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-3">
              <div className="mb-3">
                <label className="brand-label">
                  State<span className="required-mark">*</span>
                </label>
                <input id="state" type="text" className="form-control" placeholder="Enter State Name" {...register("state")} />
                <p className="text-danger mt-2">{errors.state && <p>{errors.state.message}</p>}</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-3">
              <div className="mb-3">
                <label className="brand-label">
                  Zipcode<span className="required-mark">*</span>
                </label>
                <input id="zipcode" type="text" className="form-control" placeholder="Enter Zipcode" {...register("zipCode")} />
                <p className="text-danger mt-2">{errors.zipCode && <p>{errors.zipCode.message}</p>}</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-3">
              <div className="mb-3">
                <label className="brand-label">
                  Home Phone Number<span className="required-mark">*</span>
                </label>
                <input id="homePhone" type="text" className="form-control" placeholder="Enter Home Phone Number" {...register("phone")} />
                <p className="text-danger mt-2">{errors.phone && <p>{errors.phone.message}</p>}</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-3">
              <div className="mb-3">
                <label className="brand-label">
                  Cell Phone Number<span className="required-mark">*</span>
                </label>
                <input id="cellPhone" type="text" className="form-control" placeholder="Enter Cell Phone Number" {...register("cell")} />
                <p className="text-danger mt-2">{errors.cell && <p>{errors.cell.message}</p>}</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-3">
              <div className="mb-3">
                <label className="brand-label">
                  Email Address<span className="required-mark">*</span>
                </label>
                <input id="email" type="email" className="form-control" placeholder="Enter Email Address" {...register("email")} />
                <p className="text-danger mt-2">{errors.email && <p>{errors.email.message}</p>}</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-3">
              <div className="mb-3">
                <label className="brand-label">Fax Number</label>
                <input type="text" className="form-control" placeholder="Fax Number" {...register("fax")} />
                <p className="text-danger mt-2">{errors.fax && <p>{errors.fax.message}</p>}</p>
              </div>
            </div>

            <div className="col-md-6">
              <div className="mb-3">
                <label className="brand-label">
                  Date of Birth<span className="required-mark">*</span>
                </label>
                <input id="birthday" type="date" max="2099-12-31" className="form-control" placeholder="Enter Enrollment Date" {...register("dob")} />
                <p className="text-danger mt-2">{errors.dob && <p>{errors.dob.message}</p>}</p>
              </div>
            </div>

            <div className="col-md-6">
              <div className="mb-3">
                <label className="brand-label">
                  Do you use tobacco?<span className="required-mark">*</span>
                </label>
                <select {...register("tobacco")} className="form-select" id="tobaccoUse">
                  <option value="">Select...</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
                <p className="text-danger mt-2">{errors.tobacco && <p>{errors.tobacco.message}</p>}</p>
              </div>
            </div>

            <div className="col-md-6">
              <div className="mb-3">
                <label className="brand-label">
                  Immigrant Status<span className="required-mark">*</span>
                </label>
                <select {...register("status")} className="form-select" id="usCitizenSelect">
                  <option value="">Select...</option>
                  <option value="Citizen">Citizen</option>
                  <option value="Immigrant">Immigrant</option>
                  <option value="Student">Student</option>
                  <option value="Visitor">Visitor</option>
                  <option value="Asylum">Asylum</option>
                  <option value="Work Permit">Work Permit</option>
                  <option value="Other">Other</option>
                </select>
                <p className="text-danger mt-2">{errors.status && <p>{errors.status.message}</p>}</p>
              </div>
            </div>

            <h4 className="wrap-title mb-3">Income Details</h4>

            <div className="col-md-6">
              <div className="mb-3">
                <label className="brand-label">
                  Your Employment Status
                  <span className="required-mark">*</span>
                </label>
                <select {...register("employment")} className="form-select employmentStatusSelect">
                  <option value="">Select...</option>
                  <option value="job">Job</option>
                  <option value="self-employed">Self-employed</option>
                  <option value="student">Student</option>
                  <option value="unemployed">Unemployed</option>
                  <option value="disability-income">Disability Income</option>
                  <option value="ssn-benefit">SSN Benefit</option>
                  <option value="child-alimony">Child Alimony</option>
                  <option value="pension">Pension</option>
                  <option value="property-rent">Property Rent</option>
                  <option value="others">Others</option>
                </select>
                <p className="text-danger mt-2">{errors.employment && <p>{errors.employment.message}</p>}</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3" id="income">
                <label className="brand-label">
                  Please specify Yearly Income
                  <span className="required-mark">*</span>
                </label>
                <input className="form-control" type="text" placeholder="Income Amount" {...register("income")} />
                <p className="text-danger mt-2">{errors.income && <p>{errors.income.message}</p>}</p>
              </div>
            </div>
          </div>
        </section>
        <div className="d-flex justify-content-between">
          <button className="btn btn-primary">Update Primary Info</button>
        </div>
      </form>
    </>
  );
};

export default EditPrimaryHealthcare;
