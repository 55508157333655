import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import Badge from "react-bootstrap/Badge";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import BusinessHour from "../../../Common/BusinessHour";
import { formattedDate } from "../../../Common/Utils";
import { nameRegex } from "../../../Common/regex";
import axiosInstance from "../../../config/axios-config";
import PlaceComponent from "../../healthcare/GooglePlace";

const DEVURL =
  process.env.REACT_APP_ANC_API + "/api/GasStation/GetGasStationInfoByGasId";

const validationSchema = yup.object().shape({
  firstName: yup
    .string()
    .trim()
    .matches(/^[A-Za-z ]*$/, "Please enter a valid first name")
    .max(45, "First name can not exceed 45 character")
    .required("First name is required")
    .label("First name"),
  lastName: yup
    .string()
    .trim()
    .matches(/^[A-Za-z ]*$/, "Please enter a valid last name")
    .max(45, "Last name can not exceed 45 character")
    .required("Last name is required")
    .label("Last name"),
  businessName: yup
    .string()
    .trim()
    .required("Business name is required")
    .label("Business name"),
  dbaname: yup
    .string()
    .trim()
    .nullable(true)
    .matches(nameRegex, "Please enter a valid dba")
    .label("DBA"),
  hoursOfOperation: yup
    .string()
    .trim()
    .required("Hours of operation is required")
    .label("Hours of operation"),
  locationAddress: yup
    .string()
    .trim()
    .required("Location address is required")
    .label("Location address"),
  mailingAddress: yup
    .string()
    .trim()
    .required("Mailing address is required")
    .label("Mailing address"),
  mobileNum: yup
    .string()
    .trim()
    .required("Mobile number is required")
    .matches(
      /^(\([0-9]{3}\)|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/,
      "Please enter valid mobile number"
    )
    .label("Mobile number"),
  workNum: yup
    .string()
    .trim()
    .nullable(null)
    .matches(
      /^(\([0-9]{3}\)|[0-9]{3})[\s\-]?[0-9]{3}[\s\-]?[0-9]{4}$|^$/,
      "Please enter valid work number"
    )
    .label("Work phone number"),

  email: yup
    .string()
    .trim()
    .required("Email is required")
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Enter a valid email address"
    )
    .label("Email address"),

  coverage: yup
    .array()
    .typeError("Coverage is required")
    .min(1, "Coverage is required")
    .of(yup.string())
    .required("Coverage is required")
    .label("Coverage"),

  effectiveDate: yup
    .date()
    .typeError("Effective date is required")
    .required("Effective date is required")
    .test("is-future-date", "Date must be today or in the future", (value) => {
      if (!value) {
        return true;
      }
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      const inputDate = new Date(value);
      inputDate.setHours(0, 0, 0, 0);

      return inputDate >= currentDate;
    }),
});

const Step1 = ({ stepHandler, loader, details }) => {
  console.log("details in step1", details);
  const agencyId = details?.businessCustomer?.agencyId;
  const queryParams = new URLSearchParams(window.location.search);
  const agentId = queryParams.get("agentid");

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    setError,
    getValues,
    reset,
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      businessName: "",
      dbaname: "",
      hoursOfOperation: "",
      locationAddress: "",
      locationCity: "",
      locationState: "",
      locationZip: "",
      mailingAddress: "",
      mailingCity: "",
      mailingState: "",
      mailingZip: "",
      workNum: "",
      mobileNum: "",
      email: "",
      coverage: [],
      effectiveDate: null,
      typeOfBusiness: [],
      agencyId: agencyId,
      agentId: agentId,
      customerId: "",
      gasstationId: "",
    },
    resolver: yupResolver(validationSchema),
  });

  const [isLoading, setLoading] = useState(false);
  const [addesss, setAddress] = useState("");
  const [sameAsAddress, setSameAsAddress] = useState(false);
  const [garageAddress, setGarageAddress] = useState("");
  const mobileNum = watch("mobileNum");
  const workNum = watch("workNum");
  const coverage = watch("coverage");
  const effectiveDate = watch("effectiveDate");
  const locationCity = watch("locationCity");
  const locationState = watch("locationState");
  const locationZip = watch("locationZip");
  const [hoursOfOperation, setHoursOfOperation] = useState(["", ""]);

  const getLocation = async (data) => {
    let address_components = data.address_components;
    let formatted_address = data.formatted_address;
    setValue("locationAddress", formatted_address);
    setAddress(formatted_address);
    formatted_address = formatted_address.split(",");
    let zip_code = "";
    address_components.map((data) => {
      if (data.types.includes("postal_code")) {
        zip_code = data.long_name + zip_code;
      }
      // if (data.types.includes("postal_code_suffix")) {
      //     zip_code =  zip_code +'-'+ data.long_name;
      // }
      if (data.types.includes("locality")) {
        setValue("locationCity", data.long_name);
      }
      if (data.types.includes("administrative_area_level_1")) {
        setValue("locationState", data.long_name);
      }
    });
    setValue("locationZip", zip_code);
  };

  const getGarageAddress = async (data) => {
    let address_components = data?.address_components;
    let formatted_address = data?.formatted_address;
    setValue("mailingAddress", formatted_address);
    formatted_address = formatted_address.split(",");
    let zip_code = "";
    address_components.map((data) => {
      if (data.types.includes("postal_code")) {
        zip_code = data.long_name + zip_code;
      }
      // if (data.types.includes("postal_code_suffix")) {
      //     zip_code =  zip_code +'-'+ data.long_name;
      // }
      if (data.types.includes("locality")) {
        setValue("mailingCity", data.long_name);
      }
      if (data.types.includes("administrative_area_level_1")) {
        setValue("mailingState", data.long_name);
      }
    });
    setValue("mailingZip", zip_code);
    return false;
  };

  const getStep1HotelInfo = (hotelInfo) => {
    const hotelInfoOb = {};
    hotelInfoOb.customerId = hotelInfo?.businessCustomer?.customerId;
    hotelInfoOb.firstName = hotelInfo.businessCustomer.firstName;
    hotelInfoOb.lastName = hotelInfo.businessCustomer.lastName;
    hotelInfoOb.businessName = hotelInfo.businessCustomer.businessName;
    hotelInfoOb.dbaname = hotelInfo.businessCustomer.dbaname;
    hotelInfoOb.hoursOfOperation = hotelInfo.businessCustomer.hoursOfOperation;
    hotelInfoOb.locationAddress = hotelInfo.businessCustomer.locationAddress;
    hotelInfoOb.locationCity = hotelInfo.businessCustomer.locationCity;
    hotelInfoOb.locationState = hotelInfo.businessCustomer.locationState;
    hotelInfoOb.locationZip = hotelInfo.businessCustomer.locationZip;
    hotelInfoOb.mailingAddress = hotelInfo.businessCustomer.mailingAddress;
    hotelInfoOb.mailingCity = hotelInfo.businessCustomer.mailingCity;
    hotelInfoOb.mailingState = hotelInfo.businessCustomer.mailingState;
    hotelInfoOb.mailingZip = hotelInfo.businessCustomer.mailingZip;
    hotelInfoOb.workNum = hotelInfo.businessCustomer.workNum;
    hotelInfoOb.mobileNum = hotelInfo.businessCustomer.mobileNum;
    hotelInfoOb.email = hotelInfo.businessCustomer.email;
    hotelInfoOb.coverage = hotelInfo?.gasStation?.coverage;
    hotelInfoOb.effectiveDate = hotelInfo?.gasStation?.effectiveDate;
    return hotelInfoOb;
  };

  const getStep2HotelInfo = (hotelInfo) => {
    const hotelInfoOb = {};
    hotelInfoOb.yearOfBuild = hotelInfo?.gasStation?.yearOfBuild;
    hotelInfoOb.noOfBuilding = hotelInfo?.gasStation?.noOfBuilding;
    hotelInfoOb.buildingValue = hotelInfo?.gasStation?.buildingValue;
    hotelInfoOb.pumpValue = hotelInfo?.gasStation?.pumpValue;
    hotelInfoOb.isAutomaticShutPump =
      hotelInfo?.gasStation?.isAutomaticShutPump;
    hotelInfoOb.canopyValue = hotelInfo?.gasStation?.canopyValue;
    hotelInfoOb.protectiveSafeguard =
      hotelInfo?.gasStation?.protectiveSafeguard;
    hotelInfoOb.propaneSale = hotelInfo?.gasStation?.propaneSale;
    hotelInfoOb.moneyOrder = hotelInfo?.gasStation?.moneyOrder;
    hotelInfoOb.signValue = hotelInfo?.gasStation?.signValue;
    hotelInfoOb.securityLimit = hotelInfo?.gasStation?.securityLimit;
    hotelInfoOb.priorLossAmt = hotelInfo?.gasStation?.priorLossAmt;
    hotelInfoOb.lastRoofUpdate =
      hotelInfo?.gasStation?.lastRoofUpdate === null
        ? null
        : formattedDate(hotelInfo?.gasStation?.lastRoofUpdate);
    hotelInfoOb.lastWireUpdate =
      hotelInfo?.gasStation?.lastWireUpdate === null
        ? null
        : formattedDate(hotelInfo?.gasStation?.lastWireUpdate);
    hotelInfoOb.lastPlumbingUpdate =
      hotelInfo?.gasStation?.lastPlumbingUpdate === null
        ? null
        : formattedDate(hotelInfo?.gasStation?.lastPlumbingUpdate);
    hotelInfoOb.lastHeatUpdate =
      hotelInfo?.gasStation?.lastHeatUpdate === null
        ? null
        : formattedDate(hotelInfo?.gasStation?.lastHeatUpdate);
    hotelInfoOb.atmMachineFlag = hotelInfo?.gasStation?.atmMachineFlag;
    hotelInfoOb.bppLimit = hotelInfo?.gasStation?.bppLimit;
    hotelInfoOb.numOfPump = hotelInfo?.gasStation?.numOfPump;
    hotelInfoOb.isCheckCashing =
      hotelInfo?.gasStation?.isCheckCashing?.toString();
    hotelInfoOb.sprinkerType = hotelInfo?.gasStation?.sprinkerType;
    hotelInfoOb.lossRunYear = hotelInfo?.gasStation?.lossRunYear;
    hotelInfoOb.anyClaim = hotelInfo?.gasStation?.anyClaim?.toString();
    hotelInfoOb.claimDate = formattedDate(hotelInfo?.gasStation?.claimDate);
    hotelInfoOb.claimType = hotelInfo?.gasStation?.claimType;
    return hotelInfoOb;
  };

  const getStep3HotelInfo = (hotelInfo) => {
    const hotelInfoOb = {};
    hotelInfoOb.grossSale = hotelInfo?.gasStation?.grossSale;
    hotelInfoOb.gasolineAnnualSale = hotelInfo?.gasStation?.gasolineAnnualSale;
    hotelInfoOb.cookingType = hotelInfo?.gasStation?.cookingType;
    hotelInfoOb.leasedOutSqft = hotelInfo?.gasStation?.leasedOutSqft;
    hotelInfoOb.annualPropaneSale = hotelInfo?.gasStation?.annualPropaneSale;
    hotelInfoOb.carWashRent = hotelInfo?.gasStation?.carWashRent;
    hotelInfoOb.carWashAnnual = hotelInfo?.gasStation?.carWashAnnual;
    hotelInfoOb.beerWineAnnualSale = hotelInfo?.gasStation?.beerWineAnnualSale;
    hotelInfoOb.gameMachineAnnualsale =
      hotelInfo?.gasStation?.gameMachineAnnualsale;
    hotelInfoOb.coinLaundryAnnualSale =
      hotelInfo.gasStation?.coinLaundryAnnualSale;
    hotelInfoOb.sqFtOfLeaseProperty =
      hotelInfo?.gasStation?.sqFtOfLeaseProperty;
    hotelInfoOb.totalPayRoll = hotelInfo?.gasStation?.totalPayRoll;
    hotelInfoOb.feinNum = hotelInfo?.gasStation?.feinNum;
    hotelInfoOb.numOfEmp = hotelInfo?.gasStation?.numOfEmp;
    hotelInfoOb.wash = hotelInfo?.gasStation?.wash;
    hotelInfoOb.isCarWash = hotelInfo?.gasStation?.isCarWash?.toString();
    return hotelInfoOb;
  };

  const fillupStep1GasSattionInfo = (formData) => {
    setValue("agencyId", agencyId);
    setValue("agentId", agentId);
    setValue("customerId", formData?.businessCustomer?.customerId);
    setValue("firstName", formData.businessCustomer.firstName);
    setValue("lastName", formData.businessCustomer.lastName);
    setValue("businessName", formData.businessCustomer.businessName);
    setValue("dbaname", formData.businessCustomer.dbaname);
    setValue("hoursOfOperation", formData.businessCustomer.hoursOfOperation);
    let hrOfOperation = formData.businessCustomer.hoursOfOperation;
    hrOfOperation = hrOfOperation.split("-");
    setHoursOfOperation(hrOfOperation);
    setValue("locationAddress", formData.businessCustomer.locationAddress);
    setValue("locationCity", formData.businessCustomer.locationCity);
    setValue("locationState", formData.businessCustomer.locationState);
    setValue("locationZip", formData.businessCustomer.locationZip);
    setValue("mailingAddress", formData.businessCustomer.mailingAddress);
    setValue("mailingCity", formData.businessCustomer.mailingCity);
    setValue("mailingState", formData.businessCustomer.mailingState);
    setValue("mailingZip", formData.businessCustomer.mailingZip);
    setValue("workNum", formData.businessCustomer.workNum);
    setValue("mobileNum", formData.businessCustomer.mobileNum);
    setValue("email", formData.businessCustomer.email);
    setValue("coverage", formData.gasStation?.coverage);
    setValue("effectiveDate", formattedDate(formData.gasStation.effectiveDate));
    setAddress(formData.businessCustomer.locationAddress);
    setGarageAddress(formData.businessCustomer.mailingAddress);
    if (
      formData.businessCustomer.mailingAddress ===
      formData.businessCustomer.locationAddress
    ) {
      setSameAsAddress(true);
    }
  };

  const fillupStep1GasStationInfoFromLocalStorage = (formData) => {
    setValue("firstName", formData.firstName);
    setValue("lastName", formData.lastName);
    setValue("businessName", formData.businessName);
    setValue("dbaname", formData.dbaname);
    setValue("hoursOfOperation", formData.hoursOfOperation);
    let hrOfOperation = formData.hoursOfOperation;
    hrOfOperation = hrOfOperation.split("-");
    setHoursOfOperation(hrOfOperation);
    setValue("locationAddress", formData.locationAddress);
    setValue("locationCity", formData.locationCity);
    setValue("locationState", formData.locationState);
    setValue("locationZip", formData.locationZip);
    setValue("mailingAddress", formData.mailingAddress);
    setValue("mailingCity", formData.mailingCity);
    setValue("mailingState", formData.mailingState);
    setValue("mailingZip", formData.mailingZip);
    setValue("workNum", formData.workNum);
    setValue("mobileNum", formData.mobileNum);
    setValue("email", formData.email);
    setValue("coverage", formData?.coverage);
    setValue("effectiveDate", formattedDate(formData.effectiveDate));
    setAddress(formData.locationAddress);
    setGarageAddress(formData.mailingAddress);
    if (formData.mailingAddress === formData.locationAddress) {
      setSameAsAddress(true);
    }
  };

  const setHotelInfoInLocalStorage = (data) => {
    localStorage.setItem("step1", JSON.stringify(getStep1HotelInfo(data)));
    localStorage.setItem("step2", JSON.stringify(getStep2HotelInfo(data)));
    localStorage.setItem("step3", JSON.stringify(getStep3HotelInfo(data)));
    localStorage.setItem("customerId", data?.businessCustomer?.customerId);
  };

  const loadInitialGasStationInfo = () => {
    const formData = JSON.parse(localStorage.getItem("step1"));
    if (true) {
      setLoading(true);
      axiosInstance
        .get(`${DEVURL}/${details?.gasStation?.gasstationId}`)
        .then((response) => {
          setLoading(false);
          fillupStep1GasSattionInfo(response.data);
          setHotelInfoInLocalStorage(response.data);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } else if (formData) {
      fillupStep1GasStationInfoFromLocalStorage(formData);
    }
  };

  useEffect(() => {
    loadInitialGasStationInfo();
  }, [details]);

  const handleFormSubmit = (data) => {
    stepHandler(2);
    localStorage.setItem("coverage", coverage);
    localStorage.setItem("effectiveDate", effectiveDate);
    localStorage.setItem("step1", JSON.stringify(data));
  };

  const formatTelNumber = (input) => {
    const cleaned = ("" + input).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    } else {
      return cleaned;
    }
  };

  const getTimeRange = async (range) => {
    setValue("hoursOfOperation", range);
    // if (!range) {
    //   setError("hoursOfOperation", {
    //     type: "manual",
    //     message: "Please enter a valid time",
    //   });
    // } else if (range) {
    //   setError("hoursOfOperation", {
    //     type: "manual",
    //     message: "",
    //   });
    // }
  };
  if (isLoading) {
    return loader();
  }
  return (
    <>
      <form
        onSubmit={handleSubmit(handleFormSubmit)}
        className="tab-wizard wizard-circle wizard needs-validation"
      >
        <section>
          <h4 class="wrap-title mb-3">General Information</h4>
          <div class="row">
            <div className="col-md-6 col-lg-4">
              <div className="mb-3">
                <label className="brand-label">
                  First Name<span className="required-mark">*</span>
                </label>
                <input
                  id="firstName"
                  type="text"
                  className="form-control"
                  placeholder="Enter First Name"
                  {...register("firstName")}
                />
                <p className="text-danger mt-2">
                  {errors.firstName && <p>{errors.firstName.message}</p>}
                </p>
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="mb-3">
                <label className="brand-label">
                  Last Name<span className="required-mark">*</span>
                </label>
                <input
                  id="lastName"
                  type="text"
                  className="form-control"
                  placeholder="Enter Last Name"
                  {...register("lastName")}
                />
                <p className="text-danger mt-2">
                  {errors.lastName && <p>{errors.lastName.message}</p>}
                </p>
              </div>
            </div>

            <div class="col-md-6 col-lg-4">
              <div class="mb-3">
                <label class="brand-label">
                  Business Name<span className="required-mark">*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter Business Name"
                  {...register("businessName")}
                />
                <p className="text-danger">{errors.businessName?.message}</p>
              </div>
            </div>

            <div class="col-md-6 col-lg-4">
              <div class="mb-3">
                <label class="brand-label">DBA</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter DBA"
                  {...register("dbaname")}
                />
              </div>
              <p className="text-danger">{errors.dbaname?.message}</p>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="mb-3">
                <label className="brand-label">
                  <div className="d-flex">
                    Hours of Operation<span className="required-mark">*</span>{" "}
                    &nbsp;
                    <div>
                      <Badge bg="info">(Start and End Time)</Badge>
                    </div>
                  </div>
                </label>
                {/*// for edit pass the start= 09:30 and end = 18:30 */}
                <BusinessHour
                  onTimeRangeChange={getTimeRange}
                  timeRange={hoursOfOperation}
                />

                <p className="text-danger">
                  {errors.hoursOfOperation && (
                    <p>{errors.hoursOfOperation.message}</p>
                  )}
                </p>
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="mb-3">
                <label className="brand-label">
                  Location Address<span className="required-mark">*</span>
                </label>
                <PlaceComponent
                  getLocationAddress={getLocation}
                  value={addesss}
                  type="profile-update"
                  name="locationAddress"
                  control={control}
                ></PlaceComponent>
                <p className="text-danger">
                  {errors.locationAddress && (
                    <p>{errors.locationAddress?.message}</p>
                  )}
                </p>
              </div>
            </div>

            <div className="col-md-6 col-lg-5">
              <div className="mb-3">
                <label className="brand-label" style={{ display: "flex" }}>
                  Mailing Address<span className="required-mark">*</span>&nbsp;
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={sameAsAddress}
                      onChange={(e) => {
                        setSameAsAddress(e.target.checked);
                        setValue("mailingAddress", addesss);
                        setValue("mailingCity", locationCity);
                        setValue("mailingState", locationState);
                        setValue("mailingZip", locationZip);
                        setGarageAddress(addesss);
                      }}
                      id="same-as"
                    />
                    <label
                      className="form-check-label text-capitalize"
                      htmlFor="flexCheckDefault"
                    >
                      Same As Location Address
                    </label>
                  </div>
                </label>

                <PlaceComponent
                  getLocationAddress={getGarageAddress}
                  value={garageAddress}
                  type="profile-update"
                  readOnly={sameAsAddress}
                  name="mailingAddress"
                  control={control}
                >
                  {" "}
                </PlaceComponent>
                <p className="text-danger">
                  {errors.mailingAddress && (
                    <p>{errors.mailingAddress.message}</p>
                  )}
                </p>
              </div>
            </div>

            <div class="col-md-6 col-lg-4">
              <div class="mb-3">
                <label class="brand-label">Work Number</label>
                <Controller
                  name="workNum"
                  control={control}
                  render={({ field }) => (
                    <input
                      {...field}
                      type="text"
                      class="form-control"
                      placeholder="(XXX) XXX-XXXX"
                      value={formatTelNumber(workNum)}
                      {...register("workNum")}
                    />
                  )}
                />

                <p className="text-danger">{errors.workNum?.message}</p>
              </div>
            </div>

            <div class="col-md-6 col-lg-3">
              <div class="mb-3">
                <label class="brand-label">
                  Mobile Number<span className="required-mark">*</span>
                </label>
                <Controller
                  name="mobileNum"
                  control={control}
                  render={({ field }) => (
                    <input
                      {...field}
                      type="text"
                      class="form-control"
                      placeholder="(XXX) XXX-XXXX"
                      value={formatTelNumber(mobileNum)}
                      {...register("mobileNum")}
                    />
                  )}
                />

                <p className="text-danger">{errors.mobileNum?.message}</p>
              </div>
            </div>

            <div class="col-md-6 col-lg-3">
              <div class="mb-3">
                <label class="brand-label">
                  Email Address<span className="required-mark">*</span>
                </label>
                <input
                  type="email"
                  class="form-control"
                  placeholder="Enter Email Address"
                  {...register("email")}
                />
                <p className="text-danger">{errors.email?.message}</p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="mb-3">
                <label className="brand-label">
                  Effective Date<span className="required-mark">*</span>
                </label>
                <input
                  id="effectiveDate"
                  type="date"
                  max="2099-12-31"
                  className="form-control"
                  placeholder="Enter Effective Date"
                  {...register("effectiveDate")}
                />
                <p className="text-danger">
                  {errors.effectiveDate && (
                    <p>{errors.effectiveDate.message}</p>
                  )}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-6">
              <div className="mb-3">
                <label class="brand-label">
                  Coverage<span className="required-mark">*</span>
                </label>
                <div className="row ">
                  <div className="col-md-6">
                    <div className="form-check ">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="propertyCheck"
                        value="Property"
                        {...register("coverage")}
                      />
                      <label
                        className="form-check-label text-capitalize"
                        for="propertyCheck"
                      >
                        Property
                      </label>
                    </div>
                    <div className="form-check ">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="generalLiabilityCheck"
                        value="General Liability"
                        {...register("coverage")}
                      />
                      <label
                        className="form-check-label text-capitalize"
                        for="generalLiabilityCheck"
                      >
                        General Liability
                      </label>
                    </div>
                    <div className="form-check ">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="liquorLiabilityCheck"
                        value="Liquor Liability"
                        {...register("coverage")}
                      />
                      <label
                        className="form-check-label text-capitalize"
                        for="liquorLiabilityCheck"
                      >
                        Liquor Liability
                      </label>
                    </div>
                    <div className="form-check ">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="workerCompCheck"
                        value="Worker’s compensation"
                        {...register("coverage")}
                      />
                      <label
                        className="form-check-label text-capitalize"
                        for="workerCompCheck"
                      >
                        Worker’s compensation
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-check ">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="EPLICheck"
                        value="EPLI"
                        {...register("coverage")}
                      />
                      <label
                        className="form-check-label text-capitalize"
                        for="EPLICheck"
                      >
                        EPLI
                      </label>
                    </div>
                    <div className="form-check ">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="HNOACheck"
                        value="HNOA"
                        {...register("coverage")}
                      />
                      <label
                        className="form-check-label text-capitalize"
                        for="HNOACheck"
                      >
                        HNOA
                      </label>
                    </div>
                   
                    <div className="form-check ">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="autoUmbrellaCheck"
                        value="Umbrella"
                        {...register("coverage")}
                      />
                      <label
                        className="form-check-label text-capitalize"
                        for="autoUmbrellaCheck"
                      >
                        Umbrella
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-check ">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="othersCheck"
                        value="Others"
                        {...register("coverage")}
                      />
                      <label
                        className="form-check-label text-capitalize"
                        for="othersCheck"
                      >
                        Others
                      </label>
                    </div>
                  </div>
                </div>
                <p className="text-danger">{errors.coverage?.message}</p>
              </div>
            </div>
          </div>
        </section>
        <div className="text-end">
          <button className="btn btn-primary">Next</button>
        </div>
      </form>
    </>
  );
};

export default Step1;
