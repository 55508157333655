import React, { useEffect, useState } from "react";
import { downloadExcel } from "react-export-table-to-excel";
import { ThreeDots } from "react-loader-spinner";
import { default as axios } from "../../../config/axios-config";
// import EditHealthcare from "./healthcare/EditHealthcare";
import { IconCirclePlus, IconFileExport, IconRefresh, IconSearch } from "@tabler/icons-react";
import { useRef } from "react";
import { Link } from "react-router-dom";
import { showDateFormat } from "../../helper/Helper";
import GStoreView from "../GeneralStoreForm/GStoreView";
import GStoreEdit from "../GeneralStoreForm/GeneralStoreEdit";
import { useSelector } from "react-redux";

const DEVURL = process.env.REACT_APP_ANC_API + "/api/GeneralStore";
const ACCORDDEVURL = process.env.REACT_APP_ANC_API + "/api/Acord/GenerateGeneralStoreAcord";

const GeneralStore = () => {
    const userData = useSelector((state) => state.auth.user);
    const agencyId = userData.id;
    const [loader, setLoader] = useState(false);
    /*const [searchItem, setSearchItem] = useState("");*/

    const searchInputRef = useRef("");

    const [restaurantList, setRestauranList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [nextPage, setNextPage] = useState(0);
    const [previousPage, setPreviousPage] = useState(1);
    const [nextButtonEnable, setNextButtonEnable] = useState(false);
    const [pervButtonEnable, setPrevButtonEnable] = useState(true);
    const header = ["Enrollment Year", "Customer Name", "Customer Phone", "Customer Email", "Gender", "Date Of Birth", "Immigration Status", "Yearly Income"];

    const [loader_excel, setLoader_excel] = useState(false);
    const [selectedSalonData, setSelectedSalonData] = useState({});
    const gStoreTableRef = useRef(null);

    const getSalonsByAgencyId = async (type = null) => {
        setLoader(true);
        let currentP = 1;
        if (type) {
            if (type === "next") {
                currentP = nextPage;
            } else {
                currentP = previousPage <= 1 ? 1 : previousPage;
            }
        }        
        if (type === "reset") {
            searchInputRef.current.value = "";
        }

        await axios
            .get(`${DEVURL}/GetGeneralStoreInfoByAgency?agencyId=${agencyId}&page=${currentP}&pageSize=${perPage}&searchText=${searchInputRef.current.value}&agentId=${userData.agentId}`)
            .then((response) => {
                if (response.status === 200) {
                    setRestauranList(response.data);
                    setCurrentPage(currentP);
                    setNextPage(currentP + 1);
                    setPreviousPage(currentP - 1);
                    if (response.data.length === 0) {
                        setNextButtonEnable(true);
                        setPrevButtonEnable(false);
                    }
                    if (previousPage >= 1) {
                        setPrevButtonEnable(false);
                    }
                    setLoader(false);
                }
            })
            .catch((error) => {
                setLoader(false);
            });
    };

    useEffect(() => {
        if (agencyId) {
            getSalonsByAgencyId();
        }
    }, []);

    const handleDetailClick = (item) => {
        // clearLocalStorage()
        setSelectedSalonData(item);
    };

    const handleEditClick = (data) => {
        // clearLocalStorage()
        setSelectedSalonData(data);
    };

    const handleDownloadExcel = () => {
        if (!gStoreTableRef.current) {
            setLoader_excel(false);
            return;
        }
        setLoader_excel(true);
        // Access the table and retrieve the visible rows
        const tableRows = gStoreTableRef.current.querySelectorAll("tbody tr");
        // Prepare data for export with specific columns
        const exportDataSpecificColumns = Array.from(tableRows).map((row) => {
            const columns = row.querySelectorAll("td");
            return {
                FirstName: columns[1].innerText,
                LastName: columns[2].innerText,
                BusinessName: columns[3].innerText,
                LocationAddress: columns[4].innerText,
                EffectiveDate: columns[5].innerText,
                ContactNumber: columns[0].innerText,
            };
        });
        // Use the prepared data for export
        downloadExcel({
            fileName: "GeneralStore_Table",
            sheet: "generalStore_table",
            tablePayload: {
                header: ["FirstName", "LastName", "BusinessName", "LocationAddress", "EffectiveDate", "ContactNumber"],
                body: exportDataSpecificColumns,
            },
        });
        setLoader_excel(false);
    };

    return (
        <>
            <div className="col py-3">
                <div className="content-panel">
                    <div className="panel-title mb-5 ">
                        <h4>General Business Vault</h4>
                    </div>
                    <div className="tab-content" id="myTabContent">
                        <div className="filter-box mt-3 mb-2">
                            <div className="filter-box-content">
                                <div className="row align-items-center">
                                    <div className="col-lg-5">
                                        <div className="form-floating">
                                            <input
                                                type="text"
                                                size={500}
                                                className="form-control"
                                                ref={searchInputRef}                                                
                                                id="floatingInput"
                                                placeholder=""
                                            />
                                            <label htmlFor="floatingInput">Search by first name, last name or email</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-1">
                                        <div className="d-grid d-md-flex justify-content-md-left" style={{ height: "50px" }}>
                                            <button className="btn btn-primary" type="button" onClick={() => getSalonsByAgencyId()}>
                                                <div className="button-body-for-loader">
                                                    <IconSearch />
                                                    {loader && <ThreeDots color="#ffdb58" height={30} width={40} />}
                                                </div>
                                            </button>
                                            <button className="mx-2 btn btn-warning" type="button" onClick={() => getSalonsByAgencyId("reset")}>
                                                <div className="button-body-for-loader">
                                                    <IconRefresh />
                                                </div>
                                            </button>

                                            <button
                                                className="mx-2 btn btn-success"
                                                type="button"
                                                disabled={(!restaurantList?.items?.length > 0 && !loader) || loader_excel || loader}
                                                onClick={handleDownloadExcel}
                                            >
                                                <div className="button-body-for-loader">
                                                    <IconFileExport />
                                                    {loader_excel && <ThreeDots color="#ffdb58" height={30} width={40} />}
                                                </div>
                                            </button>
                                            <Link
                                                to={userData.agentId == null ? `/generalStoreForm/${userData.agencyName}` : `/generalStoreForm/${userData.agencyName}/${userData.userId}`}
                                                target="_blank"
                                            >
                                                <button className="mx-2 h-100 btn btn-primary" type="button">
                                                    <div className="button-body-for-loader">
                                                        <IconCirclePlus />
                                                    </div>
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="data-box ">
                            <div className="activity-status-table">
                                <div className="table-responsive">
                                    <table ref={gStoreTableRef} className="table activity-status-table table-striped">
                                        <thead className="text-nowrap">
                                            <tr>
                                                <th scope="col">Contact Person</th>
                                                <th scope="col">First name</th>
                                                <th scope="col">Last name</th>
                                                <th scope="col">Business Name</th>
                                                <th scope="col">Location Address</th>
                                                <th scope="col">Effective Date</th>
                                                <th scope="col">Created Date</th>
                                                <th scope="col">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody id="body-render">
                                            {restaurantList?.items?.map((item, index) => (
                                                <tr key={index}>
                                                    {console.log("item", item)}
                                                    <td>
                                                        <div className="cust-info">
                                                            <p> {item?.customerInfo?.email} </p>
                                                            <p>{item?.customerInfo?.mobileNum} </p>
                                                            <p>Work: {item?.customerInfo?.workNum} </p>
                                                        </div>
                                                    </td>
                                                    <td className="cust-info">
                                                        <h6> {item?.customerInfo?.firstName}</h6>
                                                    </td>
                                                    <td className="cust-info">
                                                        <h6> {item?.customerInfo?.lastName}</h6>
                                                    </td>
                                                    <td className="cust-info">
                                                        <p>{item?.customerInfo?.businessName}</p>
                                                    </td>
                                                    <td>
                                                        <p>{item?.customerInfo?.locationAddress}</p>
                                                    </td>
                                                    <td>
                                                        <p>{showDateFormat(item?.generalStoreInfo?.effectiveDate)}</p>
                                                    </td>
                                                    <td>
                                                        <div className="last-received-date-time">
                                                            <p>{showDateFormat(item?.customerInfo?.startDate)}</p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <a data-bs-toggle="offcanvas" onClick={() => handleDetailClick(item)} data-bs-target="#salon-view" href="#">
                                                            <i className="bi bi-file-earmark"></i>
                                                        </a>
                                                        &nbsp;
                                                        <a data-bs-toggle="offcanvas" onClick={() => handleEditClick(item)} data-bs-target="#salon-Edit" href="#">
                                                            <i className="bi bi-pencil-square"></i>
                                                        </a>
                                                        &nbsp;
                                                        <Link to={`${ACCORDDEVURL}/${agencyId}/${item?.generalStoreInfo?.storeId}`}>
                                                            <i className="bi bi-download"></i>
                                                        </Link>
                                                    </td>
                                                </tr>
                                            ))}
                                            {restaurantList?.items?.length === 0 && !loader && (
                                                <tr>
                                                    <td colSpan={8} className="text-center text-warning">
                                                        Data Not found
                                                    </td>
                                                </tr>
                                            )}
                                            {loader && (
                                                <td colSpan={7} className="text-center">
                                                    <ThreeDots
                                                        height="40"
                                                        width="80"
                                                        color="#4fa94d"
                                                        ariaLabel="bars-loading"
                                                        wrapperStyle={{
                                                            justifyContent: "center",
                                                            paddingBottom: "10px",
                                                        }}
                                                        wrapperClass=""
                                                        visible={true}
                                                    />
                                                </td>
                                            )}
                                        </tbody>
                                    </table>

                                    <nav aria-label="Page navigation example" className="pull-right">
                                        <ul className="pagination">
                                            <li className="page-item">
                                                <button className="page-link" onClick={() => getSalonsByAgencyId("prev")} disabled={pervButtonEnable}>
                                                    Previous
                                                </button>
                                            </li>
                                            <li className="page-item">
                                                <button className="page-link" onClick={() => getSalonsByAgencyId("next")} disabled={nextButtonEnable}>
                                                    Next
                                                </button>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <GStoreView details={selectedSalonData} />
            <GStoreEdit details={selectedSalonData} />
        </>
    );
};
export default GeneralStore;
