import React, { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import Stepper from "react-stepper-horizontal";
import AgencyHeader from "../../Agencyheader";
import Footer from "../../ancfooter";
import Step1 from "./Step1";
import Step2 from "./Step2";
import ThankyouResponse from "../../Thankyoupage";
import axiosInstance from "../../../config/axios-config";


const Master = ({ details,step, setStep }) => {
 
  const steps = [{ title: "Personal Information" }, { title: "Driver(s) Information" }, { title: "Finish" }];
  var activeStep = step;
  if (step === 1) {
    activeStep = 0;
  } else {
    activeStep = activeStep - 1;
  }
  console.log("detaiuls", details);

  const loader = () => {
    return (
      <ThreeDots
        height="40"
        width="80"
        color="#4fa94d"
        ariaLabel="bars-loading"
        wrapperStyle={{ justifyContent: "center", paddingBottom: "10px" }}
        wrapperClass=""
        visible={true}
      />
    );
  };

  useEffect(() => {
    localStorage.removeItem("step1");
    localStorage.removeItem("step2");
    localStorage.removeItem("customerId");
  }, []);


  return (
    <div>
      {/* <AgencyHeader /> */}
      <div className="container">
        <div className="main-wrap">
          <div className="wizard-content">
            {/* <DisplaySelectedPlan /> */}
            <Stepper steps={steps} activeStep={activeStep} />
            {step === 1 && <Step1 stepHandler={setStep} loader={loader} details={details} />}
            {step === 2 && <Step2 stepHandler={setStep} loader={loader} details={details} />}
            {step === 3 && <ThankyouResponse />}
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default Master;
