import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { default as axios } from "../../../../config/axios-config";
import { handleNotifications } from "../../../helper/Helper";

const DEVURL = process.env.REACT_APP_ANC_API + "/api/health/";

const schema = yup.object().shape({
  dependents: yup.array().of(
    yup.object().shape({
      dependentName: yup.string().trim().required("Dependent Name is required"),
      dependentBirthday: yup
        .date()
        .typeError("Date of Birth is a required field")
        .max(
          new Date(Date.now() - 86400000),
          "Date of Birth can not be future or current date"
        )
        .required()
        .label("Date of Birth"),
      dependentGender: yup.string().required("Gender is required"),
      dependentCitizenshipSelect: yup
        .string()
        .required("Citizenship is required"),
      documentTypeSelect: yup.string().required("Document Type is required"),
      tobaccoSelect: yup.string().required("Tobacco status is required"),
    })
  ),
});

const EditDependentHealthcare = ({ selectedHealthcare }) => {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      dependents: [
        {
          dependentName: "",
          dependentBirthday: "",
          dependentGender: "",
          dependentCitizenshipSelect: "",
          documentTypeSelect: "",
          tobaccoSelect: "",
        },
      ],
    },
  });

  const [dependents, setDependents] = useState([]);
  const [disableAdd, setDisableAdd] = useState(false);

  const getDependents = () => {
    axios
      .get(`${DEVURL}healthcareInfo/${selectedHealthcare?.primaryid}`)
      .then((res) => {
        if (res.status === 200) {
          if (res?.data?.dependents) {
            setDependents(res.data.dependents);
            setValue("dependents", res.data.dependents);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (dependents.length === 6) {
      setDisableAdd(true);
    } else {
      setDisableAdd(false);
    }
    if (dependents.length === 0) {
      setDependents([{ id: 0 }]);
    } else {
      getDependents();
    }
  }, [selectedHealthcare]);

  const saveDependent = async (data) => {
    await axios
      .put(`${DEVURL}dependent/${selectedHealthcare?.primaryid}`, {
        dependentList: data.dependents,
      })
      .then((res) => {
        if (res.status === 200) {
          //alert("Dependents infomation has been updated successfully");
          handleNotifications(
            "Dependents infomation has been updated successfully"
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleFormSubmit = async (data) => {
    const answer = window.confirm(
      "Are you sure you want to update dependents infomation?"
    );
    if (answer) {
      await saveDependent(data);
    }
  };

  const handleAddDependent = () => {
    if (dependents.length < 5) {
      const newDependents = [...dependents, { id: dependents.length }];
      setDependents(newDependents);
    }
  };

  const handleRemoveDependent = (id) => {
    const newDependents = dependents.filter((dep) => dep.id !== id);
    setDependents(newDependents);
    setValue("dependents", newDependents);
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(handleFormSubmit)}
        className="tab-wizard wizard-circle wizard needs-validation"
      >
        <section>
          <h4 className="wrap-title mb-3">Dependent Details</h4>
          <div className="brand-table">
            <div className="table-responsive table-container">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Persons Name</th>
                    <th scope="col">Date of Birth</th>
                    <th scope="col">Gender</th>
                    <th scope="col">Immigration</th>
                    <th scope="col">Relation</th>
                    <th scope="col">Tobacco</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {dependents.map((dependent, index) => (
                    <tr key={dependent.id}>
                      <th scope="row">{index + 1}</th>
                      <td>
                        <Controller
                          control={control}
                          name={`dependents[${index}].dependentName`}
                          render={({ field }) => (
                            <input
                              className="form-control dependentName"
                              placeholder="Enter Dependent Name"
                              {...field}
                            />
                          )}
                        />
                        <p className="error dependentNameError">
                          {errors.dependents?.[index]?.dependentName?.message}
                        </p>
                      </td>
                      <td>
                        <Controller
                          control={control}
                          name={`dependents[${index}].dependentBirthday`}
                          render={({ field }) => (
                            <input
                              className="form-control dependentBirthday"
                              type="date"
                              max="2099-12-31"
                              {...field}
                            />
                          )}
                        />
                        <p className="error dependentBirthdayError">
                          {
                            errors.dependents?.[index]?.dependentBirthday
                              ?.message
                          }
                        </p>
                      </td>
                      <td>
                        <Controller
                          control={control}
                          name={`dependents[${index}].dependentGender`}
                          render={({ field }) => (
                            <select
                              className="form-select dependentGender"
                              {...field}
                            >
                              <option value="">Select Gender</option>
                              <option value="Male">Male</option>
                              <option value="Female">Female</option>
                            </select>
                          )}
                        />
                        <p className="error dependentGenderError">
                          {errors.dependents?.[index]?.dependentGender?.message}
                        </p>
                      </td>
                      <td>
                        <div className="mb-2">
                          <Controller
                            control={control}
                            name={`dependents[${index}].dependentCitizenshipSelect`}
                            render={({ field }) => (
                              <select
                                className="form-select dependentCitizenshipSelect"
                                {...field}
                              >
                                <option value="">Select</option>
                                <option value="Citizen">Citizen</option>
                                <option value="Immigrant">Immigrant</option>
                                <option value="Student">Student</option>
                                <option value="Visitor">Visitor</option>
                                <option value="Asylum">Asylum</option>
                                <option value="Work Permit">Work Permit</option>
                                <option value="Other">Other</option>
                              </select>
                            )}
                          />
                          <p className="error dependentCitizenshipError">
                            {
                              errors.dependents?.[index]
                                ?.dependentCitizenshipSelect?.message
                            }
                          </p>
                        </div>
                      </td>
                      <td>
                        <Controller
                          control={control}
                          name={`dependents[${index}].documentTypeSelect`}
                          render={({ field }) => (
                            <select
                              className="form-select documentTypeSelect"
                              {...field}
                            >
                              <option value="">Select</option>
                              <option value="Self">Self</option>
                              <option value="Brother or Sister">
                                Brother or Sister
                              </option>
                              <option value="Child">Child</option>
                              <option value="Collateral Dependent">
                                Collateral Dependent
                              </option>
                              <option value="Ex-Spouse">Ex-Spouse</option>
                              <option value="Foster Child">Foster Child</option>
                              <option value="Grandson or Granddaughter">
                                Grandson or Granddaughter
                              </option>
                              <option value="Life Partner">Life Partner</option>
                              <option value="Nephew or Niece">
                                Nephew or Niece
                              </option>
                              <option value="Other Relationship">
                                Other Relationship
                              </option>
                              <option value="Other Relative">
                                Other Relative
                              </option>
                              <option value="Sponsored Dependent">
                                Sponsored Dependent
                              </option>
                              <option value="Stepson or Stepdaughter">
                                Stepson or Stepdaughter
                              </option>
                              <option value="Ward">Ward</option>
                            </select>
                          )}
                        />
                        <p className="error documentTypeSelectError">
                          {
                            errors.dependents?.[index]?.documentTypeSelect
                              ?.message
                          }
                        </p>
                      </td>
                      <td>
                        <Controller
                          control={control}
                          name={`dependents[${index}].tobaccoSelect`}
                          render={({ field }) => (
                            <select
                              className="form-select tobaccoSelect"
                              {...field}
                            >
                              <option value="">Select</option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                          )}
                        />
                        <p className="error tobaccoSelectError">
                          {errors.dependents?.[index]?.tobaccoSelect?.message}
                        </p>
                      </td>
                      <td>
                        {index === 0 ? (
                          <button
                            type="button"
                            className="btn btn-primary btn-sm"
                            onClick={handleAddDependent}
                            disabled={disableAdd}
                          >
                            Add
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-danger btn-sm"
                            onClick={() => handleRemoveDependent(dependent.id)}
                          >
                            Remove
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </section>
        <div className="d-flex justify-content-between">
          <button
            type="submit"
            className="btn btn-primary"
            disabled={disableAdd}
          >
            Update Dependent Info
          </button>
        </div>
      </form>
    </>
  );
};

export default EditDependentHealthcare;
