import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import * as yup from "yup";
import { currentYear, floatRegex } from "../../../../Common/regex";
import axiosInstance from "../../../../config/axios-config";

const DEVURL = process.env.REACT_APP_ANC_API + "/api/Hotel";

const validationSchema = yup.object().shape({
  feinNumer: yup
    .string()
    .trim()
    .nullable(true)
    .matches(/^\d{2}-\d{7,8}$/, "Please enter a valid fein")
    .label("FEIN"),

  roomRatesPerDay: yup
    .string()
    .trim()
    .required("Room rates is required")
    .label("Room rates"),

  annualGrossSales: yup
    .string()
    .trim()
    .required("Annual gross sales is required")
    .label("Annual gross sales"),
  umbrellaLimit: yup
    .string()
    .trim()
    .required("Umbrella limit is required")
    .label("Umbrella limit"),
  workersCompLimit: yup
    .string()
    .trim()
    .required("Worker's comp limit is required")
    .label("Worker's comp limit"),
  claimDate: yup
    .string()
    .nullable(true)
    .typeError("Please enter valid date")
    .when("isAnyClaims", (isAnyClaims, schema) => {
      console.log("isAnyClaims", isAnyClaims);
      if (isAnyClaims && isAnyClaims.includes("1")) {
        return schema.required("Claim Date is required");
      } else return schema.notRequired();
    })
    .test("is-past-date", "Date must be a past date", (value) => {
      if (!value || isNaN(Date.parse(value))) {
        return true;
      }
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);

      const inputDate = new Date(value);
      inputDate.setHours(0, 0, 0, 0);

      return inputDate < currentDate;
    })
    .label("Claim date"),
  isAnyClaims: yup.string().required("Claims is required").label("Claims"),

  isClaimType: yup
    .string()
    .trim()
    .when("isAnyClaims", (isAnyClaims, schema) => {
      if (isAnyClaims && isAnyClaims.includes("1")) {
        return schema.required("Claim is required");
      } else return schema.notRequired();
    })
    .label("Claim"),
  lossRunYears: yup
    .string()
    .trim()
    .nullable(true)
    .matches(floatRegex, "Please enter a valid no. of years")
    .test(
      "is-past-or-current-year",
      "Year must be in the past or current year",
      function (value) {
        return value <= currentYear;
      }
    )
    .label("Loss run"),
  termsCondition: yup
    .boolean()
    .test("termsCondition", "Terms & Conditions is required", (val) => {
      return val;
    }),
});

const Step3 = ({ details, stepHandler, loader }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    reset,
    control,
    watch,
  } = useForm({
    defaultValues: {
      umbrellaLimit: null,
      roomRatesPerDay: null,
      restaurantSales: null,
      liquorSales: null,
      annualGrossSales: "",
      totalPayroll: null,
      feinNumer: null,
      noOfEmployees: null,
      bankDetails: "",
      bankPropertyValue: null,
      termsCondition: false,
      workersCompLimit: null,
      noOfFullTimer: null,
      noOfPartTimer: null,
      noOfNonClericalStaffs: null,
      noOfClericalStaffs: null,
      isPreviousWorkersCompClaim: "",
      isOwnerIncludedExcludedPayroll: "",
      isReturnPolicyEmployeeAccident: "",
      priorLosses: null,
      lossRunYears: null,
      isAnyClaims: null,
      isClaimType: "",
      claimDate: null,
    },
    resolver: yupResolver(validationSchema),
  });

  const [isLoading, setLoading] = useState(false);

  const claimsValue = watch("isAnyClaims");

  const fillupStep3HotelInfoFromLocalStorage = (formData) => {
    setValue("umbrellaLimit", formData.umbrellaLimit);
    setValue("roomRatesPerDay", formData.roomRatesPerDay);
    setValue("restaurantSales", formData.restaurantSales);
    setValue("liquorSales", formData.liquorSales);
    setValue("annualGrossSales", formData.annualGrossSales);
    setValue("totalPayroll", formData.totalPayroll);
    setValue("feinNumer", formData.feinNumer);
    setValue("noOfEmployees", formData.noOfEmployees);
    setValue("bankDetails", formData.bankDetails);
    setValue("bankPropertyValue", formData.bankPropertyValue);
    setValue("workersCompLimit", formData.workersCompLimit);
    setValue("noOfPartTimer", formData.noOfPartTimer);
    setValue("noOfFullTimer", formData.noOfFullTimer);
    setValue("noOfClericalStaffs", formData.noOfClericalStaffs);
    setValue("noOfNonClericalStaffs", formData.noOfNonClericalStaffs);
    setValue("isPreviousWorkersCompClaim", formData.isPreviousWorkersCompClaim);
    setValue(
      "isOwnerIncludedExcludedPayroll",
      formData.isOwnerIncludedExcludedPayroll
    );
    setValue(
      "isReturnPolicyEmployeeAccident",
      formData.isReturnPolicyEmployeeAccident
    );
    setValue("priorLosses", formData.priorLosses);
    setValue("termsCondition", formData?.termsCondition);
    setValue("lossRunYears", formData.lossRunYears);
    setValue("isAnyClaims", formData.isAnyClaims);
    setValue("claimDate", formData.claimDate);
    setValue("isClaimType", formData.isClaimType);
  };

  const clearLocalStorage = () => {
    localStorage.removeItem("step1");
    localStorage.removeItem("step2");
    localStorage.removeItem("step3");
    localStorage.removeItem("customerId");
  };

  useEffect(() => {
    const formData = JSON.parse(localStorage.getItem("step3"));
    if (formData) {
      fillupStep3HotelInfoFromLocalStorage(formData);
    }
  }, []);

  useEffect(() => {
    if (claimsValue?.includes("0")) {
      setValue("claimDate", null);
      setValue("isClaimType", "");
    }
  }, [claimsValue]);

  const createRestaurantInfoObj = (restaurantInfo) => {
    console.log(restaurantInfo);
    let restaurantToCreate = {
      customerInfo: {
        agencyId: restaurantInfo.agencyId,
        agentId: restaurantInfo.agentId,
        customerId: restaurantInfo.customerId,
        firstName: restaurantInfo.firstName,
        lastName: restaurantInfo.lastName,
        locationAddress: restaurantInfo.locationAddress,
        locationCity: restaurantInfo.locationCity,
        locationZip: restaurantInfo.locationZip,
        locationState: restaurantInfo.locationState,
        mailingAddress: restaurantInfo.mailingAddress,
        mailingCity: restaurantInfo.mailingCity,
        mailingZip: restaurantInfo.mailingZip,
        mailingState: restaurantInfo.mailingState,
        businessName: restaurantInfo.businessName,
        dbaname: restaurantInfo.dbaname,
        hoursOfOperation: "",
        typeOfBusiness: [],
        mobileNum: restaurantInfo.mobileNum,
        workNum: restaurantInfo.workNum,
        email: restaurantInfo.email,
        faxNum: "",
        termsCondition: restaurantInfo.termsCondition?.toString(),
      },
      hotelInfo: {
        hotelId: restaurantInfo.hotelId,
        coverage: restaurantInfo.coverage,
        effectiveDate: restaurantInfo.effectiveDate,
        yearBuilt: restaurantInfo.yearBuilt,
        noOfBuilding: restaurantInfo.noOfBuilding,
        inventoryLimit: restaurantInfo.inventoryLimit,
        eachBuilding: restaurantInfo.eachBuilding,
        signValue: restaurantInfo.signValue,
        totalArea: restaurantInfo.totalArea,
        noOfStoriesEachBuilding: restaurantInfo.noOfStoriesEachBuilding,
        isRegisteredFranchise: restaurantInfo.isRegisteredFranchise,
        isBuildingsStairs: restaurantInfo.isBuildingsStairs,
        ageGroup: restaurantInfo.ageGroup,
        isElevators: restaurantInfo.isElevators,
        isUpdateConstruction: restaurantInfo.isUpdateConstruction,
        isBanquet: restaurantInfo.isBanquet,
        banquetSqFt: restaurantInfo.banquetSqFt,
        banquetCapacity: restaurantInfo.banquetCapacity,
        isGym: restaurantInfo.isGym,
        isGymMaintenanceAvailable: restaurantInfo.isGymMaintenanceAvailable,
        gymSqft: restaurantInfo.gymSqft,
        gymHour: restaurantInfo.gymHour,
        isSwimmingPool: restaurantInfo.isSwimmingPool,
        isSwimmingPoolLocation: restaurantInfo.isSwimmingPoolLocation,
        isSwimmingWellFenced: restaurantInfo.isSwimmingWellFenced,
        isSwimmingLifeGuard: restaurantInfo.isSwimmingLifeGuard,
        isSwimmingDoorShutOff: restaurantInfo.isSwimmingDoorShutOff,
        isSwimmingDivingBoard: restaurantInfo.isSwimmingDivingBoard,
        isSwimmingHotTub: restaurantInfo.isSwimmingHotTub,
        isSwimmingHotTubLocation: restaurantInfo.isSwimmingHotTubLocation,
        swimmingPoolDepth: restaurantInfo.swimmingPoolDepth,
        swimmingNoOfPools: restaurantInfo.swimmingNoOfPools,
        swimmingNoOfHotTubs: restaurantInfo.swimmingNoOfHotTubs,
        isBarDanceFloor: restaurantInfo.isBarDanceFloor,
        isHappyHours: restaurantInfo.isHappyHours,
        barDanceHours: restaurantInfo.barDanceHours,
        isRestaurant: restaurantInfo.isRestaurant,
        seatingCapacity: restaurantInfo.seatingCapacity,
        restaurantHours: restaurantInfo.restaurantHours,
        noOfEmergencyExits: restaurantInfo.noOfEmergencyExits,
        noOfManagersPerShift: restaurantInfo.noOfManagersPerShift,
        crimeCoverageLimit: restaurantInfo.crimeCoverageLimit,
        managersCheckinCheckout: restaurantInfo.managersCheckinCheckout,
        lastRoofUpdated: restaurantInfo.lastRoofUpdated,
        lastWiringUpdated: restaurantInfo.lastWiringUpdated,
        lastPlumbingUpdated: restaurantInfo.lastPlumbingUpdated,
        lastHeatingUpdated: restaurantInfo.lastHeatingUpdated,
        isRoomRentOutBasis: restaurantInfo.isRoomRentOutBasis,
        isShuttleService: restaurantInfo.isShuttleService,
        isRoomWithKitchen: restaurantInfo.isRoomWithKitchen,
        isPlayGround: restaurantInfo.isPlayGround,
        isEntertainmentRoom: restaurantInfo?.isEntertainmentRoom,
        isCyberLiabilityCoverage: restaurantInfo.isCyberLiabilityCoverage,
        isSubsidizedRate: restaurantInfo.isSubsidizedRate,
        howManySubRooms: restaurantInfo.howManySubRooms,
        averageOccupancyRate: restaurantInfo?.averageOccupancyRate?.toString(),
        constructionType: restaurantInfo.constructionType,
        protectiveSafeguard: restaurantInfo.protectiveSafeguard,
        roomRatesPerDay: restaurantInfo.roomRatesPerDay,
        liquorSales: restaurantInfo.liquorSales,
        restaurantSales: restaurantInfo.restaurantSales,
        annualGrossSales: restaurantInfo.annualGrossSales,
        totalPayroll: restaurantInfo.totalPayroll,
        feinNumer: restaurantInfo.feinNumer,
        noOfEmployees: restaurantInfo.noOfEmployees,
        bankDetails: restaurantInfo.bankDetails,
        isAnyClaims: restaurantInfo.isAnyClaims,
        priorLosses: restaurantInfo.priorLosses,
        isReturnPolicyEmployeeAccident:
          restaurantInfo.isReturnPolicyEmployeeAccident,
        isOwnerIncludedExcludedPayroll:
          restaurantInfo.isOwnerIncludedExcludedPayroll,
        isPreviousWorkersCompClaim: restaurantInfo.isPreviousWorkersCompClaim,
        noOfNonClericalStaffs: restaurantInfo.noOfNonClericalStaffs,
        noOfClericalStaffs: restaurantInfo.noOfClericalStaffs,
        noOfFullTimer: restaurantInfo.noOfFullTimer,
        noOfPartTimer: restaurantInfo.noOfPartTimer,
        workersCompLimit: restaurantInfo.workersCompLimit,
        umbrellaLimit: restaurantInfo.umbrellaLimit,
        bankPropertyValue: restaurantInfo.bankPropertyValue,
        isClaimType: restaurantInfo.isClaimType,
        claimDate: restaurantInfo.claimDate,
        lossRunYears: restaurantInfo.lossRunYears,
      },
    };
    return restaurantToCreate;
  };

  const handleFormSubmit = async (data) => {
    localStorage.setItem("step3", JSON.stringify(data));
    const step1 = localStorage.getItem("step1");
    const step2 = localStorage.getItem("step2");
    const step3 = localStorage.getItem("step3");
    const restaurantInfo = {
      ...JSON.parse(step1),
      ...JSON.parse(step2),
      ...JSON.parse(step3),
    };

    const restaurantInfoObj = createRestaurantInfoObj(restaurantInfo);

    Swal.fire({
      title: "Are you sure you want to submit the form?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        axiosInstance
          .put(
            `${DEVURL}/UpdateHotelApp/${details?.customerInfo?.customerId}/${details?.hotelInfo?.hotelId}`,
            restaurantInfoObj
          )
          .then((res) => {
            if (res.status === 200) {
              setLoading(false);
              clearLocalStorage();
              reset();
              stepHandler(4);
              setTimeout(() => {
                stepHandler(1);
              }, 3000);
            }
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
          });
      } else {
        setLoading(false);
        return;
      }
    });
  };
  const handleFeinChange = async (e) => {
    let inputValue = e.target.value;
    inputValue = inputValue.replace(/[^0-9]/g, "");
    if (inputValue.length > 2) {
      inputValue = inputValue.slice(0, 2) + "-" + inputValue.slice(2);
    }
    const name = e.target.name;
    setValue(name, inputValue);
  };

  if (isLoading) {
    return loader();
  }

  return (
    <>
      <form
        className="tab-wizard wizard-circle wizard needs-validation"
        onSubmit={handleSubmit(handleFormSubmit)}
      >
        <section>
          <h4 className="wrap-title mb-3">General Liability Information</h4>

          <div className="row">
            <div className="col-md-6 col-lg-3">
              <div className="mb-3">
                <label className="brand-label">
                  <div>
                    Room rates per day?<span className="required-mark">*</span>
                  </div>
                </label>
                <Controller
                  name="roomRatesPerDay"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      {...field}
                      id="roomRatesPerDay"
                      className="form-control"
                      thousandSeparator={true}
                      prefix="$"
                      name="roomRatesPerDay"
                      onValueChange={(values) => {
                        setValue("roomRatesPerDay", values.floatValue);
                      }}
                      placeholder="Enter the limit"
                      {...register("roomRatesPerDay")}
                    />
                  )}
                />
                <p className="text-danger">{errors.roomRatesPerDay?.message}</p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="mb-3">
                <label className="brand-label d-grid">Liquor Sales?</label>
                <Controller
                  name="liquorSales"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      {...field}
                      id="liquorSales"
                      className="form-control"
                      thousandSeparator={true}
                      prefix="$"
                      name="liquorSales"
                      onValueChange={(values) => {
                        setValue("liquorSales", values.floatValue);
                      }}
                      placeholder="Enter annual liquor sales"
                      {...register("liquorSales")}
                    />
                  )}
                />
              </div>
            </div>

            <div className="col-md-6 col-lg-3">
              <div className="mb-3">
                <label className="brand-label d-grid">
                  <div>Restaurant Sales (if any)?</div>
                </label>
                <Controller
                  name="restaurantSales"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      {...field}
                      id="restaurantSales"
                      className="form-control"
                      thousandSeparator={true}
                      prefix="$"
                      name="restaurantSales"
                      onValueChange={(values) => {
                        setValue("restaurantSales", values.floatValue);
                      }}
                      placeholder="Enter Restaurant Sales"
                      {...register("restaurantSales")}
                    />
                  )}
                />

                <p className="text-danger">{errors.restaurantSales?.message}</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-3">
              <div className="mb-3">
                <label className="brand-label text-capitalize">
                  Annual gross sales?
                  <span className="required-mark">*</span>
                </label>
                <Controller
                  name="annualGrossSales"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      {...field}
                      id="annualGrossSales"
                      className="form-control"
                      thousandSeparator={true}
                      prefix="$"
                      name="annualGrossSales"
                      onValueChange={(values) => {
                        setValue("annualGrossSales", values.floatValue);
                      }}
                      placeholder="Enter annual catering service sales"
                      {...register("annualGrossSales")}
                    />
                  )}
                />
                <p className="text-danger">
                  {errors.annualGrossSales?.message}
                </p>
              </div>
            </div>

            <h4 className="wrap-title mb-2">Other</h4>

            <div className="col-md-4">
              <div className="mb-3">
                <label className="brand-label d-grid">Total Payroll?</label>
                <Controller
                  name="totalPayroll"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      {...field}
                      id="totalPayroll"
                      className="form-control"
                      thousandSeparator={true}
                      prefix="$"
                      name="totalPayroll"
                      onValueChange={(values) => {
                        setValue("totalPayroll", values.floatValue);
                      }}
                      placeholder="Enter Total Payroll"
                      {...register("totalPayroll")}
                    />
                  )}
                />
              </div>
            </div>

            <div className="col-md-4">
              <div className="mb-3">
                <label className="brand-label d-grid">FEIN Number</label>
                <input
                  class="form-control"
                  type="text"
                  name="feinNumer"
                  onKeyUp={handleFeinChange}
                  placeholder="Enter FEIN Number"
                  {...register("feinNumer")}
                />
                <p className="text-danger mt-2">
                  {errors.feinNumer && <p>{errors.feinNumer.message}</p>}
                </p>
              </div>
            </div>

            <div className="col-md-4">
              <div className="mb-3">
                <label className="brand-label d-grid">No. of Employees?</label>
                <Controller
                  name="noOfEmployees"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      {...field}
                      id="noOfEmployees"
                      className="form-control"
                      thousandSeparator={true}
                      name="noOfEmployees"
                      onValueChange={(values) => {
                        setValue("noOfEmployees", values.floatValue);
                      }}
                      placeholder="No. of Employees"
                      {...register("noOfEmployees")}
                    />
                  )}
                />
              </div>
            </div>

            <h6 className="wrap-title mb-2">If financed from bank, then:</h6>

            <div className="col-md-6">
              <div className="mb-3">
                <label className="brand-label">Bank Details</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Enter Bank Details"
                  {...register("bankDetails")}
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className="mb-3">
                <label className="brand-label">
                  What is the Bank Property Value?
                </label>
                <Controller
                  name="bankPropertyValue"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      {...field}
                      id="bankPropertyValue"
                      className="form-control"
                      thousandSeparator={true}
                      prefix="$"
                      name="bankPropertyValue"
                      onValueChange={(values) => {
                        setValue("bankPropertyValue", values.value);
                      }}
                      placeholder="Enter Bank Property Value"
                      {...register("bankPropertyValue")}
                    />
                  )}
                />
              </div>
            </div>
            <h5 className="wrap-title mb-2">Umbrella</h5>
            <div className="col-md-4">
              <div className="mb-3">
                <label className="brand-label">
                  Umbrella Limit?
                  <span className="required-mark">*</span>
                </label>
                <Controller
                  name="umbrellaLimit"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      {...field}
                      id="umbrellaLimit"
                      className="form-control"
                      thousandSeparator={true}
                      prefix="$"
                      name="umbrellaLimit"
                      onValueChange={(values) => {
                        setValue("umbrellaLimit", values.floatValue);
                      }}
                      placeholder="Enter Umbrella Limit"
                      {...register("umbrellaLimit")}
                    />
                  )}
                />
                <p className="text-danger">
                  {errors.umbrellaLimit && (
                    <p>{errors.umbrellaLimit.message}</p>
                  )}
                </p>
              </div>
            </div>
            <h5 className="wrap-title mb-2">Workers Comp:</h5>
            <div className="col-md-6 col-lg-4">
              <div className="mb-3">
                <label className="brand-label">
                  Worker’s Comp Limit?
                  <span className="required-mark">*</span>
                </label>
                <Controller
                  name="workersCompLimit"
                  control={control}
                  defaultValue={null}
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      {...field}
                      id="workersCompLimit"
                      className="form-control"
                      thousandSeparator={true}
                      prefix="$"
                      name="workersCompLimit"
                      onValueChange={(values) => {
                        setValue("workersCompLimit", values.floatValue);
                      }}
                      placeholder="Enter Worker’s Comp Limit"
                      {...register("workersCompLimit")}
                    />
                  )}
                />
                <p className="text-danger">
                  {errors.workersCompLimit && (
                    <p>{errors.workersCompLimit.message}</p>
                  )}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="mb-2">
                <label className="brand-label text-capitalize">
                  No. of Part Timer?
                </label>
                <Controller
                  name="noOfPartTimer"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      {...field}
                      allowNegative={false}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      id="noOfPartTimer"
                      className="form-control"
                      thousandSeparator={true}
                      name="noOfPartTimer"
                      onValueChange={(values) => {
                        setValue("noOfPartTimer", values.value);
                      }}
                      placeholder="Enter No. of Part Timer"
                      {...register("noOfPartTimer")}
                    />
                  )}
                />
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="mb-2">
                <label className="brand-label text-capitalize">
                  No. of Full Timer?
                </label>
                <Controller
                  name="noOfFullTimer"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      {...field}
                      allowNegative={false}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      id="noOfFullTimer"
                      className="form-control"
                      thousandSeparator={true}
                      name="noOfFullTimer"
                      onValueChange={(values) => {
                        setValue("noOfFullTimer", values.value);
                      }}
                      placeholder="Enter No. of Full Timer"
                      {...register("noOfFullTimer")}
                    />
                  )}
                />
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="mb-2">
                <label className="brand-label text-capitalize">
                  No. of clerical staffs?
                </label>
                <Controller
                  name="noOfClericalStaffs"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      {...field}
                      allowNegative={false}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      id="noOfClericalStaffs"
                      className="form-control"
                      thousandSeparator={true}
                      name="noOfClericalStaffs"
                      onValueChange={(values) => {
                        setValue("noOfClericalStaffs", values.value);
                      }}
                      placeholder="Enter No. of Clerical Staffs"
                      {...register("noOfClericalStaffs")}
                    />
                  )}
                />
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="mb-2">
                <label className="brand-label text-capitalize">
                  No. of non-clerical staffs?
                </label>
                <Controller
                  name="noOfNonClericalStaffs"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      {...field}
                      allowNegative={false}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      id="noOfNonClericalStaffs"
                      className="form-control"
                      thousandSeparator={true}
                      name="noOfNonClericalStaffs"
                      onValueChange={(values) => {
                        setValue("noOfNonClericalStaffs", values.value);
                      }}
                      placeholder="Enter No. of Non-Clerical Staffs"
                      {...register("noOfNonClericalStaffs")}
                    />
                  )}
                />
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="mb-2">
                <label className="brand-label text-capitalize">
                  Any previous workers comp claim?
                </label>
                <div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="isPreviousWorkersCompClaimYes"
                      value="1"
                      {...register("isPreviousWorkersCompClaim")}
                    />
                    <label
                      className="form-check-label text-capitalize"
                      for="isPreviousWorkersCompClaimYes"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="isPreviousWorkersCompClaimNo"
                      value="0"
                      {...register("isPreviousWorkersCompClaim")}
                    />
                    <label
                      className="form-check-label text-capitalize"
                      for="isPreviousWorkersCompClaimNo"
                    >
                      No
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-6">
              <div className="mb-2">
                <label className="brand-label text-capitalize">
                  Owner wants to be included/excluded from the payroll?
                </label>
                <div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="includedCheck"
                      value="1"
                      {...register("isOwnerIncludedExcludedPayroll")}
                    />
                    <label
                      className="form-check-label text-capitalize"
                      for="includedCheck"
                    >
                      Included
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="excludedCheck"
                      value="0"
                      {...register("isOwnerIncludedExcludedPayroll")}
                    />
                    <label
                      className="form-check-label text-capitalize"
                      for="excludedCheck"
                    >
                      Excluded
                    </label>
                  </div>
                </div>
                <p className="text-danger">
                  {errors.isOwnerIncludedExcludedPayroll?.message}
                </p>
              </div>
            </div>

            <div className="col-md-6 col-lg-6">
              <div className="mb-2">
                <label className="brand-label text-capitalize">
                  return policy available for employee after an accident or
                  sickness?
                </label>
                <div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="isReturnPolicyEmployeeAccidentYes"
                      value="1"
                      {...register("isReturnPolicyEmployeeAccident")}
                    />
                    <label
                      className="form-check-label text-capitalize"
                      for="isReturnPolicyEmployeeAccidentYes"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="isReturnPolicyEmployeeAccidentNo"
                      value="0"
                      {...register("isReturnPolicyEmployeeAccident")}
                    />
                    <label
                      className="form-check-label text-capitalize"
                      for="isReturnPolicyEmployeeAccidentNo"
                    >
                      No
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="mb-2">
                <label className="brand-label d-grid">Prior Losses</label>
                <Controller
                  name="priorLosses"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      {...field}
                      id="priorLosses"
                      className="form-control"
                      thousandSeparator={true}
                      prefix="$"
                      name="priorLosses"
                      onValueChange={(values) => {
                        setValue("priorLosses", values.floatValue);
                      }}
                      placeholder="Enter prior losses"
                      {...register("priorLosses")}
                    />
                  )}
                />
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="mb-3">
                <label class="brand-label d-grid">
                  <div>
                    Any Claim in past 5 years?
                    <span className="required-mark">*</span>
                  </div>
                </label>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="claimYes"
                    value="1"
                    {...register("isAnyClaims")}
                  />
                  <label class="form-check-label" for="claimYes">
                    Yes
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="claimNo"
                    value="0"
                    {...register("isAnyClaims")}
                  />
                  <label class="form-check-label" for="claimNo">
                    No
                  </label>
                </div>
                <p className="text-danger">
                  {errors.isAnyClaims && <p>{errors.isAnyClaims.message}</p>}
                </p>
              </div>
            </div>

            <div class="col-md-6 col-lg-3">
              <div class="mb-3">
                <label class="brand-label d-grid">
                  <div>Claim Type?</div>
                </label>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="propertyCheck"
                    value="1"
                    disabled={!claimsValue?.includes("1")}
                    {...register("isClaimType")}
                  />
                  <label class="form-check-label" for="propertyCheck">
                    Property
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="GLICheck"
                    value="0"
                    disabled={!claimsValue?.includes("1")}
                    {...register("isClaimType")}
                  />
                  <label class="form-check-label" for="GLICheck">
                    GL
                  </label>
                </div>

                <p className="text-danger">
                  {errors.isClaimType && <p>{errors.isClaimType.message}</p>}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="mb-3">
                <label className="brand-label">Claim Date?</label>
                <input
                  id="claimDate"
                  type="date"
                  max="2099-12-31"
                  className="form-control"
                  placeholder="Enter Claim Date"
                  disabled={!claimsValue?.includes("1")}
                  {...register("claimDate")}
                />
                <p className="text-danger">
                  {errors.claimDate && <p>{errors.claimDate.message}</p>}
                </p>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="mb-3">
                <label class="brand-label">Loss Run (Years)</label>
                <Controller
                  name="lossRunYears"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      {...field}
                      allowNegative={false}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      id="lossRunYears"
                      className="form-control"
                      name="lossRunYears"
                      onValueChange={(values) => {
                        setValue("lossRunYears", values.value);
                      }}
                      placeholder="Enter Loss Run (Years)"
                      {...register("lossRunYears")}
                    />
                  )}
                />
                <p className="text-danger">
                  {errors.lossRunYears && <p>{errors.lossRunYears.message}</p>}
                </p>
              </div>
            </div>

            <div className="col-md-12 col-lg-12 mt-1">
              <div className="mb-3">
                <div className="d-flex align-items-baseline gap-10">
                  <input
                    className="me-2"
                    type="checkbox"
                    id="termsCondition"
                    {...register("termsCondition")}
                  />
                  <label className="brand-label ">
                    I understand loss runs are required to receive a quote and I
                    hereby allow the agent to retrieve information using the
                    above-mentioned business information and provide me with
                    quote(s) for my perusal. I also understand that quotes are
                    for information and marketing purpose only.
                  </label>
                </div>

                <p className="text-danger mt-2">
                  {errors.termsCondition && (
                    <p>{errors.termsCondition.message}</p>
                  )}
                </p>
              </div>
            </div>
          </div>
        </section>
        <div className="gap-4 text-end">
          <button
            className="btn btn-warning"
            type="button"
            onClick={() => stepHandler(2)}
          >
            Previous
          </button>{" "}
          &nbsp;&nbsp;
          <button
            type="submit"
            className="btn btn-success"
            disabled={isSubmitting}
          >
            Submit
          </button>
        </div>
      </form>
    </>
  );
};

export default Step3;
