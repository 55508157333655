import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import * as yup from "yup";
import axiosInstance from "../../../../config/axios-config";

const DEVURL = process.env.REACT_APP_ANC_API + "/api/ShoppingMall";

const validationSchema = yup.object().shape({
  feinNumber: yup
    .string()
    .trim()
    .nullable(true)
    .matches(/^\d{2}-\d{7,8}$/, "Please enter a valid fein")
    .label("FEIN"),
  totalRevenue: yup
    .string()
    .trim()
    .required("Total revenue is required")
    .label("Total revenue"),

  parkingSqFt: yup
    .string()
    .trim()
    .required("Parking sq. ft. is required")
    .label("Parking sq. ft."),
  termsCondition: yup
    .boolean()
    .test("termsCondition", "Terms & conditions is required", (val) => {
      return val;
    }),
});

const Step3 = ({ stepHandler, loader, details }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
    reset,
    watch,
    control,
  } = useForm({
    defaultValues: {
      totalRevenue: null,
      parkingSqFt: null,
      totalPayroll: null,
      feinNumber: null,
      noOfEmployee: null,
      termsCondition: false,
      rentoll: "",
    },
    resolver: yupResolver(validationSchema),
  });

  const anyProferssionalLibValue = watch("anyProferssionalLib");
  const malPracticeCoverageValue = watch("malPracticeCoverage");

  const fillupStep3HotelInfoFromLocalStorage = (formData) => {
    setValue("totalRevenue", formData.totalRevenue);
    setValue("parkingSqFt", formData.parkingSqFt);
    setValue("rentoll", formData.rentoll);
    setValue("totalPayroll", formData.totalPayroll);
    setValue("feinNumber", formData.feinNumber);
    setValue("noOfEmployee", formData.noOfEmployee);
    setValue("termsCondition", formData?.termsCondition);
  };

  const [disableAdd, setDisableAdd] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const navigate = useNavigate();

  const clearLocalStorage = () => {
    localStorage.removeItem("step1");
    localStorage.removeItem("step2");
    localStorage.removeItem("step3");
    localStorage.removeItem("customerId");
  };

  useEffect(() => {
    const formData = JSON.parse(localStorage.getItem("step3"));
    if (formData) {
      fillupStep3HotelInfoFromLocalStorage(formData);
    }
  }, []);

  const createSalonInfoObj = (salonInfo) => {
    console.log(salonInfo);
    let salonToCreate = {
      customerInfo: {
        agencyId: salonInfo.agencyId,
        agentId: salonInfo.agentId,
        customerId: salonInfo.customerId,
        firstName: salonInfo.firstName,
        lastName: salonInfo.lastName,
        locationAddress: salonInfo.locationAddress,
        locationCity: salonInfo.locationCity,
        locationZip: salonInfo.locationZip,
        locationState: salonInfo.locationState,
        mailingAddress: salonInfo.mailingAddress,
        mailingCity: salonInfo.mailingCity,
        mailingZip: salonInfo.mailingZip,
        mailingState: salonInfo.mailingState,
        businessName: salonInfo.businessName,
        dbaname: salonInfo.dbaname,
        hoursOfOperation: salonInfo.hoursOfOperation,
        typeOfBusiness: salonInfo?.typeOfBusiness,
        mobileNum: salonInfo.mobileNum,
        workNum: salonInfo.workNum,
        email: salonInfo.email,
        faxNum: "",
        termsCondition: salonInfo.termsCondition?.toString(),
      },
      shoppingMallInfo: {
        shoppingId: salonInfo.shoppingId,
        coverage: salonInfo.coverage,
        effectiveDate: salonInfo.effectiveDate,
        hoursOfOperation: salonInfo.hoursOfOperation,
        yearofConstruction: salonInfo.yearofConstruction,
        typeOfBusiness: salonInfo?.typeOfBusiness,
        totalPayroll: salonInfo.totalPayroll,
        feinNumber: salonInfo.feinNumber,
        noOfEmployee: salonInfo.noOfEmployee,
        lastRoofUpdate: salonInfo.lastRoofUpdate,
        lastWiringUpdate: salonInfo.lastWiringUpdate,
        lastPlumbingUpdate: salonInfo.lastPlumbingUpdate,
        lastHeating: salonInfo.lastHeating,

        buildingValue: salonInfo.buildingValue,
        rentoll: salonInfo.rentoll,
        parkingSqFt: salonInfo.parkingSqFt,
        totalRevenue: salonInfo.totalRevenue,
        yearOfBuilt: salonInfo.yearOfBuilt,
        isBankInvolved: salonInfo.isBankInvolved,
        bankValueLimit: salonInfo.bankValueLimit,
        abouttenant: salonInfo.abouttenant,
        sqftspace: salonInfo.sqftspace,
        propUpdatedYear: salonInfo.propUpdatedYear,
        constructionType: salonInfo.constructionType,
        wireType: salonInfo.wireType,
        lossRunYear: salonInfo.lossRunYear,
        priorLoss: salonInfo.priorLoss,
        claimType: salonInfo.claimType,
        claimDate: salonInfo.claimDate,
        anyClaim: salonInfo.anyClaim,
        isPersonLiveOnProp: salonInfo.isPersonLiveOnProp,
        isManagerAvailableOnprop: salonInfo.isManagerAvailableOnprop,
        anyAdditionalInsure: salonInfo.anyAdditionalInsure,
        bppValue: salonInfo.bppValue,
        shoppingMallType: salonInfo.shoppingMallType,
        isEscrow: salonInfo.isEscrow,
        bankDetail: salonInfo.bankDetail,
      },
    };
    return salonToCreate;
  };
  const handleFormSubmit = async (data) => {
    localStorage.setItem("step3", JSON.stringify(data));
    const customerId = localStorage.getItem("customerId");
    const step1 = localStorage.getItem("step1");
    const step2 = localStorage.getItem("step2");
    const step3 = localStorage.getItem("step3");
    const salonInfo = {
      ...JSON.parse(step1),
      ...JSON.parse(step2),
      ...JSON.parse(step3),
    };

    const salonInfoObj = createSalonInfoObj(salonInfo);

    Swal.fire({
      title: "Are you sure you want to submit the form?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        axiosInstance
          .put(
            `${DEVURL}/UpdateShoppingMallApp/${details?.customerInfo?.customerId}/${details?.shoppingMallInfo?.shoppingId}`,
            salonInfoObj
          )
          .then((res) => {
            if (res.status === 200) {
              setLoading(false);
              clearLocalStorage();
              reset();
              stepHandler(4);
              setTimeout(() => {
                stepHandler(1);
              }, 3000);
            }
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
          });
      } else {
        setLoading(false);
        return;
      }
    });
  };

  const handleFeinChange = (e) => {
    let inputValue = e.target.value;
    inputValue = inputValue.replace(/[^0-9]/g, "");
    if (inputValue.length > 2) {
      inputValue = inputValue.slice(0, 2) + "-" + inputValue.slice(2);
    }
    const name = e.target.name;
    setValue(name, inputValue);
  };

  useEffect(() => {
    if (anyProferssionalLibValue?.includes("0")) {
      setValue("converageLimit", null);
    }
    if (malPracticeCoverageValue?.includes("0")) {
      setValue("malPracticeCoverageAmt", null);
    }
  }, [anyProferssionalLibValue, malPracticeCoverageValue]);

  if (isLoading) {
    return loader();
  }

  return (
    <>
      <form
        className="tab-wizard wizard-circle wizard needs-validation"
        onSubmit={handleSubmit(handleFormSubmit)}
      >
        <section>
          <h4 className="wrap-title mb-3">General Liability Information:</h4>
          <div className="row">
            <div class="col-md-6 col-lg-4">
              <div class="mb-3">
                <label class="brand-label">
                  Total Revenue?<span className="required-mark">*</span>
                </label>
                <Controller
                  name="totalRevenue"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      {...field}
                      id="totalRevenue"
                      className="form-control"
                      thousandSeparator={true}
                      prefix="$"
                      name="totalRevenue"
                      placeholder="Enter Total Revenue"
                      onValueChange={(values) => {
                        setValue("totalRevenue", values.floatValue);
                      }}
                      {...register("totalRevenue")}
                    />
                  )}
                />
                <p className="text-danger">
                  {errors.totalRevenue && <p>{errors.totalRevenue.message}</p>}
                </p>
              </div>
            </div>
           
            <div class="col-md-6 col-lg-4">
              <div class="mb-3">
                <label class="brand-label">
                  Parking Sq. Ft.?<span className="required-mark">*</span>
                </label>
                <Controller
                  name="parkingSqFt"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      {...field}
                      id="parkingSqFt"
                      className="form-control"
                      thousandSeparator={true}
                      // prefix="$"
                      name="parkingSqFt"
                      onValueChange={(values) => {
                        setValue("parkingSqFt", values.floatValue);
                      }}
                      placeholder="Enter Sq.Ft Of Parking"
                      {...register("parkingSqFt")}
                    />
                  )}
                />
                <p className="text-danger">{errors?.parkingSqFt?.message}</p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="mb-3">
                <label className="brand-label  text-capitalize">
                  What is the rent roll?
                </label>
                <Controller
                  name="rentoll"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      {...field}
                      id="rentoll"
                      className="form-control"
                      thousandSeparator={true}
                      prefix="$"
                      name="rentoll"
                      onValueChange={(values) => {
                        setValue("rentoll", values.floatValue);
                      }}
                      placeholder="Enter Bank Details"
                      {...register("rentoll")}
                    />
                  )}
                />
              </div>
            </div>

            <h4 className="wrap-title mb-3">Other:</h4>
            <div class="col-md-4">
              <div class="mb-3">
                <label class="brand-label d-grid">Total Payroll</label>
                <Controller
                  name="totalPayroll"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      {...field}
                      id="totalPayroll"
                      className="form-control"
                      thousandSeparator={true}
                      prefix="$"
                      name="totalPayroll"
                      onValueChange={(values) => {
                        setValue("totalPayroll", values.value);
                      }}
                      placeholder="Enter Total Payroll"
                      {...register("totalPayroll")}
                    />
                  )}
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-3">
                <label class="brand-label d-grid">FEIN Number</label>
                <input
                  class="form-control"
                  type="text"
                  name="feinNumber"
                  onKeyUp={handleFeinChange}
                  placeholder="Enter FEIN Number"
                  {...register("feinNumber")}
                />
                <p className="text-danger mt-2">
                  {errors.feinNumber && <p>{errors.feinNumber.message}</p>}
                </p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-3">
                <label class="brand-label d-grid">No. of Employees</label>
                <Controller
                  name="noOfEmployee"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      {...field}
                      id="noOfEmployee"
                      className="form-control"
                      thousandSeparator={true}
                      name="noOfEmployee"
                      onValueChange={(values) => {
                        setValue("noOfEmployee", values.floatValue);
                      }}
                      placeholder="No. of Employees"
                      {...register("noOfEmployee")}
                    />
                  )}
                />
              </div>
            </div>

            <div className="col-md-12 col-lg-12 mt-1">
              <div className="mb-3">
                <div className="d-flex align-items-baseline gap-10">
                  <input
                    className="me-2"
                    type="checkbox"
                    id="termsCondition"
                    {...register("termsCondition")}
                  />
                  <label className="brand-label ">
                  I understand loss runs are required to receive a quote and I
                    hereby allow the agent to retrieve information using the
                    above-mentioned business information and provide me with
                    quote(s) for my perusal. I also understand that quotes are
                    for information and marketing purpose only.
                  </label>
                </div>

                <p className="text-danger mt-2">
                  {errors.termsCondition && (
                    <p>{errors.termsCondition.message}</p>
                  )}
                </p>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-warning"
              type="button"
              onClick={() => stepHandler(2)}
            >
              Previous
            </button>
            <button
              type="submit"
              className="btn btn-primary ms-2"
              disabled={disableAdd}
            >
              Submit
            </button>
          </div>
        </section>
      </form>
    </>
  );
};

export default Step3;
