import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css'

const Header =()=>{

    // console.log(process.env.REACT_APP_TEST)
return(
    <header>
    <nav class="navbar navbar-expand-lg navbar-light">
    
        <div class="container" style={{"padding" : "0px"}}>
            <a class="navbar-brand logo" href="#">MyPolicy<span class="logo-highlight">Vault</span>
                <br/>
                <h6 class="logo-credit">Powered by Agents and Carriers Inc.</h6>
            </a>
   
       
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                    <li class="nav-item">
                        <a class="header-brand-link nav-link active" aria-current="page" href="https://www.agentsandcarriers.com/ServiceTool" target="_new">TOOLS & SERVICES</a>
                    </li>
                    <li class="nav-item">
                        <a class="header-brand-link nav-link" href="https://www.agentsandcarriers.com/contactus" target="_new">CONTACT</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</header>

);}

export default Header