import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css'
import Alert from 'react-bootstrap/Alert';
const ErrorMsg =()=>{
return(
       
    <div class="container">      
        <div >
        <Alert  variant="alert alert-danger">
        Unable to find the record.
        </Alert>
        </div>
      </div>    

);}

export default ErrorMsg