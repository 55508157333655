import React from "react";
import {
  NumberFormat,
  ThousandSeparator,
  formatDate,
  formatHours,
} from "../../../Common/Utils";

const ViewShoppingMall = ({ details }) => {
  console.log("details shopping", details);

  return (
    <>
      <div
        className="offcanvas offcanvas-end"
        tabIndex="-1"
        id="salon-view"
        style={{ width: "80%" }}
        aria-labelledby="offcanvasRightLabel"
      >
        <div className="offcanvas-header activity-status-table brand-offcanvas-header">
          <h5 id="offcanvasRightLabel">Shopping Center Information</h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <div className="activity-cust-data">
            <h6 className="mb-3">Details</h6>

            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link brand-tab active`}
                  id="build-info"
                  data-bs-toggle="tab"
                  data-bs-target="#building-info-details"
                  type="button"
                  role="tab"
                  aria-controls="building-info-details"
                  aria-selected="true"
                >
                  General Information
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link brand-tab`}
                  id="geo-location1"
                  data-bs-toggle="tab"
                  data-bs-target="#annual-info"
                  type="button"
                  role="tab"
                  aria-controls="annual-info"
                  aria-selected="false"
                >
                  Property Information
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link brand-tab`}
                  id="geo-location1"
                  data-bs-toggle="tab"
                  data-bs-target="#general-liability"
                  type="button"
                  role="tab"
                  aria-controls="general-liability"
                  aria-selected="false"
                >
                  General Liability Information
                </button>
              </li>
            </ul>
            {details && (
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade active show"
                  id="building-info-details"
                  role="tabpanel"
                  aria-labelledby="build-info"
                >
                  <div className="activity-status-table">
                    <div className="table-responsive">
                      <table className="table table-striped table table-bordered">
                        <thead></thead>
                        <tbody id="body-render">
                          <tr>
                            <td>First Name</td>
                            <td>{details?.customerInfo?.firstName}</td>
                          </tr>
                          <tr>
                            <td>Last Name</td>
                            <td>{details?.customerInfo?.lastName}</td>
                          </tr>
                          <tr>
                            <td>Business Name</td>
                            <td>{details?.customerInfo?.businessName}</td>
                          </tr>
                          <tr>
                            <td>DBA</td>
                            <td>{details?.customerInfo?.dbaname}</td>
                          </tr>
                          <tr>
                            <td>Hours of Operation</td>
                            <td>
                              {details?.shoppingMallInfo?.hoursOfOperation &&
                                formatHours(
                                  details?.shoppingMallInfo?.hoursOfOperation
                                )}
                            </td>
                          </tr>
                          <tr>
                            <td>Work Number</td>
                            <td>{details?.customerInfo?.workNum}</td>
                          </tr>
                          <tr>
                            <td>Mobile Number</td>
                            <td>{details?.customerInfo?.mobileNum}</td>
                          </tr>
                          <tr>
                            <td>Location Address</td>
                            <td>{details?.customerInfo?.locationAddress}</td>
                          </tr>
                          <tr>
                            <td>Mailing Address</td>
                            <td>{details?.customerInfo?.mailingAddress}</td>
                          </tr>
                          <tr>
                            <td>Email Address</td>
                            <td>{details?.customerInfo?.email}</td>
                          </tr>
                          <tr>
                            <td>Effective Date</td>
                            <td>
                              {details?.shoppingMallInfo?.effectiveDate &&
                                formatDate(
                                  details?.shoppingMallInfo?.effectiveDate
                                )}
                            </td>
                          </tr>

                          <tr>
                            <td>Coverage</td>
                            <td>
                              {details?.shoppingMallInfo?.coverage?.map(
                                (type, index) => {
                                  return (
                                    <span
                                      key={index}
                                      className="badge rounded-pill bg-info text-light me-1"
                                    >
                                      {type}
                                    </span>
                                  );
                                }
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>Business Type</td>
                            <td>
                              {details?.shoppingMallInfo?.typeOfBusiness?.map(
                                (type, index) => {
                                  return (
                                    <span
                                      key={index}
                                      className="badge rounded-pill bg-info text-light me-1"
                                    >
                                      {type}
                                    </span>
                                  );
                                }
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div
                  className={`tab-pane fade`}
                  id="annual-info"
                  role="tabpanel"
                  aria-labelledby="build-info"
                >
                  <div className="activity-status-table">
                    <div className="table-responsive">
                      <table className="table table-striped table table-bordered">
                        <thead></thead>
                        <tbody id="body-render">
                          <tr>
                            <td>Year of Construction</td>
                            <td>
                              {details?.shoppingMallInfo?.yearofConstruction}
                            </td>
                          </tr>
                          <tr>
                            <td>Building Value?</td>
                            <td>
                              <NumberFormat
                                value={details?.shoppingMallInfo?.buildingValue}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Year Built</td>
                            <td>{details?.shoppingMallInfo?.yearOfBuilt}</td>
                          </tr>
                          <tr>
                            <td>Is Bank Involved?</td>
                            <td>
                              {details?.shoppingMallInfo?.licensedStatus === 1
                                ? "Yes"
                                : "No"}
                            </td>
                          </tr>
                          <tr>
                            <td>Bank Value Limit</td>
                            <td>
                              <NumberFormat
                                value={
                                  details?.shoppingMallInfo?.bankValueLimit
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Who Are The Tenants?</td>
                            <td>{details?.shoppingMallInfo?.abouttenant}</td>
                          </tr>
                          <tr>
                            <td>Sq. Ft.</td>
                            <td>
                              <ThousandSeparator
                                value={details?.shoppingMallInfo?.sqftspace}
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>Update Year?</td>
                            <td>
                              {details?.shoppingMallInfo?.propUpdatedYear}
                            </td>
                          </tr>
                          <tr>
                            <td>Last Roof updated</td>
                            <td>
                              {details?.shoppingMallInfo?.lastRoofUpdate &&
                                formatDate(
                                  details?.shoppingMallInfo?.lastRoofUpdate
                                )}
                            </td>
                          </tr>
                          <tr>
                            <td>Last Wiring updated</td>
                            <td>
                              {details?.shoppingMallInfo?.lastWiringUpdate &&
                                formatDate(
                                  details?.shoppingMallInfo?.lastWiringUpdate
                                )}
                            </td>
                          </tr>
                          <tr>
                            <td>Last Plumbing updated</td>
                            <td>
                              {details?.shoppingMallInfo?.lastPlumbingUpdate &&
                                formatDate(
                                  details?.shoppingMallInfo?.lastPlumbingUpdate
                                )}
                            </td>
                          </tr>
                          <tr>
                            <td>Last Heating updated</td>
                            <td>
                              {details?.shoppingMallInfo?.lastHeating &&
                                formatDate(
                                  details?.shoppingMallInfo?.lastHeating
                                )}
                            </td>
                          </tr>

                          <tr>
                            <td>BPP Value(Fixture, furniture)?</td>
                            <td>
                              <NumberFormat
                                value={details?.shoppingMallInfo?.bppValue}
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>Shopping Mall Type?</td>
                            <td>
                              {details?.shoppingMallInfo?.shoppingMallType === 1
                                ? "Strip Mall"
                                : "Enclosed"}
                            </td>
                          </tr>
                          <tr>
                            <td>Any Escrow?</td>
                            <td>
                              {details?.shoppingMallInfo?.isEscrow === 1
                                ? "Yes"
                                : "No"}
                            </td>
                          </tr>

                          <tr>
                            <td>Bank Details?</td>
                            <td>{details?.shoppingMallInfo?.bankDetail}</td>
                          </tr>

                          <tr>
                            <td>Any Additional Insured?</td>
                            <td>
                              {details?.shoppingMallInfo
                                ?.anyAdditionalInsure === 1
                                ? "Yes"
                                : "No"}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Any Manager Or Maintenance Person Available?
                            </td>
                            <td>
                              {details?.shoppingMallInfo
                                ?.isManagerAvailableOnprop === 1
                                ? "Yes"
                                : "No"}
                            </td>
                          </tr>
                          <tr>
                            <td>Does Maintenance Person Live In Property?</td>
                            <td>
                              {details?.shoppingMallInfo?.isPersonLiveOnProp ===
                              1
                                ? "Yes"
                                : "No"}
                            </td>
                          </tr>
                          <tr>
                            <td>Any Claim in past 5 years</td>
                            <td>
                              {details?.shoppingMallInfo?.anyClaim === 1
                                ? "Yes"
                                : "No"}
                            </td>
                          </tr>
                          <tr>
                            <td>Claim Date</td>
                            <td>
                              {details?.shoppingMallInfo?.claimDate &&
                                formatDate(
                                  details?.shoppingMallInfo?.claimDate
                                )}
                            </td>
                          </tr>
                          <tr>
                            <td>Claim Type</td>
                            <td>{details?.shoppingMallInfo?.claimType}</td>
                          </tr>

                          <tr>
                            <td>Loss Run (Year)</td>
                            <td>{details?.shoppingMallInfo?.lossRunYear}</td>
                          </tr>

                          <tr>
                            <td>Prior Losses</td>
                            <td>
                              <NumberFormat
                                value={details?.shoppingMallInfo?.priorLoss}
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>Wiring Type?</td>
                            <td>
                              {details?.shoppingMallInfo?.wireType?.map(
                                (type, index) => {
                                  return (
                                    <span
                                      key={index}
                                      className="badge rounded-pill bg-info text-light me-1"
                                    >
                                      {type}
                                    </span>
                                  );
                                }
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>Construction Type?</td>
                            <td>
                              {details?.shoppingMallInfo?.constructionType?.map(
                                (type, index) => {
                                  return (
                                    <span
                                      key={index}
                                      className="badge rounded-pill bg-info text-light me-1"
                                    >
                                      {type}
                                    </span>
                                  );
                                }
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div
                  className={`tab-pane fade`}
                  id="general-liability"
                  role="tabpanel"
                  aria-labelledby="build-info"
                >
                  <div className="activity-status-table">
                    <div className="table-responsive">
                      <table className="table table-striped table table-bordered">
                        <thead></thead>
                        <tbody id="body-render">
                          <tr>
                            <td>Total Revenue?</td>
                            <td>
                              <NumberFormat
                                value={details?.shoppingMallInfo?.totalRevenue}
                              />
                            </td>
                          </tr>
                          
                          <tr>
                            <td>Parking Sq. Ft.?</td>
                            <td>
                              <ThousandSeparator
                                value={details?.shoppingMallInfo?.parkingSqFt}
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>What Is The Rent Roll?</td>
                            <td><NumberFormat
                                value={details?.shoppingMallInfo?.rentoll}
                              /></td>
                          </tr>
                          <tr>
                            <td>Total Payroll</td>
                            <td>
                              <NumberFormat
                                value={details?.shoppingMallInfo?.totalPayroll}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>FEIN Number</td>
                            <td>{details?.shoppingMallInfo?.feinNumber}</td>
                          </tr>
                          <tr>
                            <td>No. of Employees</td>
                            <td>
                              <ThousandSeparator
                                value={details?.shoppingMallInfo?.noOfEmployee}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewShoppingMall;
