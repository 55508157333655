import React, { useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { ThreeDots } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { default as axios } from "../../config/axios-config";
import "./../../portal-assets/styles.css";
import WelcomeModal from "./Modal";
import ProductDetails from "./ProductDetails";

const DEVURL = process.env.REACT_APP_ANC_API + "/api/Products";

const Products = () => {
  let allData = useSelector((state) => state.someData);
  const queryParams = new URLSearchParams(useLocation().search);
  const agencyId = queryParams.get("id");
  const userData = useSelector((state) => state.auth.user);
  const [productLists, setProductLists] = useState(null);
  const [profileDetails, setProDetails] = useState(null);
  const [activated, setActivated] = useState({});
  const [policyActivated, setPolicyActivated] = useState(false);
  const [homeActived, setHomeActivated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [welcomeModal, setWelcomeModal] = useState(false);
  const buttonStyles = (type) => {
    if (type) {
      return { cursor: "none" };
    } else {
      return { cursor: "pointer" };
    }
  };
  const policyButtonStyles = {
    cursor: policyActivated ? "none" : "pointer",
  };
  const homeButtonStyles = {
    cursor: homeActived ? "none" : "pointer",
  };

  const handleActivated = (product) => {
    let data = {
      AgencyId: profileDetails.id,
      AgencyName: profileDetails.agencyName,
      FirstName: profileDetails.firstName,
      LastName: profileDetails.lastName ?? "",
      Email: profileDetails.email,
      ProductName: product.productName,
      ProductId: product.productId,
      ProductActivation: true,
    };
    axios
      .post(`${DEVURL}/Activate`, data)
      .then((response) => {
        if (response.status === 200) {
          setTimeout(function () {
            let activated_ids = {};
            let products = productLists.map((item) => {
              if (product.productName == item.productName) {
                item.activated = true;
              }
              return item;
            });
            setProductLists(products);
          }, 1000);
          handleNotifications("Successfully activated " + product.productName + " Product");
        }
      })
      .catch((error) => {
        if (error?.response?.data?.status === 404) {
        } else {
        }
      });
  };

  const handleNotifications = (msg = "Success", type = "success") => {
    if (type === "error") {
      toast.error(msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
        progress: undefined,
      });
    } else if (type === "success") {
      toast.success(msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  const getProfileData = (data) => {
    if (allData?.data?.agencyInfo) {
      data = allData?.data?.agencyInfo;
      setProDetails(data);
    }
    let id = localStorage.getItem("AgencyId");
    axios
      .get(`${DEVURL}/getAgencyById/${id}`)
      .then((response) => {
        if (response.status === 200) {
          let data = response.data;
          setProDetails(data);
        }
      })
      .catch((error) => {
        if (error?.response?.data?.status === 404) {
        } else {
        }
      });
  };

  const getProductData = () => {
    setLoading(true);
    let AgencyId = localStorage.getItem("AgencyId");
    let AId = agencyId ?? AgencyId;
    const url = userData.agentId ? `${DEVURL}/GetProduct/${userData.id}/${userData.agentId}` : `${DEVURL}/GetProduct/${userData.id}`;
    axios
      .get(url)
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          let product = response.data;
          product = product.map((item) => {
            item.activated = item.isUseStatus;
            return item;
          });
          setProductLists(product);
        }
      })
      .catch((error) => {
        setLoading(false);
        handleNotifications("Something is wrong with get products! ", "error");
        if (error?.response?.data?.status === 404) {
        } else {
        }
      });
  };
  const handleDetailsClick = (data) => {
    setSelectedProduct(data);
  };

  useEffect(() => {
    getProductData();
    setTimeout(function () {
      getProfileData();
    }, 1000);
  }, []);
  useEffect(() => {
    if (agencyId) {
      setWelcomeModal(true);
    }
  }, []);

  const loader = (type = "3dot") => {
    if (type === "3dot") {
      return (
        <ThreeDots
          height="40"
          width="80"
          color="#4fa94d"
          ariaLabel="bars-loading"
          wrapperStyle={{ justifyContent: "center", paddingBottom: "10px" }}
          wrapperClass=""
          visible={true}
        />
      );
    } else {
      return (
        <Spinner animation="border" role="status" style={{ "margin-right": "20px" }}>
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      );
    }
  };
  return (
    <>
      <div className="col py-3">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        {/* Same as */}
        <ToastContainer />

        <div className="content-panel">
          <div className="panel-title">
            <h4>Products</h4>
          </div>
          <div className="card" id="myTabContent">
            <div className="pt-2 pb-2">
              {loading && (
                <div className="col-md-12">
                  <div className="product-card text-center">{loader()}</div>
                </div>
              )}
              {!productLists && (
                <div className="col-md-12">
                  <div className="product-card text-center">Product not found</div>
                </div>
              )}
              {productLists && (
                <div className="row">
                  {productLists?.map((product, index) => (
                    <div className="col-md-6 col-lg-4" key={index}>
                      <div className="product-card h-100 ">
                        <div className="product-card-content h-100 position-relative alert-secondary">
                          <div className="prod-icon">
                            <i className="bi bi-gear"></i>
                          </div>
                          <div className="prod-content pb-5 mb-2">
                            <h4>{product.productName}</h4>
                            <p style={{ "padding-bottom": "10px" }}>{product.productDesc}</p>
                            <div className="position-absolute bottom-0">
                              <button
                                style={buttonStyles(product.activated)}
                                data-bs-toggle="offcanvas"
                                data-bs-target="#customerDetail"
                                disabled={product.activated}
                                className={`btn p-2 ${product.activated == 1 ? "btn-success" : product.activated == 2 ? "btn-warning" : "btn-primary"}`}
                                onClick={() => handleDetailsClick(product)}
                              >
                                {" "}
                                {product.activated == 1 ? "Activated" : product.activated == 2 ? "Expired" : "Try Now Free"}
                              </button>
                              &nbsp;
                              <div>
                                <a
                                  className="btn btn-primary p-2 my-2"
                                  target="_blank"
                                  href="https://www.youtube.com/playlist?list=PL9OmvZCstRqRWQRG2RFcGTmYyOHZPR2_a"
                                  rel="noreferrer"
                                >
                                  SEE HOW IT WORKS
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <ProductDetails product={selectedProduct} profileDetails={profileDetails} getUpdateProduct={() => getProductData()}></ProductDetails>
      <WelcomeModal showModal={welcomeModal} closeModal={setWelcomeModal}></WelcomeModal>
    </>
  );
};
export default Products;
