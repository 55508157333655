import React from "react";
import EditPrimaryHealthcare from "./EditPrimaryHealthcare";
import EditDependentHealthcare from "./EditDependentHealthcare";

const EditHealthcare = ({ selectedHealthcare }) => {
  const handleCancelClick = () => {
    selectedHealthcare = null;
  };

  return (
    <>
      <div className="offcanvas offcanvas-end" tabIndex="-1" id="editHealthcare" style={{ width: "80%" }} aria-labelledby="offcanvasRightLabel">
        <div className="offcanvas-header activity-status-table brand-offcanvas-header">
          <h5 id="offcanvasRightLabel">Edit Healthcare Customer</h5>
          <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" onClick={handleCancelClick} aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
          <div className="activity-cust-data">
            <EditPrimaryHealthcare selectedHealthcare={selectedHealthcare} />
            <EditDependentHealthcare selectedHealthcare={selectedHealthcare} />
          </div>
        </div>
      </div>
    </>
  );
};

export default EditHealthcare;
