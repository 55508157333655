import { yupResolver } from "@hookform/resolvers/yup";
import { IconArrowNarrowDown, IconArrowsRightLeft, IconFilterSearch, IconHeart, IconMailShare, IconRefresh, IconRotate } from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import { Badge, Button, Col, Container, Modal, Row, Spinner } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { useForm } from "react-hook-form";
import ReactPaginate from "react-paginate";
import ReactStars from "react-rating-stars-component";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";
import { default as axios } from "../../../config/axios-config";
import Footer from "../../ancfooter";
import zIndex from "@mui/material/styles/zIndex";
import useAgencyInfo from "../../../hooks/useAgencyInfo.hook";
// import './../../../../public/assets/css/styles.css'

const validationSchema = yup.object().shape({
    carrier: yup.string().label("Carrier"),
    metal: yup.string().label("Metal"),
    orderBy: yup.string().label("Order By"),
});

localStorage.removeItem("likedItems");

const SearchResult = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const { agencyName, agentName } = useParams();
    const { data: agencyInfo, agencyId, agentId, isValidUrl } = useAgencyInfo(agencyName, agentName);
    const [query] = useSearchParams();
    const [isLoading, setIsLoading] = useState(true);
    const [isPassCodeIsVerify, setPassCodeIsVerify] = useState(false);
    const [jsonData1, setJsonData1] = useState(null);
    const [data, setData] = useState([]);
    const [carriers, setCarriers] = useState([]);
    const [metals, setMetals] = useState([]);
    const [selectedPlans, setSelectedPlan] = useState([]);
    const [show, setShow] = useState(false);
    const [showVerifyPassCodeModal, setShowVerifyPassCodeModal] = useState(true);
    const [showSendMailModal, setShowSendMailModal] = useState(false);
    const [isMailSent, setIsMailSent] = useState(false);
    const initialLikedItems = JSON.parse(localStorage.getItem("likedItems")) || [];
    const [likedItems, setLikedItems] = useState(initialLikedItems);
    const [showDrugModal, setShowDrugModal] = useState(false);
    const [selectedPlanForDrug, setSelectedPlanForDrug] = useState();
    const [showProviderModal, setShowProviderModal] = useState(false);
    const [selectedPlanForProvider, setSelectedPlanForProvider] = useState();

    const [sendMailData, setSendMailData] = useState({
        email: state?.data?.emailAddress,
        note: "",
    });
    const [sendMailDataError, setSendMailDataError] = useState({
        email: null,
        note: null,
    });
    const [singleSelectedPlan, setSingleSelectedPlan] = useState([]);
    const [page, setPage] = useState(0);
    const [filterData, setFilterData] = useState([]);
    const [drugData, setDrugData] = useState();
    const [providerData, setProviderData] = useState();
    const [passCode, setPassCode] = useState("");
    const [error, setError] = useState("");
    const [message, setMessage] = useState("");
    const [loadingForDrug, setLoadingForDrug] = useState(false);

    const noOfRecord = 10;

    const DEVURL = process.env.REACT_APP_ANC_API + "/api/health/getmarketquote";
    const DEVURLSenMAl = process.env.REACT_APP_ANC_API + "/api/health/SelectPlanSendEmail";
    const DEVURLForDrug = process.env.REACT_APP_ANC_API + "/api/health/GetDrugInfo";
    const DEVURLForProvider = process.env.REACT_APP_ANC_API + "/api/health/GetProviders";
    const queryParams = new URLSearchParams(window.location.search);
    // const agencyId = state?.data?.agencyId;
    // const agentId = state?.data?.agentId;
    const newquotelink = agentName == null ? `/quote/${agencyName}` : `/quote/${agencyName}/${agentName}`;
    const showheader = queryParams.get("showheader");
    const agent = state?.data?.id;
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
        setValue,
    } = useForm({
        defaultValues: {
            carrier: "",
            metal: "",
            orderBy: "",
        },
        resolver: yupResolver(validationSchema),
    });
    const {
        register: registerForDrug,
        handleSubmit: handleSubmitForDrug,
        reset: resetForDrug,
        formState: { isValid: isValidForDrug },
    } = useForm({});
    const {
        register: registerForProvider,
        handleSubmit: handleSubmitForProvider,
        reset: resetForProvider,
        formState: { isValid: isValidForProvider },
    } = useForm({});
    console.log("state", state);
    const createParamsForSearchResult = () => {
        let dataPrams = {
            Name: query.get("name") ?? state?.data?.name,
            Gender: query.get("gender") ?? state?.data?.gender,
            Age: "",
            ZipCode: query.get("zip") ?? state?.data?.zipcode ?? state?.data?.zip,
            Income: query.get("income") ?? state?.data?.income,
            DateOfBirth: query.get("dob") ?? state?.data?.dob,
            Email: query.get("emailAddress") ?? state?.data?.email ?? state?.data?.emailAddress,
            Mobile: query.get("mobile") ?? state?.data?.mobile,
            IsConsent: query.get("consent") ?? state?.data?.consent,
            AgencyId: state?.data?.agencyId,
            TobaccoFlag: "No",
            Utilization: "low",
            GeoLocation: JSON.stringify(state?.data?.geolocation),
            AgentId: query.get("agentId") ?? state?.data?.agentId,
            dependents: state?.data?.dependents != null ? state?.data?.dependents : [],
        };

        return dataPrams;
    };

    useEffect(() => {
        if (data.length === 0) {
            // API call is made if data is empty
            getSearchResult(createParamsForSearchResult()).then((response) => {
                setIsLoading(false);
            });
        }
    }, []);

    const getSearchResult = async (dt) => {
        let config = {
            method: "POST",
            maxBodyLength: Infinity,
            url: DEVURL,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            data: JSON.stringify(dt),
        };

        await axios(config)
            .then((response) => {
                setJsonData1(response?.data);
                setData(response?.data?.plans ?? []);
                const temporaryArray = [];
                const metalsArray = [];
                response?.data?.plans?.map((item) => {
                    if (!temporaryArray.includes(item.issuer.name)) {
                        temporaryArray.push(item.issuer.name);
                    }
                    if (!metalsArray.includes(item.metal_level)) {
                        metalsArray.push(item.metal_level);
                    }
                });
                setCarriers(temporaryArray);
                setMetals(metalsArray);
                setFilterData(
                    response?.data?.plans.filter((item, index) => {
                        return (index >= page * noOfRecord) & (index < (page + 1) * noOfRecord);
                    })
                );
            })
            .catch((error) => {
                setData([]);
                setFilterData([]);
                console.log(error);
            });
    };

    useEffect(() => {
        if (data.length > 0) {
            setFilterData(
                data.filter((item, index) => {
                    return (index >= page * noOfRecord) & (index < (page + 1) * noOfRecord);
                })
            );
        }
    }, [data, page]);

    const handleClose = () => {
        setShow(false);
    };
    const handleCloseVerifyModel = () => {
        setShowVerifyPassCodeModal(false);
    };

    const handleCloseEmailSendModal = () => {
        setShowSendMailModal(false);
    };

    const onChangePasscode = (e) => {
        let passcode = e.target.value;
        setPassCode(passcode);
        if (passcode.length === 0) {
            setError("Passcode is a required field");
        } else if (passcode.length > 0 && passcode.length < 6) {
            setError("Passcode must be 6 character length");
        } else if (passcode.length == 6) {
            setError("");
        }
    };

    const onChangeMailData = (e) => {
        let { name, value } = e.target;
        const updateMailData = { ...sendMailData, [name]: value };
        setSendMailData(updateMailData);
    };

    // const onClickPasscodeVerify = async (e) => {
    //   e.preventDefault();
    //   setMessage("");
    //   if (passCode.length === 0 || passCode.trim().length === 0) {
    //     setError("Passcode is a required field");
    //     return;
    //   }
    //   if (passCode && passCode.trim().length > 0 && passCode.trim().length < 6) {
    //     setError("Passcode must be 6 character length");
    //     return;
    //   }
    //   setError("");
    //   if (passCode === jsonData1?.passcode) {
    //     setPassCodeIsVerify(true);
    //     setShowVerifyPassCodeModal(false);
    //   } else {
    //     setError("Passcode not matching");
    //   }
    // };

    const onClickResentPasscode = async (e) => {
        e.preventDefault();
        setMessage("");
        setError("");
        setIsLoading(true);
        setTimeout(() => {
            // axios
            //     .get(`${DEVURL}/ResendPasscode/${agencyId}/${verifyId}`)
            //     .then((response) => {
            //         if (response.status === 200) {
            //             setPassCode("");
            //             setMessage("");
            //             setMessage("An email sent to with secure code to access the personalized health insurance quote tailored to your needs.");
            //         }
            //     })
            //     .catch((error) => {
            //         setError("Failed to get resent code.");
            //     })
            //     .finally(() => {
            //         setIsLoading(false);
            //     });
        }, 1000);
    };

    const handleShow = () => setShow(true);

    const handleFormSubmit = async (data) => {
        if (data.carrier) {
            const filteredData = jsonData1.plans.filter((item) => item.issuer.name === data.carrier);
            setFilterData(filteredData);
        } else {
            setFilterData(jsonData1.plans);
        }
        setPage(0);
    };
    const resetForm = () => {
        // reset();
        // setFilterData(jsonData1.plans);
        setPage(0);
        getSearchResult(createParamsForSearchResult()).then((response) => {
            setIsLoading(false);
        });
    };

    const selectPlan = (e, value) => {
        const isChecked = e.target.checked;
        if (isChecked && selectedPlans.length >= 3) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "You can not compare more than 3 items",
            });
            e.target.checked = false;
        } else if (isChecked) {
            setSelectedPlan([...selectedPlans, value]);
        } else {
            setSelectedPlan(selectedPlans.filter((item) => item.id !== value.id));
        }
    };
    const handleLowestPremium = () => {
        const tempFilterData = jsonData1.plans;
        tempFilterData.sort((a, b) => parseFloat(a.premium_w_credit) - parseFloat(b.premium_w_credit));
        setFilterData(
            tempFilterData.filter((item, index) => {
                return (index >= page * noOfRecord) & (index < (page + 1) * noOfRecord);
            })
        );
        setPage(0);
    };

    const handleLowestDeductible = () => {
        const tempFilterData = jsonData1.plans;
        tempFilterData.sort((a, b) => parseFloat(a.deductibles[0].amount) - parseFloat(b.deductibles[0].amount));
        setFilterData(
            tempFilterData.filter((item, index) => {
                return (index >= page * noOfRecord) & (index < (page + 1) * noOfRecord);
            })
        );
        setPage(0);
    };

    const handlePageChanged = (event) => {
        setPage(event.selected);
    };

    const openSendMailPopUp = (data) => {
        setShowSendMailModal(true);
        if (data) {
            let seltedPlan = singleSelectedPlan.filter((item) => item.id === data.id);
            if (seltedPlan.length === 0) {
                setSingleSelectedPlan([data]);
            }
        } else {
            setShow(false);
            setSingleSelectedPlan(selectedPlans);
        }
    };

    const onClickSendMailData = async (e, text) => {
        if (sendMailData.email === "") {
            setSendMailDataError({
                ...sendMailDataError,
                email: "Email is required",
            });
            return;
        } else {
            setSendMailDataError({ ...sendMailDataError, email: null });
        }

        let selectedPlansHlt = singleSelectedPlan?.map((item) => {
            return {
                planurl: item?.benefits_url,
                name: item?.name,
                premium: item?.premium,
                premium_w_credit: item?.premium_w_credit,
                Tax_credit: item?.ehb_premium,
                issure: item?.issuer?.name,
                planid: item?.id,
                metal: item?.metal_level,
            };
        });

        let dt = {
            emailIds: sendMailData?.email,
            Custnote: sendMailData?.note,
            SenderName: state?.data?.name,
            MobileNum: state?.data?.mobile,
            AgecyId: agencyId,
            selectedPlans: selectedPlansHlt,
        };
        let config = {
            method: "POST",
            maxBodyLength: Infinity,
            url: DEVURLSenMAl,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            data: JSON.stringify(dt),
        };
        await axios(config)
            .then((res) => {
                console.log("res", res);
                setShowSendMailModal(false);
                if ((res.status === 202 && text !== "like") || (res.status === 200 && text !== "like") || (res.statusText === "Accepted" && text !== "like")) {
                    setIsMailSent(true);
                }
            })
            .catch((error) => {
                setData([]);
                setFilterData([]);
                console.log(error);
                setIsMailSent(false);
            });
    };

    const onClickSendLikedData = async (e, text, selectedPlans) => {
        if (sendMailData.email === "") {
            setSendMailDataError({
                ...sendMailDataError,
                email: "Email is required",
            });
            return;
        } else {
            setSendMailDataError({ ...sendMailDataError, email: null });
        }

        let selectedPlansHlt = selectedPlans?.map((item) => {
            return {
                planurl: item?.benefits_url,
                name: item?.name,
                premium: item?.premium,
                premium_w_credit: item?.premium_w_credit,
                Tax_credit: item?.ehb_premium,
                issure: item?.issuer?.name,
                planid: item?.id,
                metal: item?.metal_level,
            };
        });

        let dt = {
            emailIds: sendMailData?.email,
            Custnote: "",
            SenderName: state?.data?.name,
            MobileNum: state?.data?.mobile,
            AgecyId: agencyId,
            selectedPlans: selectedPlansHlt,
        };
        let config = {
            method: "POST",
            maxBodyLength: Infinity,
            url: DEVURLSenMAl,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            data: JSON.stringify(dt),
        };
        await axios(config)
            .then((res) => {
                if ((res.status === 202 && text !== "like") || (res.status === 200 && text !== "like") || (res.statusText === "Accepted" && text !== "like")) {
                    setIsMailSent(true);
                }
            })
            .catch((error) => {
                setData([]);
                setFilterData([]);
                console.log(error);
                setIsMailSent(false);
            });
    };

    const handleLikeButton = async (e, data, text) => {
        const itemId = data.id;
        if (data) {
            let selectedPlan = singleSelectedPlan.filter((item) => item.id === data.id);
            if (selectedPlan.length === 0) {
                await setSingleSelectedPlan((prevSelectedPlan) => [data]);
            }
        } else {
            await setSingleSelectedPlan(selectedPlans);
        }

        if (likedItems?.includes(itemId)) {
            setLikedItems(likedItems?.filter((id) => id !== itemId));
        } else {
            setLikedItems([...likedItems, itemId]);
        }

        setSingleSelectedPlan((prevSelectedPlan) => {
            onClickSendLikedData(e, text, prevSelectedPlan);
            return prevSelectedPlan;
        });
    };

    if (isMailSent === true) {
        Swal.fire({
            title: "Thanks your mail has been sent",
            icon: "success",
            showCancelButton: true,
            confirmButtonText: "Ok",
            cancelButtonText: "Close",
        });
        setIsMailSent(false);
    }

    useEffect(() => {
        const navbarHeight = document.querySelector(".navbar").offsetHeight;
        const contentContainer = document.querySelector(".main-container");
        contentContainer.style.paddingTop = `${navbarHeight}px`;
    }, []);

    useEffect(() => {
        localStorage.setItem("likedItems", JSON.stringify(likedItems));
    }, [likedItems]);

    const getStartApplicationURL = (item) =>
        agentName == null
            ? "/healthform/" +
            agencyName +
            "?planId=" +
            item.id +
            "&" +
            Object.keys(state?.data)
                .map((key) => `${key}=${encodeURIComponent(state?.data[key])}`)
                .join("&")
            : "/healthform/" +
            agencyName +
            "/" +
            agentName +
            "?planId=" +
            item.id +
            "&" +
            Object.keys(state?.data)
                .map((key) => `${key}=${encodeURIComponent(state?.data[key])}`)
                .join("&");

    console.log("filterData", filterData);
    console.log("selectedPlanForProvider", selectedPlanForProvider);

    const handleDrugSearch = (formData) => {
        setLoadingForDrug(true);
        console.log("formData", formData);
        if (Boolean(formData.drugName != null)) {
            axios
                // .get(`${DEVURLForDrug}/ibuprofen/20069TX0100051`)
                .get(`${DEVURLForDrug}/${formData.drugName}/${selectedPlanForDrug.id}`)
                .then((response) => {
                    if (response.status === 200) {
                        console.log("response", response);
                        setDrugData(response.data.coverage);
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    setLoadingForDrug(false);
                });
        }
    };
    const handleProviderSearch = (formData) => {
        setLoadingForDrug(true);
        console.log("formData", formData);
        if (Boolean(formData.providerName != null)) {
            axios
                .get(`${DEVURLForProvider}/${selectedPlanForProvider.id}/${formData.providerName}/${state?.data?.zip}`)
                .then((response) => {
                    if (response.status === 200) {
                        console.log("response", response);
                        setProviderData(response.data.providerList);
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    setLoadingForDrug(false);
                });
        }
    };
    const handleCloseDrugModal = () => {
        setShowDrugModal(false);
        setDrugData(null);
        resetForDrug();
    };
    const handleCloseProviderModal = () => {
        setShowProviderModal(false);
        setProviderData(null);
        resetForProvider();
    };

    return (
        <>
            <section className="bg-light  pt-6 pb-6">
                <div className="d-flex justify-content-between navbar filter-top-bar position-fixed w-100 top-0" style={{ zIndex: 3 }}>
                    <form className="tab-wizard wizard-circle wizard needs-validation w-60" onSubmit={handleSubmit(handleFormSubmit)}>
                        <div className="health-form row m-0 custom-border-radius1">
                            <div className="col-md-3 mt-2 mt-md-0 action">
                                <select className="form-select " {...register("carrier")}>
                                    <option value="">Filter By Carrier</option>

                                    {carriers.map((item, index) => (
                                        <option key={index} value={item}>
                                            {item}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="col-md-9 mt-2 mt-md-0 action">
                                <div className="d-flex justify-content-between justify-content-md-start">
                                    <span class="d-inline-block" tabindex="0" data-bs-toggle="tooltip" title="Filter">
                                        <button className="btn btn-primary btn-sm rounded" type="submit" disabled={filterData.length === 0}>
                                            <IconFilterSearch />
                                        </button>
                                    </span>
                                    &nbsp;
                                    <span class="d-inline-block" tabindex="0" data-bs-toggle="tooltip" title="Reset">
                                        <button type="reset" onClick={resetForm} disabled={filterData.length === 0} className="btn btn-warning btn-sm">
                                            <IconRefresh color="white" />
                                        </button>
                                    </span>
                                    &nbsp;
                                    <span class="d-inline-block" tabindex="0" data-bs-toggle="tooltip" title="Compare">
                                        <button type="reset" onClick={handleShow} className="btn btn-success btn-sm" disabled={selectedPlans.length < 2 || 3 < selectedPlans.length}>
                                            <IconArrowsRightLeft />
                                        </button>
                                    </span>
                                    &nbsp;
                                    <span class="d-inline-block" tabindex="0" data-bs-toggle="tooltip" title="Start New Quote">
                                        <a href={newquotelink} className="btn btn-danger btn-sm">
                                            <IconRotate />
                                        </a>
                                    </span>
                                    &nbsp;
                                    <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                                        <input type="radio" className="btn-check" name="btnradio" id="btnradio1" autocomplete="off" disabled={filterData.length === 0} checked />

                                        <label
                                            class="btn btn-outline-primary btn-filter-tab btn-sm"
                                            onClick={handleLowestDeductible}
                                            for="btnradio1"
                                            tabindex="0"
                                            data-bs-toggle="tooltip"
                                            title="Low Deductible"
                                        >
                                            <IconArrowNarrowDown />
                                        </label>

                                        <input type="radio" className="btn-check" name="btnradio" onClick={handleLowestPremium} disabled={filterData.length === 0} id="btnradio2" autocomplete="off" />
                                        <label className="btn btn-outline-primary btn-filter-tab btn-sm" for="btnradio2" tabindex="0" data-bs-toggle="tooltip" title="Low Premium">
                                            <IconArrowNarrowDown />
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <h1 className="pe-4">{`${agencyName}${agencyInfo?.phone ? `/${agencyInfo.phone}` : ""}`}</h1>
                </div>
                <div className="main-container container pb-5 min-vw-95 min-height-89vh w-100">
                    <div className="align-items-end d-grid mt-5"></div>
                    {filterData?.length === 0 && isLoading && (
                        <div className="card my-1 custom-border-radius">
                            <div className="row col-md-12 m-0  custom-border-radius">
                                <div className="col-md-12 hero text-warning text-center">
                                    {isLoading && (
                                        <button className="btn btn-primary" type="button" disabled>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Loading...
                                        </button>
                                    )}
                                    {/* {!isLoading && "No Health Insurance Quote Available !"} */}
                                </div>
                            </div>
                        </div>
                    )}
                    {filterData?.length === 0 && !isLoading && (
                        <div className="text-center">
                            <h1 className="text-primary" style={{ fontWeight: 900 }}>
                                Thank you for your interest
                            </h1>
                            <h3>
                                One of our healthcare insurance experts will contact you from 817-710-8738. They will introduce themselves and schedule a time to discuss your specific needs and
                                tailor options accordingly.
                            </h3>
                            <p>
                                Our team of professionally licensed health and life agents brings a collective experience of 45 years across all categories of insurance products, encompassing
                                group benefits, Medicare options, individual and family plans, as well as specialty and supplemental policies. As a full-service independent broker, we are
                                dedicated to matching you with the most suitable plans tailored to your needs and budget.
                            </p>
                        </div>
                    )}

                    {filterData &&
                        filterData?.map((item) => (
                            <div className="mt-3 bg-light" key={item.id}>
                                <div className="row">
                                    <div className="col-lg-8 resp-m-end">
                                        <div className="plan-wrap">
                                            <div className="plan-border-bottom">
                                                <div className="row">
                                                    <div className="col-md-6 col-lg-6">
                                                        <h6 className="main-label">{item.issuer.name}</h6>
                                                        <h5 className="main-plan-title">{item.name}</h5>
                                                        <div className="main-plan-badges">
                                                            <span className="plan-badge">{item.metal_level}</span>
                                                            &nbsp;
                                                            <span className="plan-badge">{item.type}</span>
                                                            &nbsp;
                                                            <span className="plan-badge">{item.id}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-lg-2">
                                                        <ReactStars count={5} size={20} edit={false} value={item?.quality_rating?.global_rating} activeColor="#ffd700" />
                                                    </div>
                                                    <div className="col-md-6 col-lg-4">
                                                        <div className="form-check">
                                                            <span className="plan-badge">
                                                                <label className="form-check-label text-capitalize" htmlFor="flexCheckDefault">
                                                                    <input className="form-check-input" type="checkbox" onClick={(event) => selectPlan(event, item)} id="check1" name="option1[]" value={item.id} />
                                                                    <label className="form-check-label text-capitalize" htmlFor="flexCheckDefault">
                                                                        Check for Compare
                                                                    </label>
                                                                </label>
                                                            </span>

                                                            <IconMailShare style={{ cursor: "pointer" }} className="mx-2" color="#0d6efd" onClick={() => openSendMailPopUp(item)} />
                                                            {/*<span className="btn btn-sm btn-primary mx-2 "*/}
                                                            {/*      onClick={() => openSendMailPopUp(item)}>*/}
                                                            {/*    <i className="icon icofont-send-mail"*/}
                                                            {/*       style={{"font-size": "28px"}}></i>*/}
                                                            {/*</span>*/}
                                                        </div>
                                                        <div className="w-100 my-2">
                                                            <button
                                                                onClick={(e) => {
                                                                    handleLikeButton(e, item, "like");
                                                                }}
                                                                className="btn btn-dark w-100"
                                                                disabled={likedItems?.includes(item.id)}
                                                            >
                                                                Click here to choose the plan
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="plan-border-bottom">
                                                <div className="row">
                                                    <div className="col-md-6 col-lg-7">
                                                        <h6 className="main-label">Deductible</h6>
                                                        <h5 className="main-plan-title"> ${item.deductibles[0].amount}</h5>
                                                        <div className="main-plan-badges">
                                                            <span className="plan-badge">Family Cost: {item.deductibles[0].family_cost}</span>
                                                            <br />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-lg-5">
                                                        <h6 className="main-label">Out-of Pocket Maximize</h6>
                                                        <h5 className="main-plan-title">${item.tiered_deductibles[0].amount}</h5>
                                                        <div className="main-plan-badges">
                                                            <span className="plan-badge">Family Cost: {item.tiered_deductibles[0].family_cost}</span>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="plan-border-bottom">
                                                <div className="row">
                                                    <h6 className="main-label">Copayments / Coinsurance</h6>
                                                    <div className="row">
                                                        {item.benefits.map((benefit, index) => (
                                                            <div className="col-md-6 col-lg-3">
                                                                <p className="main-plan-badges">
                                                                    <b>{benefit.name}</b>
                                                                    <br />
                                                                    {benefit.cost_sharings[0].display_string}
                                                                </p>
                                                                <p className="main-plan-badges"></p>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 resp-m-start">
                                        <div className="plan-price-wrap">
                                            <div className="d-md-flex flex-dir-column">
                                                <div className="plan-price-wrap-details">
                                                    <p>Estimated Monthly Premium</p>
                                                    <h3>${item.premium_w_credit}</h3>
                                                    <p>Including a ${item.ehb_premium} tax credit</p>
                                                    <p>was ${Math.round(item.premium_w_credit + item.ehb_premium)}</p>
                                                </div>
                                                <br />

                                                <div className="plan-price-wrap-btn mt-5">
                                                    <div className="d-grid gap-2">
                                                        <a target="_blank" href={item.benefits_url} type="button" className="btn btn-primary" rel="noreferrer">
                                                            Plan Details
                                                        </a>
                                                        <button
                                                            type="button"
                                                            class="btn btn-light"
                                                            onClick={() => {
                                                                setSelectedPlanForProvider(item);
                                                                setShowProviderModal(true);
                                                            }}
                                                        >
                                                            Find Doctors & facilities
                                                        </button>
                                                        <button
                                                            type="button"
                                                            class="btn btn-info"
                                                            onClick={() => {
                                                                setSelectedPlanForDrug(item);
                                                                setShowDrugModal(true);
                                                            }}
                                                        >
                                                            See if your prescription drug is covered
                                                        </button>
                                                        <a href={getStartApplicationURL(item)} type="button" className="btn btn-success">
                                                            Start the application
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}

                    {selectedPlans.length ? (
                        <div className="align-items-end d-grid mt-2">
                            <ReactPaginate
                                previousLabel={"prev"}
                                nextLabel={"next"}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={Math.ceil(data.length / noOfRecord)}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={handlePageChanged}
                                containerClassName={"pagination pagination-sm right"}
                                subContainerClassName={"pages pagination "}
                                activeClassName={"active"}
                                forcePage={page}
                            />
                        </div>
                    ) : null}
                </div>
            </section>
            <Footer />

            <Modal size={selectedPlans.length == 2 ? "lg" : "xl"} show={show} onHide={handleClose}>
                <Modal.Header closeButton className="bg-cu">
                    <Modal.Title>Comparison of {selectedPlans.length} Plans </Modal.Title>
                    <IconMailShare color="#0d6efd" style={{ cursor: "pointer" }} className="mx-2" onClick={() => openSendMailPopUp(null)} />
                </Modal.Header>
                <Modal.Body className="p-1">
                    <div className="row col-md-12 m-0  custom-border-radius">
                        {selectedPlans?.map((item, index) => (
                            <>
                                <div className={selectedPlans.length == 2 ? "col-md-6 absulate" : "col-md-4 absulate"} key={index}>
                                    <div className="plan-price-wrap-details">
                                        <p>Estimated Monthly Premium</p>
                                        <h3>${item.premium_w_credit}</h3>
                                        <p>Including a ${item.ehb_premium} tax credit</p>
                                        <p>was ${Math.round(item.premium_w_credit + item.ehb_premium)}</p>
                                    </div>
                                    <section className="features" id="features">
                                        <h6 className="main-label">{item.issuer.name}</h6>
                                        <h5 className="main-plan-title">{item.name}</h5>
                                        <div className="main-plan-badges">
                                            <span className="plan-badge">{item.metal_level}</span>
                                            <span className="plan-badge">{item.type}</span>
                                            <span className="plan-badge">{item.id}</span>
                                        </div>
                                    </section>

                                    <section className="features" id="features">
                                        <div className="feature">
                                            <h6 className="main-label">Deductible</h6>
                                            <h5 className="main-plan-title"> ${item.deductibles[0].amount}</h5>
                                            <div className="main-plan-badges">
                                                <span className="plan-badge">Family Cost: {item.deductibles[0].family_cost}</span>
                                                <br />
                                            </div>
                                        </div>
                                    </section>
                                    <section className="features" id="features">
                                        <div className="col-md-7 col-lg-7">
                                            <h6 className="main-label">Out-of Pocket Maximize</h6>
                                            <h5 className="main-plan-title">${item.tiered_deductibles[0].amount}</h5>
                                            <div className="main-plan-badges">
                                                <span className="plan-badge">Family Cost: {item.tiered_deductibles[0].family_cost}</span>
                                                <br />
                                            </div>
                                        </div>
                                    </section>
                                    <section className="features" id="features">
                                        <h6 className="main-label">Copayments / Coinsurance</h6>
                                        <div className="row">
                                            {item.benefits.map((benefit, index) => (
                                                <span>
                                                    <p className="main-plan-badges">
                                                        <b>{benefit.name}</b>
                                                        <br />
                                                        {benefit.cost_sharings[0].display_string}
                                                    </p>
                                                </span>
                                            ))}
                                        </div>
                                    </section>
                                    <div className="bottom">
                                        <a
                                            target="_blank"
                                            href={"https://mypolicyvault.agentsandcarriers.com/healthform?id=" + agencyId + "&planId=" + item.id}
                                            type="button"
                                            className="btn btn-success"
                                            rel="noreferrer"
                                        >
                                            Start the application
                                        </a>
                                    </div>
                                </div>
                            </>
                        ))}
                    </div>
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
            </Modal>
            <Modal show={showDrugModal} onHide={handleCloseDrugModal} size={"lg"}>
                <Modal.Header closeButton>
                    <Modal.Title>Selected Plan: {selectedPlanForDrug?.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmitForDrug(handleDrugSearch)}>
                        <div className="row">
                            <div className="col-8">
                                <input type="text" class="form-control" placeholder="Enter Drug Name" name="drugName" {...registerForDrug("drugName", { required: true })} />
                            </div>
                            <div className="col-4">
                                <button type="submit" class="btn btn-primary btn-success w-100" disabled={!isValidForDrug}>
                                    Search
                                </button>
                            </div>
                        </div>
                    </form>
                    <Container>
                        <div class="row row-cols-1 row-cols-md-2 g-4 mt-2">
                            {loadingForDrug ? (
                                <div className="d-flex justify-content-center my-4 w-100">
                                    <Spinner animation="border" role="status" variant="secondary">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                </div>
                            ) : drugData?.length ? (
                                drugData.map((drug) => (
                                    <div class="col">
                                        <div class="card h-100">
                                            <Card.Body>
                                                <Card.Title>{drug.name}</Card.Title>
                                                <Card.Text>{drug.full_name}</Card.Text>
                                                <Badge bg="secondary">{drug.coverage}</Badge>
                                            </Card.Body>
                                        </div>
                                    </div>
                                ))
                            ) : (drugData != null) & !drugData?.length ? (
                                <div className="text-center py-4">No drugs found</div>
                            ) : null}
                        </div>
                    </Container>
                </Modal.Body>
            </Modal>
            <Modal show={showProviderModal} onHide={handleCloseProviderModal} size={"lg"}>
                <Modal.Header closeButton>
                    <Modal.Title>Selected Plan: {selectedPlanForProvider?.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmitForProvider(handleProviderSearch)}>
                        <div className="row">
                            <span
                                style={{
                                    color: "grey",
                                    fontSize: "14px",
                                }}
                            >
                                Don't include titles like Dr., Ms., or Mrs.
                            </span>
                            <div className="col-8">
                                <input type="text" class="form-control" placeholder="Enter Provider Name" name="providerName" {...registerForProvider("providerName", { required: true })} />
                            </div>
                            <div className="col-4">
                                <button type="submit" class="btn btn-primary btn-success w-100" disabled={!isValidForProvider}>
                                    Search
                                </button>
                            </div>
                        </div>
                    </form>
                    <Container>
                        <div class="row row-cols-1 row-cols-md-2 g-4 mt-2">
                            {loadingForDrug ? (
                                <div className="d-flex justify-content-center my-4 w-100">
                                    <Spinner animation="border" role="status" variant="secondary">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                </div>
                            ) : providerData?.length ? (
                                providerData.map((provider) => (
                                    <div class="col">
                                        <div class="card h-100">
                                            <Card.Body>
                                                <Card.Title>{provider.provider.name}</Card.Title>
                                                <div>
                                                    <b>Coverage:</b> {provider.coverage}
                                                </div>

                                                {provider?.provider?.specialties.map((speciality) => (
                                                    <Badge bg="secondary">{speciality}</Badge>
                                                ))}
                                                <div className="mt-2">
                                                    <span className="fw-bold">Phone: {provider?.address?.phone}</span>
                                                </div>
                                                <div>{provider?.address?.street1}</div>
                                                <div>
                                                    {provider?.address?.city}, {provider?.address?.state}
                                                </div>
                                            </Card.Body>
                                        </div>
                                    </div>
                                ))
                            ) : (providerData != null) & !providerData?.length ? (
                                <div className="text-center py-4">No provider found</div>
                            ) : null}
                        </div>
                    </Container>
                </Modal.Body>
            </Modal>

            {/* <Modal
        show={showVerifyPassCodeModal}
        onHide={handleCloseVerifyModel}
        backdrop="static"
        size={"lg"}
        keyboard={false}
      >
        <Modal.Body>
          <Card style={{ width: "100%" }}>
            <Card.Img variant="top" />
            <Card.Body>
              <Card.Title>
                <h1>Access Your Health Insurance Quote</h1>
              </Card.Title>
              <Card.Subtitle className="mt-3 mb-3">
                Please enter the passcode :
              </Card.Subtitle>
              <Card.Text>
                <input
                  type="tel"
                  className="form-control"
                  placeholder="6-digit code"
                  minLength={6}
                  maxLength={6}
                  value={passCode}
                  onChange={onChangePasscode}
                />
                {error && <p className="text-danger mt-3">{error}</p>}
              </Card.Text>
              <Card.Text style={{ float: "right" }}>
                <Button variant="primary" onClick={onClickPasscodeVerify}>
                  <div className="button-body-for-loader">
                    <span>Verify Code</span>
                  </div>
                </Button>
                <Button
                  variant="warning"
                  className=" mx-2"
                  onClick={handleCloseVerifyModel}
                >
                  <div className="button-body-for-loader mx-2">
                    <span>Close</span>
                  </div>
                </Button>
              </Card.Text>
              <Card.Text></Card.Text>
              {message && <div className="text-success">{message}</div>}
            </Card.Body>
          </Card>
        </Modal.Body>
      </Modal> */}

            <Modal
                show={showSendMailModal}
                onHide={handleCloseEmailSendModal}
            // backdrop="static"
            // size={'lg'}
            // keyboard={false}
            >
                <Modal.Body>
                    <Card style={{ width: "100%" }}>
                        <Card.Img variant="top" />
                        <Card.Body>
                            <Card.Title>
                                <h1>Send Email</h1>
                            </Card.Title>
                            <Card.Subtitle className="mt-3 mb-3">Please enter email :</Card.Subtitle>
                            <Card.Text>
                                <input type="email" name="email" required={true} className="form-control" placeholder="example@gmail.com" value={sendMailData.email} onChange={onChangeMailData} />
                                {sendMailDataError.email && <p className="text-danger mt-3">{sendMailDataError.email}</p>}
                            </Card.Text>
                            <Card.Subtitle className="mt-3 mb-3">Please enter note :</Card.Subtitle>
                            <Card.Text>
                                <textarea name="note" className="form-control" onChange={onChangeMailData} value={sendMailData.note}></textarea>
                            </Card.Text>
                            <Card.Text style={{ float: "right" }}>
                                <Button variant="primary" onClick={onClickSendMailData}>
                                    <div className="button-body-for-loader">
                                        <span>Send Mail</span>
                                    </div>
                                </Button>
                                <Button variant="warning" className=" mx-2" onClick={handleCloseEmailSendModal}>
                                    <div className="button-body-for-loader mx-2">
                                        <span>Close</span>
                                    </div>
                                </Button>
                            </Card.Text>
                            <Card.Text></Card.Text>
                            {message && <div className="text-success">{message}</div>}
                        </Card.Body>
                    </Card>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default SearchResult;
