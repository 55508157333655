import React, { useEffect, useState } from "react";
import { downloadExcel } from "react-export-table-to-excel";
import { ThreeDots } from "react-loader-spinner";
import { default as axios } from "../../../config/axios-config";
// import EditHealthcare from "./healthcare/EditHealthcare";
import { IconCirclePlus, IconFileExport, IconRefresh, IconSearch } from "@tabler/icons-react";
import { useRef } from "react";
import { Link } from "react-router-dom";
import CustomerDetailsGasStation from "./CustomerDetailsGasStation";
import CustomerEditGasStation from "./CustomerEditGasStation";
import { useSelector } from "react-redux";
import { showDateFormat } from "../../helper/Helper";

const DEVURL = process.env.REACT_APP_ANC_API + "/api/GasStation";
const ACCORDDEVURL = process.env.REACT_APP_ANC_API + "/api/Acord/GenerateGasstationAcord";

const GasStation = () => {
  const userData = useSelector((state) => state.auth.user);
  const agencyId = userData.id;
  const [loader, setLoader] = useState(false);
  const [searchItem, setSearchItem] = useState("");
  const [restaurantList, setRestauranList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [nextPage, setNextPage] = useState(0);
  const [previousPage, setPreviousPage] = useState(1);
  const [nextButtonEnable, setNextButtonEnable] = useState(false);
  const [pervButtonEnable, setPrevButtonEnable] = useState(true);
  const [loader_excel, setLoader_excel] = useState(false);
  const [selectedGasStation, setSelectedGasStation] = useState({});
  const gasTableRef = useRef(null);

  const getRestaurantsByAgencyId = async (type = null) => {
    setLoader(true);
    let currentP = 1;
    if (type) {
      if (type === "next") {
        currentP = nextPage;
      } else {
        currentP = previousPage <= 1 ? 1 : previousPage;
      }
    }
    let filter = searchItem ?? null;
    if (type === "reset") {
      setSearchItem("");
    }
    // https://dev-ancapi.azurewebsites.net/api/GasStation/GetGasStationInfoByAgency?agencyId=1&page=1&pageSize=10&searchText=
    await axios
      .get(`${DEVURL}/GetGasStationInfoByAgency?agencyId=${agencyId}&page=${currentP}&pageSize=${perPage}&searchText=${searchItem}&agentId=${userData.agentId}`)
      .then((response) => {
        if (response.status === 200) {
          setRestauranList(response.data);
          setCurrentPage(currentP);
          setNextPage(currentP + 1);
          setPreviousPage(currentP - 1);
          if (response.data.length === 0) {
            setNextButtonEnable(true);
            setPrevButtonEnable(false);
          }
          if (previousPage >= 1) {
            setPrevButtonEnable(false);
          }
          setLoader(false);
        }
      })
      .catch((error) => {
        setLoader(false);
      });
  };

  useEffect(() => {
    if (agencyId) {
      getRestaurantsByAgencyId();
    }
  }, []);

  const handleDetailClick = (item) => {
    // clearLocalStorage()
    setSelectedGasStation(item);
  };

  const handleEditClick = (data) => {
    // clearLocalStorage()
    setSelectedGasStation(data);
  };

  const handleDownloadExcel = () => {
    if (!gasTableRef.current) {
      setLoader_excel(false);
      return;
    }
    setLoader_excel(true);
    // Access the table and retrieve the visible rows
    const tableRows = gasTableRef.current.querySelectorAll("tbody tr");
    // Prepare data for export with specific columns
    const exportDataSpecificColumns = Array.from(tableRows).map((row) => {
      const columns = row.querySelectorAll("td");
      return {
        FirstName: columns[1].innerText,
        LastName: columns[2].innerText,
        BusinessName: columns[3].innerText,
        LocationAddress: columns[4].innerText,
        EffectiveDate: columns[5].innerText,
        ContactNumber: columns[0].innerText,
      };
    });
    // Use the prepared data for export
    downloadExcel({
      fileName: "GasStation_Table",
      sheet: "gasStation_table",
      tablePayload: {
        header: ["FirstName", "LastName", "BusinessName", "LocationAddress", "EffectiveDate", "ContactNumber"],
        body: exportDataSpecificColumns,
      },
    });
    setLoader_excel(false);
  };

  return (
    <>
      <div className="col py-3">
        <div className="content-panel">
          <div className="panel-title mb-5 ">
            <h4>Gas Station Vault</h4>
          </div>
          <div className="tab-content" id="myTabContent">
            <div className="filter-box mt-3 mb-2">
              <div className="filter-box-content">
                <div className="row align-items-center">
                  <div className="col-lg-5">
                    <div className="form-floating">
                      <input
                        type="text"
                        size={500}
                        className="form-control"
                        value={searchItem}
                        onChange={(event) => setSearchItem(event.target.value)}
                        id="floatingInput"
                        placeholder=""
                      />
                      <label htmlFor="floatingInput">Search by first name, last name, email or business name</label>
                    </div>
                  </div>
                  <div className="col-lg-1">
                    <div className="d-grid d-md-flex justify-content-md-left" style={{ height: "50px" }}>
                      <button className="btn btn-primary" type="button" onClick={() => getRestaurantsByAgencyId()}>
                        <div className="button-body-for-loader">
                          <IconSearch />
                          {loader && <ThreeDots color="#ffdb58" height={30} width={40} />}
                        </div>
                      </button>
                      <button className="mx-2 btn btn-warning" type="button" onClick={() => getRestaurantsByAgencyId("reset")}>
                        <div className="button-body-for-loader">
                          <IconRefresh />
                        </div>
                      </button>

                      <button
                        className="mx-2 btn btn-success"
                        type="button"
                        disabled={(!restaurantList?.items?.length > 0 && !loader) || loader_excel || loader}
                        onClick={handleDownloadExcel}
                      >
                        <div className="button-body-for-loader">
                          <IconFileExport />
                          {loader_excel && <ThreeDots color="#ffdb58" height={30} width={40} />}
                        </div>
                      </button>
                      <Link to={userData.agentId == null ? `/gasstationform/${userData.agencyName}` : `/gasstationform/${userData.agencyName}/${userData.userId}`} target="_blank">
                        <button className="mx-2 h-100 btn btn-primary" type="button">
                          <div className="button-body-for-loader">
                            <IconCirclePlus />
                          </div>
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="data-box ">
              <div className="activity-status-table">
                <div className="table-responsive">
                  <table ref={gasTableRef} className="table activity-status-table table-striped">
                    <thead className="text-nowrap">
                      <tr>
                        <th scope="col">Contact Person</th>
                        <th scope="col">First name</th>
                        <th scope="col">Last name</th>
                        <th scope="col">Business Name</th>
                        <th scope="col">Contact</th>
                        <th scope="col">Location Address</th>
                        <th scope="col">Created Date</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody id="body-render">
                      {restaurantList?.items?.map((item, index) => (
                        <tr key={index}>
                          <td>
                            <div className="cust-info">
                              <p> {item?.businessCustomer?.email} </p>
                              <p>{item?.businessCustomer?.mobileNum} </p>
                              <p>Work: {item?.businessCustomer?.workNum} </p>
                            </div>
                          </td>
                          <td className="cust-info">
                            <h6> {item?.businessCustomer?.firstName}</h6>
                          </td>
                          <td className="cust-info">
                            <h6> {item?.businessCustomer?.lastName}</h6>
                          </td>
                          <td className="cust-info">
                            <p>{item?.businessCustomer?.businessName}</p>
                          </td>

                          <td>
                            <p>{item?.businessCustomer?.mobileNum}</p>
                          </td>
                          <td>
                            <p>{item?.businessCustomer?.locationAddress}</p>
                          </td>
                          <td>
                            <div className="last-received-date-time">
                              <p>{showDateFormat(item?.businessCustomer?.startDate)}</p>
                            </div>
                          </td>
                          <td>
                            <a data-bs-toggle="offcanvas" onClick={() => handleDetailClick(item)} data-bs-target="#gas-station" href="#">
                              <i className="bi bi-file-earmark"></i>
                            </a>
                            &nbsp;
                            <a data-bs-toggle="offcanvas" onClick={() => handleEditClick(item)} data-bs-target="#gas-station-Edit" href="#">
                              <i className="bi bi-pencil-square"></i>
                            </a>
                            &nbsp;
                            <Link to={`${ACCORDDEVURL}/${agencyId}/${item?.gasStation?.gasstationId}`}>
                              <i className="bi bi-download"></i>
                            </Link>
                          </td>
                        </tr>
                      ))}
                      {restaurantList?.items?.length === 0 && !loader && (
                        <tr>
                          <td colSpan={8} className="text-center text-warning">
                            Data Not found
                          </td>
                        </tr>
                      )}
                      {loader && (
                        <td colSpan={7} className="text-center">
                          <ThreeDots
                            height="40"
                            width="80"
                            color="#4fa94d"
                            ariaLabel="bars-loading"
                            wrapperStyle={{
                              justifyContent: "center",
                              paddingBottom: "10px",
                            }}
                            wrapperClass=""
                            visible={true}
                          />
                        </td>
                      )}
                    </tbody>
                  </table>

                  <nav aria-label="Page navigation example" className="pull-right">
                    <ul className="pagination">
                      <li className="page-item">
                        <button className="page-link" onClick={() => getRestaurantsByAgencyId("prev")} disabled={pervButtonEnable}>
                          Previous
                        </button>
                      </li>
                      <li className="page-item">
                        <button className="page-link" onClick={() => getRestaurantsByAgencyId("next")} disabled={nextButtonEnable}>
                          Next
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CustomerDetailsGasStation details={selectedGasStation} />
      <CustomerEditGasStation details={selectedGasStation} />
      {/*<EditHealthcare selectedHealthcare={{}}/>*/}
    </>
  );
};
export default GasStation;
