import React, { useEffect } from "react";
import Spinner from "react-bootstrap/Spinner";
import { default as axios } from "../../../config/axios-config";
import Agencyheader from "../../Agencyheader";
import ErrorMsg from "../../ErrorMsg";
import Footer from "../../ancfooter";
import DependentForm from "./Dependent";
import PrimaryDetails from "./PrimaryDetail";

const DEVURL = process.env.REACT_APP_ANC_API + "/api/health/";

const ViewHealthApp = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const appid = queryParams.get("appid");
  const url = DEVURL + "applicationInfo/" + appid;

  const [repos, setRepos] = React.useState({});

  const [isdataexisit, setdataexisit] = React.useState(true);
  const [isdepexisit, setdepexisit] = React.useState(true);
  const [isdataloading, setDataLoading] = React.useState(true);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      setDataLoading(true);
      const response = await axios.get(url);
      if (response !== null) {
        setRepos(response.data);
        setDataLoading(false);
      }
      if (response.data.primaryCust == null) {
        setdataexisit(false);
      }
      if (response.data.dependentRequest == null) {
        setdepexisit(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {isdataloading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <>
          <Agencyheader />
          <div className="container">
            <div className="main-wrap">
              <div className="wizard-content">
                <h2 align="Center">HealthCare Application</h2>
                {isdataexisit ? (
                  <>
                    <PrimaryDetails primaryInfo={repos.primaryCust} />
                  </>
                ) : (
                  <ErrorMsg />
                )}
                {isdepexisit ? (
                  <>
                    <DependentForm dependentInfo={repos.dependentRequest} />
                  </>
                ) : (
                  <div></div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
      <Footer />
    </>
  );
};

export default ViewHealthApp;
