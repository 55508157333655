import React from "react";
import {
  NumberFormat,
  ThousandSeparator,
  formatDate,
  formatHours,
} from "../../../Common/Utils";

const GStoreView = ({ details }) => {
  console.log("details GSTORe", details);

  return (
    <>
      <div
        className="offcanvas offcanvas-end"
        tabIndex="-1"
        id="salon-view"
        style={{ width: "80%" }}
        aria-labelledby="offcanvasRightLabel"
      >
        <div className="offcanvas-header activity-status-table brand-offcanvas-header">
          <h5 id="offcanvasRightLabel">Gennaral Store Information</h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <div className="activity-cust-data">
            <h6 className="mb-3">Details</h6>

            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link brand-tab active`}
                  id="build-info"
                  data-bs-toggle="tab"
                  data-bs-target="#building-info-details"
                  type="button"
                  role="tab"
                  aria-controls="building-info-details"
                  aria-selected="true"
                >
                  General Information
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link brand-tab`}
                  id="geo-location1"
                  data-bs-toggle="tab"
                  data-bs-target="#annual-info"
                  type="button"
                  role="tab"
                  aria-controls="annual-info"
                  aria-selected="false"
                >
                  Property Information
                </button>
              </li>
            </ul>
            {details && (
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade active show"
                  id="building-info-details"
                  role="tabpanel"
                  aria-labelledby="build-info"
                >
                  <div className="activity-status-table">
                    <div className="table-responsive">
                      <table className="table table-striped table table-bordered">
                        <thead></thead>
                        <tbody id="body-render">
                          <tr>
                            <td>First Name</td>
                            <td>{details?.customerInfo?.firstName}</td>
                          </tr>
                          <tr>
                            <td>Last Name</td>
                            <td>{details?.customerInfo?.lastName}</td>
                          </tr>
                          <tr>
                            <td>Business Name</td>
                            <td>{details?.customerInfo?.businessName}</td>
                          </tr>
                          <tr>
                            <td>DBA</td>
                            <td>{details?.customerInfo?.dbaname}</td>
                          </tr>
                          <tr>
                            <td>Hours of Operation</td>
                            <td>
                              {details?.generalStoreInfo?.hoursOfOperation &&
                                formatHours(
                                  details?.generalStoreInfo?.hoursOfOperation
                                )}
                            </td>
                          </tr>
                          <tr>
                            <td>Work Number</td>
                            <td>{details?.customerInfo?.workNum}</td>
                          </tr>
                          <tr>
                            <td>Mobile Number</td>
                            <td>{details?.customerInfo?.mobileNum}</td>
                          </tr>
                          <tr>
                            <td>Location Address</td>
                            <td>{details?.customerInfo?.locationAddress}</td>
                          </tr>
                          <tr>
                            <td>Mailing Address</td>
                            <td>{details?.customerInfo?.mailingAddress}</td>
                          </tr>
                          <tr>
                            <td>Email Address</td>
                            <td>{details?.customerInfo?.email}</td>
                          </tr>
                          <tr>
                            <td>Effective Date</td>
                            <td>
                              {details?.generalStoreInfo?.effectiveDate &&
                                formatDate(
                                  details?.generalStoreInfo?.effectiveDate
                                )}
                            </td>
                          </tr>

                          <tr>
                            <td>Coverage</td>
                            <td>
                              {details?.generalStoreInfo?.coverage?.map(
                                (type, index) => {
                                  return (
                                    <span
                                      key={index}
                                      className="badge rounded-pill bg-info text-light me-1"
                                    >
                                      {type}
                                    </span>
                                  );
                                }
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div
                  className={`tab-pane fade`}
                  id="annual-info"
                  role="tabpanel"
                  aria-labelledby="build-info"
                >
                  <div className="activity-status-table">
                    <div className="table-responsive">
                      <table className="table table-striped table table-bordered">
                        <thead></thead>
                        <tbody id="body-render">
                          <tr>
                            <td>Sq. Ft.</td>
                            <td>
                              <ThousandSeparator
                                value={details?.generalStoreInfo?.sqftspace}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Sales</td>
                            <td>
                              <NumberFormat
                                value={details?.generalStoreInfo?.salesAmt}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Tenant</td>
                            <td>{details?.generalStoreInfo?.tenantInfo}</td>
                          </tr>
                          <tr>
                            <td>BPP Value</td>
                            <td>
                              <NumberFormat
                                value={details?.generalStoreInfo?.bppValue}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Sign Value</td>
                            <td>
                              <NumberFormat
                                value={details?.generalStoreInfo?.signValue}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Money & Securities Limit</td>
                            <td>
                              <NumberFormat
                                value={details?.generalStoreInfo?.moneyLimit}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>If Owner, Building Value</td>
                            <td>
                              <NumberFormat
                                value={details?.generalStoreInfo?.buildingValue}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Any Other Requirement From Landlord?</td>
                            <td>
                              {details?.generalStoreInfo?.anyLandlordRequirment}
                            </td>
                          </tr>
                          <tr>
                            <td>Franchise?</td>
                            <td>{details?.generalStoreInfo?.franchiseInfo}</td>
                          </tr>
                          <tr>
                            <td>Any Other Electronics?</td>
                            <td>
                              {details?.generalStoreInfo?.electronicseInfo}
                            </td>
                          </tr>

                          <tr>
                            <td>Protective Safeguard</td>
                            <td>
                              {details?.generalStoreInfo?.protectiveGaurd?.map(
                                (type, index) => {
                                  return (
                                    <span
                                      key={index}
                                      className="badge rounded-pill bg-info text-light me-1"
                                    >
                                      {type}
                                    </span>
                                  );
                                }
                              )}
                            </td>
                          </tr>

                          <tr>
                            <td>Store Type?</td>
                            <td>
                              {details?.generalStoreInfo?.storeType?.map(
                                (type, index) => {
                                  return (
                                    <span
                                      key={index}
                                      className="badge rounded-pill bg-info text-light me-1"
                                    >
                                      {type}
                                    </span>
                                  );
                                }
                              )}
                            </td>
                          </tr>

                          <tr>
                            <td>Any Claim in past 5 years</td>
                            <td>
                              {details?.generalStoreInfo?.anyClaim === 1
                                ? "Yes"
                                : "No"}
                            </td>
                          </tr>
                          <tr>
                            <td>Loss Run (Years)</td>
                            <td>{details?.generalStoreInfo?.lossRunYear}</td>
                          </tr>
                          <tr>
                            <td>Any Additional Insured?</td>
                            <td>
                              {details?.generalStoreInfo?.licensedStatus === 1
                                ? "Yes"
                                : "No"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default GStoreView;
