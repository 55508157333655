import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
// import * as yup from "yup";
// import { yupResolver } from "@hookform/resolvers/yup";

const DEVURL = process.env.REACT_APP_ANC_API;

const url = DEVURL + "/api/tank/";

const Step3 = ({ stepHandler }) => {
  const {
    register,
    handleSubmit,
    // formState: { errors },
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      //fName: "",
      //fAddress: "",
      tankGround: [],
      contents1: "",
      contents2: "",
      contents3: "",
      contents4: "",
      contents5: "",
      installed1: "",
      installed2: "",
      installed3: "",
      installed4: "",
      installed5: "",
      capacity1: "",
      capacity2: "",
      capacity3: "",
      capacity4: "",
      capacity5: "",
      steelSingle: [],
      steelDouble: [],
      stipSingle: [],
      stipDouble: [],
      fiberglassSingle: [],
      fiberglassDouble: [],
      cladSteelSingle: [],
      cladSteelDouble: [],
      fiberglassLined: [],
      dateLined1: "",
      dateLined2: "",
      dateLined3: "",
      dateLined4: "",
      dateLined5: "",
      pNone: [],
      pCathodic: [],
      pPainted: [],
      pConcrete: [],
      pEarthen: [],
      dNone: [],
      dGague: [],
      dStatistical: [],
      dDipstick: [],
      dInterstitial: [],
      dVisual: [],
      dMonitoring: [],
      dSeperator: [],
      dVapor: [],
      dSpill: [],
      dDate1: "",
      dDate2: "",
      dDate3: "",
      dDate4: "",
      dDate5: "",
      piping1: "",
      piping2: "",
      piping3: "",
      piping4: "",
      piping5: "",
      pipingAbove1: "",
      pipingAbove2: "",
      pipingAbove3: "",
      pipingAbove4: "",
      pipingAbove5: "",
      cSSingle: [],
      cSDouble: [],
      cSFiberglass: [],
      cDFiberglass: [],
      tpNone: [],
      tpCathodic: [],
      pOther: [],
      lDNone: [],
      lDElectronic: [],
      lDInterstitial: [],
      lDOther: [],
      dMSuction: [],
      dMPressure: [],
      dMGravity: [],
      insuredsName: "",
      insuredsAddress: "",
      insuredsName2: "",
      insuredsAddress2: "",
    },
    // resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    const formData = JSON.parse(localStorage.getItem("step3"));
    if (formData) {
      //setValue("fName", formData.fName);
      //setValue("fAddress", formData.fAddress);
      setValue("tankGround", formData.tankGround);
      setValue("contents1", formData.contents1);
      setValue("contents2", formData.contents2);
      setValue("contents3", formData.contents3);
      setValue("contents4", formData.contents4);
      setValue("contents5", formData.contents5);
      setValue("installed1", formData.installed1);
      setValue("installed2", formData.installed2);
      setValue("installed3", formData.installed3);
      setValue("installed4", formData.installed4);
      setValue("installed5", formData.installed5);
      setValue("capacity1", formData.capacity1);
      setValue("capacity2", formData.capacity2);
      setValue("capacity3", formData.capacity3);
      setValue("capacity4", formData.capacity4);
      setValue("capacity5", formData.capacity5);
      setValue("steelSingle", formData.steelSingle);
      setValue("steelDouble", formData.steelDouble);
      setValue("stipSingle", formData.stipSingle);
      setValue("stipDouble", formData.stipDouble);
      setValue("fiberglassSingle", formData.fiberglassSingle);
      setValue("fiberglassDouble", formData.fiberglassDouble);
      setValue("cladSteelSingle", formData.cladSteelSingle);
      setValue("cladSteelDouble", formData.cladSteelDouble);
      setValue("fiberglassLined", formData.fiberglassLined);
      setValue("dateLined1", formData.dateLined1);
      setValue("dateLined2", formData.dateLined2);
      setValue("dateLined3", formData.dateLined3);
      setValue("dateLined4", formData.dateLined4);
      setValue("dateLined5", formData.dateLined5);
      setValue("pNone", formData.pNone);
      setValue("pCathodic", formData.pCathodic);
      setValue("pPainted", formData.pPainted);
      setValue("pConcrete", formData.pConcrete);
      setValue("pEarthen", formData.pEarthen);
      setValue("dNone", formData.dNone);
      setValue("dGague", formData.dGague);
      setValue("dStatistical", formData.dStatistical);
      setValue("dDipstick", formData.dDipstick);
      setValue("dInterstitial", formData.dInterstitial);
      setValue("dVisual", formData.dVisual);
      setValue("dMonitoring", formData.dMonitoring);
      setValue("dSeperator", formData.dSeperator);
      setValue("dVapor", formData.dVapor);
      setValue("dSpill", formData.dSpill);
      setValue("dDate1", formData.dDate1);
      setValue("dDate2", formData.dDate2);
      setValue("dDate3", formData.dDate3);
      setValue("dDate4", formData.dDate4);
      setValue("dDate5", formData.dDate5);
      setValue("piping1", formData.piping1);
      setValue("piping2", formData.piping2);
      setValue("piping3", formData.piping3);
      setValue("piping4", formData.piping4);
      setValue("piping5", formData.piping5);
      setValue("pipingAbove1", formData.pipingAbove1);
      setValue("pipingAbove2", formData.pipingAbove2);
      setValue("pipingAbove3", formData.pipingAbove3);
      setValue("pipingAbove4", formData.pipingAbove4);
      setValue("pipingAbove5", formData.pipingAbove5);
      setValue("cSSingle", formData.cSSingle);
      setValue("cSDouble", formData.cSDouble);
      setValue("cSFiberglass", formData.cSFiberglass);
      setValue("cDFiberglass", formData.cDFiberglass);
      setValue("tpNone", formData.tpNone);
      setValue("tpCathodic", formData.tpCathodic);
      setValue("pOther", formData.pOther);
      setValue("lDNone", formData.lDNone);
      setValue("lDElectronic", formData.lDElectronic);
      setValue("lDInterstitial", formData.lDInterstitial);
      setValue("lDOther", formData.lDOther);
      setValue("dMSuction", formData.dMSuction);
      setValue("dMPressure", formData.dMPressure);
      setValue("dMGravity", formData.dMGravity);
      setValue("insuredsName", formData.insuredsName);
      setValue("insuredsAddress", formData.insuredsAddress);
      setValue("insuredsName2", formData.insuredsName2);
      setValue("insuredsAddress2", formData.insuredsAddress2);
    }
  }, []);

  const handleFormSubmit = (data) => {
    // console.log(data);
    localStorage.setItem("step3", JSON.stringify(data));

    const step1 = localStorage.getItem("step1");
    const step2 = localStorage.getItem("step2");
    const step3 = localStorage.getItem("step3");
    const step2Data = JSON.parse(step2);
    const step3data = { ...data };
    // console.log(step3);
    const request = {
      CurrentEPAFlag_q1: step2Data.q1,
      DoYouOwnTankFalg_q2: step2Data.q2,
      StorageTankClaim_q3: step2Data.q3,
      ClaimFalg_q4: step2Data.q4,
      EnvirnomentalFlag_q5: step2Data.q5,
      ContaminatFalg_q6: step2Data.q6,
      TankUpgradeFlag_q7: step2Data.q7,
      InsuredFlag_q8: step2Data.q8,
      tankGround: step3data.tankGround,
      contents1: step3data.contents1,
      contents2: step3data.contents2,
      contents3: step3data.contents3,
      contents4: step3data.contents4,
      contents5: step3data.contents5,
      installed1: step3data.installed1,
      installed2: step3data.installed2,
      installed3: step3data.installed3,
      installed4: step3data.installed4,
      installed5: step3data.installed5,
      capacity1: step3data.capacity1,
      capacity2: step3data.capacity2,
      capacity3: step3data.capacity3,
      capacity4: step3data.capacity4,
      capacity5: step3data.capacity5,
      steelSingle: step3data.steelSingle,
      steelDouble: step3data.steelDouble,
      stipSingle: step3data.steelSingle,
      stipDouble: step3data.stipDouble,
      fiberglassSingle: step3data.fiberglassSingle,
      fiberglassDouble: step3data.fiberglassDouble,
      cladSteelSingle: step3data.cladSteelSingle,
      cladSteelDouble: step3data.cladSteelDouble,
      fiberglassLined: step3data.fiberglassLined,
      dateLined1: step3data.dateLined1,
      dateLined2: step3data.dateLined2,
      dateLined3: step3data.dateLined3,
      dateLined4: step3data.dateLined4,
      dateLined5: step3data.dateLined5,
      pNone: step3data.pNone,
      pCathodic: step3data.pCathodic,
      pPainted: step3data.pPainted,
      pConcrete: step3data.pConcrete,
      pEarthen: step3data.pEarthen,
      dNone: step3data.dNone,
      dGague: step3data.dGague,
      dStatistical: step3data.dStatistical,
      dDipstick: step3data.dDipstick,
      dInterstitial: step3data.dInterstitial,
      dVisual: step3data.dVisual,
      dMonitoring: step3data.dMonitoring,
      dSeperator: step3data.dSeperator,
      dVapor: step3data.dVapor,
      dSpill: step3data.dSpill,
      dDate1: step3data.dDate1,
      dDate2: step3data.dDate2,
      dDate3: step3data.dDate3,
      dDate4: step3data.dDate4,
      dDate5: step3data.dDate5,
      piping1: step3data.piping1,
      piping2: step3data.piping2,
      piping3: step3data.piping3,
      piping4: step3data.piping4,
      piping5: step3data.piping5,
      pipingAbove1: step3data.pipingAbove1,
      pipingAbove2: step3data.pipingAbove2,
      pipingAbove3: step3data.pipingAbove3,
      pipingAbove4: step3data.pipingAbove4,
      pipingAbove5: step3data.pipingAbove5,
      cSSingle: step3data.cSSingle,
      cSDouble: step3data.cSDouble,
      cSFiberglass: step3data.cSFiberglass,
      cDFiberglass: step3data.cDFiberglass,
      pOther: step3data.pOther,
      lDNone: step3data.IDNone,
      lDElectronic: step3data.dInterstitial,
      lDInterstitial: step3data.dInterstitial,
      lDOther: [],
      dMSuction: step3data.dMSuction,
      dMPressure: step3data.dMPressure,
      dMGravity: step3data.dMGravity,
      insuredsName: step3data.insuredsName,
      insuredsAddress: step3data.insuredsAddress,
      insuredsName2: step3data.insuredsName2,
      insuredsAddress2: step3data.insuredsAddress2,
    };

    //	console.log(JSON.stringify(request));

    //axios.put(url,request)
    //.then(res=>{
    //		console.log(res.data)
    //})

    const allFormDatas = {
      ...JSON.parse(step1),
      ...JSON.parse(step2),
      ...JSON.parse(step3),
    };

    //console.log({allFormDatas});

    // clear all local storage
    localStorage.removeItem("step1");
    localStorage.removeItem("step2");
    localStorage.removeItem("step3");
    //reset();
    stepHandler(4);
  };
  return (
    <>
      <div className="container">
        <div className="main-wrap">
          <div className="wizard-content">
            <form onSubmit={handleSubmit(handleFormSubmit)}>
              <section>
                <p align="center" className="brand-label">
                  <h2>Tank Insurance Application</h2>
                </p>
                <h4 class="wrap-title mb-3">Tank Information</h4>

                <div class="container">
                  <div class="row">
                    <div class="col-md-6 col-lg-12">
                      <div class="mb-3">
                        <label class="brand-label d-grid">
                          Tank# Check if above ground
                        </label>
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            id="tank1Check"
                            value="1"
                            {...register("tankGround")}
                          />
                          <label class="form-check-label" for="tank1Check">
                            1
                          </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            id="tank2Check"
                            value="2"
                            {...register("tankGround")}
                          />
                          <label class="form-check-label" for="tank2Check">
                            2
                          </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            id="tank3Check"
                            value="3"
                            {...register("tankGround")}
                          />
                          <label class="form-check-label" for="tank3Check">
                            3
                          </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            id="tank4Check"
                            value="4"
                            {...register("tankGround")}
                          />
                          <label class="form-check-label" for="tank4Check">
                            4
                          </label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            id="tank5Check"
                            value="5"
                            {...register("tankGround")}
                          />
                          <label class="form-check-label" for="tank5Check">
                            5
                          </label>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <div class="brand-table mb-3">
                        <div class="table-responsive">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col" class="w-20">
                                  Tank #
                                </th>
                                <th scope="col">1</th>
                                <th scope="col">2</th>
                                <th scope="col">3</th>
                                <th scope="col">4</th>
                                <th scope="col">5</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th scope="row">Contents</th>
                                <td>
                                  <input
                                    type="text"
                                    class="form-control"
                                    {...register("contents1")}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    class="form-control"
                                    {...register("contents2")}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    class="form-control"
                                    {...register("contents3")}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    class="form-control"
                                    {...register("contents4")}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    class="form-control"
                                    {...register("contents5")}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Date Installed</th>
                                <td>
                                  <input
                                    type="date"
                                    max="2099-12-31"
                                    class="form-control"
                                    {...register("installed1")}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="date"
                                    max="2099-12-31"
                                    class="form-control"
                                    {...register("installed2")}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="date"
                                    max="2099-12-31"
                                    class="form-control"
                                    {...register("installed3")}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="date"
                                    max="2099-12-31"
                                    class="form-control"
                                    {...register("installed4")}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="date"
                                    max="2099-12-31"
                                    class="form-control"
                                    {...register("installed5")}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Capacity (Gallons)</th>
                                <td>
                                  <input
                                    type="text"
                                    class="form-control"
                                    {...register("capacity1")}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    class="form-control"
                                    {...register("capacity2")}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    class="form-control"
                                    {...register("capacity3")}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    class="form-control"
                                    {...register("capacity4")}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    class="form-control"
                                    {...register("capacity5")}
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <h5 class="wrap-title">Tank Construction</h5>
                    <div class="col-md-12">
                      <div class="brand-table mb-3">
                        <div class="table-responsive">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col" class="w-20">
                                  Tank #
                                </th>
                                <th scope="col">1</th>
                                <th scope="col">2</th>
                                <th scope="col">3</th>
                                <th scope="col">4</th>
                                <th scope="col">5</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th scope="row">Steel Single Wall</th>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ssw1"
                                    value="1"
                                    {...register("steelSingle")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ssw2"
                                    value="2"
                                    {...register("steelSingle")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ssw3"
                                    value="3"
                                    {...register("steelSingle")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ssw4"
                                    value="4"
                                    {...register("steelSingle")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ssw5"
                                    value="5"
                                    {...register("steelSingle")}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Steel Double Wall</th>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="sdw1"
                                    value="1"
                                    {...register("steelDouble")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="sdw2"
                                    value="2"
                                    {...register("steelDouble")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="sdw3"
                                    value="3"
                                    {...register("steelDouble")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="sdw4"
                                    value="4"
                                    {...register("steelDouble")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="sdw5"
                                    value="5"
                                    {...register("steelDouble")}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">STIP-3 Single Wall</th>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="s3sw1"
                                    value="1"
                                    {...register("stipSingle")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="s3sw2"
                                    value="2"
                                    {...register("stipSingle")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="s3sw3"
                                    value="3"
                                    {...register("stipSingle")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="s3sw4"
                                    value="4"
                                    {...register("stipSingle")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="s3sw5"
                                    value="5"
                                    {...register("stipSingle")}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">STIP-3 Double Wall</th>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="s3dw1"
                                    value="1"
                                    {...register("stipDouble")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="s3dw2"
                                    value="2"
                                    {...register("stipDouble")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="s3dw3"
                                    value="3"
                                    {...register("stipDouble")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="s3dw4"
                                    value="4"
                                    {...register("stipDouble")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="s3dw5"
                                    value="5"
                                    {...register("stipDouble")}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Fiberglass Single Wall</th>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="fsw1"
                                    value="1"
                                    {...register("fiberglassSingle")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="fsw2"
                                    value="2"
                                    {...register("fiberglassSingle")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="fsw3"
                                    value="3"
                                    {...register("fiberglassSingle")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="fsw4"
                                    value="4"
                                    {...register("fiberglassSingle")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="fsw5"
                                    value="5"
                                    {...register("fiberglassSingle")}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Fiberglass Double Wall</th>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="fdw1"
                                    value="1"
                                    {...register("fiberglassDouble")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="fdw2"
                                    value="2"
                                    {...register("fiberglassDouble")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="fdw3"
                                    value="3"
                                    {...register("fiberglassDouble")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="fdw4"
                                    value="4"
                                    {...register("fiberglassDouble")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="fdw5"
                                    value="5"
                                    {...register("fiberglassDouble")}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">
                                  Fiberglass Clad Steel Single Wall
                                </th>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="fcssw1"
                                    value="1"
                                    {...register("cladSteelSingle")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="fcssw2"
                                    value="2"
                                    {...register("cladSteelSingle")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="fcssw3"
                                    value="3"
                                    {...register("cladSteelSingle")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="fcssw4"
                                    value="4"
                                    {...register("cladSteelSingle")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="fcssw5"
                                    value="5"
                                    {...register("cladSteelSingle")}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Fiberglass Clad Double Wall</th>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="fcdw1"
                                    value="1"
                                    {...register("cladSteelDouble")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="fcdw2"
                                    value="2"
                                    {...register("cladSteelDouble")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="fcdw3"
                                    value="3"
                                    {...register("cladSteelDouble")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="fcdw4"
                                    value="4"
                                    {...register("cladSteelDouble")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="fcdw5"
                                    value="5"
                                    {...register("cladSteelDouble")}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Fiberglass Lined</th>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="fl1"
                                    value="1"
                                    {...register("fiberglassLined")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="fl2"
                                    value="2"
                                    {...register("fiberglassLined")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="fl3"
                                    value="3"
                                    {...register("fiberglassLined")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="fl4"
                                    value="4"
                                    {...register("fiberglassLined")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="fl5"
                                    value="5"
                                    {...register("fiberglassLined")}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Date Lined</th>
                                <td>
                                  <input
                                    class="form-control w-50"
                                    type="date"
                                    max="2099-12-31"
                                    id="dl1"
                                    {...register("dateLined1")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-control w-50"
                                    type="date"
                                    max="2099-12-31"
                                    id="dl2"
                                    {...register("dateLined2")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-control w-50"
                                    type="date"
                                    max="2099-12-31"
                                    id="dl3"
                                    {...register("dateLined3")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-control w-50"
                                    type="date"
                                    max="2099-12-31"
                                    id="dl4"
                                    {...register("dateLined4")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-control w-50"
                                    type="date"
                                    max="2099-12-31"
                                    id="dl5"
                                    {...register("dateLined5")}
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <h5 class="wrap-title">Tank Protection</h5>
                    <div class="col-md-12">
                      <div class="brand-table mb-3">
                        <div class="table-responsive">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col" class="w-20">
                                  Tank #
                                </th>
                                <th scope="col">1</th>
                                <th scope="col">2</th>
                                <th scope="col">3</th>
                                <th scope="col">4</th>
                                <th scope="col">5</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th scope="row">None</th>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="none1"
                                    value="option1"
                                    {...register("tpNone")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="none1"
                                    value="option2"
                                    {...register("tpNone")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="none1"
                                    value="option3"
                                    {...register("tpNone")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="none1"
                                    value="option4"
                                    {...register("tpNone")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="none1"
                                    value="option5"
                                    {...register("tpNone")}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Cathodic Protection</th>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="cp1"
                                    value="option1"
                                    {...register("tpCathodic")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="cp2"
                                    value="option2"
                                    {...register("tpCathodic")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="cp3"
                                    value="option3"
                                    {...register("tpCathodic")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="cp4"
                                    value="option4"
                                    {...register("tpCathodic")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="cp5"
                                    value="option5"
                                    {...register("tpCathodic")}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Painted/Coated</th>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="pc1"
                                    value="option1"
                                    {...register("pPainted")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="pc2"
                                    value="option2"
                                    {...register("pPainted")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="pc3"
                                    value="option3"
                                    {...register("pPainted")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="pc4"
                                    value="option4"
                                    {...register("pPainted")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="pc5"
                                    value="option5"
                                    {...register("pPainted")}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Concrete Diking</th>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="cd1"
                                    value="option1"
                                    {...register("pConcrete")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="cd2"
                                    value="option2"
                                    {...register("pConcrete")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="cd3"
                                    value="option3"
                                    {...register("pConcrete")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="cd4"
                                    value="option4"
                                    {...register("pConcrete")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="cd5"
                                    value="option5"
                                    {...register("pConcrete")}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Earthen Dikin</th>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ed1"
                                    value="option1"
                                    {...register("pEarthen")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ed2"
                                    value="option2"
                                    {...register("pEarthen")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ed3"
                                    value="option3"
                                    {...register("pEarthen")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ed4"
                                    value="option4"
                                    {...register("pEarthen")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ed5"
                                    value="option5"
                                    {...register("pEarthen")}
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <h5 class="wrap-title">Tank Leak Detection</h5>
                    <div class="col-md-12">
                      <div class="brand-table mb-3">
                        <div class="table-responsive">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col" class="w-20">
                                  Tank #
                                </th>
                                <th scope="col">1</th>
                                <th scope="col">2</th>
                                <th scope="col">3</th>
                                <th scope="col">4</th>
                                <th scope="col">5</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th scope="row">None</th>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="none1"
                                    value="option1"
                                    {...register("dNone")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="none1"
                                    value="option2"
                                    {...register("dNone")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="none1"
                                    value="option3"
                                    {...register("dNone")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="none1"
                                    value="option4"
                                    {...register("dNone")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="none1"
                                    value="option5"
                                    {...register("dNone")}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Automatice Tank Gague</th>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="cp1"
                                    value="option1"
                                    {...register("dGague")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="cp2"
                                    value="option2"
                                    {...register("dGague")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="cp3"
                                    value="option3"
                                    {...register("dGague")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="cp4"
                                    value="option4"
                                    {...register("dGague")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="cp5"
                                    value="option5"
                                    {...register("dGague")}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">
                                  Statistical Inventory Control
                                </th>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="pc1"
                                    value="option1"
                                    {...register("dStatistical")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="pc2"
                                    value="option2"
                                    {...register("dStatistical")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="pc3"
                                    value="option3"
                                    {...register("dStatistical")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="pc4"
                                    value="option4"
                                    {...register("dStatistical")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="pc5"
                                    value="option5"
                                    {...register("dStatistical")}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Dipstick Monitoring</th>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="cd1"
                                    value="option1"
                                    {...register("dDipstick")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="cd2"
                                    value="option2"
                                    {...register("dDipstick")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="cd3"
                                    value="option3"
                                    {...register("dDipstick")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="cd4"
                                    value="option4"
                                    {...register("dDipstick")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="cd5"
                                    value="option5"
                                    {...register("dDipstick")}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Interstitial monitoring</th>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ed1"
                                    value="option1"
                                    {...register("dInterstitial")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ed2"
                                    value="option2"
                                    {...register("dInterstitial")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ed3"
                                    value="option3"
                                    {...register("dInterstitial")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ed4"
                                    value="option4"
                                    {...register("dInterstitial")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ed5"
                                    value="option5"
                                    {...register("dInterstitial")}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Visual</th>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ed1"
                                    value="option1"
                                    {...register("dVisual")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ed2"
                                    value="option2"
                                    {...register("dVisual")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ed3"
                                    value="option3"
                                    {...register("dVisual")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ed4"
                                    value="option4"
                                    {...register("dVisual")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ed5"
                                    value="option5"
                                    {...register("dVisual")}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Groundwater monitoring</th>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ed1"
                                    value="option1"
                                    {...register("dMonitoring")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ed2"
                                    value="option2"
                                    {...register("dMonitoring")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ed3"
                                    value="option3"
                                    {...register("dMonitoring")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ed4"
                                    value="option4"
                                    {...register("dMonitoring")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ed5"
                                    value="option5"
                                    {...register("dMonitoring")}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Oil/Water Seperator</th>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ed1"
                                    value="option1"
                                    {...register("dSeperator")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ed2"
                                    value="option2"
                                    {...register("dSeperator")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ed3"
                                    value="option3"
                                    {...register("dSeperator")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ed4"
                                    value="option4"
                                    {...register("dSeperator")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ed5"
                                    value="option5"
                                    {...register("dSeperator")}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Vapor monitoring</th>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ed1"
                                    value="option1"
                                    {...register("dVapor")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ed2"
                                    value="option2"
                                    {...register("dVapor")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ed3"
                                    value="option3"
                                    {...register("dVapor")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ed4"
                                    value="option4"
                                    {...register("dVapor")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ed5"
                                    value="option5"
                                    {...register("dVapor")}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Spill/Overflow Protection</th>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ed1"
                                    value="option1"
                                    {...register("dSpill")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ed2"
                                    value="option2"
                                    {...register("dSpill")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ed3"
                                    value="option3"
                                    {...register("dSpill")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ed4"
                                    value="option4"
                                    {...register("dSpill")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ed5"
                                    value="option5"
                                    {...register("dSpill")}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">
                                  Date of Last Tightness Tenants
                                  <small>(Send copy of certificate)</small>
                                </th>
                                <td>
                                  <input
                                    class="form-control w-50"
                                    type="date"
                                    max="2099-12-31"
                                    {...register("dDate1")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-control w-50"
                                    type="date"
                                    max="2099-12-31"
                                    {...register("dDate2")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-control w-50"
                                    type="date"
                                    max="2099-12-31"
                                    {...register("dDate3")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-control w-50"
                                    type="date"
                                    max="2099-12-31"
                                    {...register("dDate4")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-control w-50"
                                    type="date"
                                    max="2099-12-31"
                                    {...register("dDate5")}
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <h5 class="wrap-title">Tank Piping Information</h5>
                    <div class="col-md-12">
                      <div class="brand-table mb-3">
                        <div class="table-responsive">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col" class="w-20">
                                  Tank #
                                </th>
                                <th scope="col">1</th>
                                <th scope="col">2</th>
                                <th scope="col">3</th>
                                <th scope="col">4</th>
                                <th scope="col">5</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th scope="row">Piping Install Date</th>
                                <td>
                                  <input
                                    type="date"
                                    max="2099-12-31"
                                    class="form-control"
                                    {...register("piping1")}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="date"
                                    max="2099-12-31"
                                    class="form-control"
                                    {...register("piping2")}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="date"
                                    max="2099-12-31"
                                    class="form-control"
                                    {...register("piping3")}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="date"
                                    max="2099-12-31"
                                    class="form-control"
                                    {...register("piping4")}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="date"
                                    max="2099-12-31"
                                    class="form-control"
                                    {...register("piping5")}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">% Above Ground</th>
                                <td>
                                  <input
                                    type="text"
                                    class="form-control"
                                    {...register("pipingAbove1")}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    class="form-control"
                                    {...register("pipingAbove2")}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    class="form-control"
                                    {...register("pipingAbove3")}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    class="form-control"
                                    {...register("pipingAbove4")}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    class="form-control"
                                    {...register("pipingAbove5")}
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <h5 class="wrap-title">Piping Construction</h5>
                    <div class="col-md-12">
                      <div class="brand-table mb-3">
                        <div class="table-responsive">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col" class="w-20">
                                  Tank #
                                </th>
                                <th scope="col">1</th>
                                <th scope="col">2</th>
                                <th scope="col">3</th>
                                <th scope="col">4</th>
                                <th scope="col">5</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th scope="row">Steel Single Wall</th>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ppssw1"
                                    value="option1"
                                    {...register("cSSingle")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ppssw2"
                                    value="option2"
                                    {...register("cSSingle")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ppssw3"
                                    value="option3"
                                    {...register("cSSingle")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ppssw4"
                                    value="option4"
                                    {...register("cSSingle")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ppssw5"
                                    value="option5"
                                    {...register("cSSingle")}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Steel Double Wall</th>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ppsdw1"
                                    value="option1"
                                    {...register("cSDouble")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ppsdw2"
                                    value="option2"
                                    {...register("cSDouble")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ppsdw3"
                                    value="option3"
                                    {...register("cSDouble")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ppsdw4"
                                    value="option4"
                                    {...register("cSDouble")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ppsdw5"
                                    value="option5"
                                    {...register("cSDouble")}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">
                                  Fiberglass/Flexible Single Wall
                                </th>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ppffsw1"
                                    value="option1"
                                    {...register("cSFiberglass")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ppffsw2"
                                    value="option2"
                                    {...register("cSFiberglass")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ppffsw3"
                                    value="option3"
                                    {...register("cSFiberglass")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ppffsw4"
                                    value="option4"
                                    {...register("cSFiberglass")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ppffsw5"
                                    value="option5"
                                    {...register("cSFiberglass")}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">
                                  Fiberglass/Flexible Double Wall
                                </th>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ppffdw1"
                                    value="option1"
                                    {...register("cDFiberglass")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ppffdw2"
                                    value="option2"
                                    {...register("cDFiberglass")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ppffdw3"
                                    value="option3"
                                    {...register("cDFiberglass")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ppffdw4"
                                    value="option4"
                                    {...register("cDFiberglass")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ppffdw5"
                                    value="option5"
                                    {...register("cDFiberglass")}
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <h5 class="wrap-title">Pipe Protection</h5>
                    <div class="col-md-12">
                      <div class="brand-table mb-3">
                        <div class="table-responsive">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col" class="w-20">
                                  Tank #
                                </th>
                                <th scope="col">1</th>
                                <th scope="col">2</th>
                                <th scope="col">3</th>
                                <th scope="col">4</th>
                                <th scope="col">5</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th scope="row">None</th>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ppNone1"
                                    value="option1"
                                    {...register("pNone")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ppNone2"
                                    value="option2"
                                    {...register("pNone")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ppNone3"
                                    value="option3"
                                    {...register("pNone")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ppNone4"
                                    value="option4"
                                    {...register("pNone")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ppNone5"
                                    value="option5"
                                    {...register("pNone")}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Cathodic</th>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ppCathodic1"
                                    value="option1"
                                    {...register("pCathodic")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ppCathodic2"
                                    value="option2"
                                    {...register("pCathodic")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ppCathodic3"
                                    value="option3"
                                    {...register("pCathodic")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ppCathodic4"
                                    value="option4"
                                    {...register("pCathodic")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ppCathodic5"
                                    value="option5"
                                    {...register("pCathodic")}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Other</th>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ppOther1"
                                    value="option1"
                                    {...register("pOther")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ppOther2"
                                    value="option2"
                                    {...register("pOther")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ppOther3"
                                    value="option3"
                                    {...register("pOther")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ppOther4"
                                    value="option4"
                                    {...register("pOther")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ppOther5"
                                    value="option5"
                                    {...register("pOther")}
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <h5 class="wrap-title">Pipe Leak Detection</h5>
                    <div class="col-md-12">
                      <div class="brand-table mb-3">
                        <div class="table-responsive">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col" class="w-20">
                                  Tank #
                                </th>
                                <th scope="col">1</th>
                                <th scope="col">2</th>
                                <th scope="col">3</th>
                                <th scope="col">4</th>
                                <th scope="col">5</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th scope="row">None</th>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ppldNone1"
                                    value="option1"
                                    {...register("lDNone")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ppldNone2"
                                    value="option2"
                                    {...register("lDNone")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ppldNone3"
                                    value="option3"
                                    {...register("lDNone")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ppldNone4"
                                    value="option4"
                                    {...register("lDNone")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ppldNone5"
                                    value="option5"
                                    {...register("lDNone")}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Electronic</th>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ppldElectronic1"
                                    value="option1"
                                    {...register("lDElectronic")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ppldElectronic2"
                                    value="option2"
                                    {...register("lDElectronic")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ppldElectronic3"
                                    value="option3"
                                    {...register("lDElectronic")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ppldElectronic4"
                                    value="option4"
                                    {...register("lDElectronic")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ppldElectronic5"
                                    value="option5"
                                    {...register("lDElectronic")}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Interstitial</th>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ppldInter1"
                                    value="option1"
                                    {...register("lDInterstitial")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ppldInter2"
                                    value="option2"
                                    {...register("lDInterstitial")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ppldInter3"
                                    value="option3"
                                    {...register("lDInterstitial")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ppldInter4"
                                    value="option4"
                                    {...register("lDInterstitial")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ppldInter5"
                                    value="option5"
                                    {...register("lDInterstitial")}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Other</th>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ppldOther1"
                                    value="option1"
                                    {...register("lDOther")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ppldOther2"
                                    value="option2"
                                    {...register("lDOther")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ppldOther3"
                                    value="option3"
                                    {...register("lDOther")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ppldOther4"
                                    value="option4"
                                    {...register("lDOther")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="ppldOther5"
                                    value="option5"
                                    {...register("lDOther")}
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <h5 class="wrap-title">Dispenser Method</h5>
                    <div class="col-md-12">
                      <div class="brand-table mb-3">
                        <div class="table-responsive">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col" class="w-20">
                                  Tank #
                                </th>
                                <th scope="col">1</th>
                                <th scope="col">2</th>
                                <th scope="col">3</th>
                                <th scope="col">4</th>
                                <th scope="col">5</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th scope="row">Suction</th>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="suction1"
                                    value="option1"
                                    {...register("dMSuction")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="suction1"
                                    value="option2"
                                    {...register("dMSuction")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="suction1"
                                    value="option3"
                                    {...register("dMSuction")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="suction1"
                                    value="option4"
                                    {...register("dMSuction")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="suction1"
                                    value="option5"
                                    {...register("dMSuction")}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Pressure</th>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="pressure1"
                                    value="option1"
                                    {...register("dMPressure")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="pressure2"
                                    value="option2"
                                    {...register("dMPressure")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="pressure3"
                                    value="option3"
                                    {...register("dMPressure")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="pressure4"
                                    value="option4"
                                    {...register("dMPressure")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="pressure5"
                                    value="option5"
                                    {...register("dMPressure")}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Gravity</th>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="gravity1"
                                    value="option1"
                                    {...register("dMGravity")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="gravity2"
                                    value="option2"
                                    {...register("dMGravity")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="gravity3"
                                    value="option3"
                                    {...register("dMGravity")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="gravity4"
                                    value="option4"
                                    {...register("dMGravity")}
                                  />
                                </td>
                                <td>
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    id="gravity5"
                                    value="option5"
                                    {...register("dMGravity")}
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <h4 class="wrap-title">Additional Insureds</h4>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="mb-3">
                          <label class="brand-label">Name</label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Please provide Additional Insureds Name"
                            {...register("insuredsName")}
                          />
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="mb-3">
                          <label class="brand-label">Address</label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Please provide Additional Insureds Address"
                            {...register("insuredsAddress")}
                          />
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="mb-3">
                          <label class="brand-label">Name</label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Please provide Additional Insureds Name"
                            {...register("insuredsName2")}
                          />
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="mb-3">
                          <label class="brand-label">Address</label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Please provide Additional Insureds Address"
                            {...register("insuredsAddress2")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <div className="d-flex gap-4 flex-end">
                <button
                  className="btn btn-warning"
                  type="button"
                  onClick={() => stepHandler(2)}
                >
                  Previous
                </button>
                <button type="submit" className="btn btn-success">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Step3;
