import { IconBuildingStore } from "@tabler/icons-react";
import React, { useState } from "react";
import "../../preview-assets/plugins.css";
import "../../preview-assets/prevStyles.css";
import ModalCaraousel from "./ModalCaraousel";

const Previewforms = () => {
  const [selectedCard, setSelectedCard] = useState(null);
  const [showModal, setShowModal] = useState(false);
  // Sample data for cards
  const cards = [
    {
      id: "healthcare",
      title: "Healthcare",
      content: "Healthcare content",
      img: "/assets/images/medical-symbol.png",
      images: ["/assets/previewImages/health_1.png", "/assets/previewImages/health_2.png"],
    },
    {
      id: "auto",
      title: "Auto",
      content: "Auto content",
      img: "/assets/img/icons/auto.png",
      images: ["/assets/previewImages/auto_1.png", "/assets/previewImages/auto_2.png"],
    },
    {
      id: "home",
      title: "Home",
      content: "Home content",
      img: "/assets/img/icons/home.png",
      images: ["/assets/previewImages/home.png"],
    },
    {
      id: "gas-station",
      title: "Gas Station",
      content: "Gas content",
      img: "/assets/img/icons/gas-station.png",
      images: ["/assets/previewImages/gas_1.png", "/assets/previewImages/gas_3.png", "/assets/previewImages/gas_2.png"],
    },

    {
      id: "restaurant",
      title: "Restaurant",
      content: "Restaurant content",
      img: "/assets/img/icons/restaurant.png",
      images: ["/assets/previewImages/res_1.png", "/assets/previewImages/res_3.png", "/assets/previewImages/res_2.png"],
    },
    {
      id: "salon",
      title: "Salon",
      content: "Auto content",
      img: "/assets/img/icons/salon.png",
      images: ["/assets/previewImages/sal_1.png", "/assets/previewImages/sal_2.png", "/assets/previewImages/sal_3.png"],
    },
    {
      id: "general-contractor",
      title: "General Contractor",
      content: "Auto content",
      img: "/assets/images/support.png",
      images: ["/assets/previewImages/gc_1.png", "/assets/previewImages/gc_2.png"],
    },
    {
      id: "shopping-mall",
      title: "Shopping Mall",
      content: "Auto content",
      img: "/assets/img/icons/shopping-mall.png",
      images: ["/assets/previewImages/shop_1.png", "/assets/previewImages/shop_2.png", "/assets/previewImages/shop_3.png"],
    },
    {
      id: "general-store",
      title: "General Business",
      content: "Auto content",
      icon: <IconBuildingStore className="prod-img" color="#2dad70" />,
      images: ["/assets/previewImages/gs_1.png", "/assets/previewImages/gs_2.png"],
    },
    {
      id: "hotel",
      title: "Hotel Commercial",
      content: "Auto content",
      img: "/assets/img/icons/hotel.png",
      images: ["/assets/previewImages/hotel_1.png", "/assets/previewImages/hotel_2.png", "/assets/previewImages/hotel_3.png"],
    },

    // Add more cards as needed
  ];

  const openModal = (card) => {
    setSelectedCard(card);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div>
      <div className="main-wrapper">
        <section
          className="page-title-section top-position1 bg-img cover-background left-overlay-dark contact-banner"
          data-overlay-dark="6"
          //
          style={{
            backgroundImage: `url('/assets/img/banner/anc-contact-banner.jpg')`,
          }}
        >
          <div className="container">
            <div className="page-title">
              <div className="row">
                <div className="col-md-12">
                  <h1>Smart Forms Preview</h1>
                </div>
                <div className="col-md-12">
                  <ul className="ps-0">
                    {/* <li>
                      <a href="index.html" className="text-white">
                        Home
                 </a>
                    </li>
                    <li>
                      <a href="#!" className="text-white">
                        Services
                 </a>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="d-inline-block p-2 border border-width-2 border-white top-20 right-10 ani-move position-absolute rounded-circle z-index-1"></div>
          <div className="d-inline-block p-2 border border-width-2 border-white bottom-10 left-10 ani-left-right position-absolute z-index-1"></div>
          <img src="/assets/img/content/brand-shape-5.png" className="position-absolute top-20 left-5 ani-top-bottom z-index-1" alt="..." />
        </section>

        <section className="py-1 my-1">
          <div className="container">
            <div className="row text-center">
              <h4 className="text-black">Smart forms preview is a feature that allows users to see visual representations of the forms.</h4>
            </div>
            <div className="row"></div>
            <div className="container">
              <div className="row">
                <p align="center">
                  <h4 className="text-black">Click on any business to preview the smart form.</h4>
                </p>
              </div>
            </div>
            <div className="row">
              {cards.map((card, index) => (
                <div onClick={() => openModal(card)} data-bs-toggle="modal" data-bs-target="#exampleModal" key={index} className="col-md-4">
                  <div className="brand-preview-card mt-2 card-link card-link-height">
                    {card.icon ? card.icon : <img className="prod-img prod-img-preview" src={card.img} alt="" />}
                    <h6 className="brand-title text-center mb-3 text-black" type="button">
                      {card.title}
                    </h6>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        <footer className="footer-style1 pt-0 overflow-hidden position-relative">
          {/* <div className="footer-top-info">
            <div className="container z-index-2 position-relative">
              <div className="row mt-n1-9">
                <div className="col-md-4 col-lg-6 mt-1-9">
                  <div className="footer-logo">
                    <img src="/assets/img/logos/anc-logo-trans.png" alt="..." />
                  </div>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-3 mt-1-9">
                  <div className="d-flex">
                    <div className="flex-shrink-0">
                      <i className="ti-location-pin display-15"></i>
                    </div>
                    <div className="flex-grow-1 ms-4">
                      <h4 className="h5 text-white">Location</h4>
                      <p className="mb-0 opacity7">
                        106 E Main St., Richardson, TX 75081.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-3 mt-1-9">
                  <div className="d-flex">
                    <div className="flex-shrink-0">
                      <i className="ti-headphone-alt display-15"></i>
                    </div>
                    <div className="flex-grow-1 ms-4">
                      <h4 className="h5 text-white">Call Us</h4>
                      <p className="mb-0 opacity7">(682) 272-5125</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row mt-n1-9">
              <div
                className="col-sm-6 col-lg-4 pe-5 mt-1-9 wow fadeIn"
                data-wow-delay="200ms"
              >
                <div className="footer-top">
                  <h3 className="mb-1-9 h5">About us</h3>
                  <p className="mb-1-6 text-white">
                    Our Digital tools help independent insurance agencies
                    streamline their workflow and automate respective tasks,
                    saving time and increasing efficiency.
                  </p>
                  <ul className="social-icon-style1 mb-0 d-inline-block list-unstyled">
                    <li className="d-inline-block me-2">
                      <a href="#!">
                        <i className="fab fa-facebook-f"></i>
                 
                    </li>
                    <li className="d-inline-block me-2">
                      <a href="#!">
                        <i className="fab fa-instagram"></i>
                 
                    </li>
                    <li className="d-inline-block me-2">
                      <a href="#!">
                        <i className="fab fa-twitter"></i>
                 
                    </li>
                    <li className="d-inline-block me-2">
                      <a href="#!">
                        <i className="fab fa-youtube"></i>
                 
                    </li>
                    <li className="d-inline-block">
                      <a href="#!">
                        <i className="fab fa-linkedin-in"></i>
                 
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className="col-sm-6 col-lg-2 mt-1-9 wow fadeIn"
                data-wow-delay="350ms"
              >
                <h3 className="h5 mb-1-9">Tools & Services</h3>
                <ul className="footer-list ps-0">
                  <li>
            MyPolicyList</a>
                  </li>
                  <li>
            MyHealthCare</a>
                  </li>
                  <li>
            goPayNow</a>
                  </li>
                  <li>
            Custom AI</a>
                  </li>
                  <li>
            Smart Forms</a>
                  </li>
                  <li>
            Intelligent Texting</a>
                  </li>
                </ul>
              </div>
              <div
                className="col-sm-6 col-lg-3 mt-1-9 wow fadeIn"
                data-wow-delay="500ms"
              >
                <h3 className="h5 mb-1-9">Recent News</h3>
                <div className="d-flex mb-1-9">
                  <div className="flex-shrink-0 image-hover">
                    <img
                      src="/assets/img/content/footer-thumb1.jpg"
                      className="rounded"
                      alt="..."
                    />
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <h4 className="text-white mb-2 h6">
                      <a href="#" className="text-white brand-link-hover">
                        People saying about insurance.
                 
                    </h4>
                    <small className="text-white opacity7">8 Nov, 2021</small>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="flex-shrink-0 image-hover">
                    <img
                      src="/assets/img/content/footer-thumb2.jpg"
                      className="rounded"
                      alt="..."
                    />
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <h4 className="text-white mb-2 h6">
                      <a href="#" className="text-white brand-link-hover">
                        Providing all types of insurance
                 
                    </h4>
                    <small className="text-white opacity7">3 Nov, 2021</small>
                  </div>
                </div>
              </div>
              <div
                className="col-sm-6 col-lg-3 mt-1-9 wow fadeIn"
                data-wow-delay="650ms"
              >
                <h3 className="h5 mb-1-9">NewsLetter</h3>
                <p className="text-white">
                  Subscribe to our newsletter for discounts and more.
                </p>
                <form
                  className="quform newsletter-form"
                  action="#"
                  method="post"
                  enctype="multipart/form-data"
                  onclick=""
                >
                  <div className="quform-elements">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="quform-element">
                          <div className="quform-input">
                            <input
                              className="form-control"
                              id="email_address"
                              type="text"
                              name="email_address"
                              placeholder="Subscribe with us"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="quform-submit-inner">
                          <button
                            className="butn white border-0 w-100"
                            type="submit"
                          >
                            Subscribe
                          </button>
                        </div>
                        <div className="quform-loading-wrap text-center">
                          <span className="quform-loading"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div> */}
          <div className="footer-bar">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 text-center">
                  <p>
                    &copy; <span className="current-year"></span> All Rights Reserved by{" "}
                    <a href="#!" className="brand-title-text text-white-hover">
                      Agents and Carriers Inc
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <img src="/assets/img/content/brand-shape-1.png" className="position-absolute bottom-0 start-0 ani-top-bottom" alt="..." />
          <img src="/assets/img/content/brand-shape-3.png" className="position-absolute bottom-10 left-5 d-none d-lg-block" alt="..." />
        </footer>

        <ModalCaraousel card={selectedCard} showModal={showModal} closeModal={closeModal} />
      </div>
    </div>
  );
};

export default Previewforms;
