import React, { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { emailRegex } from "../../Common/regex";
import Spinner from "react-bootstrap/Spinner";
import { default as axios } from "../../config/axios-config";
import Footer from "../ancfooter";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

const DEVURL = process.env.REACT_APP_ANC_API + "/api/Agency";

const emailValidationSchema = yup.object().shape({
    emailAddress: yup.string().trim().required().matches(emailRegex, 'Enter a valid email address').label("Email Address")
});

const EmailForPasswordVerification = () => {
    
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            emailAddress: ""
        },
        resolver: yupResolver(emailValidationSchema),
    });

    const handleFormSubmit = (formData) => {  
        setErrorMessage("");
        axios
            .get(`${DEVURL}/ValidateEmail/${formData.emailAddress}`)
            .then((response) => {                
                if (response.status === 200 && response.data === true) {
                    setSuccessMessage("An email has been sent to reset your password");
                }
            })
            .catch((error) => {                
                setErrorMessage(error?.response?.data);
            });
    };    

    return (
        <div className="container">
            <div className="section-spacing m-lg-5">
                <div className="row">
                    <div align="center">
                        <Card style={{ width: "28rem", marginTop: "2rem" }}>
                            <Card.Img variant="top" />
                            <Card.Body>
                                <form onSubmit={handleSubmit(handleFormSubmit)}>
                                    <Card.Title><h1>Enter an email to retrieve password</h1></Card.Title>
                                    <Card.Subtitle className="mt-3 mb-3">
                                        {errorMessage && <div className="text-danger mt-3">{errorMessage}</div>}
                                        {successMessage && <div className="text-success mt-3">{successMessage}</div>}
                                    </Card.Subtitle>
                                    <Card.Text>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="name@example.com"
                                            maxLength={45}
                                            {...register("emailAddress")}
                                        />
                                        <div className="text-danger mt-2">{errors?.emailAddress?.message}</div>
                                    </Card.Text>
                                    <Card.Text>
                                    </Card.Text>
                                    <Card.Text>
                                        <Button variant="primary" type="submit">
                                            Submit
                                        </Button>
                                    </Card.Text>
                                </form>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default EmailForPasswordVerification;
