import { IconAlertOctagon } from "@tabler/icons-react";
import React from "react";
import { Card } from "react-bootstrap";

const InvalidURL = () => {
  return (
    <>
      <div>
        <div className="container">
          <div className="main-wrap">
            <div className="wizard-content">
              <p align="center">
                <div>
                  <IconAlertOctagon color="#9ee9f7" size={200} />
                </div>
                <Card.Title>
                  <div className="text-center fs-1 fw-bold" style={{ color: "#04c5e8" }}>
                    Invalid URL
                  </div>
                </Card.Title>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvalidURL;
