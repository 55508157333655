import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { default as axios } from "../../config/axios-config";
import Spinner from "react-bootstrap/Spinner";
import PlaceComponent from "./GooglePlace";
import useExitConfirmationHandler from "../../hooks/useExitConfirmationHandler.hook";

const DEVURL = process.env.REACT_APP_ANC_API + "/api/health/";
const DEVURL_VERIFY = process.env.REACT_APP_ANC_API + "/api/Verify";

const validationSchema = yup.object().shape({
  firstName: yup
    .string()
    .trim()
    .matches(/^[A-Za-z ]*$/, "Please enter a valid First Name")
    .max(45)
    .required("First name is required")
    .label("First name"),
  lastName: yup
    .string()
    .trim()
    .matches(/^[A-Za-z ]*$/, "Please enter a valid Last Name")
    .max(45)
    .required("Last name is required")
    .label("Last name"),
  gender: yup.string().oneOf(["Male", "Female"], "Gender is required").required("Gender"),
  apt: yup.string().trim().required("Street address is required"),
  city: yup
    .string()
    .trim()
    .matches(/^[A-Za-z ]*$/, "Please enter a valid City")
    .max(45)
    .required("City is required")
    .label("City"),
  state: yup
    .string()
    .trim()
    .matches(/^[A-Za-z ]*$/, "Please enter a valid State")
    .max(45)
    .required("State is required")
    .label("State"),
  zipCode: yup.string().trim().required("Zip code is required").label("Zip code"),
  phone: yup
    .string()
    .trim()
    .required("Home phone number is required")
    .matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im, "Please enter a valid home phone number")
    .label("Home phone number"),
  cell: yup
    .string()
    .trim()
    .required("Cell phone number is required")
    .matches(/^(\([0-9]{3}\)|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/, "Please enter a valid cell phone number")
    .label("Cell phone number"),
  email: yup
    .string()
    .trim()
    .required("Email address is required")
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Enter a valid email address"
    )
    .label("Email address"),
  fax: yup.string().matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im, {
    message: "Please enter a valid fax number",
    excludeEmptyString: true,
  }),
  dob: yup
    .date()
    .typeError("Date of birth is required")
    .max(new Date(Date.now() - 86400000), "Date of birth can not be future or current date")
    .required()
    .label("Date of birth"),
  tobacco: yup.string().oneOf(["yes", "no"], "Tobacco is required").required().label("Tobacco"),
  status: yup.string().required("Immigrant status is required").label("Immigrant status"),
  employment: yup.string().required("Employment status is required").label("Employment status"),
  income: yup
    .string()
    .trim()
    .required("Yearly income is required")
    .matches(/[+-]?([0-9]*[.])?[0-9]+/, "Please enter a vaild income")
    .label("Yearly income"),
});

localStorage.removeItem("step2");
localStorage.removeItem("step1");

const Step1 = ({ stepHandler, agencyId, agentId }) => {
  const handleExitButton = useExitConfirmationHandler();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      gender: "",
      apt: "",
      city: "",
      state: "",
      zipCode: "",
      phone: "",
      cell: "",
      email: "",
      fax: "",
      dob: "",
      tobacco: "",
      status: "",
      employment: "",
      income: "",
      agencyId: "",
    },
    resolver: yupResolver(validationSchema),
  });

  const [isLoading, setLoading] = useState(false);
  const [addesss, setAddress] = useState("");
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const primaryId = queryParams.get("primaryid");
  const verifyId = queryParams.get("verifyid");

  const mobile = queryParams.get("mobile");
  const emailAddress = queryParams.get("emailAddress");
  const zip = queryParams.get("zip");
  const income = queryParams.get("income");
  const dob = queryParams.get("dob");
  const gender = queryParams.get("gender");
  const name = queryParams.get("name");

  const formattedDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const setStep1ToLocalStorage = (data) => {
    localStorage.setItem("step1", JSON.stringify(data));
  };

  const setStep2ToLocalStorage = (data) => {
    localStorage.setItem("step2", JSON.stringify(data));
  };

  const fillupStep1FromLocalStorage = (formData) => {
    setValue("firstName", formData.firstName);
    setValue("lastName", formData.lastName);
    setValue("gender", formData.gender);
    setValue("apt", formData.apt);
    setValue("city", formData.city);
    setValue("state", formData.state);
    setValue("zipCode", formData.zipCode);
    setValue("phone", formData.phone);
    setValue("cell", formData.cell);
    setValue("email", formData.email);
    setValue("fax", formData.fax);
    setValue("dob", formattedDate(formData.dob));
    setValue("tobacco", formData.tobacco);
    setValue("status", formData.status);
    setValue("employment", formData.employment);
    setValue("income", formData.income);
    setValue("agencyId", agencyId);
  };

  const fillupStep1FromRemote = (primaryInfo) => {
    setValue("firstName", primaryInfo.firstName);
    setValue("lastName", primaryInfo.lastName);
    setValue("gender", primaryInfo.gender);
    setValue("apt", primaryInfo.apt);
    setAddress(primaryInfo.apt);
    setValue("city", primaryInfo.city);
    setValue("state", primaryInfo.state);
    setValue("zipCode", primaryInfo.zipCode);
    setValue("phone", primaryInfo.phone);
    setValue("cell", primaryInfo.cell);
    setValue("email", primaryInfo.email);
    setValue("fax", primaryInfo.fax);
    setValue("dob", formattedDate(primaryInfo.dob));
    setValue("tobacco", primaryInfo.tobacco);
    setValue("status", primaryInfo.status);
    setValue("employment", primaryInfo.employment);
    setValue("income", primaryInfo.income);
    setValue("agencyId", primaryInfo.agencyId);
  };

  const fillupStep1FromQueryString = () => {
    setValue("firstName", name);
    setValue("gender", gender);
    setValue("zipCode", zip);
    setValue("cell", mobile);
    setValue("email", emailAddress);
    setValue("dob", formattedDate(dob));
    setValue("income", income);
  };

  const isPasscodeActive = (verifyId) => {
    setLoading(true);
    axios
      .get(`${DEVURL_VERIFY}/IsActivePasscode/${verifyId}`)
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          if (response.data !== 1) {
            navigate(`/verification?verify=${verifyId}`);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const loadInitialHealthcareInfo = () => {
    const step1Data = JSON.parse(localStorage.getItem("step1"));
    if (step1Data) {
      fillupStep1FromLocalStorage(step1Data);
    } else if (!step1Data && !mobile && !emailAddress && !zip && !income && !dob && !gender) {
      setLoading(true);
      axios
        .get(`${DEVURL}healthcareInfo/${primaryId}`)
        .then((res) => {
          setLoading(false);
          if (res.data) {
            fillupStep1FromRemote(res?.data?.primary);
            setStep2ToLocalStorage(res?.data?.dependents);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      fillupStep1FromQueryString();
    }
  };

  useEffect(() => {
    if (verifyId) {
      isPasscodeActive(verifyId);
    }
    loadInitialHealthcareInfo();
  }, []);

  const handleFormSubmit = async (data) => {
    console.log('data',data)
    // setStep1ToLocalStorage({...data, dob:data.dob.toISOString()});
    setStep1ToLocalStorage(data);
    stepHandler(2);
  };
  const getLocation = async (data) => {
    let address_components = data.address_components;
    let formatted_address = data.formatted_address;
    formatted_address = formatted_address.split(",");
    setValue("apt", data.name ?? formatted_address["0"]);
    let zip_code = "";
    address_components.map((data) => {
      if (data.types.includes("postal_code")) {
        zip_code = data.long_name + zip_code;
      }
      // if (data.types.includes("postal_code_suffix")) {
      //     zip_code =  zip_code +'-'+ data.long_name;
      // }
      if (data.types.includes("locality")) {
        setValue("city", data.long_name);
      }
      if (data.types.includes("administrative_area_level_1")) {
        setValue("state", data.long_name);
      }
    });
    setValue("zipCode", zip_code);
  };

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }
  return (
    <>
      <form onSubmit={handleSubmit(handleFormSubmit)} className="tab-wizard wizard-circle wizard needs-validation">
        <section>
          <h4 className="wrap-title mb-3">Personal Information</h4>

          <div className="row">
            <div className="col-md-6">
              <div className="mb-3">
                <label className="brand-label">
                  First Name<span className="required-mark">*</span>
                </label>
                <input id="firstName" type="text" className="form-control" placeholder="Enter First Name" {...register("firstName")} />
                <p className="text-danger mt-2">{errors.firstName && <p>{errors.firstName.message}</p>}</p>
              </div>
            </div>

            <div className="col-md-6">
              <div className="mb-3">
                <label className="brand-label">
                  Last Name<span className="required-mark">*</span>
                </label>
                <input id="lastName" type="text" className="form-control" placeholder="Enter Last Name" {...register("lastName")} />
                <p className="text-danger mt-2">{errors.lastName && <p>{errors.lastName.message}</p>}</p>
              </div>
            </div>

            <div className="col-md-6">
              <div className="mb-3">
                <label className="brand-label">
                  Gender<span className="required-mark">*</span>
                </label>

                <select className="form-select" id="applicant-gender" {...register("gender")}>
                  <option value="">Select...</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
                <p className="text-danger mt-2">{errors.gender && <p>{errors.gender.message}</p>}</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-3">
              <div className="mb-3">
                <label className="brand-label">
                  Street Address<span className="required-mark">*</span>
                </label>
                <PlaceComponent getLocationAddress={getLocation} value={addesss}>
                  {" "}
                </PlaceComponent>
                <p className="text-danger mt-2">{errors.apt && <p>{errors.apt.message}</p>}</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-3">
              <div className="mb-3">
                <label className="brand-label">
                  City<span className="required-mark">*</span>
                </label>
                <input id="city" type="text" className="form-control" placeholder="Enter City Name" {...register("city")} />
                <p className="text-danger mt-2">{errors.city && <p>{errors.city.message}</p>}</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-3">
              <div className="mb-3">
                <label className="brand-label">
                  State<span className="required-mark">*</span>
                </label>
                <input id="state" type="text" className="form-control" placeholder="Enter State Name" {...register("state")} />
                <p className="text-danger mt-2">{errors.state && <p>{errors.state.message}</p>}</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-3">
              <div className="mb-3">
                <label className="brand-label">
                  Zipcode<span className="required-mark">*</span>
                </label>
                <input id="zipcode" type="text" className="form-control" placeholder="Enter Zipcode" {...register("zipCode")} />
                <p className="text-danger mt-2">{errors.zipCode && <p>{errors.zipCode.message}</p>}</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-3">
              <div className="mb-3">
                <label className="brand-label">
                  Home Phone Number<span className="required-mark">*</span>
                </label>
                <input id="homePhone" type="text" className="form-control" placeholder="Enter Home Phone Number" {...register("phone")} />
                <p className="text-danger mt-2">{errors.phone && <p>{errors.phone.message}</p>}</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-3">
              <div className="mb-3">
                <label className="brand-label">
                  Cell Phone Number<span className="required-mark">*</span>
                </label>
                <input id="cellPhone" type="text" className="form-control" placeholder="Enter Cell Phone Number" {...register("cell")} />
                <p className="text-danger mt-2">{errors.cell && <p>{errors.cell.message}</p>}</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-3">
              <div className="mb-3">
                <label className="brand-label">
                  Email Address<span className="required-mark">*</span>
                </label>
                <input id="email" type="email" className="form-control" placeholder="Enter Email Address" {...register("email")} />
                <p className="text-danger mt-2">{errors.email && <p>{errors.email.message}</p>}</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-3">
              <div className="mb-3">
                <label className="brand-label">Fax Number</label>
                <input type="text" className="form-control" placeholder="Fax Number" {...register("fax")} />
                <p className="text-danger mt-2">{errors.fax && <p>{errors.fax.message}</p>}</p>
              </div>
            </div>

            <div className="col-md-6">
              <div className="mb-3">
                <label className="brand-label">
                  Date of Birth<span className="required-mark">*</span>
                </label>
                <input
                  id="birthday"
                  type="date"
                  max="2099-12-31"
                  className="form-control"
                  placeholder="Enter Enrollment Date"
                  {...register('dob')}
                  onChange={(e) => {
    setValue('dob',  e.target.valueAsDate.toLocaleDateString('en-CA'))
                  }}
                />
                <p className="text-danger mt-2">{errors.dob && <p>{errors.dob.message}</p>}</p>
              </div>
            </div>

            <div className="col-md-6">
              <div className="mb-3">
                <label className="brand-label">
                  Do you use tobacco?<span className="required-mark">*</span>
                </label>
                <select {...register("tobacco")} className="form-select" id="tobaccoUse">
                  <option value="">Select...</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
                <p className="text-danger mt-2">{errors.tobacco && <p>{errors.tobacco.message}</p>}</p>
              </div>
            </div>

            <div className="col-md-6">
              <div className="mb-3">
                <label className="brand-label">
                  Immigrant Status<span className="required-mark">*</span>
                </label>
                <select {...register("status")} className="form-select" id="usCitizenSelect">
                  <option value="">Select...</option>
                  <option value="Citizen">Citizen</option>
                  <option value="Immigrant">Immigrant</option>
                  <option value="Student">Student</option>
                  <option value="Visitor">Visitor</option>
                  <option value="Asylum">Asylum</option>
                  <option value="Work Permit">Work Permit</option>
                  <option value="Other">Other</option>
                </select>
                <p className="text-danger mt-2">{errors.status && <p>{errors.status.message}</p>}</p>
              </div>
            </div>

            <hr />
            <h4 className="wrap-title mb-3">Income Details</h4>

            <div className="col-md-6">
              <div className="mb-3">
                <label className="brand-label">
                  Your Employment Status
                  <span className="required-mark">*</span>
                </label>
                <select {...register("employment")} className="form-select employmentStatusSelect">
                  <option value="">Select...</option>
                  <option value="job">Job</option>
                  <option value="self-employed">Self-employed</option>
                  <option value="student">Student</option>
                  <option value="unemployed">Unemployed</option>
                  <option value="disability-income">Disability Income</option>
                  <option value="ssn-benefit">SSN Benefit</option>
                  <option value="child-alimony">Child Alimony</option>
                  <option value="pension">Pension</option>
                  <option value="property-rent">Property Rent</option>
                  <option value="others">Others</option>
                </select>
                <p className="text-danger mt-2">{errors.employment && <p>{errors.employment.message}</p>}</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3" id="income">
                <label className="brand-label">
                  Please specify Yearly Income
                  <span className="required-mark">*</span>
                </label>
                <input className="form-control" type="text" placeholder="Income Amount" {...register("income")} />
                <p className="text-danger mt-2">{errors.income && <p>{errors.income.message}</p>}</p>
              </div>
            </div>
          </div>
          <div className="text-end">
            {primaryId && (
              <button type="button" onClick={() => handleExitButton("Healthcare Application")} className="btn btn-danger ms-2 text-capitalize">
                Exit if no changes required
              </button>
            )}
            <button className="btn btn-primary ms-2">Next</button>
          </div>
        </section>
      </form>
    </>
  );
};

export default Step1;
