import React from "react";
import { NumberFormat, formatDate } from "../../../../Common/Utils";

const ViewHome = ({ details }) => {
  return (
    <>
      <div className="offcanvas offcanvas-end" tabIndex="-1" id="viewHome" style={{ width: "75%" }} aria-labelledby="offcanvasRightLabel">
        <div className="offcanvas-header activity-status-table brand-offcanvas-header">
          <h5 id="offcanvasRightLabel">Home Information</h5>
          <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
          <div className="activity-cust-data">
            <h6 className="mb-3">Details</h6>

            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link brand-tab active`}
                  id="build-info"
                  data-bs-toggle="tab"
                  data-bs-target="#building-info-details"
                  type="button"
                  role="tab"
                  aria-controls="building-info-details"
                  aria-selected="true"
                >
                  Home Information
                </button>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">
              <div className="tab-pane fade active show" id="building-info-details" role="tabpanel" aria-labelledby="build-info">
                <div className="activity-status-table">
                  <div className="table-responsive">
                    <table className="table table-striped table table-bordered">
                      <thead></thead>
                      <tbody id="body-render">
                        <tr>
                          <td>First Name</td>
                          <td>{details?.customerFirstName}</td>
                        </tr>
                        <tr>
                          <td>Last Name</td>
                          <td>{details?.customerLastName}</td>
                        </tr>
                        <tr>
                          <td>Email Address</td>
                          <td>{details?.customerEmail}</td>
                        </tr>

                        <tr>
                          <td>Mobile Number</td>
                          <td>{details?.customerMobileNum}</td>
                        </tr>
                        <tr>
                          <td>Work Number</td>
                          <td>{details?.customerWorkNum}</td>
                        </tr>
                        <tr>
                          <td>Date of birth</td>
                          <td>{details?.customerDateOfBirth && formatDate(details?.customerDateOfBirth)}</td>
                        </tr>

                        <tr>
                          <td>Spouse First Name</td>
                          <td>{details?.spouseFirstName}</td>
                        </tr>
                        <tr>
                          <td>Spouse Last Name</td>
                          <td>{details?.spouseLastName}</td>
                        </tr>
                        <tr>
                          <td>Spouse Date of Birth</td>
                          <td>{details?.dateOfBirth && formatDate(details?.dateOfBirth)}</td>
                        </tr>
                        <tr>
                          <td>Property Address</td>
                          <td>{details?.propertyAddress}</td>
                        </tr>

                        <tr>
                          <td>Discount</td>
                          <td>
                            {details?.DiscountsCheck?.map((type, index) => {
                              return (
                                <span key={index} className="badge rounded-pill bg-info text-light me-1">
                                  {type}
                                </span>
                              );
                            })}
                          </td>
                        </tr>
                        <tr>
                          <td>Current Home value</td>
                          <td>{details?.currentHomeValue && formatDate(details?.currentHomeValue)}</td>
                        </tr>
                        <tr>
                          <td>Last Roof updated</td>
                          <td>{details?.isRoofUpdated && formatDate(details?.isRoofUpdated)}</td>
                        </tr>
                        <tr>
                          <td>Last Wiring updated</td>
                          <td>{details?.isWiringUpdated && formatDate(details?.isWiringUpdated)}</td>
                        </tr>
                        <tr>
                          <td>Last Plumbing updated</td>
                          <td>{details?.isPlumbingUpdated && formatDate(details?.isPlumbingUpdated)}</td>
                        </tr>
                        <tr>
                          <td>Last Heating updated</td>
                          <td>{details?.isHeatingUpdated && formatDate(details?.isHeatingUpdated)}</td>
                        </tr>
                        <tr>
                          <td>Claim Date</td>
                          <td>{details?.dateOfClaim && formatDate(details?.dateOfClaim)}</td>
                        </tr>
                        <tr>
                          <td>Claim Reason</td>
                          <td>{details?.claimReason}</td>
                        </tr>
                        <tr>
                          <td>Carrier Name</td>
                          <td>{details?.carrierName}</td>
                        </tr>
                        <tr>
                          <td>Carrier Duration</td>
                          <td>{details?.carrierDuration}</td>
                        </tr>
                        <tr>
                          <td>Claim Renewal Date</td>
                          <td>{details?.renewalDate}</td>
                        </tr>
                        <tr>
                          <td>Premium Amount</td>
                          <td>
                            <NumberFormat value={details?.premiumAmount} />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewHome;
