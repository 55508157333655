import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import useAgencyInfo from "./useAgencyInfo.hook";

const useExitConfirmationHandler = () => {
  const navigate = useNavigate();
  const { agencyName, agentName } = useParams();
  // const { agencyId, agentId, data} = useAgencyInfo(agencyName, agentName);
const URL= agentName ==null? `/${agencyName}` : `/${agencyName}/${agentName}`
  const handleExitButton = async (title) => {
    Swal.fire({
      title: "Are you sure you want to exit?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        // console.log('first', agencyId, agentId)
        navigate(`/ExitPage${URL}?title=${title}`);
      } else {
        return;
      }
    });
  };

  return handleExitButton;
};

export default useExitConfirmationHandler;
