import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import "sweetalert2/dist/sweetalert2.min.css";
import * as yup from "yup";
import { currentYear, floatRegex } from "../../Common/regex";
import { useLocation } from "react-router-dom";
import useExitConfirmationHandler from "../../hooks/useExitConfirmationHandler.hook";

const validationSchema = yup.object().shape({
  yearofConstruction: yup
    .string()
    .trim()
    .required("Year of construction is required")
    .matches(floatRegex, "Please enter a valid no. of years")
    .test(
      "is-past-or-current-year",
      "Year must be in the past or current year",
      function (value) {
        return value <= currentYear;
      }
    )
    .label("Year of construction"),
  propUpdatedYear: yup
    .string()
    .trim()
    .required("Year of update is required")
    .matches(floatRegex, "Please enter a valid no. of years")
    .test(
      "is-past-or-current-year",
      "Year must be in the past or current year",
      function (value) {
        return value <= currentYear;
      }
    )
    .label("Year of update"),
  buildingValue: yup
    .string()
    .trim()
    .required("Building value is required")
    .label("Building value"),
  lastRoofUpdate: yup
    .string()
    .nullable(true)
    .test("is-past-date", "Date must be a past date", (value) => {
      if (!value || isNaN(Date.parse(value))) {
        return true;
      }
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);

      const inputDate = new Date(value);
      inputDate.setHours(0, 0, 0, 0);
      return inputDate < currentDate;
    })
    .label("Roof date"),

  lastWiringUpdate: yup
    .string()
    .nullable(true)
    .typeError("Please enter valid date")
    .test("is-past-date", "Date must be a past date", (value) => {
      if (!value || isNaN(Date.parse(value))) {
        return true;
      }
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);

      const inputDate = new Date(value);
      inputDate.setHours(0, 0, 0, 0);

      return inputDate < currentDate;
    })
    .label("Electrical date"),

  lastPlumbingUpdate: yup
    .string()
    .nullable(true)
    .typeError("Please enter valid date")
    .test("is-past-date", "Date must be a past date", (value) => {
      if (!value || isNaN(Date.parse(value))) {
        return true;
      }
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);

      const inputDate = new Date(value);
      inputDate.setHours(0, 0, 0, 0);

      return inputDate < currentDate;
    })
    .label("Plumbing date"),
  lastHeating: yup
    .string()
    .nullable(true)
    .typeError("Please enter valid date")
    .test("is-past-date", "Date must be a past date", (value) => {
      if (!value || isNaN(Date.parse(value))) {
        return true;
      }
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);

      const inputDate = new Date(value);
      inputDate.setHours(0, 0, 0, 0);

      return inputDate < currentDate;
    })
    .label("Havc date"),
  claimDate: yup
    .string()
    .nullable(true)
    .typeError("Please enter valid date")
    .when("anyClaim", (anyClaim, schema) => {
      if (anyClaim && anyClaim.includes("1")) {
        return schema.required("Claim date is required");
      } else return schema.notRequired();
    })
    .test("is-past-date", "Date must be a past date", (value) => {
      if (!value || isNaN(Date.parse(value))) {
        return true;
      }
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);

      const inputDate = new Date(value);
      inputDate.setHours(0, 0, 0, 0);

      return inputDate < currentDate;
    })
    .label("Claim date"),
  anyClaim: yup.string().required("Claim is required").label("Claims"),
  yearOfBuilt: yup
    .string()
    .trim()
    .required("Year built is required")
    .matches(floatRegex, "Please enter a valid no. of years")
    .test(
      "is-past-or-current-year",
      "Year must be in the past or current year",
      function (value) {
        return value <= currentYear;
      }
    )
    .label("Year built"),
  constructionType: yup
    .array()
    .typeError("Construction type is required")
    .min(1, "Construction type is required")
    .of(yup.string())
    .required("Construction type is required")
    .label("Construction type"),
  wireType: yup
    .array()
    .typeError("Wiring type is required")
    .min(1, "Wiring type is required")
    .of(yup.string())
    .required("Wiring type is required")
    .label("Wiring type"),
  isManagerAvailableOnprop: yup
    .string()
    .required("Any maintenance person is required")
    .label("Any maintenance person"),
  anyAdditionalInsure: yup
    .string()
    .required("Insured is required")
    .label("Insured"),

  isEscrow: yup.string().required("Escrow is required").label("Escrow"),
  shoppingMallType: yup
    .string()
    .required("Shopping mall type is required")
    .label("Shopping mall type"),

  abouttenant: yup
    .string()
    .trim()
    .required("Tenant is required")
    .label("Tenant"),
  bppValue: yup
    .string()
    .trim()
    .required("Bpp value is required")
    .label("Bpp value"),

  sqftspace: yup.string().trim().required("Sq. ft. is required").label("Sq. ft."),
  isBankInvolved: yup
    .string()
    .trim()
    .required("Bank involve is required")
    .label("Bank involve"),
  claimType: yup
    .string()
    .when("anyClaim", (anyClaim, schema) => {
      if (anyClaim && anyClaim?.includes("1")) {
        return schema.required("Claim type is required");
      } else return schema.notRequired();
    })
    .label("Claim type"),
  lossRunYear: yup
    .string()
    .trim()
    .nullable(true)
    .matches(floatRegex, "Please enter a valid no. of years")
    .test(
      "is-past-or-current-year",
      "Year must be in the past or current year",
      function (value) {
        return value <= currentYear;
      }
    )
    .label("Loss run"),
});

const Step2 = ({ stepHandler, loader }) => {
  const  handleExitButton  = useExitConfirmationHandler();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
    reset,
    watch,
    control,
  } = useForm({
    defaultValues: {
      yearofConstruction: null,
      buildingValue: null,
      lastRoofUpdate: null,
      lastWiringUpdate: null,
      lastPlumbingUpdate: null,
      lastHeating: null,
      claimDate: null,
      lossRunYear: null,
      anyClaim: "",
      claimType: "",
      priorLoss: null,
      yearOfBuilt: null,
      anyAdditionalInsure: "",
      bankDetail: "",
      isManagerAvailableOnprop: "",
      isPersonLiveOnProp: null,
      abouttenant: "",
      sqftspace: null,
      propUpdatedYear: null,
      bankValueLimit: null,
      bppValue: null,
      shoppingMallType: "",
      wireType: [],
      constructionType: [],
      isEscrow: "",
      isBankInvolved: "",
    },
    resolver: yupResolver(validationSchema),
  });
  const query = new URLSearchParams(useLocation().search);
  const shoppingmallId = query.get("primaryid");
  const claimsValue = watch("anyClaim");

  const fillupStep2HotelInfoFromLocalStorage = (formData) => {
    setValue("yearofConstruction", formData.yearofConstruction);
    setValue("buildingValue", formData.buildingValue);
    setValue("yearOfBuilt", formData.yearOfBuilt);
    setValue("priorLoss", formData.priorLoss);
    setValue("lastRoofUpdate", formData.lastRoofUpdate);
    setValue("lastWiringUpdate", formData.lastWiringUpdate);
    setValue("lastPlumbingUpdate", formData.lastPlumbingUpdate);
    setValue("lastHeating", formData.lastHeating);
    setValue("lossRunYear", formData.lossRunYear);
    setValue("anyClaim", formData.anyClaim);
    setValue("claimDate", formData.claimDate);
    setValue("claimType", formData.claimType);
    setValue("isBankInvolved", formData.isBankInvolved);
    setValue("anyAdditionalInsure", formData.anyAdditionalInsure);
    setValue("bankDetail", formData.bankDetail);
    setValue("isManagerAvailableOnprop", formData.isManagerAvailableOnprop);
    setValue("isPersonLiveOnProp", formData.isPersonLiveOnProp);
    setValue("abouttenant", formData.abouttenant);
    setValue("sqftspace", formData.sqftspace);
    setValue("propUpdatedYear", formData.propUpdatedYear);
    setValue("bankValueLimit", formData.bankValueLimit);
    setValue("bppValue", formData.bppValue);
    setValue("shoppingMallType", formData.shoppingMallType);
    setValue("wireType", formData.wireType);
    setValue("constructionType", formData.constructionType);
    setValue("isEscrow", formData.isEscrow);
  };

  useEffect(() => {
    const formData = JSON.parse(localStorage.getItem("step2"));
    if (formData) {
      fillupStep2HotelInfoFromLocalStorage(formData);
    }
  }, []);
  useEffect(() => {
    if (claimsValue?.includes("0")) {
      setValue("claimDate", null);
      setValue("claimType", "");
      // setValue("priorLoss", null);
    }
  }, [claimsValue]);

  const setStep2ToLocalStorage = (data) => {
    localStorage.setItem("step2", JSON.stringify(data));
  };

  const handleFormSubmit = async (data) => {
    if (data.lastRoofUpdate?.trim().length === 0) {
      data.lastRoofUpdate = null;
    }
    if (data.lastWiringUpdate?.trim().length === 0) {
      data.lastWiringUpdate = null;
    }
    if (data.lastPlumbingUpdate?.trim().length === 0) {
      data.lastPlumbingUpdate = null;
    }
    if (data.lastHeating?.trim().length === 0) {
      data.lastHeating = null;
    }
    stepHandler(3);
    setStep2ToLocalStorage(data);
  };

  return (
    <>
      <form
        className="tab-wizard wizard-circle wizard needs-validation"
        onSubmit={handleSubmit(handleFormSubmit)}
      >
        <section>
          <h4 className="wrap-title mb-3">Building Information</h4>
          <div className="row">
            <div class="col-md-6 col-lg-3">
              <div className="mb-2">
                <label className="brand-label">
                  Year of Construction?<span className="required-mark">*</span>
                </label>

                <Controller
                  name="yearofConstruction"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      {...field}
                      allowNegative={false}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      id="yearofConstruction"
                      className="form-control"
                      // thousandSeparator={false}
                      name="yearofConstruction"
                      onValueChange={(values) => {
                        setValue("yearofConstruction", values.floatValue);
                      }}
                      placeholder="Enter Year of Construction"
                      {...register("yearofConstruction")}
                    />
                  )}
                />

                <p className="text-danger">
                  {errors.yearofConstruction?.message}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="mb-2">
                <label className="brand-label">
                  Building Value?<span className="required-mark">*</span>
                </label>
                <Controller
                  name="buildingValue"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      {...field}
                      allowNegative={false}
                      id="buildingValue"
                      className="form-control"
                      thousandSeparator={true}
                      prefix="$"
                      name="buildingValue"
                      onValueChange={(values) => {
                        setValue("buildingValue", values.floatValue);
                      }}
                      placeholder="Enter Building Value"
                      {...register("buildingValue")}
                    />
                  )}
                />
                <p className="text-danger">{errors.buildingValue?.message}</p>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div className="mb-2">
                <label className="brand-label">
                  Year Built?<span className="required-mark">*</span>
                </label>
                <Controller
                  name="yearOfBuilt"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      {...field}
                      allowNegative={false}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      id="yearOfBuilt"
                      className="form-control"
                      name="yearOfBuilt"
                      onValueChange={(values) => {
                        setValue("yearOfBuilt", values.value);
                      }}
                      placeholder="Enter Year of Built"
                      {...register("yearOfBuilt")}
                    />
                  )}
                />

                <p className="text-danger">{errors.yearOfBuilt?.message}</p>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="mb-3">
                <label class="brand-label d-grid">
                  <div>
                    Is Bank Involved?
                    <span className="required-mark">*</span>
                  </div>
                </label>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="yesisBankInvolved"
                    value="1"
                    {...register("isBankInvolved")}
                  />
                  <label class="form-check-label" for="yesisBankInvolved">
                    Yes
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="noisBankInvolved"
                    value="0"
                    {...register("isBankInvolved")}
                  />
                  <label class="form-check-label" for="noisBankInvolved">
                    No
                  </label>
                </div>
                <p className="text-danger">
                  {errors.isBankInvolved && (
                    <p>{errors.isBankInvolved.message}</p>
                  )}
                </p>
              </div>
            </div>

            <div className="col-md-6 col-lg-3">
              <div className="mb-2">
                <label className="brand-label text-capitalize">
                  Bank Value Limit?
                </label>
                <Controller
                  name="bankValueLimit"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      {...field}
                      id="bankValueLimit"
                      className="form-control"
                      thousandSeparator={true}
                      prefix="$"
                      name="bankValueLimit"
                      onValueChange={(values) => {
                        console.log("val", values);
                        setValue("bankValueLimit", values.floatValue);
                      }}
                      placeholder="Enter Bank Value Limit"
                      {...register("bankValueLimit")}
                    />
                  )}
                />
                <p className="text-danger">{errors.bankValueLimit?.message}</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-3">
              <div className="mb-2">
                <label className="brand-label text-capitalize">
                  Who are the tenants?
                  <span className="required-mark">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Enter About Tenant"
                  {...register("abouttenant")}
                />
                <p className="text-danger">{errors.abouttenant?.message}</p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="mb-2">
                <label className="brand-label">
                  Sq. Ft.?<span className="required-mark">*</span>
                </label>

                <Controller
                  name="sqftspace"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      {...field}
                      id="sqftspace"
                      className="form-control"
                      thousandSeparator={true}
                      name="sqftspace"
                      onValueChange={(values) => {
                        setValue("sqftspace", values.floatValue);
                      }}
                      placeholder="Enter Sq. Ft."
                      {...register("sqftspace")}
                    />
                  )}
                />
                <p className="text-danger">{errors.sqftspace?.message}</p>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div className="mb-2">
                <label className="brand-label">
                  Update Year?<span className="required-mark">*</span>
                </label>
                <Controller
                  name="propUpdatedYear"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      {...field}
                      allowNegative={false}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      id="propUpdatedYear"
                      className="form-control"
                      name="propUpdatedYear"
                      onValueChange={(values) => {
                        setValue("propUpdatedYear", values.value);
                      }}
                      placeholder="Enter Year of Update"
                      {...register("propUpdatedYear")}
                    />
                  )}
                />

                <p className="text-danger">{errors.propUpdatedYear?.message}</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-3">
              <div className="mb-3">
                <label className="brand-label">Last Roof Updated?</label>
                <input
                  id="lastRoofUpdate"
                  type="date"
                  max="2099-12-31"
                  className="form-control"
                  placeholder="Enter Last Roof Update"
                  {...register("lastRoofUpdate")}
                />
                <p className="text-danger">
                  {errors.lastRoofUpdate && (
                    <p>{errors.lastRoofUpdate.message}</p>
                  )}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="mb-3">
                <label className="brand-label">Last Wiring Updated?</label>
                <input
                  id="lastWiringUpdate"
                  type="date"
                  max="2099-12-31"
                  className="form-control"
                  placeholder="Enter Last Wiring Update"
                  {...register("lastWiringUpdate")}
                />
                <p className="text-danger">
                  {errors.lastWiringUpdate && (
                    <p>{errors.lastWiringUpdate.message}</p>
                  )}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="mb-3">
                <label className="brand-label">Last Plumbing Updated?</label>
                <input
                  type="date"
                  max="2099-12-31"
                  className="form-control"
                  placeholder="Enter Last Plumbing Update"
                  id="lastPlumbingUpdate"
                  {...register("lastPlumbingUpdate")}
                />
                <p className="text-danger">
                  {errors.lastPlumbingUpdate && (
                    <p>{errors.lastPlumbingUpdate.message}</p>
                  )}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="mb-3">
                <label className="brand-label">Last Heating Updated?</label>
                <input
                  type="date"
                  max="2099-12-31"
                  className="form-control"
                  placeholder="Enter Last Heating Update"
                  id="lastHeating"
                  name="lastHeating"
                  {...register("lastHeating")}
                />
                <p className="text-danger">
                  {errors.lastHeating && <p>{errors.lastHeating.message}</p>}
                </p>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="mb-3">
                <label class="brand-label">
                  BPP Value?
                  <span className="required-mark">*</span>
                </label>
                <Controller
                  name="bppValue"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      {...field}
                      id="bppValue"
                      className="form-control"
                      thousandSeparator={true}
                      prefix="$"
                      name="bppValue"
                      onValueChange={(values) => {
                        setValue("bppValue", values.floatValue);
                      }}
                      placeholder="Enter Bpp Value"
                      {...register("bppValue")}
                    />
                  )}
                />

                <p className="text-danger">
                  {errors.bppValue && <p>{errors.bppValue.message}</p>}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="mb-2">
                <label className="brand-label d-grid">
                  <div>
                    Shopping Mall Type?
                    <span className="required-mark">*</span>
                  </div>
                </label>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="stripMallCheck"
                    value="1"
                    {...register("shoppingMallType")}
                  />
                  <label
                    className="form-check-label text-capitalize"
                    for="stripMallCheck"
                  >
                    Strip Mall
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="enclosedCheck"
                    value="0"
                    {...register("shoppingMallType")}
                  />
                  <label
                    className="form-check-label text-capitalize"
                    for="enclosedCheck"
                  >
                    Enclosed
                  </label>
                </div>
                <p className="text-danger">
                  {errors.shoppingMallType?.message}
                </p>
              </div>
            </div>

            <div class="col-md-6 col-lg-3">
              <div class="mb-3">
                <label class="brand-label d-grid">
                  <div>
                    Any Escrow?
                    <span className="required-mark">*</span>
                  </div>
                </label>

                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="escrowYes"
                    value="1"
                    {...register("isEscrow")}
                  />
                  <label class="form-check-label" for="escrowYes">
                    Yes
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="escrowNo"
                    value="0"
                    {...register("isEscrow")}
                  />
                  <label class="form-check-label" for="escrowNo">
                    No
                  </label>
                </div>
                <p className="text-danger">
                  {errors.isEscrow && <p>{errors.isEscrow.message}</p>}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="mb-2">
                <label className="brand-label">Bank Details?</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Enter Bank Details"
                  {...register("bankDetail")}
                />
                <p className="text-danger">{errors.bankDetail?.message}</p>
              </div>
            </div>

            <div class="col-md-6 col-lg-3">
              <div class="mb-3">
                <label class="brand-label d-grid">
                  <div>
                    Any Additional Insured?
                    <span className="required-mark">*</span>
                  </div>
                </label>

                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="insuredYes"
                    value="1"
                    {...register("anyAdditionalInsure")}
                  />
                  <label class="form-check-label" for="insuredYes">
                    Yes
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="insuredNo"
                    value="0"
                    {...register("anyAdditionalInsure")}
                  />
                  <label class="form-check-label" for="insuredNo">
                    No
                  </label>
                </div>
                <p className="text-danger">
                  {errors.anyAdditionalInsure && (
                    <p>{errors.anyAdditionalInsure.message}</p>
                  )}
                </p>
              </div>
            </div>

            <div class="col-md-6 col-lg-3">
              <div class="mb-3">
                <label class="brand-label d-grid text-capitalize">
                  <div>
                    Any manager or maintenance person available?
                    <span className="required-mark">*</span>
                  </div>
                </label>

                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="maintenancePersonYes"
                    value="1"
                    {...register("isManagerAvailableOnprop")}
                  />
                  <label class="form-check-label" for="maintenancePersonYes">
                    Yes
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="maintenancePersonNo"
                    value="0"
                    {...register("isManagerAvailableOnprop")}
                  />
                  <label class="form-check-label" for="maintenancePersonNo">
                    No
                  </label>
                </div>
                <p className="text-danger">
                  {errors.isManagerAvailableOnprop && (
                    <p>{errors.isManagerAvailableOnprop.message}</p>
                  )}
                </p>
              </div>
            </div>

            <div class="col-md-6 col-lg-3">
              <div class="mb-3">
                <label class="brand-label d-grid text-capitalize">
                  <div>Does maintenance person live in property?</div>
                </label>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="isPersonLiveOnPropYes"
                    value="1"
                    {...register("isPersonLiveOnProp")}
                  />
                  <label class="form-check-label" for="isPersonLiveOnPropYes">
                    Yes
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="isPersonLiveOnPropNo"
                    value="0"
                    {...register("isPersonLiveOnProp")}
                  />
                  <label class="form-check-label" for="isPersonLiveOnPropNo">
                    No
                  </label>
                </div>
                <p className="text-danger">
                  {errors.isPersonLiveOnProp && (
                    <p>{errors.isPersonLiveOnProp.message}</p>
                  )}
                </p>
              </div>
            </div>

            <div class="col-md-6 col-lg-3">
              <div class="mb-3">
                <label class="brand-label d-grid text-capitalize">
                  <div>
                    Any Claim in past 5 years?
                    <span className="required-mark">*</span>
                  </div>
                </label>

                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="claimYes"
                    value="1"
                    onSelect={(e) => {
                      console.log("yesbutton", e);
                    }}
                    {...register("anyClaim")}
                  />
                  <label class="form-check-label" for="claimYes">
                    Yes
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="claimNo"
                    value="0"
                    {...register("anyClaim")}
                  />
                  <label class="form-check-label" for="claimNo">
                    No
                  </label>
                </div>
                <p className="text-danger">
                  {errors.anyClaim && <p>{errors.anyClaim.message}</p>}
                </p>
              </div>
            </div>

            <div class="col-md-6 col-lg-3">
              <div class="mb-3">
                <label class="brand-label d-grid">
                  <div>Claim Type?</div>
                </label>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="propertyCheck"
                    value="Property"
                    disabled={!claimsValue?.includes("1")}
                    {...register("claimType")}
                  />
                  <label class="form-check-label" for="propertyCheck">
                    Property
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="GLICheck"
                    value="GL"
                    disabled={!claimsValue?.includes("1")}
                    {...register("claimType")}
                  />
                  <label class="form-check-label" for="GLICheck">
                    GL
                  </label>
                </div>

                <p className="text-danger">
                  {errors.claimType && <p>{errors.claimType.message}</p>}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="mb-3">
                <label className="brand-label">Claim Date?</label>
                <input
                  id="claimDate"
                  type="date"
                  max="2099-12-31"
                  className="form-control"
                  placeholder="Enter Claim Date"
                  disabled={!claimsValue?.includes("1")}
                  {...register("claimDate")}
                />
                <p className="text-danger">
                  {errors.claimDate && <p>{errors.claimDate.message}</p>}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="mb-2">
                <label className="brand-label d-grid">Loss Runs (Year)</label>
                <Controller
                  name="lossRunYear"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      {...field}
                      allowNegative={false}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      id="lossRunYear"
                      className="form-control"
                      name="lossRunYear"
                      onValueChange={(values) => {
                        setValue("lossRunYear", values.value);
                      }}
                      placeholder="Enter Loss Run (Years)"
                      {...register("lossRunYear")}
                    />
                  )}
                />
                <p className="text-danger">{errors.lossRunYear?.message}</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-3">
              <div className="mb-2">
                <label className="brand-label d-grid">Prior Losses</label>
                <Controller
                  name="priorLoss"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      {...field}
                      id="priorLoss"
                      className="form-control"
                      thousandSeparator={true}
                      prefix="$"
                      name="priorLoss"
                      onValueChange={(values) => {
                        setValue("priorLoss", values.floatValue);
                      }}
                      placeholder="Enter if any prior losses"
                      {...register("priorLoss")}
                    />
                  )}
                />
                <p className="text-danger">{errors.priorLoss?.message}</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-6">
              <div className="mb-2">
                <label className="brand-label d-grid">
                  <div>
                    Wiring Type?<span className="required-mark">*</span>
                  </div>
                </label>
                <div className="row">
                  <div className="col-md-6">
                    {" "}
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="aluminumCheck"
                        value="Aluminum"
                        {...register("wireType")}
                      />
                      <label
                        className="form-check-label text-capitalize"
                        for="aluminumCheck"
                      >
                        Aluminum
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="copperCheck"
                        value="Copper"
                        {...register("wireType")}
                      />
                      <label
                        className="form-check-label text-capitalize"
                        for="copperCheck"
                      >
                        Copper
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    {" "}
                    <div className="form-check ">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="pigTailedCheck"
                        value="Pigtail"
                        {...register("wireType")}
                      />
                      <label
                        className="form-check-label text-capitalize"
                        for="pigTailedCheck"
                      >
                        Pigtail
                      </label>
                    </div>
                    <div className="form-check ">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="otherswireTypeCheck"
                        value="Others"
                        {...register("wireType")}
                      />
                      <label
                        className="form-check-label text-capitalize"
                        for="otherswireTypeCheck"
                      >
                        Others
                      </label>
                    </div>
                  </div>
                </div>

                <p className="text-danger">{errors.wireType?.message}</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-6">
              <div className="mb-2">
                <label className="brand-label d-grid">
                  <div>
                    Construction Type?<span className="required-mark">*</span>
                  </div>
                </label>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="frameCheck"
                        value="Frame"
                        {...register("constructionType")}
                      />
                      <label
                        className="form-check-label text-capitalize"
                        for="frameCheck"
                      >
                        Frame
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="mNCCheck"
                        value="MNC"
                        {...register("constructionType")}
                      />
                      <label
                        className="form-check-label text-capitalize"
                        for="mNCCheck"
                      >
                        MNC
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-check ">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="nCCheck"
                        value="NC"
                        {...register("constructionType")}
                      />
                      <label
                        className="form-check-label text-capitalize"
                        for="nCCheck"
                      >
                        NC
                      </label>
                    </div>
                    <div className="form-check ">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="jMCheck"
                        value="JM"
                        {...register("constructionType")}
                      />
                      <label
                        className="form-check-label text-capitalize"
                        for="jMCheck"
                      >
                        JM
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-check ">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="othersConstructionTypeCheck"
                        value="Others"
                        {...register("constructionType")}
                      />
                      <label
                        className="form-check-label text-capitalize"
                        for="othersConstructionTypeCheck"
                      >
                        Others
                      </label>
                    </div>
                  </div>
                </div>

                <p className="text-danger">
                  {errors.constructionType?.message}
                </p>
              </div>
            </div>
            <div className="d-flex justify-content-end">
            {shoppingmallId && (
                <button
                  type="button"
                  onClick={()=>handleExitButton('Shopping Center Application')}
                  className="btn btn-danger ms-2 text-capitalize"
                >
                  Exit if no changes required
                </button>
              )}
              <button
                className="btn btn-warning ms-2"
                type="button"
                onClick={() => stepHandler(1)}
              >
                Previous
              </button>
              <button className="btn btn-primary ms-2">Next</button>
            </div>
          </div>
        </section>
      </form>
    </>
  );
};

export default Step2;
