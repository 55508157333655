import React, { useEffect, useState } from "react";
import EditPrimaryHealthcare from "./EditPrimaryHealthcare";
import EditDependentHealthcare from "./EditDependentHealthcare";
import Master from "../../AutoUpdateForm/Master";

const EditHealthcare = ({ details, setSelectedActivity }) => {
    const [step, setStep] = useState(1);
  const handleCancelClick = () => {
    details = null;
    setStep(1)
  };

  return (
    <>
      <div className="offcanvas offcanvas-end" tabIndex="-1" id="editHealthcare" aria-labelledby="offcanvasRightLabel">
        <div className="offcanvas-header activity-status-table brand-offcanvas-header">
          <h5 id="offcanvasRightLabel">Edit Auto Product</h5>
          <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" onClick={handleCancelClick} aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
          <div className="activity-cust-data">
            <Master details={details} setStep={setStep} step={step}/>
           </div>
        </div>
      </div>
    </>
  );
};

export default EditHealthcare;
