import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import Badge from "react-bootstrap/Badge";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import BusinessHour from "../../Common/BusinessHour";
import { formattedDate } from "../../Common/Utils";
import { emailRegex, floatRegex, nameRegex } from "../../Common/regex";
import { default as axios } from "../../config/axios-config";
import PlaceComponent from "../healthcare/GooglePlace";
import useExitConfirmationHandler from "../../hooks/useExitConfirmationHandler.hook";

const DEVURL = process.env.REACT_APP_ANC_API + "/api/Restaurant";
const DEVURL_VERIFY = process.env.REACT_APP_ANC_API + "/api/Verify";

const validationSchema = yup.object().shape({
  firstName: yup.string().trim().required("First name is required").matches(nameRegex, "Please enter a valid name").label("First name"),
  lastName: yup.string().trim().required("Last name is required").matches(nameRegex, "Please enter a valid name").label("Last name"),
  businessName: yup.string().trim().required("Business name is required").label("Business name"),
  dba: yup.string().nullable(true).matches(nameRegex, "Please enter a valid dba").label("DBA"),
  hoursOfOperation: yup.string().trim().typeError("Please enter a valid time").required("Hours of operation is required").label("Hours of operation"),
  businessType: yup
    .array()
    .typeError("Business type is required")
    .min(1, "Business type is required")
    .of(yup.string())
    .required("Business type is required")
    .label("Business type"),
  businessTypeOtherValue: yup
    .string()
    .test("isRequired", "Business type is required", function (value) {
      const businessType = this.parent.businessType;
      return businessType && businessType?.includes("others") ? !!value : true;
    })
    .label("Others type"),
  locationAddress: yup.string().trim().required("Location address is required").label("Location address"),
  mailingAddress: yup.string().trim().required("Mailing address is required").label("Mailing address"),
  mobileNum: yup
    .string()
    .trim()
    .required("Mobile number is required")
    .matches(/^(\([0-9]{3}\)|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/, "Please enter valid mobile number")
    .label("Mobile number"),
  workNum: yup
    .string()
    .nullable(null)
    .matches(/^(\([0-9]{3}\)|[0-9]{3})[\s\-]?[0-9]{3}[\s\-]?[0-9]{4}$|^$/, "Please enter valid work number")
    .label("Work number"),

  email: yup.string().trim().required("Email is required").matches(emailRegex, "Enter a valid email").label("Email address"),
  coverage: yup.array().typeError("Coverage is required").min(1, "Coverage is required").of(yup.string()).required("Coverage is required").label("Coverage"),
  coverageOtherValue: yup
    .string()
    .test("isRequired", "Coverage is required", function (value) {
      const coverage = this.parent.coverage;
      return coverage && coverage?.includes("others") ? !!value : true;
    })
    .label("Others type"),
  effectiveDate: yup
    .string()
    .trim()
    .required("Effective date is required")
    .test("is-future-date", "Date must be today or future date", (value) => {
      if (!value) {
        return true;
      }
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      const inputDate = new Date(value);
      inputDate.setHours(0, 0, 0, 0);

      return inputDate >= currentDate;
    })
    .label("Effective date"),
  howManyYearOnSameLocation: yup
    .string()
    .trim()
    .required("No. of years is required")
    .matches(floatRegex, "Enter a valid years at the same location")
    .label("Years at the same location"),
  experienceRestaurant: yup
    .string()
    .trim()
    .required("No. of years is required")
    .matches(floatRegex, "Enter a valid no. of years")
    .label("Years of experience in restaurant business"),
});

const Step1 = ({ stepHandler, loader, agencyId, agentId }) => {
  const navigate = useNavigate();
  const handleExitButton = useExitConfirmationHandler();
  const query = new URLSearchParams(useLocation().search);
  const verifyId = query.get("verifyid");
  const id = query.get("primaryid");
  const mode = query.get("mode");
  const isView = mode ? (mode === "view" ? true : false) : "";
  const [sameAsAddress, setSameAsAddress] = useState(false);
  const [garageAddress, setGarageAddress] = useState("");
  const queryParams = new URLSearchParams(window.location.search);

  const restaurantId = query.get("primaryid");
  const [hoursOfOperation, setHoursOfOperation] = useState(["", ""]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    control,
    watch,
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      businessName: "",
      dba: "",
      hoursOfOperation: "",
      businessType: [],
      locationAddress: "",
      locationCity: "",
      locationState: "",
      locationZip: "",
      mailingAddress: "",
      mailingCity: "",
      mailingState: "",
      mailingZip: "",
      workNum: "",
      mobileNum: "",
      email: "",
      coverage: [],
      effectiveDate: null,
      howManyYearOnSameLocation: "",
      experienceRestaurant: "",
      agencyId: agencyId,
      agentId: agentId,
      customerId: "",
      restaurantId: "",
      coverageOtherValue: "",
      businessTypeOtherValue: "",
    },
    resolver: yupResolver(validationSchema),
  });

  const [isLoading, setLoading] = useState(false);
  const [addesss, setAddress] = useState("");
  const mobileNum = watch("mobileNum");
  const workNum = watch("workNum");
  const locationCity = watch("locationCity");
  const locationState = watch("locationState");
  const locationZip = watch("locationZip");

  const getLocation = async (data) => {
    let address_components = data.address_components;
    let formatted_address = data.formatted_address;
    setValue("locationAddress", formatted_address);
    setAddress(formatted_address);
    formatted_address = formatted_address.split(",");
    let zip_code = "";
    address_components.map((data) => {
      if (data.types.includes("postal_code")) {
        zip_code = data.long_name + zip_code;
      }
      // if (data.types.includes("postal_code_suffix")) {
      //     zip_code =  zip_code +'-'+ data.long_name;
      // }
      if (data.types.includes("locality")) {
        setValue("locationCity", data.long_name);
      }
      if (data.types.includes("administrative_area_level_1")) {
        setValue("locationState", data.long_name);
      }
    });
    setValue("locationZip", zip_code);
  };

  const getGarageAddress = async (data) => {
    console.log("garageAddress data", data);
    let address_components = data?.address_components;
    let formatted_address = data?.formatted_address;
    setValue("mailingAddress", formatted_address);
    formatted_address = formatted_address.split(",");
    let zip_code = "";
    address_components.map((data) => {
      if (data.types.includes("postal_code")) {
        zip_code = data.long_name + zip_code;
      }
      // if (data.types.includes("postal_code_suffix")) {
      //     zip_code =  zip_code +'-'+ data.long_name;
      // }
      if (data.types.includes("locality")) {
        setValue("mailingCity", data.long_name);
      }
      if (data.types.includes("administrative_area_level_1")) {
        setValue("mailingState", data.long_name);
      }
    });
    setValue("mailingZip", zip_code);
    return false;
  };

  const coverageValue = watch("coverage");
  const businessTypeValue = watch("businessType");

  const fillupStep1HotelInfo = (restaurant) => {
    if (restaurant) {
      setValue("coverage", restaurant.restaurantInfo?.coverage);
      setValue("coverageOtherValue", restaurant?.coverageOtherValue);
      setValue("businessType", restaurant.restaurantInfo.businessType);
      setValue("businessTypeOtherValue", restaurant?.businessTypeOtherValue);
      setValue("agencyId", agencyId);
      setValue("customerId", restaurant?.customerInfo?.customerId);
      setValue("firstName", restaurant.customerInfo.firstName);
      setValue("lastName", restaurant.customerInfo.lastName);
      setValue("businessName", restaurant.customerInfo.businessName);
      setValue("dba", restaurant.customerInfo.dbaname);
      setValue("hoursOfOperation", restaurant.restaurantInfo.hoursOfOperation);
      let hrOfOperation = restaurant.restaurantInfo.hoursOfOperation;
      hrOfOperation = hrOfOperation.split("-");
      setHoursOfOperation(hrOfOperation);
      setValue("locationAddress", restaurant.customerInfo.locationAddress);
      setValue("locationCity", restaurant.customerInfo.locationCity);
      setValue("locationState", restaurant.customerInfo.locationState);
      setValue("locationZip", restaurant.customerInfo.locationZip);
      setValue("mailingAddress", restaurant.customerInfo.mailingAddress);
      setValue("mailingCity", restaurant.customerInfo.mailingCity);
      setValue("mailingState", restaurant.customerInfo.mailingState);
      setValue("mailingZip", restaurant.customerInfo.mailingZip);
      setValue("workNum", restaurant.customerInfo.workNum);
      setValue("mobileNum", restaurant.customerInfo.mobileNum);
      setValue("email", restaurant.customerInfo.email);

      setValue("howManyYearOnSameLocation", restaurant.restaurantInfo.howManyYearOnSameLocation);
      setValue("experienceRestaurant", restaurant.restaurantInfo.experienceRestaurant);
      setValue("effectiveDate", formattedDate(restaurant?.restaurantInfo?.effectiveDate));
      setAddress(restaurant.customerInfo.locationAddress);
      setGarageAddress(restaurant.customerInfo.mailingAddress);
      if (restaurant.customerInfo.mailingAddress === restaurant.customerInfo.locationAddress) {
        setSameAsAddress(true);
      }
    }
  };

  useEffect(() => {
    if (!coverageValue?.includes("others")) {
      setValue("coverageOtherValue", "");
    }
    if (!businessTypeValue?.includes("others")) {
      setValue("businessTypeOtherValue", "");
    }
  }, [coverageValue, businessTypeValue]);

  const getStep1HotelInfo = (restaurant) => {
    const hotelInfoOb = {};
    hotelInfoOb.customerId = restaurant.customerInfo.customerId;
    hotelInfoOb.firstName = restaurant.customerInfo.firstName;
    hotelInfoOb.lastName = restaurant.customerInfo.lastName;
    hotelInfoOb.businessName = restaurant.customerInfo.businessName;
    hotelInfoOb.coverage = restaurant.restaurantInfo.coverage;
    hotelInfoOb.coverageOtherValue = restaurant.restaurantInfo.coverageOtherValue;
    hotelInfoOb.businessType = restaurant.restaurantInfo.businessType;
    hotelInfoOb.businessTypeOtherValue = restaurant.restaurantInfo.businessTypeOtherValue;
    hotelInfoOb.dba = restaurant.customerInfo.dbaname;
    hotelInfoOb.hoursOfOperation = restaurant.restaurantInfo.hoursOfOperation;
    hotelInfoOb.locationAddress = restaurant.customerInfo.locationAddress;
    hotelInfoOb.city = restaurant.customerInfo.locationCity;
    hotelInfoOb.state = restaurant.customerInfo.locationState;
    hotelInfoOb.zipcode = restaurant.customerInfo.locationZip;
    hotelInfoOb.mailingAddress = restaurant.customerInfo.mailingAddress;
    hotelInfoOb.mailingCity = restaurant.customerInfo.mailingCity;
    hotelInfoOb.mailingState = restaurant.customerInfo.mailingState;
    hotelInfoOb.mailingZip = restaurant.customerInfo.mailingZip;
    hotelInfoOb.workNum = restaurant.customerInfo.workNum;
    hotelInfoOb.mobileNum = restaurant.customerInfo.mobileNum;
    hotelInfoOb.email = restaurant.customerInfo.email;

    hotelInfoOb.effectiveDate = restaurant.restaurantInfo.effectiveDate;
    hotelInfoOb.howManyYearOnSameLocation = restaurant.restaurantInfo.howManyYearOnSameLocation;
    hotelInfoOb.experienceRestaurant = restaurant.restaurantInfo.experienceRestaurant;
    return hotelInfoOb;
  };

  const getStep2HotelInfo = (restaurant) => {
    const hotelInfoOb = {};
    setValue("restaurantId", restaurant.restaurantInfo.restaurantId);
    hotelInfoOb.restaurantId = restaurant.restaurantInfo.restaurantId;
    hotelInfoOb.propertyYearOfBuil = restaurant.restaurantInfo.propertyYearOfBuil;
    hotelInfoOb.propertyNoofBuilding = restaurant.restaurantInfo.propertyNoofBuilding;
    hotelInfoOb.propertyBpp = restaurant.restaurantInfo.propertyBpp;
    hotelInfoOb.propertySignLimit = restaurant.restaurantInfo.propertySignLimit;
    hotelInfoOb.propertySqft = restaurant.restaurantInfo.propertySqft;
    hotelInfoOb.propertySignedValue = restaurant.restaurantInfo.propertySignedValue;
    hotelInfoOb.propertyProtactive = restaurant.restaurantInfo.propertyProtactive;
    hotelInfoOb.protectiveGaurdOtherValue = restaurant.restaurantInfo.protectiveGaurdOtherValue;
    hotelInfoOb.propertyAnyTable = restaurant.restaurantInfo.propertyAnyTable;
    hotelInfoOb.propertyAnyBuffet = restaurant.restaurantInfo.propertyAnyBuffet;
    hotelInfoOb.propertyAnyBar = restaurant.restaurantInfo.propertyAnyBar;
    hotelInfoOb.propertyServiceContract = restaurant.restaurantInfo.propertyServiceContract;
    hotelInfoOb.propertyCatering = restaurant.restaurantInfo.propertyCatering;
    hotelInfoOb.propertySpoilageLimit = restaurant.restaurantInfo.propertySpoilageLimit;
    hotelInfoOb.propertyFoodContainerLimit = restaurant.restaurantInfo.propertyFoodContainerLimit;
    hotelInfoOb.propertyMoneyLimit = restaurant.restaurantInfo.propertyMoneyLimit;
    hotelInfoOb.propertyVehicle = restaurant.restaurantInfo.propertyVehicle;
    hotelInfoOb.propertyPriorLoss = restaurant.restaurantInfo.propertyPriorLoss;
    hotelInfoOb.propertyAnyUpdate = restaurant.restaurantInfo.propertyAnyUpdate;
    hotelInfoOb.claimDate = formattedDate(restaurant.restaurantInfo.claimDate);
    hotelInfoOb.roofDate = restaurant.restaurantInfo.roofDate === null ? null : formattedDate(restaurant.restaurantInfo.roofDate);
    hotelInfoOb.electricalDate = restaurant.restaurantInfo.electricalDate === null ? null : formattedDate(restaurant.restaurantInfo.electricalDate);
    hotelInfoOb.plumbingDate = restaurant.restaurantInfo.plumbingDate === null ? null : formattedDate(restaurant.restaurantInfo.plumbingDate);

    hotelInfoOb.hvacDate = restaurant.restaurantInfo.hvacDate === null ? null : formattedDate(restaurant.restaurantInfo.hvacDate);

    hotelInfoOb.propertyLossRun = restaurant.restaurantInfo.propertyLossRun;
    hotelInfoOb.propertyIfClaim = restaurant.restaurantInfo.propertyIfClaim;

    return hotelInfoOb;
  };

  const getStep3HotelInfo = (restaurant) => {
    const hotelInfoOb = {};
    hotelInfoOb.agentId = restaurant.customerInfo.agentId;
    hotelInfoOb.glCookingType = restaurant.restaurantInfo.glCookingType;
    hotelInfoOb.glCookingTypeOtherValue = restaurant.restaurantInfo.glCookingTypeOtherValue;
    hotelInfoOb.glSeatingCapacity = restaurant.restaurantInfo.glSeatingCapacity;
    hotelInfoOb.glLiquour = restaurant.restaurantInfo.glLiquour;
    hotelInfoOb.glHappyHours = restaurant.restaurantInfo.glHappyHours;
    hotelInfoOb.glHoodAndDuck = restaurant.restaurantInfo.glHoodAndDuck;
    hotelInfoOb.glOpenFireCooking = restaurant.restaurantInfo.glOpenFireCooking;
    hotelInfoOb.glLiquorLimit = restaurant.restaurantInfo.glLiquorLimit;
    hotelInfoOb.glAnnualFoodSale = restaurant.restaurantInfo.glAnnualFoodSale;
    hotelInfoOb.glAnnualLiquorSales = restaurant.restaurantInfo.glAnnualLiquorSales;
    hotelInfoOb.glCateringServce = restaurant.restaurantInfo.glCateringServce;
    hotelInfoOb.glIsBanquest = restaurant.restaurantInfo.glIsBanquest;
    hotelInfoOb.glTotalPayRoll = restaurant.restaurantInfo.glTotalPayRoll;
    hotelInfoOb.glFein = restaurant.restaurantInfo.glFein;
    hotelInfoOb.glNoEmp = restaurant.restaurantInfo.glNoEmp;
    hotelInfoOb.glBankDetails = restaurant.restaurantInfo.glBankDetails;
    hotelInfoOb.glPropValueByBank = restaurant.restaurantInfo.glPropValueByBank;
    return hotelInfoOb;
  };
  const fillupStep1HotelInfoFromLocalStorage = async (formData) => {
    if (formData) {
      console.log("forma", formData);
      setValue("firstName", formData.firstName);
      setValue("lastName", formData.lastName);
      setValue("businessName", formData.businessName);
      setValue("dba", formData.dba);
      setValue("coverage", formData?.coverage);
      setValue("coverageOtherValue", formData?.coverageOtherValue);
      setValue("businessType", formData.businessType);
      setValue("businessTypeOtherValue", formData?.businessTypeOtherValue);
      setValue("hoursOfOperation", formData.hoursOfOperation);
      let hrOfOperation = formData.hoursOfOperation;
      hrOfOperation = hrOfOperation.split("-");
      setHoursOfOperation(hrOfOperation);
      setValue("locationAddress", formData.locationAddress);
      setValue("locationCity", formData.locationCity);
      setValue("locationState", formData.locationState);
      setValue("locationZip", formData.locationZip);
      setValue("mailingAddress", formData.mailingAddress);
      setValue("mailingCity", formData.mailingCity);
      setValue("mailingState", formData.mailingState);
      setValue("mailingZip", formData.mailingZip);
      setValue("workNum", formData.workNum);
      setValue("mobileNum", formData.mobileNum);
      setValue("email", formData.email);

      setValue("effectiveDate", formattedDate(formData.effectiveDate));
      setValue("howManyYearOnSameLocation", formData.howManyYearOnSameLocation);
      setValue("experienceRestaurant", formData.experienceRestaurant);
      setAddress(formData.locationAddress);
      setGarageAddress(formData.mailingAddress);
      if (formData.mailingAddress === formData.locationAddress) {
        setSameAsAddress(true);
      }
    }
  };

  const setHotelInfoInLocalStorage = (data) => {
    localStorage.setItem("step1", JSON.stringify(getStep1HotelInfo(data)));
    localStorage.setItem("step2", JSON.stringify(getStep2HotelInfo(data)));
    localStorage.setItem("step3", JSON.stringify(getStep3HotelInfo(data)));
    localStorage.setItem("customerId", data?.customerInfo?.customerId);
  };

  const formatTelNumber = (input) => {
    const cleaned = ("" + input).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    } else {
      return cleaned;
    }
  };

  const getTimeRange = async (range) => {
    setValue("hoursOfOperation", range);
  };

  const isPasscodeActive = (verifyId) => {
    setLoading(true);
    axios
      .get(`${DEVURL_VERIFY}/IsActivePasscode/${verifyId}`)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setLoading(false);
          if (response.data !== 1) {
            navigate(`/verification?verify=${verifyId}`);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error?.response?.status === 404) {
          navigate(`/verification?verify=${verifyId}`);
        }
      });
  };

  const loadInitialGasStationInfo = () => {
    const formData = JSON.parse(localStorage.getItem("step1"));
    if (!formData) {
      setLoading(true);
      axios
        .get(`${DEVURL}/GetRestaurantInfoByRestaurantId/${id}`)
        .then((response) => {
          setLoading(false);
          fillupStep1HotelInfo(response.data);
          setHotelInfoInLocalStorage(response.data);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } else if (formData) {
      fillupStep1HotelInfoFromLocalStorage(formData);
    }
  };

  useEffect(() => {
    if (verifyId) {
      isPasscodeActive(verifyId);
    }
    loadInitialGasStationInfo();
  }, []);

  const handleFormSubmit = (data) => {
    localStorage.setItem("step1", JSON.stringify(data));
    stepHandler(2);
  };

  if (isLoading) {
    return loader();
  }

  return (
    <>
      <form className="tab-wizard wizard-circle wizard needs-validation" onSubmit={handleSubmit(handleFormSubmit)}>
        <section>
          <h4 className="wrap-title mb-3">General Information</h4>
          <div className="row">
            <div className="col-md-6 col-lg-3">
              <div className="mb-3">
                <label className="brand-label">First Name</label>
                <span className="required-mark">*</span>
                <input type="text" className="form-control" placeholder="Enter First Name" readOnly={isView} {...register("firstName")} />
                <p className="text-danger">{errors.firstName && <p>{errors.firstName.message}</p>}</p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="mb-3">
                <label className="brand-label">Last Name</label>
                <span className="required-mark">*</span>
                <input type="text" className="form-control" placeholder="Enter Last Name" readOnly={isView} {...register("lastName")} />
                <p className="text-danger">{errors.lastName && <p>{errors.lastName.message}</p>}</p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="mb-3">
                <label className="brand-label">Business Name</label>
                <span className="required-mark">*</span>
                <input type="text" className="form-control" placeholder="Enter Business Name" readOnly={isView} {...register("businessName")} />
                <p className="text-danger">{errors.businessName && <p>{errors.businessName.message}</p>}</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-3">
              <div className="mb-3">
                <label className="brand-label">DBA</label>
                <input type="text" className="form-control" placeholder="Enter DBA" readOnly={isView} {...register("dba")} />
              </div>
              <p className="text-danger">{errors.dba && <p>{errors.dba.message}</p>}</p>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="mb-3">
                <label className="brand-label">
                  <div className="d-flex">
                    Hours of Operation<span className="required-mark">*</span> &nbsp;
                    <div>
                      <Badge bg="info">(Start and End Time)</Badge>
                    </div>
                  </div>
                </label>
                {/*// for edit pass the start= 09:30 and end = 18:30 */}
                <BusinessHour onTimeRangeChange={getTimeRange} timeRange={hoursOfOperation} />

                <p className="text-danger">{errors.hoursOfOperation && <p>{errors.hoursOfOperation.message}</p>}</p>
              </div>
            </div>

            <div class="col-md-6 col-lg-4">
              <div class="mb-3">
                <label class="brand-label">
                  Work Number
                  {/* <span className="required-mark">*</span> */}
                </label>
                <Controller
                  name="mobileNum"
                  control={control}
                  render={({ field }) => (
                    <input {...field} type="text" class="form-control" placeholder="(XXX) XXX-XXXX" value={formatTelNumber(workNum)} {...register("workNum")} />
                  )}
                />
                <p className="text-danger">{errors.workNum?.message}</p>
              </div>
            </div>

            <div class="col-md-6 col-lg-4">
              <div class="mb-3">
                <label class="brand-label">
                  Mobile Number<span className="required-mark">*</span>
                </label>
                <Controller
                  name="mobileNum"
                  control={control}
                  render={({ field }) => (
                    <input {...field} type="text" class="form-control" placeholder="(XXX) XXX-XXXX" value={formatTelNumber(mobileNum)} {...register("mobileNum")} />
                  )}
                />

                <p className="text-danger">{errors.mobileNum?.message}</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="mb-3">
                <label className="brand-label">
                  Location Address<span className="required-mark">*</span>
                </label>
                <PlaceComponent
                  getLocationAddress={getLocation}
                  value={addesss}
                  onChange={(e) => {
                    setAddress(e.target.value);
                    setValue("locationAddress", e.target.value);
                    setValue("locationCity", null);
                    setValue("locationState", null);
                    setValue("locationZip", null);
                  }}
                  type="profile-update"
                  name="locationAddress"
                  control={control}
                ></PlaceComponent>
                <p className="text-danger">{errors.locationAddress && <p>{errors.locationAddress?.message}</p>}</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-5">
              <div className="mb-3">
                <label className="brand-label" style={{ display: "flex" }}>
                  Mailing Address<span className="required-mark">*</span>&nbsp;
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={sameAsAddress}
                      onChange={(e) => {
                        setSameAsAddress(e.target.checked);
                        setValue("mailingAddress", addesss);
                        setValue("mailingCity", locationCity);
                        setValue("mailingState", locationState);
                        setValue("mailingZip", locationZip);
                        setGarageAddress(addesss);
                      }}
                      id="same-as"
                    />
                    <label className="form-check-label text-capitalize" htmlFor="flexCheckDefault">
                      Same As Location Address
                    </label>
                  </div>
                </label>

                <PlaceComponent
                  getLocationAddress={getGarageAddress}
                  value={garageAddress}
                  onChange={(e) => {
                    setGarageAddress(e.target.value);
                    setValue("mailingAddress", e.target.value);
                    setValue("mailingZip", null);
                    setValue("mailingState", null);
                    setValue("mailingCity", null);
                  }}
                  type="profile-update"
                  readOnly={sameAsAddress}
                  name="mailingAddress"
                  control={control}
                >
                  {" "}
                </PlaceComponent>
                <p className="text-danger">{errors.mailingAddress && <p>{errors.mailingAddress.message}</p>}</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-3">
              <div className="mb-3">
                <label className="brand-label">Email Address</label>
                <span className="required-mark">*</span>
                <input type="email" className="form-control" placeholder="Enter Email Address" readOnly={isView} {...register("email")} />
                <p className="text-danger">{errors.email && <p>{errors.email.message}</p>}</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="mb-3">
                <label className="brand-label">Effective Date</label>
                <span className="required-mark">*</span>
                <input type="date" max="2099-12-31" className="form-control" placeholder="Enter Effective Date" readOnly={isView} {...register("effectiveDate")} />
                <p className="text-danger">{errors.effectiveDate && <p>{errors.effectiveDate.message}</p>}</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="mb-3">
                <label className="brand-label">
                  No. Of Years At The Same Location
                  <span className="required-mark">*</span>
                </label>

                <input type="tel" maxLength={3} className="form-control" placeholder="Enter number of years" readOnly={isView} {...register("howManyYearOnSameLocation")} />
                <p className="text-danger">{errors.howManyYearOnSameLocation && <p>{errors.howManyYearOnSameLocation.message}</p>}</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="mb-3">
                <label className="brand-label text-capitalize">
                  Years Of Experience In Restaurant Business
                  <span className="required-mark">*</span>
                </label>

                <input type="tel" className="form-control" maxLength={3} placeholder="Enter number of years" readOnly={isView} {...register("experienceRestaurant")} />
                <p className="text-danger">{errors.experienceRestaurant && <p>{errors.experienceRestaurant.message}</p>}</p>
              </div>
            </div>

            <div className="col-md-7 col-lg-7">
              <div className="mb-3">
                <label class="brand-label">
                  Business Type<span className="required-mark">*</span>
                </label>
                <div className="row ">
                  <div className="col-md-6">
                    <div className="form-check ">
                      <input className="form-check-input" type="checkbox" id="fineDiningCheck" value="Fine dining" {...register("businessType")} />
                      <label className="form-check-label text-capitalize" for="fineDiningCheck">
                        Fine dining
                      </label>
                    </div>
                    <div className="form-check ">
                      <input className="form-check-input" type="checkbox" id="diningWithTableServiceCheck" value="Family dining with table service" {...register("businessType")} />
                      <label className="form-check-label text-capitalize" for="diningWithTableServiceCheck">
                        Family dining with table service
                      </label>
                    </div>
                    <div className="form-check ">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="diningWithoutTableServiceCheck"
                        value="Family dining without table service"
                        {...register("businessType")}
                      />
                      <label className="form-check-label text-capitalize" for="diningWithoutTableServiceCheck">
                        Family dining without table service
                      </label>
                    </div>
                    <div className="form-check ">
                      <input className="form-check-input" type="checkbox" id="diningWithBarCheck" value="Dining with bar" {...register("businessType")} />
                      <label className="form-check-label text-capitalize" for="diningWithBarCheck">
                        Dining with bar
                      </label>
                    </div>
                    <div className="form-check ">
                      <input className="form-check-input" type="checkbox" id="fastFoodCheck" value="Fast food" {...register("businessType")} />
                      <label className="form-check-label text-capitalize" for="fastFoodCheck">
                        Fast food
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-check ">
                      <input className="form-check-input" type="checkbox" id="deliCheck" value="Deli" {...register("businessType")} />
                      <label className="form-check-label text-capitalize" for="deliCheck">
                        Deli
                      </label>
                    </div>
                    <div className="form-check ">
                      <input className="form-check-input" type="checkbox" id="pizzaCheck" value="Pizza" {...register("businessType")} />
                      <label className="form-check-label text-capitalize" for="pizzaCheck">
                        Pizza
                      </label>
                    </div>
                    <div className="form-check ">
                      <input className="form-check-input" type="checkbox" id="donutShopCheck" value="Donut shop" {...register("businessType")} />
                      <label className="form-check-label text-capitalize" for="donutShopCheck">
                        Donut shop
                      </label>
                    </div>
                    <div className="form-check ">
                      <input className="form-check-input" type="checkbox" id="casualDiningCheck" value="Casual dining" {...register("businessType")} />
                      <label className="form-check-label text-capitalize" for="casualDiningCheck">
                        Casual dining
                      </label>
                    </div>
                    <div className="form-check ">
                      <input className="form-check-input" type="checkbox" id="businessOthersCheck" value="others" {...register("businessType")} />
                      <label className="form-check-label text-capitalize" for="businessOthersCheck">
                        Others
                      </label>
                    </div>
                    <div>
                      {watch("businessType")?.includes("others") && (
                        <>
                          <input
                            className="form-control"
                            type="text"
                            name="businessTypeOtherValue"
                            id="businessTypeOtherValue"
                            placeholder="Enter Business Type"
                            {...register("businessTypeOtherValue")}
                          />

                          <p className="text-danger">{errors?.businessTypeOtherValue?.message}</p>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <p className="text-danger">{errors.businessType && <p>{errors.businessType.message}</p>}</p>
              </div>
            </div>
            <div className="col-md-5 col-lg-5">
              <div className="mb-3">
                <label class="brand-label">
                  Coverage<span className="required-mark">*</span>
                </label>
                <div>
                  <div className="form-check ">
                    <input className="form-check-input" type="checkbox" id="propertyCheck" value="Property" {...register("coverage")} />
                    <label className="form-check-label text-capitalize" for="propertyCheck">
                      Property
                    </label>
                  </div>
                  <div className="form-check ">
                    <input className="form-check-input" type="checkbox" id="generalLiabilityCheck" value="General Liability" {...register("coverage")} />
                    <label className="form-check-label text-capitalize" for="generalLiabilityCheck">
                      General Liability
                    </label>
                  </div>
                  <div className="form-check ">
                    <input className="form-check-input" type="checkbox" id="equipmentbreakdownCheck" value="Equipment breakdown" {...register("coverage")} />
                    <label className="form-check-label text-capitalize" for="equipmentbreakdownCheck">
                      Equipment breakdown
                    </label>
                  </div>
                  <div className="form-check ">
                    <input className="form-check-input" type="checkbox" id="workerCompensationCheck" value="Worker’s compensation" {...register("coverage")} />
                    <label className="form-check-label text-capitalize" for="workerCompensationCheck">
                      Worker’s compensation
                    </label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" id="othersCheck" value="others" {...register("coverage")} />
                    <label className="form-check-label text-capitalize" for="othersCheck">
                      Others
                    </label>
                  </div>
                  <div>
                    {watch("coverage")?.includes("others") && (
                      <>
                        <input
                          className="form-control"
                          type="text"
                          name="coverageOtherValue"
                          id="coverageOtherValue"
                          placeholder="Enter Coverage"
                          {...register("coverageOtherValue")}
                        />

                        <p className="text-danger">{errors?.coverageOtherValue?.message}</p>
                      </>
                    )}
                  </div>
                </div>
                <p className="text-danger">{errors.coverage?.message}</p>
              </div>
            </div>
          </div>
        </section>
        <div className="text-end">
          {restaurantId && (
            <button type="button" onClick={() => handleExitButton("Restaurant Insurance Application")} className="btn btn-danger ms-2 text-capitalize">
              Exit if no changes required
            </button>
          )}
          <button className="btn btn-primary ms-2">Next</button>
        </div>
      </form>
    </>
  );
};

export default Step1;
