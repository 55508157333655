import React from "react";
import {
  NumberFormat,
  ThousandSeparator,
  formatDate,
  formatHours,
} from "../../../Common/Utils";

const SalonView = ({ details }) => {
  console.log("details gas", details);

  return (
    <>
      <div
        className="offcanvas offcanvas-end"
        tabIndex="-1"
        id="salon-view"
        style={{ width: "80%" }}
        aria-labelledby="offcanvasRightLabel"
      >
        <div className="offcanvas-header activity-status-table brand-offcanvas-header">
          <h5 id="offcanvasRightLabel">Salon/Spa Information</h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <div className="activity-cust-data">
            <h6 className="mb-3">Details</h6>

            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link brand-tab active`}
                  id="build-info"
                  data-bs-toggle="tab"
                  data-bs-target="#building-info-details"
                  type="button"
                  role="tab"
                  aria-controls="building-info-details"
                  aria-selected="true"
                >
                  General Information
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link brand-tab`}
                  id="geo-location1"
                  data-bs-toggle="tab"
                  data-bs-target="#annual-info"
                  type="button"
                  role="tab"
                  aria-controls="annual-info"
                  aria-selected="false"
                >
                  Property Information
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link brand-tab`}
                  id="geo-location1"
                  data-bs-toggle="tab"
                  data-bs-target="#general-liability"
                  type="button"
                  role="tab"
                  aria-controls="general-liability"
                  aria-selected="false"
                >
                  General Liability Information
                </button>
              </li>
            </ul>
            {details && (
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade active show"
                  id="building-info-details"
                  role="tabpanel"
                  aria-labelledby="build-info"
                >
                  <div className="activity-status-table">
                    <div className="table-responsive">
                      <table className="table table-striped table table-bordered">
                        <thead></thead>
                        <tbody id="body-render">
                          <tr>
                            <td>First Name</td>
                            <td>{details?.customerInfo?.firstName}</td>
                          </tr>
                          <tr>
                            <td>Last Name</td>
                            <td>{details?.customerInfo?.lastName}</td>
                          </tr>
                          <tr>
                            <td>Business Name</td>
                            <td>{details?.customerInfo?.businessName}</td>
                          </tr>
                          <tr>
                            <td>DBA</td>
                            <td>{details?.customerInfo?.dbaname}</td>
                          </tr>
                          <tr>
                            <td>Hours of Operation</td>
                            <td>
                              {details?.salonInfo?.hoursOfOperation &&
                                formatHours(
                                  details?.salonInfo?.hoursOfOperation
                                )}
                            </td>
                          </tr>
                          <tr>
                            <td>Work Number</td>
                            <td>{details?.customerInfo?.workNum}</td>
                          </tr>
                          <tr>
                            <td>Mobile Number</td>
                            <td>{details?.customerInfo?.mobileNum}</td>
                          </tr>
                          <tr>
                            <td>Location Address</td>
                            <td>{details?.customerInfo?.locationAddress}</td>
                          </tr>
                          <tr>
                            <td>Mailing Address</td>
                            <td>{details?.customerInfo?.mailingAddress}</td>
                          </tr>
                          <tr>
                            <td>Email Address</td>
                            <td>{details?.customerInfo?.email}</td>
                          </tr>
                          <tr>
                            <td>Effective Date</td>
                            <td>
                              {details?.salonInfo?.effectiveDate &&
                                formatDate(details?.salonInfo?.effectiveDate)}
                            </td>
                          </tr>

                          <tr>
                            <td>Coverage</td>
                            <td>
                              {details?.salonInfo?.coverage?.map(
                                (type, index) => {
                                  return (
                                    <span
                                      key={index}
                                      className="badge rounded-pill bg-info text-light me-1"
                                    >
                                      {type}
                                    </span>
                                  );
                                }
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>Business Type</td>
                            <td>
                              {details?.salonInfo?.typeOfBusiness?.map(
                                (type, index) => {
                                  return (
                                    <span
                                      key={index}
                                      className="badge rounded-pill bg-info text-light me-1"
                                    >
                                      {type}
                                    </span>
                                  );
                                }
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div
                  className={`tab-pane fade`}
                  id="annual-info"
                  role="tabpanel"
                  aria-labelledby="build-info"
                >
                  <div className="activity-status-table">
                    <div className="table-responsive">
                      <table className="table table-striped table table-bordered">
                        <thead></thead>
                        <tbody id="body-render">
                          <tr>
                            <td>Year of Construction</td>
                            <td>{details?.salonInfo?.yearOfConstruction}</td>
                          </tr>
                          <tr>
                            <td>No. of Locations</td>
                            <td>
                              <ThousandSeparator
                                value={details?.salonInfo?.noOfLocation}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>No. of Buildings in Each Location</td>
                            <td>
                              <ThousandSeparator
                                value={
                                  details?.salonInfo?.noOfBuildingEachLocation
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Business Interruption Limit</td>
                            <td>
                              <NumberFormat
                                value={details?.salonInfo?.busInterruptionLimit}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Sign Limit</td>
                            <td>
                              <NumberFormat
                                value={details?.salonInfo?.signLimit}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Money & Securities Limit</td>
                            <td>
                              <NumberFormat
                                value={details?.salonInfo?.moneyNsecurity}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Theft Limit</td>
                            <td>
                              <NumberFormat
                                value={details?.salonInfo?.theftLimit}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Building Value</td>
                            <td>
                              <NumberFormat
                                value={details?.salonInfo?.buildingValue}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Equipments Value</td>
                            <td>
                              <NumberFormat
                                value={details?.salonInfo?.equipmentValue}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Sq. Ft.</td>
                            <td>
                              <ThousandSeparator
                                value={details?.salonInfo?.sqftspace}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>No. of beauticians for each service</td>
                            <td>
                              <ThousandSeparator
                                value={details?.salonInfo?.noOfBeauticians}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>How Many Part Timer</td>
                            <td>
                              <ThousandSeparator
                                value={details?.salonInfo?.partTimeEmployee}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>How Many Full Timer</td>
                            <td>
                              <ThousandSeparator
                                value={
                                  details?.salonInfo?.totalFullTimeEmployee
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Loss Run (Years)</td>
                            <td>{details?.salonInfo?.lossRunYear}</td>
                          </tr>
                          <tr>
                            <td>Are They Licensed</td>
                            <td>
                              {details?.salonInfo?.licensedStatus === 1
                                ? "Yes"
                                : "No"}
                            </td>
                          </tr>
                          <tr>
                            <td>Prior Losses</td>
                            <td>
                              <NumberFormat
                                value={details?.salonInfo?.priorLoss}
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>Type Of Work</td>
                            <td>
                              {details?.salonInfo?.workType?.map(
                                (type, index) => {
                                  return (
                                    <span
                                      key={index}
                                      className="badge rounded-pill bg-info text-light me-1"
                                    >
                                      {type}
                                    </span>
                                  );
                                }
                              )}
                            </td>
                          </tr>

                          <tr>
                            <td>Last Roof updated</td>
                            <td>
                              {details?.salonInfo?.lastRoofUpdate &&
                                formatDate(details?.salonInfo?.lastRoofUpdate)}
                            </td>
                          </tr>
                          <tr>
                            <td>Last Wiring updated</td>
                            <td>
                              {details?.salonInfo?.lastWiringUpdate &&
                                formatDate(
                                  details?.salonInfo?.lastWiringUpdate
                                )}
                            </td>
                          </tr>
                          <tr>
                            <td>Last Plumbing updated</td>
                            <td>
                              {details?.salonInfo?.lastPlumbingUpdate &&
                                formatDate(
                                  details?.salonInfo?.lastPlumbingUpdate
                                )}
                            </td>
                          </tr>
                          <tr>
                            <td>Last Heating updated</td>
                            <td>
                              {details?.salonInfo?.lastHeating &&
                                formatDate(details?.salonInfo?.lastHeating)}
                            </td>
                          </tr>
                          <tr>
                            <td>Any Claim in past 5 years</td>
                            <td>
                              {details?.salonInfo?.anyClaim === 1
                                ? "Yes"
                                : "No"}
                            </td>
                          </tr>
                          <tr>
                            <td>Claim Date</td>
                            <td>
                              {details?.salonInfo?.claimDate &&
                                formatDate(details?.salonInfo?.claimDate)}
                            </td>
                          </tr>
                          <tr>
                            <td>Claim Type</td>
                            <td>{details?.salonInfo?.claimType}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div
                  className={`tab-pane fade`}
                  id="general-liability"
                  role="tabpanel"
                  aria-labelledby="build-info"
                >
                  <div className="activity-status-table">
                    <div className="table-responsive">
                      <table className="table table-striped table table-bordered">
                        <thead></thead>
                        <tbody id="body-render">
                          <tr>
                            <td>Need Professional Liabilities Coverage?</td>
                            <td>
                              {details?.salonInfo?.anyProferssionalLib === 1
                                ? "Yes"
                                : "No"}
                            </td>
                          </tr>
                          <tr>
                            <td>Need Professional Mal Practice Coverage?</td>
                            <td>
                              {details?.salonInfo?.malPracticeCoverage === 1
                                ? "Yes"
                                : "No"}
                            </td>
                          </tr>
                          <tr>
                            <td>Liabilities Coverage Limit</td>
                            <td>
                              <NumberFormat
                                value={details?.salonInfo?.converageLimit}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Mal Practice Coverage Limit</td>
                            <td>
                              <NumberFormat
                                value={
                                  details?.salonInfo?.malPracticeCoverageAmt
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Annual Gross Sales?</td>
                            <td>
                              <NumberFormat
                                value={details?.salonInfo?.annualGrossSale}
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>Total Payroll</td>
                            <td>
                              <NumberFormat
                                value={details?.salonInfo?.totalPayroll}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>FEIN Number</td>
                            <td>{details?.salonInfo?.feinNumber}</td>
                          </tr>
                          <tr>
                            <td>No. of Employees</td>
                            <td>
                              <ThousandSeparator
                                value={details?.salonInfo?.noOfEmployee}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Bank Details</td>
                            <td>{details?.salonInfo?.bankDetails}</td>
                          </tr>
                          <tr>
                            <td>Bank Property Value</td>
                            <td>{details?.salonInfo?.propValueByBank}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SalonView;
