import React, { useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import Stepper from "react-stepper-horizontal";
import ThankyouResponseForUpdate from "../../../ThankyoupageForUpdate";
import Step1 from "./Step1";
import Step2 from "./Step2";
const Master = ({ details }) => {
  const [step, setStep] = useState(1);
  const steps = [
    { title: "General Information" },
    { title: "Contractor Information" },
    { title: "Finish" },
  ];
  var activeStep = step;
  if (step === 1) {
    activeStep = 0;
  } else {
    activeStep = activeStep - 1;
  }

  const loader = () => {
    return (
      <ThreeDots
        height="40"
        width="80"
        color="#4fa94d"
        ariaLabel="bars-loading"
        wrapperStyle={{ justifyContent: "center", paddingBottom: "10px" }}
        wrapperClass=""
        visible={true}
      />
    );
  };

  return (
    <div>
      <Stepper steps={steps} activeStep={activeStep} />
      {step === 1 && (
        <Step1 stepHandler={setStep} details={details} loader={loader} />
      )}
      {step === 2 && (
        <Step2 stepHandler={setStep} loader={loader} details={details} />
      )}

      {step === 3 && <ThankyouResponseForUpdate />}
    </div>
  );
};

export default Master;
