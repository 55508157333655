import React, { useEffect, useState } from "react";
import CanvasJSReact from "@canvasjs/react-charts";
import axios from "axios";
import { ThreeDots } from "react-loader-spinner";
import { convertChartData } from "../helper/Helper";

const mockData = {
  totalAgency: 30,
  totalAgent: 55,
  clientByMonthStat: {
    agency: {
      may: 0,
      jun: 0,
      jul: 4,
      aug: 0,
      sep: 2,
      oct: 0,
      nov: 0,
      dec: 0,
      jan: 0,
      feb: 0,
      mar: 0,
      apr: 0,
    },
    agent: {
      may: 0,
      jun: 0,
      jul: 0,
      aug: 0,
      sep: 1,
      oct: 0,
      nov: 4,
      dec: 28,
      jan: 13,
      feb: 1,
      mar: 8,
      apr: 0,
    },
  },
  serviceStat: {
    myPersonalLine: 7,
    myCommercialLine: 31,
    myHealthcare: 15,
  },
  totalFormSubmissionByMonthStat: {
    may: 0,
    jun: 0,
    jul: 0,
    aug: 0,
    sep: 5,
    oct: 52,
    nov: 56,
    dec: 28,
    jan: 39,
    feb: 116,
    mar: 36,
    apr: 0,
  },
  totalFormSubmissionByTypeStat: {
    data: {
      home: 0,
      auto: 10,
      generalContractor: 0,
      healthcare: 0,
      hotel: 30,
      salon: 62,
      restaurant: 57,
      generalstore: 10,
      shoppingmall: 20,
    },
  },
};



const DEVURL = process.env.REACT_APP_ANC_API + "/api";

const AdminDashboard = () => {
  var CanvasJSChart = CanvasJSReact.CanvasJSChart;
  const [isLoading, setIsLoading] = useState(true);
  const [clientByMonthStat_Agency, setClientByMonthStat_Agency] = useState([]);
  const [clientByMonthStat_Agent, setClientByMonthStat_Agent] = useState([]);
  const [serviceStat, setServiceStat] = useState([]);
  const [totalFormSubmissionByMonthStat, setTotalFormSubmissionByMonthStat] = useState([]);
  const [totalFormSubmissionByTypeStat, setTtalFormSubmissionByTypeStat] = useState([]);
  console.log("clientByMonthStat_Agent", clientByMonthStat_Agent);
  const clientByMonthStat_options = {
    animationEnabled: true,
    title: {
      text: "Annual Clients Report",
    },
    axisX: {
      // title: "Sales (in USD)",
    },
    axisY: {
      // title: "Sales (in USD)",
    },
    toolTip: {
      shared: "true",
    },

    data: [
      {
        type: "spline",
        showInLegend: true,
        name: "Agency",
        dataPoints: clientByMonthStat_Agency,
      },
      {
        type: "spline",
        showInLegend: true,
        name: "Agent",
        dataPoints: clientByMonthStat_Agent,
      },
    ],
  };
  const serviceStat_options = {
    animationEnabled: true,
    title: {
      text: "Product usage",
    },
    // subtitles: [{
    //   text: "71% Positive",
    //   verticalAlign: "center",
    //   fontSize: 24,
    //   dockInsidePlotArea: true
    // }],
    data: [
      {
        type: "doughnut",
        showInLegend: true,
        indexLabel: "{name}: {y}",
        yValueFormatString: "#,###",
        dataPoints: serviceStat,
      },
    ],
  };
  const totalFormSubmissionByMonthStat_options = {
    title: {
      text: "Annual form submission report",
    },
    data: [
      {
        type: "column",
        dataPoints: totalFormSubmissionByMonthStat,
      },
    ],
  };
  const totalFormSubmissionByTypeStat_options = {
    exportEnabled: true,
    animationEnabled: true,
    title: {
      text: "Monthly form submission report",
    },
    data: [
      {
        type: "pie",
        startAngle: 75,
        toolTipContent: "<b>{label}</b>: {y}",
        showInLegend: "true",
        legendText: "{label}",
        indexLabelFontSize: 16,
        indexLabel: "{label} - {y}",
        dataPoints: totalFormSubmissionByTypeStat,
      },
    ],
  };

  const getControlPanelData = () => {
    axios
      .get(`${DEVURL}/ControlPanel/GetDashboardData`)
      .then((response) => {
        if (response.status === 200) {
          let data = response.data;
          setClientByMonthStat_Agency(convertChartData(data.clientByMonthStat.agency));
          setClientByMonthStat_Agent(convertChartData(data.clientByMonthStat.agent));
          setServiceStat(convertChartData(data.serviceStat, "name", "y", "service"));
          setTotalFormSubmissionByMonthStat(convertChartData(data.totalFormSubmissionByMonthStat));
          setTtalFormSubmissionByTypeStat(convertChartData(data.totalFormSubmissionByTypeStat.data));
          setIsLoading(false);
        }
      })
      .catch((error) => {
        if (error?.response?.data?.status === 404) {
        } else {
        }
      });
  };
  console.log("loading", isLoading);
  useEffect(() => {
    getControlPanelData();
  }, []);

  const loader = () => {
    return (
      <ThreeDots
        height="40"
        width="80"
        color="#4fa94d"
        ariaLabel="bars-loading"
        wrapperStyle={{ justifyContent: "center", alignItems: "center", height: "100%", paddingBottom: "10px", overflow: "hidden" }}
        wrapperClass=""
        visible={true}
      />
    );
  };

  if (isLoading) {
    return <div style={{ height: "90vh", width: "70vw", overflow: "hidden" }}>{loader()}</div>;
  }

  return (
    <div className="col p-8">
      <div className="row w-full mx-auto my-4 py-4 d-flex justify-content-center">
        <div className="col-2 ">
          <div className="p-2 border border-3 border-info rounded-3">
            <p>Total Agency:</p>
            <h1>{mockData.totalAgency}</h1>
          </div>
        </div>
        <div className="col-2 ">
          <div className="p-2 border border-3 border-info rounded-3">
            <p>Total Agent:</p>
            <h1>{mockData.totalAgent}</h1>
          </div>
        </div>
      </div>
      <div className="row me-2 my-4 py-4">
        <div className="col-7 ">
          <CanvasJSChart options={clientByMonthStat_options} />
        </div>
        <div className="col-5 ">
          <CanvasJSChart options={serviceStat_options} />
        </div>
      </div>
      <div className="row me-2 my-4 py-4">
        <div className="col-7 ">
          <CanvasJSChart options={totalFormSubmissionByMonthStat_options} />
        </div>
        <div className="col-5 ">
          <CanvasJSChart options={totalFormSubmissionByTypeStat_options} />
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
