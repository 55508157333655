import React from "react";
import {
  NumberFormat,
  ThousandSeparator,
  formatDate,
  formatHours,
} from "../../../Common/Utils";
import { formatText } from "../../helper/Helper";

const GenaralCView = ({ details }) => {
  console.log("details GC", details);

  return (
    <>
      <div
        className="offcanvas offcanvas-end"
        tabIndex="-1"
        id="salon-view"
        style={{ width: "80%" }}
        aria-labelledby="offcanvasRightLabel"
      >
        <div className="offcanvas-header activity-status-table brand-offcanvas-header">
          <h5 id="offcanvasRightLabel">General Contractor Information</h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <div className="activity-cust-data">
            <h6 className="mb-3">Details</h6>

            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link brand-tab active`}
                  id="build-info"
                  data-bs-toggle="tab"
                  data-bs-target="#building-info-details"
                  type="button"
                  role="tab"
                  aria-controls="building-info-details"
                  aria-selected="true"
                >
                  General Information
                </button>
              </li>

              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link brand-tab`}
                  id="geo-location1"
                  data-bs-toggle="tab"
                  data-bs-target="#general-liability"
                  type="button"
                  role="tab"
                  aria-controls="general-liability"
                  aria-selected="false"
                >
                  Contractor Information
                </button>
              </li>
            </ul>
            {details && (
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade active show"
                  id="building-info-details"
                  role="tabpanel"
                  aria-labelledby="build-info"
                >
                  <div className="activity-status-table">
                    <div className="table-responsive">
                      <table className="table table-striped table table-bordered">
                        <thead></thead>
                        <tbody id="body-render">
                          <tr>
                            <td>First Name</td>
                            <td>{details?.customerInfo?.firstName}</td>
                          </tr>
                          <tr>
                            <td>Last Name</td>
                            <td>{details?.customerInfo?.lastName}</td>
                          </tr>
                          <tr>
                            <td>Business Name</td>
                            <td>{details?.customerInfo?.businessName}</td>
                          </tr>
                          <tr>
                            <td>DBA</td>
                            <td>{details?.customerInfo?.dbaname}</td>
                          </tr>
                          <tr>
                            <td>Hours of Operation</td>
                            <td>
                              {details?.generalContractorInfo
                                ?.hoursOfOperation &&
                                formatHours(
                                  details?.generalContractorInfo
                                    ?.hoursOfOperation
                                )}
                            </td>
                          </tr>
                          <tr>
                            <td>Work Number</td>
                            <td>{details?.customerInfo?.workNum}</td>
                          </tr>
                          <tr>
                            <td>Mobile Number</td>
                            <td>{details?.customerInfo?.mobileNum}</td>
                          </tr>
                          <tr>
                            <td>Location Address</td>
                            <td>{details?.customerInfo?.locationAddress}</td>
                          </tr>
                          <tr>
                            <td>Mailing Address</td>
                            <td>{details?.customerInfo?.mailingAddress}</td>
                          </tr>
                          <tr>
                            <td>Email Address</td>
                            <td>{details?.customerInfo?.email}</td>
                          </tr>
                          <tr>
                            <td>Effective Date</td>
                            <td>
                              {details?.generalContractorInfo?.effectiveDate &&
                                formatDate(
                                  details?.generalContractorInfo?.effectiveDate
                                )}
                            </td>
                          </tr>

                          <tr>
                            <td>Coverage</td>
                            <td>
                              {details?.generalContractorInfo?.coverage?.map(
                                (type, index) => {
                                  return (
                                    <span
                                      key={index}
                                      className="badge rounded-pill bg-info text-light me-1"
                                    >
                                      {type}
                                    </span>
                                  );
                                }
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>Business Type</td>
                            <td>
                              {details?.generalContractorInfo?.typeOfBusiness}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div
                  className={`tab-pane fade`}
                  id="general-liability"
                  role="tabpanel"
                  aria-labelledby="build-info"
                >
                  <div className="activity-status-table">
                    <div className="table-responsive">
                      <table className="table table-striped table table-bordered">
                        <thead></thead>
                        <tbody id="body-render">
                          <tr>
                            <td>Annual Gross Sales</td>
                            <td>
                              <NumberFormat
                                value={
                                  details?.generalContractorInfo
                                    ?.annualGrossSale
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Cost For Sub-Contractor Out Of Gross Sales</td>
                            <td>
                              <NumberFormat
                                value={
                                  details?.generalContractorInfo
                                    ?.costOfSubContractor
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Percentage For Sub-Contractor Work</td>
                            <td>
                              {
                                details?.generalContractorInfo
                                  ?.persentOfSubContractorWrk
                              }
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Percentage Of Work Done By The Own Employees
                            </td>
                            <td>
                              {details?.generalContractorInfo?.persentOfEmprWrk}
                            </td>
                          </tr>
                          <tr>
                            <td>Payroll For The Employees</td>
                            <td>
                              <NumberFormat
                                value={
                                  details?.generalContractorInfo?.payrollOfEmp
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>How Many 1099 Employees</td>
                            <td>
                              <ThousandSeparator
                                value={
                                  details?.generalContractorInfo?._1099employee
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>How Many W-2 Employee</td>
                            <td>
                              <ThousandSeparator
                                value={
                                  details?.generalContractorInfo?.howmanyw2
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Do Employees Need To Be Added In The Coverage?
                            </td>
                            <td>
                              {details?.generalContractorInfo
                                ?.doEmpNeedToAddCoverage === "1"
                                ? "Yes"
                                : "No"}
                            </td>
                          </tr>
                          <tr>
                            <td>Need Coverage For Sub-Contractor?</td>
                            <td>
                              {details?.generalContractorInfo
                                ?.subContractorCoverage === 1
                                ? "Yes"
                                : "No"}
                            </td>
                          </tr>
                          <tr>
                            <td>Any Custom Homes Work?</td>
                            <td>
                              {details?.generalContractorInfo
                                ?.isCustomHomeWrk === 1
                                ? "Yes"
                                : "No"}
                            </td>
                          </tr>
                          <tr>
                            <td>Any Work For Hospitals?</td>
                            <td>
                              {details?.generalContractorInfo
                                ?.isWorkInHospital === 1
                                ? "Yes"
                                : "No"}
                            </td>
                          </tr>
                          <tr>
                            <td>Any Masonry Works?</td>
                            <td>
                              {details?.generalContractorInfo?.isMasonryWrk ===
                              1
                                ? "Yes"
                                : "No"}
                            </td>
                          </tr>
                          <tr>
                            <td>Carpentry?</td>
                            <td>
                              {details?.generalContractorInfo?.isCarpentry === 1
                                ? "Yes"
                                : "No"}
                            </td>
                          </tr>
                          <tr>
                            <td>Boiler Installations?</td>
                            <td>
                              {details?.generalContractorInfo
                                ?.isBoilerInstall === 1
                                ? "Yes"
                                : "No"}
                            </td>
                          </tr>
                          <tr>
                            <td>Concrete Construction?</td>
                            <td>
                              {details?.generalContractorInfo?.isConcreteWrk ===
                              1
                                ? "Yes"
                                : "No"}
                            </td>
                          </tr>
                          <tr>
                            <td>Air Conditioning/Heating?</td>
                            <td>
                              {details?.generalContractorInfo
                                ?.isAirConditionHeating === 1
                                ? "Yes"
                                : "No"}
                            </td>
                          </tr>
                          <tr>
                            <td>Environmental Cleanup?</td>
                            <td>
                              {details?.generalContractorInfo
                                ?.isEnvirnomentalCleanUp === 1
                                ? "Yes"
                                : "No"}
                            </td>
                          </tr>
                          <tr>
                            <td>Any Claim in past 5 years</td>
                            <td>
                              {details?.generalContractorInfo?.anyClaim === 1
                                ? "Yes"
                                : "No"}
                            </td>
                          </tr>
                          <tr>
                            <td>Claim Date</td>
                            <td>
                              {details?.generalContractorInfo?.claimDate &&
                                formatDate(
                                  details?.generalContractorInfo?.claimDate
                                )}
                            </td>
                          </tr>
                          <tr>
                            <td>Claim Type</td>
                            <td>{details?.generalContractorInfo?.claimType}</td>
                          </tr>
                          <tr>
                            <td>Prior Losses</td>
                            <td>
                              <NumberFormat
                                value={
                                  details?.generalContractorInfo?.priorLoss
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Select The Work You Do In A Regular Basis</td>
                            <td>
                              {" "}
                              {details?.generalContractorInfo?.selectedWork?.map(
                                (type, index) => {
                                  return (
                                    <span
                                      key={index}
                                      className="badge rounded-pill bg-info text-light me-1 text-capitalize"
                                    >
                                      {formatText(type)}%
                                    </span>
                                  );
                                }
                              )}
                            </td>
                          </tr>

                          <tr>
                            <td>Total Payroll</td>
                            <td>
                              <NumberFormat
                                value={
                                  details?.generalContractorInfo?.totalPayRoll
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>FEIN Number</td>
                            <td>{details?.generalContractorInfo?.feinNum}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default GenaralCView;
