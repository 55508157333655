import React, {useState} from 'react';
import Step1 from './step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Agencyheader from '../Agencyheader';
import ThankyouResponse from '../Thankyoupage';

const Master = () => {
    const [step, setStep] = useState(1)
    return (
        <div>
            <Agencyheader/>
            {step === 1 && <Step1 stepHandler={setStep} />}
            {step === 2 && <Step2 stepHandler={setStep} />}
            {step === 3 && <Step3 stepHandler={setStep} />}
            {step === 4 && <ThankyouResponse />}

        </div>
    );
};

export default Master;