import { IconFileExport, IconRefresh, IconSearch } from "@tabler/icons-react";
import React, { useEffect, useRef, useState } from "react";
import { downloadExcel } from "react-export-table-to-excel";
import { ThreeDots } from "react-loader-spinner";
import { default as axios } from "../../../config/axios-config";
import { showDateFormat } from "../../helper/Helper";
import CustomerDetails from "../CustomerDetails";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import AddComment from "../AddComment";

const DEVURL = process.env.REACT_APP_ANC_API + "/api/leads";

const Leads = () => {
    const navigation = useNavigate();
    const userData = useSelector((state) => state.auth.user);
    const [productLists, setProductLists] = useState(null);
    const [loader, setLoader] = useState(false);
    const [nextPage, setNextPage] = useState(0);
    const [previousPage, setPreviousPage] = useState(1);
    const searchInputRef = useRef("");
    const [currentPage, setCurrentPage] = useState(1);
    const [nextButtonEnable, setNextButtonEnable] = useState(false);
    const [pervButtonEnable, setPrevButtonEnable] = useState(true);
    const [selectedActivity, setSelectedActivity] = useState(null);
    const [perPage, setPerPage] = useState(10);
    const [geoLocation, setGeoLocation] = useState(null);
    const [loader_excel, setLoader_excel] = useState(false);
    const agencyId = localStorage.getItem("AgencyId");
    const salonTableRef = useRef(null);
    const getProduct = (type = null) => {
        setLoader(true);
        let currentP = 1;
        if (type) {
            if (type === "next") {
                currentP = nextPage; 
            } else {
                currentP = previousPage <= 1 ? 1 : previousPage;
            }
        }
        let filter = searchInputRef.current.value.length == 0 ? null : searchInputRef.current.value;
        if (type === "reset") {
            filter = null;   
            searchInputRef.current.value = null;
        }
        axios
            .get(`${DEVURL}/GetLeadsbyAgency/${userData.id}/${userData.agentId}/${filter}/${currentP}/${perPage}`)
            .then((response) => {
                if (response.status === 200) {
                    console.log('leadList',response);
                    setProductLists(response.data);
                }
                setCurrentPage(currentP);
                setNextPage(currentP + 1);
                setPreviousPage(currentP - 1);
                if (response.data.length === 0) {
                    setNextButtonEnable(true);
                    setPrevButtonEnable(false);
                }
                if (previousPage >= 1) {
                    setPrevButtonEnable(false);
                }
                setLoader(false);
            })
            .catch((error) => {
                setLoader(false);
                if (error?.response?.data?.status === 404) {
                } else {
                }
            });
    };

    useEffect(() => {
        getProduct();
    }, []);
    const handleDetailsClick = (data) => {
        setSelectedActivity(data);
    };
    const getData = async () => {
        const res = await axios.get("https://geolocation-db.com/json/");
        setGeoLocation(res.data);
    };
    useEffect(() => {
        // getData();
    }, []);
    const handleQuoteClick = (data) => {
        const result_Url = "/result?id=" + agencyId;
        navigation(result_Url, { state: { data } });
    };
    const handleDownloadExcel = () => {
        if (!salonTableRef.current) {
            setLoader_excel(false);
            return;
        }
        setLoader_excel(true);
        // Access the table and retrieve the visible rows
        const tableRows = salonTableRef.current.querySelectorAll("tbody tr");
        // Prepare data for export with specific columns
        const exportDataSpecificColumns = Array.from(tableRows).map((row) => {
            const columns = row.querySelectorAll("td");
            return {
                Name: columns[0].innerText,
                Email: columns[1].innerText,
                Mobile: columns[2].innerText,
                ZipCode: columns[3].innerText,
                Gender: columns[4].innerText,
                Income: columns[5].innerText,
                DateOfBirth: columns[6].innerText,
                CreatedDate: columns[7].innerText,
            };
        });
        // Use the prepared data for export
        downloadExcel({
            fileName: "Leads_Table",
            sheet: "leads_table",
            tablePayload: {
                header: ["Name", "Email", "Mobile", "Zip Code", "Gender", "Income", "Date Of Birth", "Created Date"],
                body: exportDataSpecificColumns,
            },
        });
        setLoader_excel(false);
    };

    return (
        <>
            <div>
                <div className="filter-box mt-3 mb-2">
                    <div className="filter-box-content">
                        <div className="row align-items-center">
                            <div className="col-lg-5">
                                <div className="form-floating">
                                    <input
                                        type="text"
                                        size={500}
                                        className="form-control"
                                        ref={searchInputRef}
                                        id="floatingInput"
                                        placeholder=""
                                    />
                                    <label htmlFor="floatingInput">Search by first name, last name or email</label>
                                </div>
                            </div>
                            <div className="col-lg-1">
                                <div className="d-grid d-md-flex justify-content-md-left" style={{ height: "50px" }}>
                                    <button className="btn btn-primary" type="button" onClick={() => getProduct()}>
                                        <div className="button-body-for-loader">
                                            <IconSearch />
                                            {loader && <ThreeDots color="#ffdb58" height={30} width={40} />}
                                        </div>
                                    </button>
                                    <button className="mx-2 btn btn-warning" type="button" onClick={() => getProduct("reset")}>
                                        <div className="button-body-for-loader">
                                            <IconRefresh />
                                        </div>
                                    </button>
                                    <button
                                        className="mx-2 btn btn-success"
                                        type="button"
                                        disabled={(!productLists?.items?.length > 0 && !loader) || loader_excel || loader}
                                        onClick={handleDownloadExcel}
                                    >
                                        <div className="button-body-for-loader">
                                            <IconFileExport />
                                            {loader_excel && <ThreeDots color="#ffdb58" height={30} width={40} />}
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card p-2 my-2">
                    <table ref={salonTableRef} className="table activity-status-table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                {productLists?.some(obj => obj.hasOwnProperty('agencyName')) && <th scope="col">Agency Name</th>}
                                <th scope="col">Name</th>
                                <th scope="col">Email</th>
                                <th scope="col">Mobile</th>
                                <th scope="col">Zip Code</th>
                                <th scope="col">Gender</th>
                                <th scope="col">Income</th>
                                <th scope="col">Date Of Birth</th>
                                <th scope="col">Created Date</th>
                                <th scope="col">Actions</th>
                                {/*<th scope="col">Renewal Date</th>*/}
                            </tr>
                        </thead>
                        <tbody>
                            {loader && (
                                <td colSpan={9} className="text-center">
                                    <ThreeDots
                                        height="50"
                                        width="60"
                                        color="#4fa94d"
                                        ariaLabel="bars-loading"
                                        wrapperStyle={{
                                            justifyContent: "center",
                                            paddingBottom: "10px",
                                        }}
                                        wrapperClass=""
                                        visible={true}
                                    />
                                </td>
                            )}
                            {!loader &&
                                productLists?.map((item, index) => (
                                    <tr key={index}>
                                        <th scope="row">{(currentPage - 1) * perPage + (index + 1)}</th>
                                        {item.hasOwnProperty('agencyName') && <td>{item.agencyName}</td>}
                                        <td>{item.name}</td>
                                        <td>{item.email}</td>
                                        <td>{item.mobile}</td>
                                        <td>{item.zipcode}</td>
                                        <td>{item.gender}</td>
                                        <td>{item.income}</td>
                                        <td>{showDateFormat(item.dateOfBirth)}</td>
                                        <td>{showDateFormat(item.createdDate)}</td>
                                        <td>
                                            <a data-bs-toggle="offcanvas" onClick={() => handleDetailsClick(item)} data-bs-target="#customerDetail" href="#">
                                                <span class="badge text-bg-primary">View </span>
                                            </a>
                                            &nbsp;
                                            {/* <a
                                  data-bs-toggle="offcanvas"
                                  onClick={() => handleQuoteClick(item)}
                                  data-bs-target="#editHealthcare"
                                  href="#"
                                >
                                Quote
                                </a> */}
                                            <span class="badge bg-secondary" style={{ cursor: "pointer" }} onClick={() => handleQuoteClick(item)}>
                                            Request Quote
                                            </span>{" "}
                                            &nbsp;
                                            <a data-bs-toggle="offcanvas" onClick={() => handleDetailsClick(item)} data-bs-target="#addComment" href="#">
                                                <span class="badge bg-info">Add Comment</span>
                                            </a>
                                        </td>
                                        {/*<td>{ showDateFormat(item.productRevnewalDate)}</td>*/}
                                    </tr>
                                ))}
                        </tbody>
                    </table>

                    <nav aria-label="Page navigation example" className="pull-right">
                        <ul className="pagination">
                            <li className="page-item">
                                <button className="page-link" onClick={() => getProduct("prev")} disabled={pervButtonEnable}>
                                    Previous
                                </button>
                            </li>
                            <li className="page-item">
                                <button className="page-link" onClick={() => getProduct("next")} disabled={nextButtonEnable}>
                                    Next
                                </button>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
            <CustomerDetails details={selectedActivity} type="Leads" />
            <AddComment details={selectedActivity} getProduct={getProduct} />
        </>
    );
};

export default Leads;
