import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const SmartCardMapping = () => {
  const { cardNumber } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (cardNumber) navigate(`/smartlink/${cardNumber}`);
  }, []);

  return null;
};

export default SmartCardMapping;
