import * as React from "react";
import { useEffect } from "react";
export default function BusinessHour(props) {
  const [startTime, setStartTime] = React.useState("");
  const [endTime, setEndTime] = React.useState("");
  const disabled = props?.disabled;

  useEffect(() => {
    setTimeout(() => {
      setStartTime(props.timeRange[0]);
      setEndTime(props.timeRange[1]);
    }, 500);
  }, [props.timeRange]);

  const handleStartTimeChange = (event) => {
    setStartTime(event.target.value);
    parseTimeRange(event.target.value, endTime);
  };

  const handleEndTimeChange = (event) => {
    setEndTime(event.target.value);
    parseTimeRange(startTime, event.target.value);
  };

  const parseTimeRange = (start, end) => {
    if (start !== "" && end !== "" && start !== end) {
      props.onTimeRangeChange(start + "-" + end);
    } else {
      props.onTimeRangeChange("");
    }
  };

  return (
    <div className="date-range">
      {/*<label className="pr-2">From</label>*/}
      <input
        type="time"
        value={startTime}
        className="form-control"
        disabled={disabled}
        onChange={handleStartTimeChange}
      />

      <label className="px-2">To</label>
      <input
        type="time"
        value={endTime}
        className="form-control"
        disabled={disabled}
        onChange={handleEndTimeChange}
      />
    </div>
  );
}
