import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { Badge } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import { useLocation } from "react-router-dom";
import * as yup from "yup";
import BusinessHour from "../../Common/BusinessHour";
import { currentYear, floatRegex } from "../../Common/regex";
import useExitConfirmationHandler from "../../hooks/useExitConfirmationHandler.hook";

const validationSchema = yup.object().shape({
  lastRoofUpdated: yup
    .string()
    .nullable(true)
    .test("is-past-date", "Date must be a past date", (value) => {
      if (!value || isNaN(Date.parse(value))) {
        return true;
      }
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);

      const inputDate = new Date(value);
      inputDate.setHours(0, 0, 0, 0);
      return inputDate < currentDate;
    })
    .label("Roof date"),

  lastWiringUpdated: yup
    .string()
    .nullable(true)
    .typeError("Please enter valid date")
    .test("is-past-date", "Date must be a past date", (value) => {
      if (!value || isNaN(Date.parse(value))) {
        return true;
      }
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);

      const inputDate = new Date(value);
      inputDate.setHours(0, 0, 0, 0);

      return inputDate < currentDate;
    })
    .label("Electrical date"),

  lastPlumbingUpdated: yup
    .string()
    .nullable(true)
    .typeError("Please enter valid date")
    .test("is-past-date", "Date must be a past date", (value) => {
      if (!value || isNaN(Date.parse(value))) {
        return true;
      }
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);

      const inputDate = new Date(value);
      inputDate.setHours(0, 0, 0, 0);

      return inputDate < currentDate;
    })
    .label("Plumbing date"),
  lastHeatingUpdated: yup
    .string()
    .nullable(true)
    .typeError("Please enter valid date")
    .test("is-past-date", "Date must be a past date", (value) => {
      if (!value || isNaN(Date.parse(value))) {
        return true;
      }
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);

      const inputDate = new Date(value);
      inputDate.setHours(0, 0, 0, 0);

      return inputDate < currentDate;
    })
    .label("Havc date"),

  yearBuilt: yup
    .string()
    .trim()
    .required("Year built is required")
    .matches(floatRegex, "Please enter a valid no. of years")
    .test(
      "is-past-or-current-year",
      "Year must be in the past or current year",
      function (value) {
        return value <= currentYear;
      }
    )
    .label("Year built"),
  noOfBuilding: yup
    .string()
    .trim()
    .required("No. of buildings is required")
    .matches(floatRegex, "Please enter a valid no. of buildings")
    .label("No. of buildings"),
  inventoryLimit: yup.string().trim().required("BPP is required").label("BPP"),
  howManySubRooms: yup
    .string()
    .trim()
    .required("No. of rooms is required")
    .label("No. of rooms"),
  eachBuilding: yup
    .string()
    .trim()
    .required("Sq. ft. of each building is required")
    .matches(floatRegex, "Please enter a valid sq. ft.")
    .label("Sq. ft. of each building"),
  totalArea: yup
    .string()
    .trim()
    .required("Total area is required")
    .matches(floatRegex, "Please enter a valid total area")
    .label("Total area"),
  signValue: yup
    .string()
    .trim()
    .required("Sign value is required")
    .matches(floatRegex, "Please enter a valid sign value")
    .label("Sign value"),

  isRegisteredFranchise: yup
    .string()
    .required("Registered franchise is required")
    .label("Registered franchise"),

  isUpdateConstruction: yup
    .string()
    .required("Construction update is required")
    .label("Construction update"),
  noOfStoriesEachBuilding: yup
    .string()
    .trim()
    .required("No. of storeys is required")
    .matches(floatRegex, "Please enter valid no. of storeys")
    .label("No. of storeys"),
  constructionType: yup
    .array()
    .typeError("Construction type is required")
    .min(1, "Construction type is required")
    .of(yup.string())
    .required("Construction type is required")
    .label("Construction type"),
  isBanquet: yup
    .string()
    .required("Banquet is required")
    .label("Banquet or conference room"),
  banquetSqFt: yup
    .string()
    .when("isBanquet", (isBanquet, schema) => {
      if (isBanquet && isBanquet.includes("1")) {
        return schema.required("Banquet sq. ft. is required");
      } else return schema.notRequired();
    })
    .label("Banquet sq. ft."),
  banquetCapacity: yup
    .string()
    .when("isBanquet", (isBanquet, schema) => {
      if (isBanquet && isBanquet.includes("1")) {
        return schema.required("Banquet capacity is required");
      } else return schema.notRequired();
    })
    .label("Banquet capacity"),
  isBuildingsStairs: yup
    .string()
    .required("Buildings stairs with is required")
    .label("Buildings stairs with"),

  isElevators: yup
    .string()
    .when("isBuildingsStairs", (isBuildingsStairs, schema) => {
      if (isBuildingsStairs && isBuildingsStairs.includes("1")) {
        return schema.required("Elevators is required");
      } else return schema.notRequired();
    })
    .label("Elavators"),

  isGym: yup.string().required("Gym is required").label("Gym"),
  gymHour: yup
    .string()
    .when("isGym", (isGym, schema) => {
      if (isGym && isGym?.includes("1")) {
        return schema.required("Gym hours is required");
      } else return schema.notRequired();
    })
    .label("Gym hours"),

  gymSqft: yup
    .string()
    .when("isGym", (isGym, schema) => {
      if (isGym && isGym?.includes("1")) {
        return schema.required("Gym sq. ft. is required");
      } else return schema.notRequired();
    })
    .label("Gym sq. ft."),
  isGymMaintenanceAvailable: yup
    .string()
    .when("isGym", (isGym, schema) => {
      if (isGym && isGym?.includes("1")) {
        return schema.required("Maintenance is required");
      } else return schema.notRequired();
    })
    .label("Maintenance"),
  isSwimmingPool: yup
    .string()
    .required("Swimming pool is required")
    .label("Swimming pool"),
  swimmingNoOfPools: yup
    .string()
    .when("isSwimmingPool", (isSwimmingPool, schema) => {
      if (isSwimmingPool && isSwimmingPool?.includes("1")) {
        return schema.required("No. of swimming pool is required");
      } else return schema.notRequired();
    })
    .label("No. of swimming pool"),
  isSwimmingPoolLocation: yup
    .string()
    .when("isSwimmingPool", (isSwimmingPool, schema) => {
      if (isSwimmingPool && isSwimmingPool?.includes("1")) {
        return schema.required("Swimming pool location is required");
      } else return schema.notRequired();
    })
    .label("Swimming pool location"),
  swimmingPoolDepth: yup
    .string()
    .when("isSwimmingPool", (isSwimmingPool, schema) => {
      if (isSwimmingPool && isSwimmingPool?.includes("1")) {
        return schema.required("Swimming pool depth is required");
      } else return schema.notRequired();
    })
    .label("Swimming pool depth"),
  isSwimmingWellFenced: yup
    .string()
    .when("isSwimmingPool", (isSwimmingPool, schema) => {
      if (isSwimmingPool && isSwimmingPool?.includes("1")) {
        return schema.required("Well fenced is required");
      } else return schema.notRequired();
    })
    .label("Well fenced"),
  isSwimmingLifeGuard: yup
    .string()
    .when("isSwimmingPool", (isSwimmingPool, schema) => {
      if (isSwimmingPool && isSwimmingPool?.includes("1")) {
        return schema.required("Availability of life guard  is required");
      } else return schema.notRequired();
    })
    .label("Availability of life guard"),
  isSwimmingDoorShutOff: yup
    .string()
    .when("isSwimmingPool", (isSwimmingPool, schema) => {
      if (isSwimmingPool && isSwimmingPool?.includes("1")) {
        return schema.required("Automatic door shutoff  is required");
      } else return schema.notRequired();
    })
    .label("Automatic door shutoff"),
  isSwimmingDivingBoard: yup
    .string()
    .when("isSwimmingPool", (isSwimmingPool, schema) => {
      if (isSwimmingPool && isSwimmingPool?.includes("1")) {
        return schema.required("Diving board is required");
      } else return schema.notRequired();
    })
    .label("Diving board"),
  isSwimmingHotTub: yup
    .string()
    .required("Hot tub is required")
    .label("Hot tub"),
  swimmingNoOfHotTubs: yup
    .string()
    .when("isSwimmingHotTub", (isSwimmingHotTub, schema) => {
      if (isSwimmingHotTub && isSwimmingHotTub?.includes("1")) {
        return schema.required("No. of hot tub is required");
      } else return schema.notRequired();
    })
    .label("No. of hot tubs"),
  isSwimmingHotTubLocation: yup
    .string()
    .when("isSwimmingHotTub", (isSwimmingHotTub, schema) => {
      if (isSwimmingHotTub && isSwimmingHotTub?.includes("1")) {
        return schema.required("Hot tub location is required");
      } else return schema.notRequired();
    })
    .label("Hot tub location"),
  isBarDanceFloor: yup
    .string()
    .required("Bar or dance floor is required")
    .label("Bar or dance floor"),
  barDanceHours: yup
    .string()
    .when("isBarDanceFloor", (isBarDanceFloor, schema) => {
      if (isBarDanceFloor && isBarDanceFloor?.includes("1")) {
        return schema.required("Bar/dance hours is required");
      } else return schema.notRequired();
    })
    .label("Hours"),
  ageGroup: yup
    .string()
    .when("isBarDanceFloor", (isBarDanceFloor, schema) => {
      if (isBarDanceFloor && isBarDanceFloor?.includes("1")) {
        return schema.required("Age group is required");
      } else return schema.notRequired();
    })
    .label("Age group"),
  isHappyHours: yup
    .string()
    .when("isBarDanceFloor", (isBarDanceFloor, schema) => {
      if (isBarDanceFloor && isBarDanceFloor?.includes("1")) {
        return schema.required("Happy hours is required");
      } else return schema.notRequired();
    })
    .label("Happy hours"),
  isRestaurant: yup
    .string()
    .required("Restaurant is required")
    .label("Restaurant"),
  isRoomRentOutBasis: yup
    .string()
    .required("Room rent out is required")
    .label("Room rent out"),
  seatingCapacity: yup
    .string()
    .when("isRestaurant", (isRestaurant, schema) => {
      if (isRestaurant && isRestaurant?.includes("1")) {
        return schema.required("Seating capacity is required");
      } else return schema.notRequired();
    })
    .label("Seating capacity"),
  restaurantHours: yup
    .string()
    .when("isRestaurant", (isRestaurant, schema) => {
      if (isRestaurant && isRestaurant?.includes("1")) {
        return schema.required("Restaurant hours is required");
      } else return schema.notRequired();
    })
    .label("Hours of operation"),
  crimeCoverageLimit: yup
    .string()
    .trim()
    .required("Crime coverage limit is required")
    .matches(floatRegex, "Crime coverage limit is required")
    .label("Crime coverage limit"),
  noOfManagersPerShift: yup
    .string()
    .trim()
    .required("No. of managers per shift is required")
    .matches(floatRegex, "No. of managers per shift is required")
    .label("No. of managers per shift"),
  managersCheckinCheckout: yup
    .string()
    .trim()
    .typeError("Please enter a valid time")
    .required("CheckIn checkout time is required")
    .label("CheckIn checkout time"),
  protectiveSafeguard: yup
    .array()
    .typeError("Protective safeguard is required")
    .min(1, "Protective safeguard is required")
    .of(yup.string())
    .required("Protective safeguard is required")
    .label("Protective safeguard"),

  isShuttleService: yup
    .string()
    .required("Shuttle service is required")
    .label("Shuttle service"),
  isRoomWithKitchen: yup
    .string()
    .required("Rooms with kitchen is required")
    .label("Rooms with kitchen"),
  isPlayGround: yup
    .string()
    .required("Playground is required")
    .label("Playground"),
  isEntertainmentRoom: yup
    .string()
    .required("Entertainment rooms is required")
    .label("Entertainment rooms"),
  isSubsidizedRate: yup
    .string()
    .required("Subsidized rates is required")
    .label("Subsidized rates"),
});

const Step2 = ({ stepHandler }) => {
  const  handleExitButton  = useExitConfirmationHandler();
  const query = new URLSearchParams(useLocation().search);
  const restaurantId = query.get("primaryid");
  const mode = query.get("mode");
  const isView = mode ? (mode === "view" ? true : false) : "";
  const [gymHour, setgymHour] = useState(["", ""]);
  const [barDanceHours, setbarDanceHours] = useState(["", ""]);
  const [restaurantHours, setrestaurantHours] = useState(["", ""]);
  const [managersCheckinCheckout, setmanagersCheckinCheckout] = useState([
    "",
    "",
  ]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
    reset,
    watch,
    control,
  } = useForm({
    defaultValues: {
      yearBuilt: null,
      noOfBuilding: null,
      constructionType: [],
      protectiveSafeguard: [],
      inventoryLimit: "",
      totalArea: null,
      eachBuilding: null,
      signValue: null,
      isElevators: "",
      isRegisteredFranchise: "",
      isBuildingsStairs: "",
      isBanquet: "",
      noOfStoriesEachBuilding: null,
      lastRoofUpdated: null,
      lastWiringUpdated: null,
      lastPlumbingUpdated: null,
      lastHeatingUpdated: null,
      isUpdateConstruction: "",
      isGym: "",
      banquetSqFt: null,
      banquetCapacity: null,
      gymSqft: null,
      gymHour: "",
      isGymMaintenanceAvailable: "",
      isSwimmingPool: "",
      swimmingNoOfPools: null,
      isSwimmingPoolLocation: "",
      swimmingPoolDepth: null,
      isSwimmingWellFenced: "",
      isSwimmingLifeGuard: "",
      isSwimmingDoorShutOff: "",
      isSwimmingDivingBoard: "",
      isSwimmingHotTub: "",
      swimmingNoOfHotTubs: null,
      isSwimmingHotTubLocation: "",
      isBarDanceFloor: "",
      barDanceHours: "",
      ageGroup: "",
      isHappyHours: "",
      isRestaurant: "",
      seatingCapacity: null,
      noOfEmergencyExits: null,
      crimeCoverageLimit: null,
      noOfManagersPerShift: null,
      managersCheckinCheckout: "",
      restaurantHours: "",
      isRoomRentOutBasis: "",
      isShuttleService: "",
      isRoomWithKitchen: "",
      isPlayGround: "",
      isEntertainmentRoom: "",
      isSubsidizedRate: "",
      isCyberLiabilityCoverage: "",
      howManySubRooms: null,
      averageOccupancyRate: null,
    },
    resolver: yupResolver(validationSchema),
  });

  const isBanquetValue = watch("isBanquet");
  const isBuildingsStairsValue = watch("isBuildingsStairs");
  const isGymValue = watch("isGym");
  const isSwimmingPoolValue = watch("isSwimmingPool");
  const isSwimmingHotTubValue = watch("isSwimmingHotTub");
  const isBarDanceFloorValue = watch("isBarDanceFloor");
  const isRestaurantValue = watch("isRestaurant");

  const fillupStep2HotelInfoFromLocalStorage = (formData) => {
    setValue("yearBuilt", formData.yearBuilt);
    setValue("noOfBuilding", formData.noOfBuilding);
    setValue("inventoryLimit", formData.inventoryLimit);
    setValue("eachBuilding", formData.eachBuilding);
    setValue("signValue", formData.signValue);
    setValue("totalArea", formData.totalArea);
    setValue("constructionType", formData.constructionType);
    setValue("isUpdateConstruction", formData.isUpdateConstruction);
    setValue("protectiveSafeguard", formData.protectiveSafeguard);
    setValue("isElevators", formData.isElevators);
    setValue("isRegisteredFranchise", formData.isRegisteredFranchise);
    setValue("isBuildingsStairs", formData.isBuildingsStairs);
    setValue("isBanquet", formData.isBanquet);
    setValue("noOfStoriesEachBuilding", formData.noOfStoriesEachBuilding);
    setValue("lastRoofUpdated", formData.lastRoofUpdated);
    setValue("lastWiringUpdated", formData.lastWiringUpdated);
    setValue("lastPlumbingUpdated", formData.lastPlumbingUpdated);
    setValue("lastHeatingUpdated", formData.lastHeatingUpdated);
    setValue("isGym", formData.isGym);
    setValue("banquetSqFt", formData.banquetSqFt);
    setValue("banquetCapacity", formData.banquetCapacity);
    setValue("gymSqft", formData.gymSqft);
    setValue("isGymMaintenanceAvailable", formData.isGymMaintenanceAvailable);
    setValue("gymHour", formData.gymHour);
    let gymHrOfOperation = formData.gymHour;
    gymHrOfOperation = gymHrOfOperation.split("-");
    setgymHour(gymHrOfOperation);
    setValue("isSwimmingPool", formData.isSwimmingPool);
    setValue("swimmingNoOfPools", formData.swimmingNoOfPools);
    setValue("isSwimmingPoolLocation", formData.isSwimmingPoolLocation);
    setValue("swimmingPoolDepth", formData.swimmingPoolDepth);
    setValue("isSwimmingWellFenced", formData.isSwimmingWellFenced);
    setValue("isSwimmingLifeGuard", formData.isSwimmingLifeGuard);
    setValue("isSwimmingDoorShutOff", formData.isSwimmingDoorShutOff);
    setValue("isSwimmingDivingBoard", formData.isSwimmingDivingBoard);
    setValue("isSwimmingHotTub", formData.isSwimmingHotTub);
    setValue("swimmingNoOfHotTubs", formData.swimmingNoOfHotTubs);
    setValue("isSwimmingHotTubLocation", formData.isSwimmingHotTubLocation);
    setValue("isBarDanceFloor", formData.isBarDanceFloor);
    setValue("barDanceHours", formData.barDanceHours);
    let hrOfBarDanceFloor = formData.barDanceHours;
    hrOfBarDanceFloor = hrOfBarDanceFloor.split("-");
    setbarDanceHours(hrOfBarDanceFloor);
    setValue("ageGroup", formData.ageGroup);
    setValue("isHappyHours", formData.isHappyHours);
    setValue("isRestaurant", formData.isRestaurant);
    setValue("seatingCapacity", formData.seatingCapacity);
    setValue("restaurantHours", formData.restaurantHours);
    let hrOfOperation = formData.restaurantHours;
    hrOfOperation = hrOfOperation.split("-");
    setrestaurantHours(hrOfOperation);
    setValue("noOfEmergencyExits", formData.noOfEmergencyExits);
    setValue("crimeCoverageLimit", formData.crimeCoverageLimit);
    setValue("noOfManagersPerShift", formData.noOfManagersPerShift);
    setValue("managersCheckinCheckout", formData.managersCheckinCheckout);
    let hrOfmanagersCheckinCheckout = formData.managersCheckinCheckout;
    hrOfmanagersCheckinCheckout = hrOfmanagersCheckinCheckout.split("-");
    setmanagersCheckinCheckout(hrOfmanagersCheckinCheckout);
    setValue("isRoomRentOutBasis", formData.isRoomRentOutBasis);
    setValue("protectiveSafeguard", formData.protectiveSafeguard);
    setValue("isShuttleService", formData.isShuttleService);
    setValue("isRoomWithKitchen", formData.isRoomWithKitchen);
    setValue("isPlayGround", formData.isPlayGround);
    setValue("isEntertainmentRoom", formData.isEntertainmentRoom);
    setValue("isSubsidizedRate", formData.isSubsidizedRate);
    setValue("isCyberLiabilityCoverage", formData.isCyberLiabilityCoverage);
    setValue("howManySubRooms", formData.howManySubRooms);
    setValue("averageOccupancyRate", formData.averageOccupancyRate);
  };

  useEffect(() => {
    const formData = JSON.parse(localStorage.getItem("step2"));
    if (formData) {
      fillupStep2HotelInfoFromLocalStorage(formData);
    }
  }, []);

  useEffect(() => {
    if (isBanquetValue?.includes("0")) {
      setValue("banquetCapacity", null);
      setValue("banquetSqFt", null);
    }
    if (isBuildingsStairsValue?.includes("0")) {
      setValue("isElevators", "");
    }
    if (isGymValue?.includes("0")) {
      setValue("gymSqft", null);
      setValue("gymHour", "");
      setgymHour(["", ""]);
      setValue("isGymMaintenanceAvailable", "");
    }
  }, [isBanquetValue, isBuildingsStairsValue, isGymValue]);

  useEffect(() => {
    if (isSwimmingPoolValue?.includes("0")) {
      setValue("swimmingNoOfPools", null);
      setValue("isSwimmingPoolLocation", "");
      setValue("swimmingPoolDepth", null);
      setValue("isSwimmingWellFenced", "");
      setValue("isSwimmingLifeGuard", "");
      setValue("isSwimmingDoorShutOff", "");
      setValue("isSwimmingDivingBoard", "");
    }
    if (isSwimmingHotTubValue?.includes("0")) {
      setValue("swimmingNoOfHotTubs", null);
      setValue("isSwimmingHotTubLocation", "");
    }
    if (isBarDanceFloorValue?.includes("0")) {
      setValue("barDanceHours", "");
      setbarDanceHours(["", ""]);
      setValue("ageGroup", "");
      setValue("isHappyHours", "");
    }
    if (isRestaurantValue?.includes("0")) {
      setValue("seatingCapacity", null);
      setValue("restaurantHours", "");
      setrestaurantHours(["", ""]);
    }
  }, [
    isSwimmingPoolValue,
    isSwimmingHotTubValue,
    isBarDanceFloorValue,
    isRestaurantValue,
  ]);

  const setStep2ToLocalStorage = (data) => {
    localStorage.setItem("step2", JSON.stringify(data));
  };

  const handleFormSubmit = async (data) => {
    if (data.lastRoofUpdated?.trim().length === 0) {
      data.lastRoofUpdated = null;
    }
    if (data.lastWiringUpdated?.trim().length === 0) {
      data.lastWiringUpdated = null;
    }
    if (data.lastPlumbingUpdated?.trim().length === 0) {
      data.lastPlumbingUpdated = null;
    }
    if (data.lastHeatingUpdated?.trim().length === 0) {
      data.lastHeatingUpdated = null;
    }
    stepHandler(3);
    setStep2ToLocalStorage(data);
  };

  const handlePrevious = (e) => {
    stepHandler(1);
  };

  const getTimeRange = async (range) => {
    setValue("gymHour", range);
  };

  const getBarDanceTimeRange = async (range) => {
    setValue("barDanceHours", range);
  };

  const getHrOfOpTimeRange = (range) => {
    setValue("restaurantHours", range);
    console.log(range);
  };
  const getHrOfmanagersCheckinCheckout = (range) => {
    setValue("managersCheckinCheckout", range);
  };

  return (
    <>
      <form
        className="tab-wizard wizard-circle wizard needs-validation"
        onSubmit={handleSubmit(handleFormSubmit)}
      >
        <section>
          <h4 className="wrap-title mb-3">Building Information</h4>
          <div className="row">
            <div class="col-md-6 col-lg-3">
              <div className="mb-2">
                <label className="brand-label text-capitalize">
                  Year Built?<span className="required-mark">*</span>
                </label>
                <Controller
                  name="yearBuilt"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      {...field}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      className="form-control"
                      name="yearBuilt"
                      onValueChange={(values) => {
                        setValue("yearBuilt", values.value);
                      }}
                      placeholder="Enter Year Built"
                      readOnly={isView}
                      {...register("yearBuilt")}
                    />
                  )}
                />

                <p className="text-danger">{errors.yearBuilt?.message}</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-3">
              <div className="mb-2">
                <label className="brand-label text-capitalize">
                  No. of Buildings?<span className="required-mark">*</span>
                </label>
                <Controller
                  name="noOfBuilding"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      {...field}
                      allowNegative={false}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      id="noOfBuilding"
                      className="form-control"
                      thousandSeparator={true}
                      name="noOfBuilding"
                      onValueChange={(values) => {
                        setValue("noOfBuilding", values.value);
                      }}
                      placeholder="Enter No. of Buildings"
                      {...register("noOfBuilding")}
                    />
                  )}
                />
                <p className="text-danger">{errors.noOfBuilding?.message}</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-3">
              <div className="mb-2">
                <label className="brand-label text-capitalize">
                  BPP/Inventory Limit?
                  <span className="required-mark">*</span>
                </label>
                <Controller
                  name="inventoryLimit"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      {...field}
                      id="inventoryLimit"
                      className="form-control"
                      thousandSeparator={true}
                      prefix="$"
                      name="inventoryLimit"
                      onValueChange={(values) => {
                        setValue("inventoryLimit", values.floatValue);
                      }}
                      placeholder="Enter BPP/Inventory Limit"
                      readOnly={isView}
                      {...register("inventoryLimit")}
                    />
                  )}
                />
                <p className="text-danger">{errors.inventoryLimit?.message}</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-3">
              <div className="mb-2">
                <label className="brand-label text-capitalize">
                  Sq. Ft. of Each Building?
                  <span className="required-mark">*</span>
                </label>

                <Controller
                  name="eachBuilding"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      {...field}
                      id="eachBuilding"
                      className="form-control"
                      thousandSeparator={true}
                      name="eachBuilding"
                      onValueChange={(values) => {
                        setValue("eachBuilding", values.floatValue);
                      }}
                      placeholder="Enter Sq. Ft. of Each Building"
                      readOnly={isView}
                      {...register("eachBuilding")}
                    />
                  )}
                />
                <p className="text-danger">{errors.eachBuilding?.message}</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-3">
              <div className="mb-2">
                <label className="brand-label text-capitalize">
                  Sign Value?<span className="required-mark">*</span>
                </label>
                <Controller
                  name="signValue"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      {...field}
                      allowNegative={false}
                      id="signValue"
                      className="form-control"
                      thousandSeparator={true}
                      prefix="$"
                      name="signValue"
                      onValueChange={(values) => {
                        setValue("signValue", values.floatValue);
                      }}
                      placeholder="Enter Sign Value"
                      readOnly={isView}
                      {...register("signValue")}
                    />
                  )}
                />
                <p className="text-danger">{errors.signValue?.message}</p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="mb-2">
                <label className="brand-label text-capitalize">
                  Total area?
                  <span className="required-mark">*</span>
                </label>
                <Controller
                  name="totalArea"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      {...field}
                      id="totalArea"
                      className="form-control"
                      thousandSeparator={true}
                      name="totalArea"
                      onValueChange={(values) => {
                        setValue("totalArea", values.floatValue);
                      }}
                      placeholder="Enter Total Area"
                      readOnly={isView}
                      {...register("totalArea")}
                    />
                  )}
                />
                <p className="text-danger">{errors.totalArea?.message}</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-3">
              <div className="mb-2">
                <label className="brand-label text-capitalize">
                  No. of storeys (each building)?
                  <span className="required-mark">*</span>
                </label>
                <Controller
                  name="noOfStoriesEachBuilding"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      {...field}
                      id="noOfStoriesEachBuilding"
                      className="form-control"
                      thousandSeparator={true}
                      name="noOfStoriesEachBuilding"
                      onValueChange={(values) => {
                        setValue("noOfStoriesEachBuilding", values.floatValue);
                      }}
                      placeholder="Enter No. of Storeys (Each Building)"
                      readOnly={isView}
                      {...register("noOfStoriesEachBuilding")}
                    />
                  )}
                />
                <p className="text-danger">
                  {errors.noOfStoriesEachBuilding?.message}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="mb-2">
                <label className="brand-label text-capitalize">
                  Any Registered franchise?
                  <span className="required-mark">*</span>
                </label>
                <div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="isRegisteredFranchiseYes"
                      value="1"
                      {...register("isRegisteredFranchise")}
                    />
                    <label
                      className="form-check-label text-capitalize"
                      for="isRegisteredFranchiseYes"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="isRegisteredFranchiseNo"
                      value="0"
                      {...register("isRegisteredFranchise")}
                    />
                    <label
                      className="form-check-label text-capitalize"
                      for="isRegisteredFranchiseNo"
                    >
                      No
                    </label>
                  </div>
                </div>
                <p className="text-danger">
                  {errors.isRegisteredFranchise?.message}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="mb-2">
                <label className="brand-label text-capitalize">
                  Buildings Stairs With?
                  <span className="required-mark">*</span>
                </label>
                <div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="isBuildingsStairsYes"
                      value="1"
                      {...register("isBuildingsStairs")}
                    />
                    <label
                      className="form-check-label text-capitalize"
                      for="isBuildingsStairsYes"
                    >
                      Interior
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="isBuildingsStairsNo"
                      value="0"
                      {...register("isBuildingsStairs")}
                    />
                    <label
                      className="form-check-label text-capitalize"
                      for="isBuildingsStairsNo"
                    >
                      Exterior
                    </label>
                  </div>
                </div>
                <p className="text-danger">
                  {errors.isBuildingsStairs?.message}
                </p>
              </div>
            </div>

            <div className="col-md-6 col-lg-3">
              <div className="mb-2">
                <label className="brand-label text-capitalize">
                  <div>Any Elevators?</div>
                </label>
                <div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="isElevatorsYes"
                      value="1"
                      disabled={!isBuildingsStairsValue?.includes("1")}
                      {...register("isElevators")}
                    />
                    <label
                      className="form-check-label text-capitalize"
                      for="isElevatorsYes"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="isElevatorsNo"
                      value="0"
                      disabled={!isBuildingsStairsValue?.includes("1")}
                      {...register("isElevators")}
                    />
                    <label
                      className="form-check-label text-capitalize"
                      for="isElevatorsNo"
                    >
                      No
                    </label>
                  </div>
                </div>

                <p className="text-danger">{errors.isElevators?.message}</p>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="mb-3">
                <label class="brand-label text-capitalize">
                  Any update in construction?
                  <span className="required-mark">*</span>
                </label>

                <div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="updateConstructionYes"
                      value="1"
                      {...register("isUpdateConstruction")}
                    />
                    <label class="form-check-label" for="updateConstructionYes">
                      Yes
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="updateConstructionNo"
                      value="0"
                      {...register("isUpdateConstruction")}
                    />
                    <label class="form-check-label" for="updateConstructionNo">
                      No
                    </label>
                  </div>
                </div>
                <p className="text-danger">
                  {errors.isUpdateConstruction && (
                    <p>{errors.isUpdateConstruction.message}</p>
                  )}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="mb-2">
                <label className="brand-label text-capitalize">
                  Any banquet?
                  <span className="required-mark">*</span>
                </label>
                <div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="isBanquetYes"
                      value="1"
                      {...register("isBanquet")}
                    />
                    <label
                      className="form-check-label text-capitalize"
                      for="isBanquetYes"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="isBanquetNo"
                      value="0"
                      {...register("isBanquet")}
                    />
                    <label
                      className="form-check-label text-capitalize"
                      for="isBanquetNo"
                    >
                      No
                    </label>
                  </div>
                </div>
                <p className="text-danger">{errors.isBanquet?.message}</p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="mb-2">
                <label className="brand-label text-capitalize">
                  Banquet Sq. Ft.?
                </label>
                <Controller
                  name="banquetSqFt"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      {...field}
                      id="banquetSqFt"
                      className="form-control"
                      thousandSeparator={true}
                      name="banquetSqFt"
                      value={isBanquetValue?.includes("1") ? field.value : ""}
                      disabled={!isBanquetValue?.includes("1")}
                      onValueChange={(values) => {
                        setValue("banquetSqFt", values.floatValue);
                      }}
                      placeholder="Enter Banquet Sq. Ft."
                      readOnly={isView}
                      {...register("banquetSqFt")}
                    />
                  )}
                />
                <p className="text-danger">{errors.banquetSqFt?.message}</p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="mb-2">
                <label className="brand-label text-capitalize">
                  Banquet Capacity?
                </label>
                <Controller
                  name="banquetCapacity"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      {...field}
                      id="banquetCapacity"
                      className="form-control"
                      thousandSeparator={true}
                      name="banquetCapacity"
                      value={isBanquetValue?.includes("1") ? field.value : ""}
                      disabled={!isBanquetValue?.includes("1")}
                      onValueChange={(values) => {
                        setValue("banquetCapacity", values.floatValue);
                      }}
                      placeholder="Enter Banquet Capacity"
                      {...register("banquetCapacity")}
                    />
                  )}
                />
                <p className="text-danger">{errors.banquetCapacity?.message}</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-3">
              <div className="mb-2">
                <label className="brand-label text-capitalize">
                  Any Gym?
                  <span className="required-mark">*</span>
                </label>
                <div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="isGymYes"
                      value="1"
                      {...register("isGym")}
                    />
                    <label
                      className="form-check-label text-capitalize"
                      for="isGymYes"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="isGymNo"
                      value="0"
                      {...register("isGym")}
                    />
                    <label
                      className="form-check-label text-capitalize"
                      for="isGymNo"
                    >
                      No
                    </label>
                  </div>
                </div>
                <p className="text-danger">{errors.isGym?.message}</p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="mb-2">
                <label className="brand-label text-capitalize">
                  maintenance available?
                </label>
                <div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="isGymMaintenanceAvailableYes"
                      value="1"
                      disabled={!isGymValue?.includes("1")}
                      {...register("isGymMaintenanceAvailable")}
                    />
                    <label
                      className="form-check-label text-capitalize"
                      for="isGymMaintenanceAvailableYes"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="isGymMaintenanceAvailableNo"
                      value="0"
                      disabled={!isGymValue?.includes("1")}
                      {...register("isGymMaintenanceAvailable")}
                    />
                    <label
                      className="form-check-label text-capitalize"
                      for="isGymMaintenanceAvailableNo"
                    >
                      No
                    </label>
                  </div>
                </div>
                <p className="text-danger">
                  {errors.isGymMaintenanceAvailable?.message}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="mb-2">
                <label className="brand-label text-capitalize">
                  Gym Sq. Ft.?
                </label>
                <Controller
                  name="gymSqft"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      {...field}
                      id="gymSqft"
                      className="form-control"
                      thousandSeparator={true}
                      name="gymSqft"
                      value={isGymValue?.includes("1") ? field.value : ""}
                      disabled={!isGymValue?.includes("1")}
                      onValueChange={(values) => {
                        setValue("gymSqft", values.floatValue);
                      }}
                      placeholder="Enter Gym Sq. Ft."
                      readOnly={isView}
                      {...register("gymSqft")}
                    />
                  )}
                />
                <p className="text-danger">{errors.gymSqft?.message}</p>
              </div>
            </div>
            <div className="col-md-6 col-lg-6">
              <div className="mb-3">
                <label className="brand-label">
                  <div className="d-flex">
                    Gym Hours&nbsp;
                    <div>
                      <Badge bg="info">(Start and End Time)</Badge>
                    </div>
                  </div>
                </label>
                {/*// for edit pass the start= 09:30 and end = 18:30 */}
                <BusinessHour
                  onTimeRangeChange={getTimeRange}
                  timeRange={gymHour}
                  disabled={!isGymValue?.includes("1")}
                />

                <p className="text-danger">
                  {errors.gymHour && <p>{errors.gymHour.message}</p>}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="mb-2">
                <label className="brand-label text-capitalize">
                  Any Swimming pools?
                  <span className="required-mark">*</span>
                </label>
                <div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="isSwimmingPoolYes"
                      value="1"
                      {...register("isSwimmingPool")}
                    />
                    <label
                      className="form-check-label text-capitalize"
                      for="isSwimmingPoolYes"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="isSwimmingPoolNo"
                      value="0"
                      {...register("isSwimmingPool")}
                    />
                    <label
                      className="form-check-label text-capitalize"
                      for="isSwimmingPoolNo"
                    >
                      No
                    </label>
                  </div>
                </div>
                <p className="text-danger">{errors.isSwimmingPool?.message}</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-3">
              <div className="mb-2">
                <label className="brand-label text-capitalize">
                  Swimming pool location?
                </label>
                <div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="insideCheck"
                      disabled={!isSwimmingPoolValue?.includes("1")}
                      value="1"
                      {...register("isSwimmingPoolLocation")}
                    />
                    <label
                      className="form-check-label text-capitalize"
                      for="insideCheck"
                    >
                      Inside
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="outsideCheck"
                      disabled={!isSwimmingPoolValue?.includes("1")}
                      value="0"
                      {...register("isSwimmingPoolLocation")}
                    />
                    <label
                      className="form-check-label text-capitalize"
                      for="outsideCheck"
                    >
                      Outside
                    </label>
                  </div>
                </div>
                <p className="text-danger">
                  {errors.isSwimmingPoolLocation?.message}
                </p>
              </div>
            </div>

            <div className="col-md-6 col-lg-3">
              <div className="mb-2">
                <label className="brand-label text-capitalize">
                  well fenced?
                </label>
                <div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="isSwimmingWellFencedCheckYes"
                      disabled={!isSwimmingPoolValue?.includes("1")}
                      value="1"
                      {...register("isSwimmingWellFenced")}
                    />
                    <label
                      className="form-check-label text-capitalize"
                      for="isSwimmingWellFencedCheckYes"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="isSwimmingWellFencedCheckNo"
                      disabled={!isSwimmingPoolValue?.includes("1")}
                      value="0"
                      {...register("isSwimmingWellFenced")}
                    />
                    <label
                      className="form-check-label text-capitalize"
                      for="isSwimmingWellFencedCheckNo"
                    >
                      No
                    </label>
                  </div>
                </div>
                <p className="text-danger">
                  {errors.isSwimmingWellFenced?.message}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="mb-2">
                <label className="brand-label text-capitalize">
                  life guard available?
                </label>
                <div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="lifeGuardCheckYes"
                      disabled={!isSwimmingPoolValue?.includes("1")}
                      value="1"
                      {...register("isSwimmingLifeGuard")}
                    />
                    <label
                      className="form-check-label text-capitalize"
                      for="lifeGuardCheckYes"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="lifeGuardCheckNo"
                      disabled={!isSwimmingPoolValue?.includes("1")}
                      value="0"
                      {...register("isSwimmingLifeGuard")}
                    />
                    <label
                      className="form-check-label text-capitalize"
                      for="lifeGuardCheckNo"
                    >
                      No
                    </label>
                  </div>
                </div>
                <p className="text-danger">
                  {errors.isSwimmingLifeGuard?.message}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="mb-2">
                <label className="brand-label text-capitalize">
                  Automatic door Shutoff?
                </label>
                <div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="isSwimmingDoorShutOffCheckYes"
                      disabled={!isSwimmingPoolValue?.includes("1")}
                      value="1"
                      {...register("isSwimmingDoorShutOff")}
                    />
                    <label
                      className="form-check-label text-capitalize"
                      for="isSwimmingDoorShutOffCheckYes"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="isSwimmingDoorShutOffCheckNo"
                      disabled={!isSwimmingPoolValue?.includes("1")}
                      value="0"
                      {...register("isSwimmingDoorShutOff")}
                    />
                    <label
                      className="form-check-label text-capitalize"
                      for="isSwimmingDoorShutOffCheckNo"
                    >
                      No
                    </label>
                  </div>
                </div>
                <p className="text-danger">
                  {errors.isSwimmingDoorShutOff?.message}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="mb-2">
                <label className="brand-label text-capitalize">
                  diving boards?
                </label>
                <div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="isSwimmingDivingBoardYes"
                      disabled={!isSwimmingPoolValue?.includes("1")}
                      value="1"
                      {...register("isSwimmingDivingBoard")}
                    />
                    <label
                      className="form-check-label text-capitalize"
                      for="isSwimmingDivingBoardYes"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="isSwimmingDivingBoardNo"
                      disabled={!isSwimmingPoolValue?.includes("1")}
                      value="0"
                      {...register("isSwimmingDivingBoard")}
                    />
                    <label
                      className="form-check-label text-capitalize"
                      for="isSwimmingDivingBoardNo"
                    >
                      No
                    </label>
                  </div>
                </div>
                <p className="text-danger">
                  {errors.isSwimmingDivingBoard?.message}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="mb-2">
                <label className="brand-label text-capitalize">
                  Any hot tub?
                  <span className="required-mark">*</span>
                </label>
                <div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="isSwimmingHotTubYes"
                      value="1"
                      {...register("isSwimmingHotTub")}
                    />
                    <label
                      className="form-check-label text-capitalize"
                      for="isSwimmingHotTubYes"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="isSwimmingHotTubNo"
                      value="0"
                      {...register("isSwimmingHotTub")}
                    />
                    <label
                      className="form-check-label text-capitalize"
                      for="isSwimmingHotTubNo"
                    >
                      No
                    </label>
                  </div>
                </div>
                <p className="text-danger">
                  {errors.isSwimmingHotTub?.message}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="mb-2">
                <label className="brand-label text-capitalize">
                  Hot tub location?
                </label>
                <div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="isSwimmingHotTubLocationInsideCheck"
                      disabled={!isSwimmingHotTubValue?.includes("1")}
                      value="1"
                      {...register("isSwimmingHotTubLocation")}
                    />
                    <label
                      className="form-check-label text-capitalize"
                      for="isSwimmingHotTubLocationInsideCheck"
                    >
                      Inside
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="isSwimmingHotTubLocationOutsideCheck"
                      disabled={!isSwimmingHotTubValue?.includes("1")}
                      value="0"
                      {...register("isSwimmingHotTubLocation")}
                    />
                    <label
                      className="form-check-label text-capitalize"
                      for="isSwimmingHotTubLocationOutsideCheck"
                    >
                      Outside
                    </label>
                  </div>
                </div>
                <p className="text-danger">
                  {errors.isSwimmingHotTubLocation?.message}
                </p>
              </div>
            </div>

            <div className="col-md-6 col-lg-3">
              <div className="mb-2">
                <label className="brand-label text-capitalize">
                Swimming Pool Depth (in ft.)
                </label>
                <Controller
                  name="swimmingPoolDepth"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      {...field}
                      id="swimmingPoolDepth"
                      className="form-control"
                      thousandSeparator={true}
                      name="swimmingPoolDepth"
                      value={
                        isSwimmingPoolValue?.includes("1") ? field.value : ""
                      }
                      disabled={!isSwimmingPoolValue?.includes("1")}
                      onValueChange={(values) => {
                        setValue("swimmingPoolDepth", values.floatValue);
                      }}
                      placeholder="Enter Swimming Pool Depth"
                      {...register("swimmingPoolDepth")}
                    />
                  )}
                />
                <p className="text-danger">
                  {errors.swimmingPoolDepth?.message}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="mb-2">
                <label className="brand-label text-capitalize">
                  No. of Swimming pools?
                </label>
                <Controller
                  name="swimmingNoOfPools"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      {...field}
                      id="swimmingNoOfPools"
                      className="form-control"
                      thousandSeparator={true}
                      name="swimmingNoOfPools"
                      value={
                        isSwimmingPoolValue?.includes("1") ? field.value : ""
                      }
                      disabled={!isSwimmingPoolValue?.includes("1")}
                      onValueChange={(values) => {
                        setValue("swimmingNoOfPools", values.floatValue);
                      }}
                      placeholder="Enter No. of Swimming Pools"
                      readOnly={isView}
                      {...register("swimmingNoOfPools")}
                    />
                  )}
                />
                <p className="text-danger">
                  {errors.swimmingNoOfPools?.message}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="mb-2">
                <label className="brand-label text-capitalize">
                  No. of hot tubs?
                </label>
                <Controller
                  name="swimmingNoOfHotTubs"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      {...field}
                      id="swimmingNoOfHotTubs"
                      className="form-control"
                      thousandSeparator={true}
                      name="swimmingNoOfHotTubs"
                      value={
                        isSwimmingHotTubValue?.includes("1") ? field.value : ""
                      }
                      disabled={!isSwimmingHotTubValue?.includes("1")}
                      onValueChange={(values) => {
                        setValue("swimmingNoOfHotTubs", values.floatValue);
                      }}
                      placeholder="Enter No. of Hot Tubs"
                      readOnly={isView}
                      {...register("swimmingNoOfHotTubs")}
                    />
                  )}
                />
                <p className="text-danger">
                  {errors.swimmingNoOfHotTubs?.message}
                </p>
              </div>
            </div>

            <div className="col-md-6 col-lg-3">
              <div className="mb-2">
                <label className="brand-label text-capitalize">
                  bar or dance floor?
                  <span className="required-mark">*</span>
                </label>
                <div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="isBarDanceFloorCheckYes"
                      value="1"
                      {...register("isBarDanceFloor")}
                    />
                    <label
                      className="form-check-label text-capitalize"
                      for="isBarDanceFloorCheckYes"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="isBarDanceFloorCheckNo"
                      value="0"
                      {...register("isBarDanceFloor")}
                    />
                    <label
                      className="form-check-label text-capitalize"
                      for="isBarDanceFloorCheckNo"
                    >
                      No
                    </label>
                  </div>
                </div>
                <p className="text-danger">{errors.isBarDanceFloor?.message}</p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="mb-2">
                <label className="brand-label text-capitalize">
                  Any happy hours?
                </label>
                <div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="isHappyHoursYes"
                      disabled={!isBarDanceFloorValue?.includes("1")}
                      value="1"
                      {...register("isHappyHours")}
                    />
                    <label
                      className="form-check-label text-capitalize"
                      for="isHappyHoursYes"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="isHappyHoursNo"
                      disabled={!isBarDanceFloorValue?.includes("1")}
                      value="0"
                      {...register("isHappyHours")}
                    />
                    <label
                      className="form-check-label text-capitalize"
                      for="isHappyHoursNo"
                    >
                      No
                    </label>
                  </div>
                </div>
                <p className="text-danger">{errors.isHappyHours?.message}</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-3">
              <div className="mb-3">
                <label className="brand-label">Age group?</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Enter Age Group"
                  disabled={!isBarDanceFloorValue?.includes("1")}
                  {...register("ageGroup")}
                />
                <p className="text-danger">
                  {errors.ageGroup && <p>{errors.ageGroup.message}</p>}
                </p>
              </div>
            </div>

            <div className="col-md-6 col-lg-6">
              <div className="mb-3">
                <label className="brand-label">
                  <div className="d-flex">
                    Bar/Dance Hours &nbsp;
                    <div>
                      <Badge bg="info">(Start and End Time)</Badge>
                    </div>
                  </div>
                </label>

                <BusinessHour
                  onTimeRangeChange={getBarDanceTimeRange}
                  timeRange={barDanceHours}
                  disabled={!isBarDanceFloorValue?.includes("1")}
                />

                <p className="text-danger">
                  {errors.barDanceHours && (
                    <p>{errors.barDanceHours.message}</p>
                  )}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="mb-2">
                <label className="brand-label text-capitalize">
                  Any Restaurant?<span className="required-mark">*</span>
                </label>
                <div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="isRestaurantYes"
                      value="1"
                      {...register("isRestaurant")}
                    />
                    <label
                      className="form-check-label text-capitalize"
                      for="isRestaurantYes"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="isRestaurantNo"
                      value="0"
                      {...register("isRestaurant")}
                    />
                    <label
                      className="form-check-label text-capitalize"
                      for="isRestaurantNo"
                    >
                      No
                    </label>
                  </div>
                </div>
                <p className="text-danger">{errors.isRestaurant?.message}</p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="mb-2">
                <label className="brand-label text-capitalize">
                  Seating capacity?
                </label>
                <Controller
                  name="seatingCapacity"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      {...field}
                      id="seatingCapacity"
                      className="form-control"
                      thousandSeparator={true}
                      name="seatingCapacity"
                      value={
                        isRestaurantValue?.includes("1") ? field.value : ""
                      }
                      disabled={!isRestaurantValue?.includes("1")}
                      onValueChange={(values) => {
                        setValue("seatingCapacity", values.floatValue);
                      }}
                      placeholder="Enter No. of Seating Capacity"
                      {...register("seatingCapacity")}
                    />
                  )}
                />
                <p className="text-danger">{errors.seatingCapacity?.message}</p>
              </div>
            </div>
            <div className="col-md-6 col-lg-6">
              <div className="mb-3">
                <label className="brand-label">
                  <div className="d-flex">
                    Restaurant Hours&nbsp;
                    <div>
                      <Badge bg="info">(Start and End Time)</Badge>
                    </div>
                  </div>
                </label>
                <BusinessHour
                  onTimeRangeChange={getHrOfOpTimeRange}
                  disabled={!isRestaurantValue?.includes("1")}
                  timeRange={restaurantHours}
                />

                <p className="text-danger">
                  {errors.restaurantHours && (
                    <p>{errors.restaurantHours.message}</p>
                  )}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="mb-2">
                <label className="brand-label text-capitalize">
                  No. of emergencies exits?
                </label>
                <Controller
                  name="noOfEmergencyExits"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      {...field}
                      id="noOfEmergencyExits"
                      className="form-control"
                      thousandSeparator={true}
                      name="noOfEmergencyExits"
                      onValueChange={(values) => {
                        setValue("noOfEmergencyExits", values.floatValue);
                      }}
                      placeholder="Enter No. of Emergencies Exits"
                      {...register("noOfEmergencyExits")}
                    />
                  )}
                />
                <p className="text-danger">
                  {errors.noOfEmergencyExits?.message}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="mb-2">
                <label className="brand-label">
                  Crime Coverage Limit?
                  <span className="required-mark">*</span>
                </label>
                <Controller
                  name="crimeCoverageLimit"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      {...field}
                      id="crimeCoverageLimit"
                      className="form-control"
                      thousandSeparator={true}
                      prefix="$"
                      onValueChange={(values) => {
                        setValue("crimeCoverageLimit", values.floatValue);
                      }}
                      placeholder="Enter Coverage Limit"
                      {...register("crimeCoverageLimit")}
                    />
                  )}
                />
                <p className="text-danger">
                  {errors.crimeCoverageLimit?.message}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="mb-2">
                <label className="brand-label text-capitalize">
                  No. of managers per shift?
                  <span className="required-mark">*</span>
                </label>
                <Controller
                  name="noOfManagersPerShift"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      {...field}
                      id="noOfManagersPerShift"
                      className="form-control"
                      thousandSeparator={true}
                      prefix="$"
                      onValueChange={(values) => {
                        setValue("noOfManagersPerShift", values.floatValue);
                      }}
                      placeholder="Enter No. of Managers Per Shift"
                      {...register("noOfManagersPerShift")}
                    />
                  )}
                />
                <p className="text-danger">
                  {errors.noOfManagersPerShift?.message}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-6">
              <div className="mb-3">
                <label className="brand-label">
                  <div className="d-flex text-capitalize">
                    Managers checkin & checkout time
                    <span className="required-mark">*</span>&nbsp;
                    <div>
                      <Badge bg="info">(Start and End Time)</Badge>
                    </div>
                  </div>
                </label>

                <BusinessHour
                  onTimeRangeChange={getHrOfmanagersCheckinCheckout}
                  timeRange={managersCheckinCheckout}
                />

                <p className="text-danger">
                  {errors.managersCheckinCheckout && (
                    <p>{errors.managersCheckinCheckout.message}</p>
                  )}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="mb-3">
                <label className="brand-label text-capitalize">
                  Last Roof Updated?
                </label>
                <input
                  id="lastRoofUpdated"
                  type="date"
                  max="2099-12-31"
                  className="form-control"
                  placeholder="Enter Last Roof Update"
                  {...register("lastRoofUpdated")}
                />
                <p className="text-danger">
                  {errors.lastRoofUpdated && (
                    <p>{errors.lastRoofUpdated.message}</p>
                  )}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="mb-3">
                <label className="brand-label text-capitalize">
                  Last Wiring Updated?
                </label>
                <input
                  id="lastWiringUpdated"
                  type="date"
                  max="2099-12-31"
                  className="form-control"
                  placeholder="Enter Last Wiring Update"
                  {...register("lastWiringUpdated")}
                />
                <p className="text-danger">
                  {errors.lastWiringUpdated && (
                    <p>{errors.lastWiringUpdated.message}</p>
                  )}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="mb-3">
                <label className="brand-label text-capitalize">
                  Last Plumbing Updated?
                </label>
                <input
                  type="date"
                  max="2099-12-31"
                  className="form-control"
                  placeholder="Enter Last Plumbing Update"
                  id="lastPlumbingUpdated"
                  {...register("lastPlumbingUpdated")}
                />
                <p className="text-danger">
                  {errors.lastPlumbingUpdated && (
                    <p>{errors.lastPlumbingUpdated.message}</p>
                  )}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3">
              <div className="mb-3">
                <label className="brand-label text-capitalize">
                  Last Heating Updated?
                </label>
                <input
                  type="date"
                  max="2099-12-31"
                  className="form-control"
                  placeholder="Enter Last Heating Update"
                  id="lastHeatingUpdated"
                  name="lastHeatingUpdated"
                  {...register("lastHeatingUpdated")}
                />
                <p className="text-danger">
                  {errors.lastHeatingUpdated && (
                    <p>{errors.lastHeatingUpdated.message}</p>
                  )}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="mb-2">
                <label className="brand-label text-capitalize">
                  Room rent out basis?<span className="required-mark">*</span>
                </label>
                <div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="isRoomRentOutBasisYes"
                      value="1"
                      {...register("isRoomRentOutBasis")}
                    />
                    <label
                      className="form-check-label text-capitalize"
                      for="isRoomRentOutBasisYes"
                    >
                      per day
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="isRoomRentOutBasisNo"
                      value="0"
                      {...register("isRoomRentOutBasis")}
                    />
                    <label
                      className="form-check-label text-capitalize"
                      for="isRoomRentOutBasisNo"
                    >
                      monthly
                    </label>
                  </div>
                </div>
                <p className="text-danger">
                  {errors.isRoomRentOutBasis?.message}
                </p>
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="mb-2">
                <label className="brand-label text-capitalize">
                  Shuttle Service?<span className="required-mark">*</span>
                </label>
                <div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="isShuttleServiceYes"
                      value="1"
                      {...register("isShuttleService")}
                    />
                    <label
                      className="form-check-label text-capitalize"
                      for="isShuttleServiceYes"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="isShuttleServiceNo"
                      value="0"
                      {...register("isShuttleService")}
                    />
                    <label
                      className="form-check-label text-capitalize"
                      for="isShuttleServiceNo"
                    >
                      No
                    </label>
                  </div>
                </div>
                <p className="text-danger">
                  {errors.isShuttleService?.message}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="mb-2">
                <label className="brand-label text-capitalize">
                  Rooms with kitchen?
                  <span className="required-mark">*</span>
                </label>
                <div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="isRoomWithKitchenYes"
                      value="1"
                      {...register("isRoomWithKitchen")}
                    />
                    <label
                      className="form-check-label text-capitalize"
                      for="isRoomWithKitchenYes"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="isRoomWithKitchenNo"
                      value="0"
                      {...register("isRoomWithKitchen")}
                    />
                    <label
                      className="form-check-label text-capitalize"
                      for="isRoomWithKitchenNo"
                    >
                      No
                    </label>
                  </div>
                </div>
                <p className="text-danger">
                  {errors.isRoomWithKitchen?.message}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="mb-2">
                <label className="brand-label text-capitalize">
                  Playground?
                  <span className="required-mark">*</span>
                </label>
                <div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="isPlayGroundYes"
                      value="1"
                      {...register("isPlayGround")}
                    />
                    <label
                      className="form-check-label text-capitalize"
                      for="isPlayGroundyes"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="isPlayGroundNo"
                      value="0"
                      {...register("isPlayGround")}
                    />
                    <label
                      className="form-check-label text-capitalize"
                      for="isPlayGroundNo"
                    >
                      No
                    </label>
                  </div>
                </div>
                <p className="text-danger">{errors.isPlayGround?.message}</p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="mb-2">
                <label className="brand-label text-capitalize">
                  entertainment rooms?
                  <span className="required-mark">*</span>
                </label>
                <div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="isEntertainmentRoomYes"
                      value="1"
                      {...register("isEntertainmentRoom")}
                    />
                    <label
                      className="form-check-label text-capitalize"
                      for="isEntertainmentRoomYes"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="isEntertainmentRoomNo"
                      value="0"
                      {...register("isEntertainmentRoom")}
                    />
                    <label
                      className="form-check-label text-capitalize"
                      for="isEntertainmentRoomNo"
                    >
                      No
                    </label>
                  </div>
                </div>
                <p className="text-danger">
                  {errors.isEntertainmentRoom?.message}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="mb-2">
                <label className="brand-label text-capitalize">
                  Need Cyber Liability coverage?
                </label>
                <div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="isCyberLiabilityCoverageYes"
                      value="1"
                      {...register("isCyberLiabilityCoverage")}
                    />
                    <label
                      className="form-check-label text-capitalize"
                      for="isCyberLiabilityCoverageYes"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="isCyberLiabilityCoverageNo"
                      value="0"
                      {...register("isCyberLiabilityCoverage")}
                    />
                    <label
                      className="form-check-label text-capitalize"
                      for="isCyberLiabilityCoverageNo"
                    >
                      No
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="mb-2">
                <label className="brand-label text-capitalize">
                  subsidized rates(students/elder)?
                  <span className="required-mark">*</span>
                </label>
                <div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="isSubsidizedRateYes"
                      value="1"
                      {...register("isSubsidizedRate")}
                    />
                    <label
                      className="form-check-label text-capitalize"
                      for="isSubsidizedRateYes"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="isSubsidizedRateNo"
                      value="0"
                      {...register("isSubsidizedRate")}
                    />
                    <label
                      className="form-check-label text-capitalize"
                      for="isSubsidizedRateNo"
                    >
                      No
                    </label>
                  </div>
                </div>
                <p className="text-danger">
                  {errors.isSubsidizedRate?.message}
                </p>
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="mb-2">
                <label className="brand-label text-capitalize">
                  How many rooms?
                  <span className="required-mark">*</span>
                </label>
                <Controller
                  name="howManySubRooms"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      {...field}
                      id="howManySubRooms"
                      className="form-control"
                      thousandSeparator={true}
                      onValueChange={(values) => {
                        setValue("howManySubRooms", values.floatValue);
                      }}
                      placeholder="Enter No. of Rooms"
                      {...register("howManySubRooms")}
                    />
                  )}
                />
                <p className="text-danger">{errors.howManySubRooms?.message}</p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="mb-2">
                <label className="brand-label text-capitalize">
                  average occupancy rate?
                </label>
                <Controller
                  name="averageOccupancyRate"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <NumericFormat
                      allowNegative={false}
                      {...field}
                      thousandSeparator={true}
                      prefix="$"
                      id="averageOccupancyRate"
                      className="form-control"
                      onValueChange={(values) => {
                        setValue("averageOccupancyRate", values.floatValue);
                      }}
                      placeholder="Enter Average Occupancy Rate"
                      {...register("averageOccupancyRate")}
                    />
                  )}
                />
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="mb-2">
                <label className="brand-label text-capitalize">
                  Construction Type?<span className="required-mark">*</span>
                </label>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="frameCheck"
                        value="Frame"
                        {...register("constructionType")}
                      />
                      <label
                        className="form-check-label text-capitalize"
                        for="frameCheck"
                      >
                        Frame
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="mNCCheck"
                        value="MNC"
                        {...register("constructionType")}
                      />
                      <label
                        className="form-check-label text-capitalize"
                        for="mNCCheck"
                      >
                        MNC
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-check ">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="nCCheck"
                        value="NC"
                        {...register("constructionType")}
                      />
                      <label
                        className="form-check-label text-capitalize"
                        for="nCCheck"
                      >
                        NC
                      </label>
                    </div>
                    <div className="form-check ">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="jMCheck"
                        value="JM"
                        {...register("constructionType")}
                      />
                      <label
                        className="form-check-label text-capitalize"
                        for="jMCheck"
                      >
                        JM
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-check ">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="othersconstructionTypeCheck"
                        value="Others"
                        {...register("constructionType")}
                      />
                      <label
                        className="form-check-label text-capitalize"
                        for="othersconstructionTypeCheck"
                      >
                        Others
                      </label>
                    </div>
                  </div>
                </div>

                <p className="text-danger">
                  {errors.constructionType?.message}
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-6">
              <div className="mb-2">
                <label className="brand-label">Protective Safeguard?</label>
                <span className="required-mark">*</span>
                <div className="row">
                  <div className="col-md-6 col-lg-6">
                    <div className="form-check ">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="cctvCheck"
                        value="CCTV Camera"
                        disabled={isView}
                        {...register("protectiveSafeguard")}
                      />
                      <label
                        className="form-check-label text-capitalize"
                        for="cctvCheck"
                      >
                        CCTV Camera
                      </label>
                    </div>
                    <div className="form-check ">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="fireAlarmCheck"
                        value="Fire Alarm"
                        disabled={isView}
                        {...register("protectiveSafeguard")}
                      />
                      <label
                        className="form-check-label text-capitalize"
                        for="fireAlarmCheck"
                      >
                        Fire Alarm
                      </label>
                    </div>
                    <div className="form-check ">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="burglarAlarmCheck"
                        value="Burglar Alarm"
                        disabled={isView}
                        {...register("protectiveSafeguard")}
                      />
                      <label
                        className="form-check-label text-capitalize"
                        for="burglarAlarmCheck"
                      >
                        Burglar Alarm
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="centralStationCheck"
                        value="Central Station"
                        disabled={isView}
                        {...register("protectiveSafeguard")}
                      />
                      <label
                        className="form-check-label text-capitalize"
                        for="centralStationCheck"
                      >
                        Central Station
                      </label>
                    </div>
                    <div className="form-check ">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="smokeDetectionCheck"
                        value="Smoke Detector"
                        disabled={isView}
                        {...register("protectiveSafeguard")}
                      />
                      <label
                        className="form-check-label text-capitalize"
                        for="smokeDetectionCheck"
                      >
                        Smoke Detector
                      </label>
                    </div>
                    <div className="form-check ">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="sprinklerCheck"
                        value="Sprinkler System "
                        disabled={isView}
                        {...register("protectiveSafeguard")}
                      />
                      <label
                        className="form-check-label text-capitalize"
                        for="sprinklerCheck"
                      >
                        Sprinkler System
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6">
                    <div className="form-check ">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="protactiveOthersCheck"
                        value="Others"
                        {...register("protectiveSafeguard")}
                      />
                      <label
                        className="form-check-label text-capitalize"
                        for="protactiveOthersCheck"
                      >
                        Others
                      </label>
                    </div>
                  </div>
                </div>
                <p className="text-danger">
                  {errors.protectiveSafeguard?.message}
                </p>
              </div>
            </div>
          </div>
        </section>

        <div className="gap-4">
          <div className="gap-4 text-end">
        {restaurantId && (
                <button
                  type="button"
                  onClick={()=>handleExitButton('Hotel Commercial Application')}
                  className="btn btn-danger ms-2 text-capitalize"
                >
                  Exit if no changes required
                </button>
              )}
            <button
              className="btn btn-warning ms-2"
              type="button"
              onClick={handlePrevious}
            >
              Previous
            </button>
            <button className="btn btn-primary ms-2">Next</button>
          </div>
        </div>
      </form>
    </>
  );
};

export default Step2;
