import axios from "axios";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { updateData } from "../redux/action/someDataAction";

const DEV_AGENCY_URL = process.env.REACT_APP_ANC_API + "/api/Agency";

export function showDateFormat(date) {
  if (date) {
    const newDate = new Date(date);
    return newDate.toLocaleDateString("en-US", {
      year: "numeric",
      month: "numeric" /*long*/,
      day: "numeric",
    });
  }
  return "";
}

export function handleNotifications(msg = "Success", type = "success") {
  if (type === "error") {
    toast.error(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "dark",
      progress: undefined,
    });
  } else if (type === "success") {
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  } else {
    toast.warning(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  }
}

export function formattedDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

export function getAllDataTogether(agencyId = null, agentId = null, dispatch) {
  let AgencyId = localStorage.getItem("AgencyId");
  let AId = agencyId ?? AgencyId;
  const url = agentId == null ? `${DEV_AGENCY_URL}/getAgencyWithProductInfo/${AId}` : `${DEV_AGENCY_URL}/getAgencyWithProductInfo/${AId}/${agentId}`;
  axios
    .get(url, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_ANC_API_HEADER_KEY}`, // Add the "Authorization" header
      },
    })
    .then((response) => {
      if (response.status === 200) {
        // updateData(response.data)
        dispatch(updateData(response.data));
      }
    })
    .catch((error) => {
      // handleNotifications("Something is wrong with get products! ", 'error');
      if (error?.response?.data?.status === 404) {
      } else {
      }
    });
}

export function formatTelNumber(input) {
  const cleaned = ("" + input).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  } else {
    return cleaned;
  }
}

export const formatText = (text) => {
  return text.replace(/_/g, " ");
};

export const convertChartData = (data, xLabel = "label", yLabel = "y", keyType = "month") => {
  return Object.entries(data).map(([key, value]) => ({
    [xLabel]: keyType === "month" ? key.charAt(0).toUpperCase() + key.slice(1) : key,
    [yLabel]: value,
  }));
};

export const addColorsToChartData = (data, type) => {
  const colors = ["#3cb44b", "#e6194B", "#ffe119", "#4363d8", "#f58231", "#911eb4", "#42d4f4", "#f032e6", "#bfef45"];

  data.forEach((item, index) => {
    if (type === "pie") item.color = colors[index];
    if (type === "column") item.color = colors[0];
  });

  return data;
};
