import React, { useEffect, useState } from "react";
import { formattedDate, showDateFormat } from "../helper/Helper";
import { useLocation } from "react-router-dom";
import axios from "axios";

const DEVURL = process.env.REACT_APP_ANC_API + "/api";

const CustomerDetails = ({ details, type }) => {
  const query = new URLSearchParams(useLocation().search);
  const tab = query.get("tab");
  const [commentList, setCommentList] = useState([]);
  console.log("details", details);
  useEffect(() => {
    if (details) {
      axios
        .get(`${DEVURL}/ControlPanel/GetCommentsInfo/${details.id}`)
        .then((response) => {
          if (response.status === 200) {
            setCommentList(response.data);
            console.log(response.data);
          }
        })
        .catch((error) => {
          if (error?.response?.data?.status === 404) {
          } else {
          }
        });
    }
  }, [details]);

  return (
    <div className="offcanvas offcanvas-end cust-width" tabIndex="-1" id="customerDetail" aria-labelledby="offcanvasRightLabel">
      <div className="offcanvas-header activity-status-table brand-offcanvas-header">
        <h5 id="offcanvasRightLabel">Customer Information</h5>
        <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div className="offcanvas-body">
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className={`nav-link brand-tab ${tab != 2 ? "active" : ""}`}
              id="details-tab"
              data-bs-toggle="tab"
              data-bs-target="#details"
              type="button"
              role="tab"
              aria-controls="details"
              aria-selected="true"
            >
              Details
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className={`nav-link brand-tab ${tab == 2 ? "active" : ""}`}
              id="comments-tab"
              data-bs-toggle="tab"
              data-bs-target="#comments"
              type="button"
              role="tab"
              aria-controls="comments"
              aria-selected="false"
            >
              Comments
            </button>
          </li>
        </ul>
        <div className="tab-content" id="myTabContent">
          <div className={`tab-pane fade ${tab != 2 ? "show active" : ""}`} id="details" role="tabpanel" aria-labelledby="details-tab">
            {type === "HealthCare" && (
              <div className="activity-cust-data">
                <ul className="list-group">
                  <li className="list-group-item d-flex justify-content-left align-items-left">
                    <h6 className="mb-0">Address:&nbsp;</h6>
                    <p className="mb-0">
                      {details?.address} {details?.city}
                      {details?.zipCode} {details?.state}
                    </p>
                  </li>
                </ul>
              </div>
            )}
            <div className="activity-cust-data">
              <div className="data-box mt-4">
                <div className="d-flex align-items-center mb-4">
                  <h6>Name</h6>&nbsp;:&nbsp;<span>{details?.name}</span>
                </div>
                <div className="d-flex align-items-center mb-4">
                  <h6>Email</h6>&nbsp;:&nbsp;<span>{details?.email}</span>
                </div>
                <div className="d-flex align-items-center mb-4">
                  <h6>Mobile</h6>&nbsp;:&nbsp;<span>{details?.mobile}</span>
                </div>
                <div className="d-flex align-items-center mb-4">
                  <h6>Date Of Birth</h6>&nbsp;:&nbsp;<span>{showDateFormat(details?.dateOfBirth)}</span>
                </div>
                <div className="d-flex align-items-center mb-4">
                  <h6>Zip Code</h6>&nbsp;:&nbsp;<span>{details?.zipcode}</span>
                </div>
                <div className="d-flex align-items-center mb-4">
                  <h6>Gender</h6>&nbsp;:&nbsp;<span>{details?.gender}</span>
                </div>
                <div className="d-flex align-items-center mb-4">
                  <h6>Income</h6>&nbsp;:&nbsp;<span>{details?.income}</span>
                </div>

                <div className="activity-status-table">
                  <div className="table-responsive">
                    {type === "HealthCare" && (
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col">Full Name</th>
                            <th scope="col">Gender</th>
                            <th scope="col">Date Of Birth</th>
                            <th scope="col">Immigration Status</th>
                          </tr>
                        </thead>
                        <tbody id="body-render">
                          {details?.dependents?.map((dependent, index) => (
                            <tr key={index}>
                              <td>{dependent.firstName}</td>
                              <td>{dependent.gender}</td>
                              <td>{showDateFormat(dependent.dateOfBirth)}</td>
                              <td>{dependent.immigratonStatus}</td>
                            </tr>
                          ))}
                          {details?.dependents?.length === 0 && (
                            <tr>
                              <td colSpan="4" className="text-center">
                                No dependents found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    )}
                    {type === "Leads" && (
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col">Age</th>
                            <th scope="col">Gender</th>
                            <th scope="col">Tobacco</th>
                          </tr>
                        </thead>
                        <tbody id="body-render">
                          {details?.dependent &&
                            JSON.parse(details.dependent)?.map((dependent, index) => (
                              <tr key={index}>
                                <td>{dependent.Age}</td>
                                <td>{dependent.Gender}</td>
                                <td>{dependent.Tobacco}</td>
                              </tr>
                            ))}
                          {details?.dependents?.length === 0 && (
                            <tr>
                              <td colSpan="4" className="text-center">
                                No dependents found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`tab-pane fade ${tab != 2 ? "show active" : ""}`} id="comments" role="tabpanel" aria-labelledby="comments-tab">
            <div className="data-box mt-4">
              <div className="activity-status-table">
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Comment</th>
                        <th scope="col">Added by</th>
                        <th scope="col">Date</th>
                      </tr>
                    </thead>
                    <tbody id="body-render">
                      {commentList.length ?
                        commentList?.map((comment, index) => (
                          <tr key={index}>
                            <td>{comment.commentText}</td>
                            <td>{comment.agentId == null ? comment.agencyName : comment.agentName}</td>
                            <td>{formattedDate(comment.createdAt)}</td>
                          </tr>
                        )):null}
                      {commentList?.length === 0 && (
                        <tr>
                          <td colSpan="4" className="text-center">
                            No dependents found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerDetails;
