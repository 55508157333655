import React, { useEffect } from "react";
import Badge from "react-bootstrap/Badge";
import Card from "react-bootstrap/Card";
import { useLocation, useParams } from "react-router-dom";
import AgencyHeader from "./Agencyheader";
import Footer from "./ancfooter";
import { IconCheck } from "@tabler/icons-react";
import { IconDiscountCheck } from "@tabler/icons-react";
import { IconCircleCheck } from "@tabler/icons-react";

const ExitResponse = () => {
  const { agencyName, agentName } = useParams();
  const {state} = useLocation();
  const [getAgencyData, setAgencyData] = React.useState(null);
  const query = new URLSearchParams(useLocation().search);
  const title = query.get("title");
  function closeTab() {
    window.open("", "_self", "");
    window.close();
  }
  console.log('state',state)
  useEffect(() => {
    if (localStorage.key("agency_data") !== null) {
      const agencyData = JSON.parse(localStorage.getItem("agency_data"));
      setAgencyData(agencyData);
    }
  }, []);
  const getSmartLinkURL = agentName!=null ? `/smartLink/${agencyName}/${agentName}` :`/smartLink/${agencyName}` 
  return (
    <>
      <div>
        <AgencyHeader shouldShowEmail={true}/>
        <div className="container">
          <div className="main-wrap">
            <div className="wizard-content">
              <h2>{title}</h2>
              <p align="center">
                <Card>
                  <Card.Body>
                    <div>
                      <IconCircleCheck color="#42cc0c" size={200} />
                    </div>
                    <Card.Title>
                      <h4>You are all set!</h4>
                    </Card.Title>
                    <Card.Text>
                     
                      Want to create another form? Go to <a className=" btn-link" href={getSmartLinkURL}>Smart Link</a> 
                    </Card.Text>
                  </Card.Body>
                </Card>
              </p>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};
export default ExitResponse;
